import { Dropdown, UserIntersect } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useStoreState } from '../../../store'
import { Link } from '../../UI/Link'
import { Avatar } from '../Avatar'
import { BackBtn } from '../Back.btn/Back.btn'
import { LogoFull } from '../Branding'
import { GiveKudosBtn } from '../Give.kudos.btn'
import { InviteIconBtn } from '../Invite.user.btn/Invite.icon.btn'
import { NotificationsBtn } from '../Notifications.btn'
import { ShareBtn } from '../Share.btn'
import { UserMenu } from '../UserMenu/UserMenu'

export const appHeaderStyles = css`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	background: ${props => props.theme.colors.white.val};
	border-bottom: 2px solid ${props => props.theme.colors.lavender.val};
	padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
	min-height: ${props => props.theme.sizes.header.mobile.val};
	max-height: ${props => props.theme.sizes.header.mobile.val};
	position: fixed;
	z-index: 10;
	width: 100%;

	${props => props.theme.media.tablet} {
		padding: 0 ${props => props.theme.sizes.gutter.tablet.val};
		display: flex;
		align-items: center;
	}

	${props => props.theme.media.sdesk} {
		padding: 0 ${props => props.theme.sizes.gutter.sdesk.val};
		position: relative;
	}

	.merify-branding {
		display: none;

		${props => props.theme.media.tablet} {
			display: flex;
			align-items: center;
			margin-right: auto;
		}
	}
	.user-profile-ctrl-desk {
		display: none;
		${props => props.theme.media.tablet} {
			display: flex;
			align-items: center;
			margin-left: 10px;
		}
	}

	.col {
		display: flex;
		align-items: center;
		.col-item {
			i {
				color: ${props => props.theme.colors.secondary.val};
			}
		}
	}

	.col-1 {
		justify-content: flex-start;

		${props => props.theme.media.tablet} {
			display: none;
		}

		.col-item {
			margin-right: 10px;
		}
	}
	.col-2 {
		justify-content: flex-end;
		.col-item {
			margin-left: 10px;
		}
	}

	.dropdown {
		.dropdown-ctrl-group {
			right: auto;
			left: -20px;
			bottom: -9px;
			${props => props.theme.media.tablet} {
				right: 0;
				left: auto;
			}
		}
	}
`

const AppHeaderView = styled.header`
	${appHeaderStyles}
`

export type AppHeaderColType =
	| 'notifications'
	| 'avatar'
	| 'logo'
	| 'back'
	| 'share'
	| 'invite'
	| 'kudos'
	| null

type ColProps = { col: string | null }

const Col = ({ col }: ColProps): JSX.Element => {
	const user = useStoreState(state => state.candidate.user)
	return (
		<div className='col-item'>
			{col === 'notifications' ? (
				<NotificationsBtn />
			) : col === 'invite' ? (
				<InviteIconBtn />
			) : col === 'avatar' ? (
				// @ts-ignore MIGRATION
				<Dropdown ctrl={<Avatar user={user as UserIntersect} />}>
					<UserMenu />
				</Dropdown>
			) : col === 'logo' ? (
				<LogoFull />
			) : col === 'back' ? (
				<BackBtn />
			) : col === 'share' ? (
				<ShareBtn />
			) : col === 'kudos' ? (
				<GiveKudosBtn />
			) : null}
		</div>
	)
}

export type AppHeaderProps = {}
export const AppHeader = (): JSX.Element => {
	const header = useStoreState(state => state.view.header)
	const user = useStoreState(state => state.candidate.user)

	return (
		<AppHeaderView className='app-header'>
			<Link to='/' className='merify-branding'>
				<LogoFull />
			</Link>
			<div className='col col-1'>
				{header.col1.map((col, idx) => (
					<Col col={col} key={idx} />
				))}
			</div>
			<div className='col col-2'>
				{header.col2.map((col, idx) => (
					<Col col={col} key={idx} />
				))}
				<Dropdown
					className='user-profile-ctrl-desk'
					// @ts-ignore MIGRATION
					ctrl={<Avatar user={user as UserIntersect} />}
					closeOnContentClick
				>
					<UserMenu />
				</Dropdown>
			</div>
		</AppHeaderView>
	)
}
