import { MerifyMSvg, Wizard } from '@merify/ui'
import styled, { css } from 'styled-components'
import { WelcomeScreenStepOne } from './Welcome.screen.step.1'
import { WelcomeScreenStepTwo } from './Welcome.screen.step.2'
import { WelcomeScreenStepThree } from './Welcome.screen.step.3'

export const welcomeScreenStyles = css`
	background: ${props => props.theme.colors.paleGrey.val};

	.bg {
		z-index: 0;
		display: block;
		width: 100vw;
		height: 100vh;
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		${props => props.theme.media.sdesk} {
		}
		.merify-m {
			width: 200%;
			transform: translate(-7%, 10%);
			height: auto;
			margin: 0 auto;
			color: ${props => props.theme.colors.white.val};
			position: absolute;
			opacity: 0.75;
			${props => props.theme.media.tablet} {
				width: 160%;
			}
			${props => props.theme.media.sdesk} {
				width: 120%;
			}
		}
	}
	.step {
		.cta-wrap {
			//position: fixed;
			button {
				${props => props.theme.media.sdesk} {
					&:hover {
					}
				}
			}
		}
	}
`

const WelcomeScreenView = styled.div`
	${welcomeScreenStyles}
`

type WelcomeScreenProps = {}
export const WelcomeScreen = (_: WelcomeScreenProps): JSX.Element => {
	return (
		<WelcomeScreenView>
			<div className='bg'>
				<MerifyMSvg />
			</div>
			<Wizard>
				<WelcomeScreenStepOne />
				<WelcomeScreenStepTwo />
				<WelcomeScreenStepThree />
			</Wizard>
		</WelcomeScreenView>
	)
}
