import { P } from '@merify/ui'
import styled, { css } from 'styled-components'

export const ReviewsGuideStyles = css`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	.step-section {
		flex: 1;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		padding: 5px 20px;

		.step-number {
			display: flex;
			align-items: center;
			justify-content: center;
			color: ${props => props.theme.colors.white.val};
			font-size: 1.5rem;
			font-weight: 600;
			padding: 10px;
			margin-right: 10px;
			border-radius: 50%;
			min-width: 40px;
			max-width: 40px;
			min-height: 40px;
			max-height: 40px;
			border: 2px solid ${props => props.theme.colors.black.val};
			background: ${props => props.theme.colors.primary.val};
		}
	}
`

const ReviewsGuideView = styled.div`
	${ReviewsGuideStyles}
`

const copy = [
	`All feedback is completely anonymous`,
	`Honest feedback helps your colleague's career the most`,
	`Unfairly positive or negative reviews are detected and automatically filtered out`,
	`Use "Skip" if you have not seen the skill enough to comfortably assess`,
]

export const ReviewsGuide = (): JSX.Element => {
	return (
		<ReviewsGuideView className='reviews-guide'>
			{copy.map((text, idx) => (
				<div className='step-section' key={idx}>
					<div className='step-number'>{idx + 1}</div>
					<P>{text}</P>
				</div>
			))}
		</ReviewsGuideView>
	)
}
