import {
	Async,
	Btn,
	CandidateDemographicsName,
	Card,
	Heading,
	reduce,
	Text,
	useModalCtrl,
} from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetUserDemographic } from '../../../../api/api.candidate'
import { TutDemographicInfo } from '../../../global/Tut.demographicInfo'
import { CircleQuestionMark } from '../../../UI/CircleQuestionMark'
import { ModalFull } from '../../../UI/Modal'

export const candidateDemographicsStyles = css`
	.demographic-info {
		.value {
			font-size: 1.33rem;
		}
		h3 {
			color: ${props => props.theme.colors.secondary.val};
			font-size: 1.3rem;
			display: flex;
			align-items: center;
			padding: ${props => props.theme.sizes.gutter.mobile.val};
			padding-top: 0;
			button {
				margin-left: 10px;
			}
		}
		.card-inner {
			background: ${props => props.theme.colors.lavender.opacity(25).val};
			.row {
				&:first-of-type {
					margin-top: 0;
				}
			}
		}
	}
`
const CandidateDemographicsView = styled.div`
	${candidateDemographicsStyles}
`

type CandidateDemographicsProps = {
	candidateId: string
}

type DemographicObj = {
	value: string
	label: string
}

const genLabelFromKey = (key: CandidateDemographicsName) =>
	key === 'ethnicityOptionName'
		? 'Ethnicity'
		: key === 'genderIdentityOptionName'
		? 'Gender'
		: key === 'sexualOrientationOptionName'
		? 'Sexual Orientation'
		: key === 'disabilityOptionName'
		? 'Disability'
		: key === 'veteranStatusOptionName'
		? 'Veteran Status'
		: ''

export const CandidateDemographics = ({ candidateId }: CandidateDemographicsProps): JSX.Element => {
	const results = useGetUserDemographic()
	const [[showModal, openModal, closeModal]] = useModalCtrl()

	const { data } = results

	const demographics = reduce(
		data,
		(arry, val, key) => {
			const label = genLabelFromKey(key as CandidateDemographicsName)
			if (!label) return arry
			const value = val as string
			const obj: DemographicObj = {
				value,
				label,
			}

			return [...arry, obj]
		},
		[] as DemographicObj[]
	)

	return (
		<CandidateDemographicsView>
			<Async fetchResults={results}>
				{data ? (
					<>
						<div className='demographic-info'>
							<Heading hTag='h3' displayType='block-heading'>
								Demographic Info
								<Btn onClick={openModal}>
									<CircleQuestionMark />
								</Btn>
							</Heading>
							<ModalFull
								isOpen={showModal}
								closeHandler={closeModal}
								headerContent='Demographic Info FAQ'
								headerAlign='left'
							>
								<TutDemographicInfo />
							</ModalFull>
							<Card padBottom hasGutter background='none'>
								{demographics.map(({ label, value }) => (
									<div key={label} className='row'>
										<Text className='label'>{label}</Text>
										<Text className='value'>{value}</Text>
									</div>
								))}
							</Card>
						</div>
					</>
				) : null}
			</Async>
		</CandidateDemographicsView>
	)
}
