import { Async, NoResults } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetJobsBySkill } from '../../../api/api.jobs'
import { CandidateJobsList } from '../Candidate.jobs.list'

export const SkillJobsStyles = css`
	.no-results {
		padding: 10px 20px;
	}
`

const SkillJobsView = styled.div`
	${SkillJobsStyles}
`

export type SkillJobsProps = {
	candidateSkillId: number
}
export const SkillJobs = ({ candidateSkillId }: SkillJobsProps): JSX.Element => {
	const results = useGetJobsBySkill(candidateSkillId)

	const jobs = results.data
	return (
		<SkillJobsView>
			<Async fetchResults={results}>
				{jobs?.length ? (
					<CandidateJobsList candidateJobs={jobs} />
				) : (
					<NoResults titleText='No linked jobs' display='inline' />
				)}
			</Async>
		</SkillJobsView>
	)
}
