import { css } from 'styled-components'

type modalAlertProps = {}

export const modalAlertStyles = css<modalAlertProps>`
	transition: all 150ms ease-in-out;
	position: relative;
	height: 100%;
	width: 100%;
	padding: ${props => props.theme.sizes.gutter.mobile.val};
	padding-top: 10vh;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	text-align: center;

	.alert-modal-overlay {
		background: ${props => props.theme.colors.black.opacity(68).val};
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 0;
		//pointer-events: none;
	}

	.alert-modal-inner-wrap {
		position: relative;
		background: ${props => props.theme.colors.white.val};
		border-radius: 5px;
		overflow: hidden;
		width: 100%;
		z-index: 1;

		${props => props.theme.media.tablet} {
			max-width: 550px;
		}
	}
	.alert-modal-header {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		color: ${props => props.theme.colors.text.val};
		padding: 3px 30px;
		min-height: 37.5px;

		& > * {
			color: inherit;
		}
		button {
			padding: 6px;
			position: absolute;
			right: 0;
		}
	}
	.modal-content {
		padding: 3em 4em;
		padding-top: 0;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	.alert-btns {
		display: flex;
		padding: 1em 2em;
		padding-top: 0;
	}

	&.__reverse-btns {
		.alert-btns {
			flex-direction: row-reverse;
		}
	}

	&.__no-pad {
		.modal-content {
			padding: 0;
		}
	}

	&.__has-title {
		.alert-modal-header {
			background: ${props => props.theme.colors.lightGrey.light(0).val};
		}
	}
`
