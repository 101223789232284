import styled, { css } from 'styled-components'
import { Link } from './UI/Link'

export const oNetLicenseStyles = css`
	p {
		font-size: 0.8em;
	}
`

const ONetLicenseView = styled.div`
	${oNetLicenseStyles}
`

export type ONetLicenseProps = {}
export const ONetLicense = (): JSX.Element => {
	return (
		<ONetLicenseView className='o-net-license'>
			<p>
				This app includes information from{' '}
				<Link to='https://www.onetcenter.org/'>O*NET Resource Center</Link> by the U.S. Department
				of Labor, Employment and Training Administration (USDOL/ETA). Used under the{' '}
				<Link to='https://creativecommons.org/licenses/by/4.0/'>CC BY 4.0</Link> license. Merify LLC
				has modified all or some of this information. USDOL/ETA has not approved, endorsed, or
				tested these modifications.
			</p>
		</ONetLicenseView>
	)
}
