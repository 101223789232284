import {
	Btn,
	CandidateSkillIntersect,
	CTA,
	Ctas,
	ErrorBlock,
	Heading,
	Skill,
	skillLevelsWithInfo,
	Spinner,
	useState,
} from '@merify/ui'
import styled, { css } from 'styled-components'
import { useAddSkillsMutation, useGetSkillsWithScores } from '../../../api/api.skills'
import { toaster } from '../../UI/Toaster'
import { SkillLevelSet } from '../Skill/Skill.levelSet'

export const SkillAddSetLevelStyles = css`
	transition: all ${props => props.theme.times.tranM};
	padding: 0;
	/* height: calc(100vh - ${props => props.theme.sizes.header.mobile.val}); */
	& > header {
		padding: ${props => props.theme.sizes.gutter.mobile.val};
		/* border-top: solid 1px ${props => props.theme.colors.lightGrey.val}; */
		border-bottom: solid 1px ${props => props.theme.colors.lightGrey.val};
	}
	section {
		padding: ${props => props.theme.sizes.gutter.mobile.val};
	}
	.level-info {
	}
	.btns {
		width: 100%;
		display: flex;
		justify-content: center;
		.ctas {
			width: auto;
			.cta-wrap {
				button {
					width: 80px;
				}
			}
		}
	}
`

const SkillAddSetLevelView = styled.div`
	${SkillAddSetLevelStyles}
`

export type SkillAddSetLevelProps = {
	skill: Pick<Skill, 'name' | 'skillId'> | null
	cb: (candidateSkillId: number) => void
	cancel: () => void
}
export const SkillAddSetLevel = ({ skill, cb, cancel }: SkillAddSetLevelProps): JSX.Element => {
	const { name, skillId } = skill || {}
	const { data } = useGetSkillsWithScores()
	const [levelId, setLevelId] = useState<number>(1, 'levelId')
	const levelInfo = skillLevelsWithInfo.find(l => l.levelId === levelId)

	const { mutateAsync, isLoading, error } = useAddSkillsMutation()
	const submitHandler = async () => {
		try {
			if (!skillId || !levelId) return

			const skills =
				data
					?.map(({ skillId, currentSkillLevelId }) => ({
						skillId,
						currentSkillLevelId,
					}))
					?.filter(s => s.skillId !== skillId) || []

			const results = await mutateAsync([
				...skills,
				{
					skillId,
					currentSkillLevelId: levelId,
				},
			])

			toaster.ok(`${name || 'New skill'} added!`)

			const newSkill = results.find(
				(s: CandidateSkillIntersect) => s.skillId === skillId
			) as CandidateSkillIntersect
			cb(newSkill.candidateSkillId)
		} catch (err) {}
	}
	return (
		<SkillAddSetLevelView style={{ background: levelInfo?.color }}>
			{isLoading ? <Spinner /> : null}
			<ErrorBlock error={error} display='inline' />
			<header>
				<Heading hTag='h4' displayType='block-heading' dir='center' color='white'>
					{name}
				</Heading>
			</header>
			<section className='set-level-section'>
				<SkillLevelSet levelId={levelId} onChange={setLevelId} enabled />
			</section>
			<section className='btns'>
				<Ctas pad={true}>
					<CTA size='sm' invert>
						<Btn onClick={cancel}>Cancel</Btn>
					</CTA>
					<CTA size='sm' displayType='solid'>
						<Btn onClick={submitHandler} disabled={!levelId}>
							Save
						</Btn>
					</CTA>
				</Ctas>
			</section>
		</SkillAddSetLevelView>
	)
}
