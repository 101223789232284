import { Btn, CandidateIntersect, CTA, Wizard } from '@merify/ui'
import styled, { css } from 'styled-components'
import { SuccessScreen } from '../../screens/Success/Success.screen'

import { SuccessAnimation } from '../SuccessAnimation'
import { ReviewRequestProps } from './Review.request'
import { ReviewRequestAddUsers } from './Review.request.addUsers'
import { ReviewRequestProvider, useReviewRequest } from './Review.request.provider'
import { ReviewRequestSelectJob } from './Review.request.selectJob'

export const reviewRequestFromProfileStyles = css``

const ReviewRequestFromProfileView = styled.div`
	${reviewRequestFromProfileStyles}
`

export type ReviewRequestFromProfileProps = ReviewRequestProps & {
	candidate: CandidateIntersect
}
export const ReviewRequestFromProfile = ({
	candidate,
	...reviewReqProps
}: ReviewRequestFromProfileProps): JSX.Element => {
	return (
		<ReviewRequestProvider preselectedUsers={[candidate]}>
			<ReviewRequestForm {...reviewReqProps} />
		</ReviewRequestProvider>
	)
}

const ReviewRequestForm = ({ cb }: ReviewRequestProps) => {
	const { isSuccessful, candidateJobId } = useReviewRequest()
	return isSuccessful ? (
		<SuccessScreen
			titleText='Review Requested!'
			animation={<SuccessAnimation type='requestReview' />}
			cta={
				cb ? (
					<CTA>
						<Btn onClick={cb}>Back to Profile</Btn>
					</CTA>
				) : undefined
			}
		/>
	) : (
		<ReviewRequestFromProfileView>
			<Wizard includeDots scrollToTop>
				<ReviewRequestSelectJob cancel={cb} />
				{candidateJobId ? <ReviewRequestAddUsers /> : <div />}
			</Wizard>
		</ReviewRequestFromProfileView>
	)
}
