import React, { ReactNode } from 'react'
import styled, { css, useTheme } from 'styled-components'

type ContainerStyleProps = {
	stickyFootHeight?: number
}
export const containerStyles = css<ContainerStyleProps>`
	&.__sticky-feet {
		/* display: flex;
		flex-direction: column; */
		/* height: calc(100vh - ${props => props.stickyFootHeight}px);
		transition: height ${props => props.theme.times.tranM}; */

		.content {
			/* flex: 1 0 auto; */
		}
		.sticky-foot {
			/* flex-shrink: 0; */
		}

		&.__wizard-step-inactive {
			/* height: 80vh;
			overflow: hidden; */
		}
	}
`

const ContainerView = styled.div`
	${containerStyles}
`

type ContainerProps = {
	className?: string
	children: ReactNode[]
	stickyOffsets?: Array<'footer' | 'wizardDots'>
	isStickyFooter?: boolean
	isInactiveWizardStep?: boolean
}
export const Container = ({
	children,
	stickyOffsets = [],
	isStickyFooter,
	isInactiveWizardStep,
	className,
}: ContainerProps): JSX.Element => {
	const content = children.slice(0, -1)
	const footer = children.slice(-1)

	const theme = useTheme()
	const headerHeight = theme.sizes.header.mobile.num
	const navHeight = theme.sizes.footer.mobile.num
	let stickyFootHeight = headerHeight
	stickyOffsets.forEach(offset => {
		if (offset === 'footer') stickyFootHeight += navHeight
		else if (offset === 'wizardDots') stickyFootHeight += headerHeight
	})
	return (
		<ContainerView
			stickyFootHeight={stickyFootHeight}
			className={`container${className ? ` ${className}` : ''}${
				isStickyFooter ? ' __sticky-feet' : ''
			}${isInactiveWizardStep ? ' __wizard-step-inactive' : ''}`}
		>
			{isStickyFooter ? (
				<>
					<div className='content'>{content}</div>
					<div className='sticky-foot'>{footer}</div>
				</>
			) : (
				children
			)}
		</ContainerView>
	)
}
