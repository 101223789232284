import { Heading, MerifyVettingGuideSvg, Shield, Text } from '@merify/ui'
import styled, { css } from 'styled-components'
import { VettingNumbers } from '../Skill/Skill.vetting'

export const tutSkillVettingStyles = css`
	padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
	p {
		margin: 15px 0;
	}
	.row {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		span {
			font-weight: 700;
			font-style: normal;
		}

		.shield {
			min-height: 35px;
			min-width: 32px;
			margin-right: 10px;
			svg {
				min-width: 32px;
				max-width: 32px;
				width: 100%;
			}
		}
		.percentage {
			font-size: 1rem;
		}
		&.percent-section {
			justify-content: center;
		}
	}
	.vetting-guide-svg {
		width: 50%;
		margin: 20px;
		height: 100px;
	}
	.shield-star-svg {
		max-width: 35px;
		min-width: 35px;
	}
`

const TutSkillVettingView = styled.div`
	${tutSkillVettingStyles}
`

export type TutSkillVettingProps = Partial<VettingNumbers> & {}
export const TutSkillVetting = ({
	overallVetting,
	totalReviewVetting,
	reviewerPoolDiversityVetting,
	levelVetting,
}: TutSkillVettingProps): JSX.Element => {
	return (
		<TutSkillVettingView>
			<Text textType='p' size='small'>
				Merify vets the data it receives from you and your professional community to ensure the
				information is as honest and accurate as possible.
			</Text>
			<Text textType='p' size='small'>
				We display the overall vetting status of a skill as a percentage inside a shield next to the
				skill name. This vetting status is the Merify system’s confidence in the accuracy of your
				skill level and score. It is an average of 3 vetting metrics:{' '}
			</Text>
			<div className='row'>
				<Shield value={totalReviewVetting || 0} />
				<div>
					<Heading hTag='h6' color='text'>
						<Text textType='italic'>
							<Text textType='bold'>Total Reviews</Text>
						</Text>
					</Heading>
					<Text textType='italic' size='small'>
						How many reviews have you received for this skill?
					</Text>
				</div>
			</div>
			<div className='row'>
				<Shield value={reviewerPoolDiversityVetting || 0} />
				<div>
					<Heading hTag='h6' color='text'>
						<Text textType='italic'>
							<Text textType='bold'>Reviewer Network</Text>
						</Text>
					</Heading>
					<Text textType='italic' size='small'>
						How many different people have reviewed you in this skill and how vetted are they?
					</Text>
				</div>
			</div>
			<div className='row'>
				<Shield value={levelVetting || 0} />
				<div>
					<Heading hTag='h6' color='text'>
						<Text textType='italic'>
							<Text textType='bold'>Review Alignment</Text>
						</Text>
					</Heading>
					<Text textType='italic' size='small'>
						How does your self-evaluation align with the community?
					</Text>
				</div>
			</div>

			<Text textType='p' size='small'>
				As your colleagues’ reviews meet more of the metrics, you’ll see the system’s confidence
				level growing as a percentage within the shield until it becomes Merified, or 100% vetted.
				Once the skill is Merified, the percentage changes to a star.
			</Text>

			<div className='row percent-section'>
				<Shield value={0} />
				<MerifyVettingGuideSvg />
				<Shield value={1} />
			</div>

			<Text textType='p' size='small'>
				The same metrics are used for all levels, but the numbers needed to become Merified vary
				from level to level. For example, it will take a higher number of Total Reviews to be
				Merified in a skill at an Expert level than it will to become Merified at a Beginner level.
			</Text>
			<Heading hTag='h5' displayType='block-heading' color='darkGrey'>
				Level Adjusting
			</Heading>
			<Text textType='p' size='small'>
				The Merify platform encourages you to explore how your skill-vetting changes at different
				levels. For example, you may have rated yourself as an Intermediate in C# and become
				Merified at that level, but the community data received indicates that you could move up to
				Advanced and still be Merified in C#. On the other hand, if you are having trouble becoming
				Merified in C# at the Advanced level, try changing your self-evaluation to Intermediate to
				see how vetting is affected.
			</Text>
		</TutSkillVettingView>
	)
}
