import { Btn, CandidateJobAndSkills, Circle, Icon, IconType } from '@merify/ui'
import { useNavigate } from '@reach/router'
import styled, { css } from 'styled-components'
import { SelectedIco } from '../../UI/Selected.ico'
import { CandidateJobSummary } from '../Candidate.job.summary'

export const candidateJobItemStyles = css`
	display: flex;
	align-items: center;
	&:hover,
	&.__selected {
		background: ${props => props.theme.colors.primary.opacity(5).val};
	}

	.select-job-btn {
		flex-grow: 1;
		width: 100%;
		display: flex;
		align-items: center;
		padding: 10px ${props => props.theme.sizes.gutter.mobile.val};
		text-align: left;

		.candidate-job-info {
			margin-left: 1em;
		}
		.circle {
			margin-left: auto;
		}
	}

	.option-btn {
		margin-left: auto;
		padding: 1em;
		display: flex;
		align-items: center;
		justify-content: center;
		color: ${props => props.theme.colors.primary.val};

		&:hover {
			background: ${props => props.theme.colors.lightNeutral.val};
		}
	}
`

const CandidateJobItemView = styled.div`
	${candidateJobItemStyles}
`

export type CandidateJobItemProps = {
	job: CandidateJobAndSkills
	onSelect?: (job: CandidateJobAndSkills) => void
	isSelected?: boolean
	showSelectRadios?: boolean
	isDisabled?: boolean
	option?: {
		icon: IconType
		onClick: (candidateJobId: number) => void
	}
}
export const CandidateJobItem = ({
	job,
	onSelect,
	isSelected = false,
	showSelectRadios,
	isDisabled,
	option,
}: CandidateJobItemProps): JSX.Element => {
	const navTo = useNavigate()
	const { candidateJob, candidateJobSkills } = job
	const { candidateJobId } = candidateJob

	return (
		<CandidateJobItemView className={`job-item${isSelected ? ' __selected' : ''}`}>
			<Btn
				className='select-job-btn'
				disabled={isDisabled}
				onClick={() => {
					if (onSelect) onSelect(job)
					else navTo(`/work/${candidateJobId}`)
				}}
			>
				{onSelect && showSelectRadios ? <SelectedIco isSelected={isSelected} /> : null}
				<div className='job-info'>
					<CandidateJobSummary candidateJob={candidateJob} />
				</div>
				<Circle
					className='circle'
					isSolid={false}
					value={candidateJobSkills?.length}
					label='skills'
				/>
			</Btn>
			{option ? (
				<Btn className='option-btn' onClick={() => option.onClick(candidateJobId)}>
					<Icon type={option.icon} />
				</Btn>
			) : null}
		</CandidateJobItemView>
	)
}
