import { Btn, CTA } from '@merify/ui'
import styled, { css } from 'styled-components'
import { SuccessScreen } from '../../screens/Success/Success.screen'

import { SuccessAnimation } from '../SuccessAnimation'
import { ReviewRequestProps } from './Review.request'
import { ReviewRequestAddUsers } from './Review.request.addUsers'
import { ReviewRequestProvider, useReviewRequest } from './Review.request.provider'

export const reviewRequestFromJobStyles = css``

const ReviewRequestFromJobView = styled.div`
	${reviewRequestFromJobStyles}
`

export type ReviewRequestFromJobProps = ReviewRequestProps & {
	candidateJobId: number
}
export const ReviewRequestFromJob = ({
	candidateJobId,
	...reviewReqProps
}: ReviewRequestFromJobProps): JSX.Element => {
	return (
		<ReviewRequestProvider candidateJobId={candidateJobId}>
			<ReviewRequestForm {...reviewReqProps} />
		</ReviewRequestProvider>
	)
}

const ReviewRequestForm = ({ cb }: ReviewRequestProps) => {
	const { isSuccessful } = useReviewRequest()
	return isSuccessful ? (
		<SuccessScreen
			titleText='Review Requested!'
			animation={<SuccessAnimation type='requestReview' />}
			cta={
				cb ? (
					<CTA>
						<Btn onClick={cb}>Back to Job</Btn>
					</CTA>
				) : undefined
			}
		/>
	) : (
		<ReviewRequestFromJobView>
			<ReviewRequestAddUsers cancel={cb} />
		</ReviewRequestFromJobView>
	)
}
