import { RouteComponentProps } from '@reach/router'
import { ReactNode, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { LegalLinks } from '../../Legal/Legal.links'
import { AppDrawerDisplayType, useAppDrawer } from '../Drawer.app'
import { AppFooter } from '../Footer.app'
import { AppHeader } from '../Header.app'
import { AppNavDesk } from '../Nav.app/Nav.desk'
import { useWindow } from '../Window.provider'
import { AppDrawerInCol } from './AppDrawerInCol'
import { AppDrawerInOverlay } from './AppDrawerInOverlay'

export const appLayoutStyles = css`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	position: relative;

	${props => props.theme.media.tablet} {
		height: auto;
		max-height: initial;
	}

	.page {
		display: flex;
		flex-direction: row;
		min-height: calc(100vh - ${props => props.theme.sizes.header.mobile.val});
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		width: 100vw;
		padding-top: ${props => props.theme.sizes.header.mobile.val};
		padding-bottom: ${props => props.theme.sizes.footer.mobile.num + 80}px;

		${props => props.theme.media.tablet} {
			height: 100vh;
			padding-bottom: 0;
		}

		${props => props.theme.media.sdesk} {
			height: calc((100vh + 10px) - ${props => props.theme.sizes.header.ldesk.val});
			padding-top: 0;
		}

		.desk-menu {
			display: flex;
			flex-direction: column;
			background: ${props => props.theme.colors.white.val};
			z-index: 9;
			flex: 1;
			height: 100vh;
			max-width: 200px;
			${props => props.theme.media.tablet} {
				height: 100%;
			}
			/* ${props => props.theme.media.sdesk} {
				height: calc(100vh - 70px);
			} */

			.legal-links {
				margin-top: auto;
			}
		}

		.screen-content {
			flex: 5;
			overflow-y: auto;
			overflow-x: hidden;
		}
	}

	.app-footer {
		width: 100%;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 2;
	}
`

const AppLayoutView = styled.div`
	${appLayoutStyles}
`

export type AppLayoutProps = RouteComponentProps & {
	children?: ReactNode // main content (left column)
	displayType?: AppDrawerDisplayType // overrides the drawer display type if set on the route
}
export const AppLayout = ({ children, displayType = 'one-col' }: AppLayoutProps): JSX.Element => {
	const { isMobile } = useWindow()

	const { setDisplayType, renderDrawerInCol, renderDrawerInOverlay } = useAppDrawer()

	useEffect(() => {
		if (displayType) setDisplayType(displayType)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayType])

	return (
		<AppLayoutView className='layout'>
			<AppHeader />
			<div className='page'>
				{!isMobile ? (
					<aside className='desk-menu'>
						<AppNavDesk />
						<LegalLinks />
					</aside>
				) : null}
				<main className='screen-content'>
					{renderDrawerInOverlay ? (
						<AppDrawerInOverlay>{children}</AppDrawerInOverlay>
					) : renderDrawerInCol ? (
						<AppDrawerInCol>{children}</AppDrawerInCol>
					) : null}
				</main>
			</div>
			{isMobile ? <AppFooter /> : null}
		</AppLayoutView>
	)
}
