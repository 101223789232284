import { Card, ErrorBlock, Heading, Spinner, Text, WizardStepProps } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useReview } from '../Review.provider/Review.provider'
import { ReviewLinkedJobs } from './Review.jobs.link'
import { ReviewSkill } from './Review.skill'
import { RateReviewSkills } from './Review.skills.rate'
import { ReviewSubmitBtns } from './Review.submitBtns'

export const reviewScoreStyles = css`
	padding-bottom: 80px;

	.linked-jobs-card {
		.card-inner {
			padding: 1em ${props => props.theme.sizes.gutter.mobile.val};
		}
	}

	.rate-skills-section-heading {
		padding: 1em ${props => props.theme.sizes.gutter.mobile.val};
		margin-top: 1em;
		font-weight: normal;
	}

	.skills-for-rate {
		.accordion {
			background: ${props => props.theme.colors.white.val};
			border: solid 2px transparent;
			border-bottom: solid 1px ${props => props.theme.colors.midNeutral.val};
			transition: all ${props => props.theme.times.tranM};

			.ctrl-group .inner-wrap {
				padding: 0;
			}

			&.__open {
				border: solid 2px ${props => props.theme.colors.primary.val};
				.skill {
					box-shadow: 0 2px 4px ${props => props.theme.colors.neutral.opacity(20).val};
					& > button {
						.name {
							color: ${props => props.theme.colors.neutral.val};
						}
					}
				}
			}
		}
	}
	.bottom-btns {
		padding-top: 1.5em;

		${props => props.theme.media.sdesk} {
			display: none;
		}
	}
`

const ReviewSkillsView = styled.div`
	${reviewScoreStyles}
`

export type ReviewSkillsProps = {}
export const ReviewSkills = ({ ...stepProps }: ReviewSkillsProps): JSX.Element => {
	const { nextStep, currentStep, totalSteps } = stepProps as WizardStepProps
	const {
		reviewId,
		reviewerJobId,
		prevRatedSkills,
		skillsToRate,
		isAllRated,
		isSubmitting,
		submitError,
		reviewType,
		cancel,
		submitReview,
	} = useReview()

	const canRenderLinkedJobs = !!reviewerJobId && !!reviewId

	const hasPrevRatedSkills = !!prevRatedSkills?.length
	const singleSkillForRate = skillsToRate?.length === 1

	return (
		<ReviewSkillsView className={`review-skills-rate`}>
			{canRenderLinkedJobs ? (
				<section className='linked-jobs-section'>
					<Card className='linked-jobs-card'>
						<ReviewLinkedJobs linkedJobId={reviewerJobId} reviewId={reviewId} />
					</Card>
				</section>
			) : null}
			<div className='rating-inputs'>
				{isSubmitting ? <Spinner /> : null}
				<section className='cta-btns top-btns'>
					<ReviewSubmitBtns
						reviewType={reviewType}
						nextStep={nextStep}
						currentStep={currentStep}
						totalSteps={totalSteps}
						cancel={cancel}
						submitHandler={submitReview}
						disable={!isAllRated || isSubmitting}
					/>
				</section>
				{submitError ? (
					<section className='error-section'>
						<ErrorBlock error={submitError} />
					</section>
				) : null}
				<section className='rate-skills-section'>
					<Heading className='rate-skills-section-heading' displayType='p-heading'>
						{singleSkillForRate ? 1 : skillsToRate.length}{' '}
						{hasPrevRatedSkills ? <Text textType='bold'>(new)</Text> : ''} skill
						{singleSkillForRate ? '' : 's'} to {reviewType === 'review' ? 'review' : 'rate'}
					</Heading>

					<div className='skills-for-rate'>
						<RateReviewSkills isLastStep={currentStep === totalSteps} nextStep={nextStep} />
					</div>
				</section>
			</div>
			<section className='previous-rated-skills-section'>
				{hasPrevRatedSkills ? (
					<div className='previous-rated-skills'>
						{prevRatedSkills.map(skill => (
							<ReviewSkill skill={skill} key={skill.skillId} />
						))}
					</div>
				) : null}
			</section>
		</ReviewSkillsView>
	)
}
