import { Async, Btn, CTA, Ctas, Heading, WizardStepProps } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetKudosJobsOnCandidate } from '../../../api/api.jobs'
import { Container } from '../../UI/Container'
import { GiveKudosJobItem } from './Give.kudos.job.item'

const GiveKudosSelectJobStyles = css`
	.jobs {
		margin-top: 2rem;
		background: ${props => props.theme.colors.white.val};
	}
`

const GiveKudosSelectJobView = styled.div`
	${GiveKudosSelectJobStyles}
`

type GiveKudosSelectJobProps = {
	className?: string
	candidateId: number
	candidateJobId: number | null
	setJobId: (candidateJobId: number | null) => void
	continueHandler: () => void
}
export const GiveKudosSelectJob = ({
	className,
	candidateId,
	candidateJobId,
	setJobId,
	continueHandler,
	...stepProps
}: GiveKudosSelectJobProps): JSX.Element => {
	const { previousStep, isActive } = stepProps as WizardStepProps

	const results = useGetKudosJobsOnCandidate(candidateId)
	const jobs = results.data

	const jobsForRender = jobs?.length
		? [...jobs].sort((a, b) => {
				// sort criteria: If there's no end date it's at the top in chronological order by start date
				// If there is an end date then sort chronologically on end date (latest end dates at the top)
				// If end dates are the same then sort on start date

				const aStart = a.startDate
				const bStart = b.startDate
				const aEnd = a.endDate || ''
				const bEnd = b.endDate || ''

				// If not end date it goes to the top
				if (aEnd && !bEnd) return +1
				else if (!aEnd && bEnd) return -1
				else if (aEnd && bEnd) {
					// Both have end dates
					if (aEnd > bEnd) return -1
					else if (aEnd < bEnd) return +1
					// If both end dates are the same we sort on start date
					else if (aStart > bStart) return -1
					else if (aStart < bStart) return +1
					else return 0
				} else if (!aEnd && !bEnd) {
					if (aStart > bStart) return -1
					else if (aStart < bStart) return +1
					else return 0
				} else return 0
		  })
		: undefined

	return (
		<GiveKudosSelectJobView className={`give-kudos-select-job${className ? ` ${className}` : ''}`}>
			<Container isStickyFooter stickyOffsets={['wizardDots']} isInactiveWizardStep={!isActive}>
				<Heading mBot hTag='h4' displayType='dialog-heading' dir='center'>
					Select the job to give kudos for
				</Heading>
				<Async fetchResults={results}>
					<header>
						<Ctas maxW='350px' mTop>
							<CTA size='sm'>
								<Btn onClick={previousStep}>Back</Btn>
							</CTA>
							<CTA displayType='solid' size='sm'>
								<Btn onClick={continueHandler} disabled={candidateJobId ? false : true}>
									Continue
								</Btn>
							</CTA>
						</Ctas>
					</header>
					<div className='jobs'>
						{jobsForRender?.map(job => (
							<div className='job' key={job.candidateJobId}>
								<GiveKudosJobItem
									jobTitle={job.jobTitle}
									projectName={job.projectName}
									startDate={job.startDate}
									endDate={job.endDate}
									employer={job.employer}
									isChecked={candidateJobId === job.candidateJobId}
									onChange={() => {
										setJobId(job.candidateJobId)
									}}
								/>
							</div>
						))}
					</div>
				</Async>
			</Container>
		</GiveKudosSelectJobView>
	)
}
