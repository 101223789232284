import { css } from 'styled-components'

export const welcomeScreenStepStyles = css`
	&.__active {
	}
	.inner-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		position: relative;
		height: 100vh;
		width: 100%;
		${props => props.theme.media.tablet} {
			width: 550px;
			padding: 2rem 0;
			margin: 0 auto;
		}

		.content {
			text-align: center;
			position: relative;
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.text {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				padding: 0 1.5rem;

				h2,
				p {
					color: ${props => props.theme.colors.secondary.val};
				}
			}
		}

		.cta {
			width: 100%;
			position: relative;
			padding-bottom: ${props => props.theme.sizes.footer.mobile.val};
			${props => props.theme.media.tablet} {
				//width: 550px;
				padding: 2rem 0;
				margin: 0 auto;
			}
		}
	}
`
