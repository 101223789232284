import { Icon, Job, Text, formatUTC } from '@merify/ui'
import styled, { css } from 'styled-components'

const GiveKudosJobItemStyles = css`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 1em;
	border-bottom: 1px solid ${props => props.theme.colors.lightNeutral.val};

	input[type='radio'] {
		opacity: 0;
		position: absolute;
		border: 1px solid red;
		z-index: 20;
		height: 32.5px;
		width: 32.5px;
	}

	.job-title {
		cursor: pointer;
	}

	.checkbox {
		border: solid 1px ${props => props.theme.colors.midNeutral.val};
		background: ${props => props.theme.colors.white.val};
		margin-right: 1em;
		border-radius: 50%;
		height: 32.5px;
		width: 32.5px;
		display: flex;
		align-items: center;
		justify-content: center;

		.icon {
			opacity: 0;
		}
	}

	&.__selected {
		.checkbox {
			.icon {
				opacity: 1;
			}
		}
	}
`

const GiveKudosJobItemView = styled.div`
	${GiveKudosJobItemStyles}
`

type GiveKudosJobItemProps = Pick<Job, 'jobTitle' | 'startDate' | 'endDate' | 'employer'> & {
	className?: string
	isChecked: boolean
	projectName?: string | null
	onChange: () => void
}
export const GiveKudosJobItem = ({
	className,
	jobTitle,
	startDate,
	endDate,
	employer,
	isChecked,
	projectName,
	onChange,
}: GiveKudosJobItemProps): JSX.Element => {
	return (
		<GiveKudosJobItemView
			className={`give-kudos-job-item${className ? ` ${className}` : ''}${
				isChecked ? ' __selected' : ''
			}`}
		>
			<input name='job' id={jobTitle} type='radio' checked={isChecked} onChange={onChange} />

			<div className='checkbox'>
				<Icon type='checkCircle' />
			</div>

			<label className='job-title' htmlFor={jobTitle}>
				<Text size='med'>{jobTitle}</Text>
				<br />
				{projectName ? (
					<>
						<Text size='small'>{projectName}</Text>
						<br />
					</>
				) : null}
				<Text size='small'>{employer}</Text>
				<br />
				<Text size='small'>
					{endDate
						? `${formatUTC(startDate)} - ${formatUTC(endDate)}`
						: `${formatUTC(startDate)} - Present`}
				</Text>
			</label>
		</GiveKudosJobItemView>
	)
}
