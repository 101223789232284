import { Heading } from '@merify/ui'
import styled, { css } from 'styled-components'

export const circleQuestionMarkStyles = css`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid ${props => props.theme.colors.secondary.val};
	border-radius: 50%;
	color: ${props => props.theme.colors.secondary.val};
	h5 {
		font-size: 1.7rem;
		color: inherit;
	}
`

const CircleQuestionMarkView = styled.div`
	${circleQuestionMarkStyles}
`

export type CircleQuestionMarkProps = {}
export const CircleQuestionMark = (): JSX.Element => {
	return (
		<CircleQuestionMarkView>
			<Heading hTag='h5' displayType='block-heading'>
				?
			</Heading>
		</CircleQuestionMarkView>
	)
}
