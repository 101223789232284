import { css } from 'styled-components'

export const notificationStyles = css`
	.card-inner {
		padding: 1em ${props => props.theme.sizes.gutter.mobile.val};
		min-height: 250px;
	}
	.single-cta {
		.delete-notification-cta {
			justify-content: center;
		}
	}
`
