import { Async, Card, CTA, Ctas, Heading, NotificationType } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetNotificationByIdAndType } from '../../../api/api.notifications'
import { Link } from '../../UI/Link'
import { DeleteNotificationCta } from '../Notification.cta.delete'
import { DynamicNotificationParams } from '../Notification.detail'
import { NotificationInfo } from '../Notification.detail.info'
import { notificationStyles } from '../Notification.styles'

export const skillVerificationReminderNotificationDetailStyles = css`
	${notificationStyles}

	.steps {
		padding-top: 1em;
		ol {
			margin-left: 1em;
			padding-left: 1em;
			li {
				display: list-item;
				margin: 0.3em 0;
			}
		}
	}
`

const SkillVerificationReminderNotificationDetailView = styled.div`
	${skillVerificationReminderNotificationDetailStyles}
`

export type SkillVerificationReminderNotificationDetailProps = DynamicNotificationParams

export const SkillVerificationReminderNotificationDetail = ({
	id,
	cb,
}: SkillVerificationReminderNotificationDetailProps): JSX.Element => {
	const type: NotificationType = 'candidateSkillVerificationReminderNotifications'

	const notificationsRes = useGetNotificationByIdAndType({
		notificationId: id,
		notificationType: type,
	})

	const notification = notificationsRes.data
	const { additionalSteps } = notification || {}

	return (
		<SkillVerificationReminderNotificationDetailView>
			<Async fetchResults={[notificationsRes]}>
				<Card>
					{notification ? <NotificationInfo notification={notification} /> : null}
					<div className='steps'>
						<Heading>Steps:</Heading>
						<ol>
							{additionalSteps?.map((step, idx) => (
								<li key={idx}>{step}</li>
							))}
						</ol>
					</div>
				</Card>
				<Ctas>
					<CTA size='sm'>
						<Link to='/skills'>Go to skills</Link>
					</CTA>
					<DeleteNotificationCta notificationId={id} notificationType={type} cb={cb} />
				</Ctas>
			</Async>
		</SkillVerificationReminderNotificationDetailView>
	)
}
