import styled, { css } from 'styled-components'
import { useAppDrawer } from './Drawer.app'

export const appDrawerPlaceholderContainerStyles = css`
	transition: opacity ${props => props.theme.times.tranS};
	&.__closed {
		opacity: 0;
	}
	&.__open {
		opacity: 1;
	}
`

const AppDrawerPlaceholderContainerView = styled.div`
	${appDrawerPlaceholderContainerStyles}
`

export type AppDrawerPlaceholderContainerProps = {}
export const AppDrawerPlaceholderContainer = (): JSX.Element => {
	const { isAppDrawerOpen, placeholder } = useAppDrawer()
	return (
		<AppDrawerPlaceholderContainerView
			className={`app-drawer-placeholder${isAppDrawerOpen ? ' __closed' : ' __open'}`}
		>
			{placeholder}
		</AppDrawerPlaceholderContainerView>
	)
}
