// import { Avatar as LibAvatar, AvatarProps as LibAvatarProps } from '@merify/ui'
import {
	Avatar as LibAvatar,
	AvatarProps as LibAvatarProps,
	useModalCtrl,
	UserIntersect,
} from '@merify/ui'
import { ImageUploader } from '../../UI/ImageUploader'
import { ModalFull } from '../../UI/Modal'

export type AvatarProps = LibAvatarProps & {
	canEdit?: boolean
}

export const Avatar = ({ canEdit, user, ...avatarProps }: AvatarProps): JSX.Element => {
	const [[showModal, openModal, closeModal]] = useModalCtrl()

	return (
		<>
			<LibAvatar {...avatarProps} user={user} onEditClick={canEdit ? openModal : undefined} />

			<ModalFull isOpen={showModal} closeHandler={closeModal} headerContent='Upload image'>
				<ImageUploader user={user as UserIntersect} cb={closeModal} />
			</ModalFull>
		</>
	)
}
