import { Btn, CTA, Heading, Icon } from '@merify/ui'
import { RouteComponentProps } from '@reach/router'
import styled, { css } from 'styled-components'
import { useSetAppHeader } from '../../../utils/useSetAppHeader'
import { ONetLicense } from '../../ONetLicense'
import { ReferenceGuide } from '../../ReferenceGuide'
import { Link } from '../../UI/Link'
import { ModalFull, useModalCtrl } from '../../UI/Modal'

export const HelpScreenStyles = css`
	padding: 10vh ${props => props.theme.sizes.gutter.mobile.val};
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - ${props => props.theme.sizes.footer.mobile.val});
	${props => props.theme.media.tablet} {
		min-height: 80vh;
	}

	h4 {
		color: ${props => props.theme.colors.secondary.val};
		text-align: center;
		font-weight: bold;
		margin-bottom: 1em;
	}
	& > section {
		padding-bottom: 1em;
	}
	section.help {
	}
	section.o-net-license {
		margin-top: auto;
	}
`

const HelpScreenView = styled.div`
	${HelpScreenStyles}
`

type HelpScreenProps = RouteComponentProps & {}

export const HelpScreen = (_: HelpScreenProps): JSX.Element => {
	useSetAppHeader('default')
	const [[showGuide, openGuide, closeGuide]] = useModalCtrl()
	return (
		<HelpScreenView>
			<section className='help'>
				<Heading hTag='h4' displayType='dialog-heading' dir='center'>
					Need help?
				</Heading>
				<CTA mBot>
					<Btn onClick={() => openGuide()}>
						Quick Reference Guide
						<Icon type='magnifyingGlass' />
					</Btn>
				</CTA>
				<CTA mBot>
					<Link to='/video-tutorials'>Video Tutorials</Link>
				</CTA>

				<CTA mBot>
					<Link to='/feedback'>Send Feedback</Link>
				</CTA>
				<CTA>
					<Link to='https://www.merify.com/features/candidates'>
						More Details <Icon type='external' />
					</Link>
				</CTA>
			</section>
			<section className='o-net-license'>
				<ONetLicense />
			</section>
			<ModalFull isOpen={showGuide} closeHandler={closeGuide} headerContent='Reference Guide'>
				<ReferenceGuide />
			</ModalFull>
		</HelpScreenView>
	)
}
