import {
	Async,
	Btn,
	CandidateIndustry,
	Card,
	CTA,
	Ctas,
	ErrorBlock,
	FormikForm,
	FormikSubmitBtn,
	FormikText,
	FormikTextarea,
	FormRequiredNotice,
	isEqual,
	sanitizeForForm,
} from '@merify/ui'
import styled, { css } from 'styled-components'
import * as Yup from 'yup'
import { useGetUserIndustries } from '../../../../api/api.admin'
import {
	useGetMe,
	useGetUserDemographic,
	useUpdateDemographics,
	UseUpdateDemographicsArgs,
	useUpdateProfile,
	UseUpdateProfileArgs,
} from '../../../../api/api.candidate'
import {
	defaultCandidateDemographicValues,
	defaultCandidateProfileValues,
} from '../../../../utils/defaults'
import { DemographicsFields } from '../../../UI/FormFields/Demograpics.fields'
import { FormikSelectApi } from '../../../UI/FormFields/Formik.select.api'
import { LocationField } from '../../../UI/FormFields/Location.field'
import { toaster } from '../../../UI/Toaster'
import { EditCandidateProfilePic } from './Candidate.profile.editPic'

export const candidateProfileEditFormStyles = css`
	padding-bottom: 150px;
	position: relative;
	display: block;

	${props => props.theme.media.sdesk} {
		padding-bottom: 0;
	}

	.edit-candidate-profile-pic {
		padding: ${props => props.theme.sizes.gutter.mobile.val};
		padding-bottom: 0;
	}
	.__err {
		padding: 0;
	}
	.card {
		padding-top: ${props => props.theme.sizes.gutter.mobile.val};
	}
	.field-wrap {
		margin-bottom: 2em;
	}
	.demographic-fields {
		h4 {
			padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
		}

		.field-wrap {
			padding: 0;
		}
	}
	.profile-edit-btn {
		padding: 0 5px;
	}
`

const CandidateProfileEditFormView = styled.div`
	${candidateProfileEditFormStyles}
`

const candidateProfileSchema = Yup.object().shape({
	firstName: Yup.string()
		.max(100, 'First Name must be at most 100 characters')
		.required('Please enter your first name'),
	lastName: Yup.string()
		.max(100, 'Last Name must be at most 100 characters')
		.required('Please enter your last name'),
	pronouns: Yup.string().max(100, 'Prounouns must be at most 100 characters'),
	formerName: Yup.string().max(100, 'Former Name must be at most 100 characters'),
	industryId: Yup.string().required('Industry is required'),
	title: Yup.string().max(100, 'Title must be at most 100 characters'),
	bio: Yup.string().max(5000, 'Bio must be at most 5000 characters'),
	city: Yup.string().max(100, 'City must be at most 100 characters'),
	stateOrProvince: Yup.string().max(100, 'State must be at most 100 characters'),
	country: Yup.string().max(100, 'Country must be at most 100 characters'),
	postalCode: Yup.string().max(100, 'Postal Code must be at most 100 characters'),
})

type UpdateProfileVals = UseUpdateProfileArgs & {
	demographics: UseUpdateDemographicsArgs
}

type CandidateProfileEditFormProps = {
	cb: () => void
}
export const CandidateProfileEditForm = ({ cb }: CandidateProfileEditFormProps): JSX.Element => {
	const candidateResults = useGetMe()
	const demographicsResults = useGetUserDemographic()
	const { mutateAsync, error } = useUpdateProfile()
	const { mutateAsync: mutateDemo, error: errorDemo } = useUpdateDemographics()
	const initialVals: UpdateProfileVals = {
		...sanitizeForForm(defaultCandidateProfileValues, candidateResults?.data),
		demographics: sanitizeForForm(defaultCandidateDemographicValues, demographicsResults?.data),
	}
	return (
		<CandidateProfileEditFormView className='candidate-profile-form'>
			<EditCandidateProfilePic />
			<Async fetchResults={[candidateResults, demographicsResults]}>
				<FormikForm
					validationSchema={candidateProfileSchema}
					initialValues={initialVals}
					onSubmit={async (vals: UpdateProfileVals) => {
						try {
							const { demographics, ...data } = vals
							await mutateAsync(data)
							if (!isEqual(initialVals.demographics, demographics)) await mutateDemo(demographics)
							cb()
							toaster.ok('Profile saved!')
						} catch (err) {}
					}}
				>
					<Card hasGutter padBottom>
						<FormRequiredNotice />
						<ErrorBlock error={error || errorDemo} display='inline' hideCta />
						<FormikText name='firstName' config={{ label: 'First Name', isReq: true }} />
						<FormikText name='lastName' config={{ label: 'Last Name', isReq: true }} />
						<FormikText name='pronouns' config={{ label: 'Pronouns' }} />
						<FormikText name='formerName' config={{ label: 'Former Name' }} />
						{/* @ts-ignore MIGRATION */}
						<FormikSelectApi
							name='industryId'
							fetchHook={useGetUserIndustries}
							config={{
								label: 'Industry',
								isReq: true,
							}}
						>
							{(industries: CandidateIndustry[]) =>
								industries.map(({ industryId, name }) => ({
									val: industryId,
									txt: name,
								}))
							}
						</FormikSelectApi>
						<FormikText name='title' config={{ label: 'Title' }} />
						<FormikTextarea name='bio' config={{ label: 'Bio' }} rows={6} />
						<LocationField />
					</Card>
					<DemographicsFields />
					<Ctas className='profile-edit-btn'>
						<CTA>
							<Btn onClick={cb}> Cancel</Btn>
						</CTA>
						<FormikSubmitBtn ctaConfig={{ displayType: 'solid' }} />
					</Ctas>
				</FormikForm>
			</Async>
		</CandidateProfileEditFormView>
	)
}
