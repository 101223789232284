import { MerifyMSvg } from '@merify/ui'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { AppDrawerPlaceholderContainer, useAppDrawer } from '../../Drawer.app'
import { AppDrawerInColDrawer } from './AppDrawerInCol.drawer'

export const appDrawerInColStyles = css`
	.col-left {
		overflow-y: auto;
		max-width: 550px;
		background: ${props => props.theme.colors.offWhite.val};
		height: calc((100vh + 10px) - ${props => props.theme.sizes.header.sdesk.val});
		z-index: 8;
		flex: 1;
	}

	.bg-m {
		width: 100%;
		height: calc(100vh - ${props => props.theme.sizes.header.mobile.val});
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		.merify-m {
			width: 100%;
			height: auto;
			margin: 0 auto;
			opacity: 0.1;
			color: ${props => props.theme.colors.white.val};
			position: fixed;
			pointer-events: none;
			transform: translate(-7%, 13%);
		}
	}

	&.__display-one-col {
		.col-left {
			margin: 0 auto;
		}
		.col-right {
			display: none;
		}
	}

	&.__display-two-col {
		.col-left {
			margin-left: 3vw;
			.bg-m {
				display: none;
			}
		}
		.col-right {
			padding: 0 20px;
			margin-left: 20px;
			height: calc((100vh + 10px) - ${props => props.theme.sizes.header.sdesk.val});
			margin-left: 10px;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 1;

			.app-drawer-placeholder {
				display: none;

				${props => props.theme.media.sdesk} {
					display: block;
					margin: 0 20px;
					position: absolute;
					max-width: 510px;
				}
			}
		}
	}
`

const AppDrawerInColView = styled.div`
	${appDrawerInColStyles}
`

export type AppDrawerInColProps = {
	children: ReactNode
	className?: string
}
export const AppDrawerInCol = ({ children }: AppDrawerInColProps): JSX.Element => {
	const {
		displayType, // one-col, two-col
		isAppDrawerOpen,
	} = useAppDrawer()

	return (
		<AppDrawerInColView
			className={`cols __display-${displayType}${isAppDrawerOpen ? ' __app-drawer-open' : ''}`}
		>
			<div className='col-left'>
				<div className='bg-m'>
					<MerifyMSvg />
				</div>
				{children}
			</div>
			<div className='col-right'>
				<div className='bg-m'>
					<MerifyMSvg />
				</div>
				<AppDrawerPlaceholderContainer />
				<AppDrawerInColDrawer />
			</div>
		</AppDrawerInColView>
	)
}
