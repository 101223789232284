import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react'
import { createTheme, get, isDev, themeColors } from '@merify/ui'
import { AxiosError } from 'axios'
import { StoreProvider } from 'easy-peasy'
import React from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { GlobalStyles } from './Global.styles'
import { AppDrawerProvider } from './components/global/Drawer.app'
import { WindowProvider } from './components/global/Window.provider'
import { ErrorFallbackScreen } from './components/screens/error/Error.fallback.screen'
import { Router } from './routing/Router'
import { store } from './store'

const BS_KEY = process.env.REACT_APP_BS_KEY as string

const theme = createTheme()

const App = () => {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				retry: (count, error) => {
					const err = error as AxiosError

					// Only retry max of 3 times
					if (count > 3) return false
					// We should only retry on 500 and network errors
					else if (err.response?.status || 0 >= 500) return true
					else if (!err.response?.status) return true
					else return false
				},
			},
		},
	})

	// This suppresses a warning due to next.js re-rendering see: https://github.com/bugsnag/bugsnag-js/issues/811
	if (!get(Bugsnag, '_client'))
		Bugsnag.start({
			apiKey: BS_KEY,
			plugins: [new BugsnagPluginReact()],
			onError: (event: any) => {
				// Disable for dev
				if (isDev) {
					console.log(
						`%c BUGSNAG - report that would have sent:`,
						`color: ${themeColors?.white || '#FFF'}; background-color: ${
							themeColors?.err || '#de0021'
						}; padding: 4px;`,
						event
					)
					return false
				}

				// NOTE: You can mutate error report sent to bugsnag
				// see: https://docs.bugsnag.com/platforms/react-native/expo/customizing-error-reports/
			},
		})

	const FakeErrBoundary: React.FC = ({ children }) => <>{children}</> // NOTE: This should never render

	const ErrorBoundary =
		Bugsnag.getPlugin('react')?.createErrorBoundary(React) ||
		(FakeErrBoundary as BugsnagErrorBoundary)

	return (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore FIXME_MIGRATION
		<ErrorBoundary FallbackComponent={ErrorFallbackScreen}>
			<WindowProvider>
				<QueryClientProvider client={queryClient}>
					<StoreProvider store={store}>
						<ThemeProvider theme={theme as unknown as DefaultTheme}>
							<GlobalStyles theme={theme as unknown as DefaultTheme} />
							<ToastContainer position='bottom-left' />
							<SkeletonTheme color={themeColors.grey} highlightColor={themeColors.lightGrey}>
								<AppDrawerProvider>
									<Router />
								</AppDrawerProvider>
							</SkeletonTheme>
						</ThemeProvider>
					</StoreProvider>
					<ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
				</QueryClientProvider>
			</WindowProvider>
		</ErrorBoundary>
	)
}
export default React.memo(App)
