import {
	Btn,
	CandidateJob,
	CandidateSkillList,
	Card,
	CTA,
	formatHtmlDateForUTC,
	Heading,
	Icon,
	Spinner,
	WizardStepProps,
} from '@merify/ui'
import { useFormikContext } from 'formik'
import styled, { css } from 'styled-components'
import { useGetSkillsWithScores } from '../../../api/api.skills'
import { Container } from '../../UI/Container'
import { SmallCtas } from '../../UI/SmallCtas'
import { CandidateJobSummary } from '../Candidate.job.summary'

export const createCandidateJobSkillsStyles = css`
	header {
		padding: ${props => props.theme.sizes.gutter.mobile.val} 0;
	}
	.candidate-job-summary {
		padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
	}
	.skills {
		> h4 {
			padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
			margin: 20px 0 10px 0;
			font-weight: 700;
		}

		.select-all-btn {
			margin-left: 20px;
			margin-top: 10px;
			display: flex;
			align-items: center;

			.check {
				border: solid 1px ${props => props.theme.colors.lightGrey.val};
				background: ${props => props.theme.colors.white.val};
				margin-right: 1em;
				border-radius: 50%;
				height: 32.5px;
				width: 32.5px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
`

const CreateCandidateJobSkillsView = styled.div`
	${createCandidateJobSkillsStyles}
`

export type CreateCandidateJobSkillsProps = {}
export const CreateCandidateJobSkills = ({
	...stepProps
}: CreateCandidateJobSkillsProps): JSX.Element => {
	const { previousStep, isActive } = stepProps as WizardStepProps
	const { isSubmitting, setFieldValue, values } = useFormikContext<
		{ candidateSkillIds: number[] } & CandidateJob
	>()
	const { candidateSkillIds, startDate, endDate } = values
	const { data, isLoading, error } = useGetSkillsWithScores()

	const allSkillIds = data?.map(skill => skill.candidateSkillId)

	const candidateJob = {
		...values,
		startDate: formatHtmlDateForUTC(startDate),
		endDate: formatHtmlDateForUTC(endDate || ''),
	} as CandidateJob

	// const isMounted = useRef(true)
	// useEffect(() => {
	// 	return () => {
	// 		isMounted.current = false
	// 	}
	// }, [])
	// if (!isMounted.current) return null
	return (
		<CreateCandidateJobSkillsView>
			<Container isStickyFooter isInactiveWizardStep={!isActive}>
				<header>
					<Heading hTag='h4' displayType='block-heading' dir='center'>
						Add skills to this job
					</Heading>
				</header>
				<section className='candidate-job-info'>
					<Card hasGutter>
						<CandidateJobSummary candidateJob={candidateJob} size='sm' />
					</Card>
				</section>

				<SmallCtas>
					<CTA size='sm'>
						<Btn onClick={() => previousStep()}>Previous</Btn>
					</CTA>
					<CTA size='sm'>
						<Btn noDefault={false}>Save</Btn>
					</CTA>
				</SmallCtas>

				<section className='skills'>
					<Heading hTag='h4' displayType='block-heading'>
						Skills to link
					</Heading>

					<Btn
						className='select-all-btn'
						onClick={() => {
							if (allSkillIds) {
								if (candidateSkillIds.length === allSkillIds.length)
									setFieldValue('candidateSkillIds', [])
								else setFieldValue('candidateSkillIds', allSkillIds)
							}
						}}
					>
						<div className='check'>
							{candidateSkillIds.length === allSkillIds?.length ? (
								<Icon type='checkCircle' />
							) : (
								<div className='circle' />
							)}
						</div>
						<span className='text'>
							{candidateSkillIds.length === (data?.length || 0) ? 'Deselect all' : 'Select all'}
						</span>
					</Btn>

					<CandidateSkillList
						skills={data}
						isLoading={isLoading}
						error={error}
						onSelectSkill={skill => {
							const matchIdx = candidateSkillIds.findIndex(id => skill.candidateSkillId === id)
							const newSkillIds = [...candidateSkillIds]
							if (matchIdx >= 0) newSkillIds.splice(matchIdx, 1)
							else newSkillIds.push(skill.candidateSkillId)

							setFieldValue('candidateSkillIds', newSkillIds)
						}}
						selectedSkillIds={candidateSkillIds}
					/>
				</section>
				{isSubmitting ? <Spinner /> : null}
			</Container>
		</CreateCandidateJobSkillsView>
	)
}
