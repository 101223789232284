import React from 'react'
import styled, { css } from 'styled-components'
import { AppNav } from './Nav.app'

export const appNavMobileStyles = css`
	nav {
		width: 100%;
		height: ${props => props.theme.sizes.footer.mobile.val};
		display: flex;
		align-items: stretch;
		margin-bottom: 5px;
		li {
			flex-grow: 1;
			.link {
				width: 100%;
				height: 100%;
			}
		}
	}
`

const AppNavMobileView = styled.div`
	${appNavMobileStyles}
`

export type AppNavMobileProps = {}
export const AppNavMobile = (): JSX.Element => {
	return (
		<AppNavMobileView>
			<AppNav />
		</AppNavMobileView>
	)
}
