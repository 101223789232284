import { ReviewSkill, useState } from '@merify/ui'
import { useCallback, useEffect } from 'react'
import { ReviewedSkillInput } from '../../../api/api.reviews'

export type RatedSkill = {
	skillId: number
	skillName: string
	levelId?: number | null
	rating?: number | null
	skipped?: boolean
	skillComments?: string | null
}

export type RateSkillHandler = (ratedSkill: RatedSkill) => void
export type AddCommentsToSkillHandler = (skillId: number, comments: string) => void

export const useReviewProviderSkills = (jobSkills?: ReviewSkill[], reviewId?: number) => {
	const [skillsToRate, setSkillsToRate] = useState<RatedSkill[]>([])
	const [prevRatedSkills, setPrevRatedSkills] = useState<RatedSkill[]>([])

	const ratedSkills = [] as ReviewedSkillInput[]
	const addressedSkills = [] as RatedSkill[]

	skillsToRate.forEach(skill => {
		const { skillId, levelId, rating, skipped, skillComments } = skill
		const ratedSkill = {
			skillId,
			skillLevelId: levelId || null,
			rating: rating || null,
			skillComments,
		} as ReviewedSkillInput
		ratedSkills.push(ratedSkill)

		if ((levelId && rating) || skipped) addressedSkills.push(skill)
	})

	const isAllRated = ratedSkills.length === addressedSkills.length
	const isLastSkill = ratedSkills.length === addressedSkills.length + 1

	const rateSkill: RateSkillHandler = useCallback((skill?: RatedSkill) => {
		if (skill)
			setSkillsToRate(state => {
				const newArry = [...state]
				const idx = state.findIndex(({ skillId }) => skillId === skill.skillId)
				if (idx >= 0) {
					const stateSkill = state[idx]

					newArry[idx] = {
						...stateSkill,
						rating: skill.skipped ? null : skill.rating ?? stateSkill.rating,
						levelId: skill.skipped ? null : skill.levelId ?? stateSkill.levelId,
						skipped: typeof skill.skipped === 'boolean' ? skill.skipped : stateSkill.skipped,
					}
				}
				return newArry
			})
	}, [])

	const addCommentsToSkill: AddCommentsToSkillHandler = useCallback(
		(skillId, comments) => {
			setSkillsToRate(state => {
				const newArry = [...state]
				const idx = state.findIndex(({ skillId: id }) => id === skillId)
				if (idx >= 0) {
					const stateSkill = state[idx]

					newArry[idx] = {
						...stateSkill,
						skillComments: comments,
					}
				}
				return newArry
			})
		},
		[setSkillsToRate]
	)

	useEffect(() => {
		if (jobSkills?.length) {
			const ratedSkills: RatedSkill[] = []
			const unratedSkills: RatedSkill[] = []

			jobSkills.forEach(jobSkill => {
				const { hasExistingSkillReview, skillId, skillName } = jobSkill

				if (hasExistingSkillReview)
					ratedSkills.push({
						skillId,
						skillName,
					})
				else
					unratedSkills.push({
						skillId,
						skillName,
					})
			})

			setPrevRatedSkills(ratedSkills)
			setSkillsToRate(unratedSkills)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jobSkills, reviewId])

	return {
		skillsToRate,
		prevRatedSkills,
		ratedSkills,
		isAllRated,
		isLastSkill,
		rateSkill,
		addCommentsToSkill,
	}
}
