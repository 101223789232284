import {
	Async,
	Btn,
	CANDIDATE_URL,
	Heading,
	Icon,
	ToggleField,
	parseNameFromUser,
} from '@merify/ui'
import copy from 'copy-to-clipboard'
import { useCallback } from 'react'
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	RedditIcon,
	RedditShareButton,
	TwitterIcon,
	TwitterShareButton,
} from 'react-share'
import styled, { css } from 'styled-components'
import { useGetSharedProfileToken, useShareProfile } from '../../../api/api.candidate'
import { useStoreState } from '../../../store'
import { Link } from '../../UI/Link'
import { toaster } from '../../UI/Toaster'

export const shareProfileStyles = css`
	text-align: center;
	color: ${props => props.theme.colors.secondary.val};
	//display: grid;
	//grid-gap: 1rem;

	section {
		margin: 1rem 0;

		&.share-profile-toggle {
			position: relative;
			min-height: 90px;
			display: flex;
			align-items: center;

			.toggle-field {
				justify-content: center;
				.label {
					font-size: 1.6rem;
					font-weight: 600;
				}
			}
		}

		&.copy-section {
			border: solid 1px ${props => props.theme.colors.grey.val};
			padding: 1rem;
			border-radius: 5px;

			${props => props.theme.media.tablet} {
				padding: 2rem;
			}

			.heading {
				color: ${props => props.theme.colors.darkNeutral.val};
				font-weight: 600;
			}

			.btn {
				font-size: 1.4rem;
				font-weight: 600;
				text-align: left;
			}

			.link-buttons {
				display: flex;

				.copy-btn {
					width: 64px;
					color: ${props => props.theme.colors.primary.val};
				}

				.url-link {
					width: 100%;
					color: ${props => props.theme.colors.primary.val};
					line-break: anywhere;
				}
			}
		}
	}

	svg {
		max-width: 288px;
		width: 100%;
		height: auto;
	}

	.share-buttons {
		.react-share__ShareButton {
			margin: 0 1px;
			max-width: 64px;
			max-height: 64px;
		}
	}
`

const ShareProfileView = styled.div`
	${shareProfileStyles}
`

/**
 *  Wraps react-share
 *  see: https://github.com/nygardk/react-share
 * */

export type ShareProfileProps = {
	cb?: () => void
}
export const ShareProfile = ({ cb: _ }: ShareProfileProps): JSX.Element => {
	const user = useStoreState(state => state.candidate.user)
	const name = parseNameFromUser(user)
	const title = `${name} on Merify`
	const description = `No keywords. No assessment tests. No pages of written references. Merify is a simple and effective way to share your talents and advance your career.`
	const hashtags = ['#merify', '#hiring', '#recruiting']

	const results = useGetSharedProfileToken()

	const { token, optedIn } = results.data || {}

	const hasToken = !results.isLoading && !results.error && token

	const shareResults = useShareProfile()
	const mut = shareResults.mutateAsync
	const shareToken = useCallback(
		async (optedIn: boolean) => {
			try {
				await mut({ optedIn })
			} catch (err) {
				toaster.err('Unable to create share link')
			}
		},
		[mut]
	)

	const url = token ? `${CANDIDATE_URL}/public/candidates/${token}` : ''

	const copyHandler = useCallback(() => {
		if (url) {
			const copied = copy(url)
			if (copied) {
				toaster.ok('Copied to clipboard!')
				// if (cb) cb()
			} else toaster.err('Unable to access clipboard')
		}
	}, [url])

	const shareProps = {
		url,
	}
	return (
		<ShareProfileView>
			<Async fetchResults={results} hideErrors>
				<section className='share-profile-toggle'>
					<Async fetchResults={shareResults}>
						<ToggleField
							className='share-toggle'
							name='share-link-toggle'
							label={hasToken ? 'Share my profile' : 'Create share link'}
							isOn={optedIn || false}
							onChange={shareToken}
						/>
					</Async>
				</section>
				{hasToken && optedIn ? (
					<>
						{/* <section>
							<MerifyIrmaReviewsSvg />
						</section> */}
						<section className='copy-section'>
							<Heading displayType='block-heading' mBot>
								Share Link:
							</Heading>

							<div className='link-buttons'>
								<Btn onClick={copyHandler} className='copy-btn'>
									<Icon type='clipboard' />
								</Btn>

								<Link to={url} className='url-link btn'>
									{url}
								</Link>
							</div>
						</section>
						<section className='share-buttons'>
							<LinkedinShareButton
								{...shareProps}
								title={title}
								summary={description}
								source='Merify Me'
							>
								<LinkedinIcon />
							</LinkedinShareButton>

							<FacebookShareButton {...shareProps} quote={title} hashtag={hashtags[0]}>
								<FacebookIcon />
							</FacebookShareButton>

							<TwitterShareButton
								{...shareProps}
								title={title}
								hashtags={hashtags}
								related={['https://twitter.com/merifyme']}
							>
								<TwitterIcon />
							</TwitterShareButton>

							<RedditShareButton {...shareProps} title={title}>
								<RedditIcon />
							</RedditShareButton>
						</section>
					</>
				) : null}
			</Async>
		</ShareProfileView>
	)
}
