import { Btn, Icon, useModalCtrl } from '@merify/ui'
import styled, { css } from 'styled-components'
import { ModalAlert } from '../../UI/Modal'
import { ShareProfile } from './Share.profile'

export const shareBtnStyles = css`
	button {
		color: ${props => props.theme.colors.secondary.val};
	}
`

const ShareBtnView = styled.div`
	${shareBtnStyles}
`

export type ShareBtnProps = {}
export const ShareBtn = (): JSX.Element => {
	const [[showModal, openModal, closeModal]] = useModalCtrl()
	return (
		<ShareBtnView>
			<Btn onClick={openModal}>
				<Icon type='share' />
			</Btn>
			<ModalAlert isOpen={showModal} closeHandler={closeModal}>
				<ShareProfile cb={closeModal} />
			</ModalAlert>
		</ShareBtnView>
	)
}
