import { createThemeGlobalStyles } from '@eclo/mode'
import { createTheme } from '@merify/ui'
import { createGlobalStyle } from 'styled-components'
import { modalStyles } from './modal.styles'

const theme = createTheme()

export const GlobalStyles = createGlobalStyle`
  ${createThemeGlobalStyles(theme)};

  html{
    font-size: 12px;
    width: 100%;
    height: 100%;

   &.public{
      ${props => props.theme.media.tablet}{
        /* patching scrollbar issue on windows */
        overflow-y: auto;
        scrollbar-gutter: stable both-edges;
        background: ${props => props.theme.colors.lightNeutral.val};
      }

      body{
        ${props => props.theme.media.tablet} {
          box-shadow: 0 0 15px ${props => props.theme.colors.neutral.opacity(80).val};;
        }
      }
   }

  }

	/* RSUITE */
	.rs-picker-menu {
		z-index: 15;
	}

	/* React Modal */
	${modalStyles}

  /* React Toastify */
  .Toastify__toast-container{
    bottom: ${props => props.theme.sizes.footer.mobile.val};
  }

	legend {
		box-sizing: border-box;
	}

	body {
		letter-spacing: 0.5px;
		background-color: #F5F5F5;
    ${props => props.theme.media.sdesk} {
		background: ${props => props.theme.colors.offWhite.dark().val};
	}
		&.__no-scroll {
			height: 100%;
			position: relative;
			overflow: hidden;
		}
		&.ReactModal__Body--open {
			overflow: hidden;
		}
	}

	div,button,a,ul,ol,article,blockquote {
		color: ${props => props.theme.colors.text.val};
	}


	/* Utility classes */
	.__hide-mobile {
		@media screen and (max-width: ${props => props.theme.breaks.tablet.num}px) {
			display: none;
		}
	}
	.__hide-tablet {
		@media only screen and (min-width: ${props =>
			props.theme.breaks.tablet.num}px) and (max-width: ${props =>
	props.theme.breaks.sdesk.num}px) {
			display: none;
		}
	}
	.__hide-desk {
		${props => props.theme.media.sdesk} {
			display: none;
		}
	}

`
