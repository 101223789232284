import { CandidateDemographicsOptions, CandidateIndustry, Skill } from '@merify/ui'
import { useMutation, UseQueryResult } from 'react-query'
import { authenticatedRequest } from '../utils/api.utils'
import { useCurrentUser } from '../utils/useCurrentUser'
import { useAuthedFetch } from '../utils/useFetch'
import { fetch } from './fetch'

export const useGetUserIndustries = (options?: { activeOnly?: boolean }) => {
	const { activeOnly = false } = options || {}
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<CandidateIndustry[]>('active-industries', () =>
		authenticatedRequest({ uid, token }, () =>
			fetch('GET', `/Admin/Industries`, token, {
				params: {
					activeOnly,
				},
			})
		)
	)
}

export const useGetAllDemographics = () => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<CandidateDemographicsOptions>('all-demographics', () =>
		authenticatedRequest({ uid, token }, () => fetch('GET', `/Admin/Demographics`, token))
	)
}

export const useGetAllSoftSkills = () => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<Skill[]>('all-soft-skills', () =>
		authenticatedRequest({ uid, token }, () => fetch('GET', `/Admin/Skills/Soft`, token))
	)
}

export const useGetAllTutorialVideos = () => {
	// TODO: replace mock data with endpoint once it's built
	return {
		data: [
			{
				youTubeVidId: 'nV_9A13CWyc',
				url: 'https://youtu.be/nV_9A13CWyc',
				title: 'Add Skills to Your Merify Profile',
				description:
					'This how-to video walks you through the quick and easy steps of adding skills to your Merify profile.',
				datePosted: '3.28.2022',
				tags: [],
				length: '2:34',
			},
			{
				youTubeVidId: 'mix-9VRe1-4',
				url: 'https://youtu.be/mix-9VRe1-4',
				title: 'Add Jobs to Your Merify Profile',
				description:
					'This how-to video walks you through the quick and easy process of adding jobs to your Merify profile.',
				datePosted: '3.28.2022',
				tags: [],
				length: '01:31',
			},
			{
				youTubeVidId: 'bXHM6dIJpjc',
				url: 'https://youtu.be/bXHM6dIJpjc',
				title: 'Add Reviews to Your Merify Profile',
				description:
					'This how-to video walks you through the quick and easy steps of adding skills to your Merify profile.',
				datePosted: '3.28.2022',
				tags: [],
				length: '3:41',
			},
		],
	} as UseQueryResult<any, unknown>
}

export const useInviteUser = () => {
	const { uid, token } = useCurrentUser()

	return useMutation(`${uid}-invite-user`, (email: string) =>
		authenticatedRequest({ uid, token }, () =>
			fetch('POST', `/Candidates/${uid}/Invite`, token, {
				data: {
					recipientEmailAddress: email,
				},
			})
		)
	)
}

type SubmitFeedbackArgs = {
	topic: string
	message: string
}
export const useSubmitFeedback = () => {
	const { uid, token } = useCurrentUser()

	return useMutation(`${uid}-submit-feedback`, (data: SubmitFeedbackArgs) =>
		authenticatedRequest({ uid, token }, () =>
			fetch('POST', `/Notifications/Candidates/${uid}/Feedback`, token, {
				data,
			})
		)
	)
}
