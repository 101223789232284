import { Btn, CTA, ErrorBlock, Heading, Spinner, Text, useModalCtrl } from '@merify/ui'
import { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useRequestNewSkill } from '../../../api/api.skills'
import { ModalAlert } from '../../UI/Modal'

export const skillRequestStyles = css`
	padding-bottom: 50px;
	p {
		text-align: center;
		font-size: 1.3rem;
		line-height: 1.6rem;
		width: 80%;
		margin: 20px auto 30px auto;
	}
`

const SkillRequestView = styled.div`
	${skillRequestStyles}
`

export type SkillRequestProps = {
	skillStr?: string
	cb?: () => void
}
export const SkillRequest = ({ skillStr, cb }: SkillRequestProps): JSX.Element => {
	const { mutateAsync, isLoading, error } = useRequestNewSkill()
	const [[showRequestModal, openRequestModal, closeRequestModal]] = useModalCtrl()
	const [[showConfirmModal, openConfirmModal, closeConfirmModal]] = useModalCtrl()

	const onSubmit = async () => {
		try {
			if (!skillStr) return
			await mutateAsync({ name: skillStr })
			openConfirmModal()
		} catch (err) {}
	}

	const isMounted = useRef(true)
	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])
	if (!isMounted.current) return <></>

	return (
		<SkillRequestView>
			{isLoading ? <Spinner /> : null}
			<ErrorBlock error={error} display='inline' />
			{skillStr ? (
				<CTA>
					<Btn onClick={openRequestModal}>Request to add skill</Btn>
				</CTA>
			) : null}
			{skillStr && skillStr?.length > 100 ? (
				<Text textType='italic' color='err'>
					Skill name must be at most 100 characters
				</Text>
			) : null}
			<Text textType='p'>
				Not seeing the skill you want? Type the full name above and request that we add it.
			</Text>
			<ModalAlert
				isOpen={showRequestModal}
				closeHandler={closeRequestModal}
				confirmTxt={'Yes'}
				showAlertBtns={true}
				asyncConfirmHandler={onSubmit}
			>
				<Heading hTag='h4' displayType='block-heading' dir='center'>
					Are you sure you want to request to add {skillStr}?
				</Heading>
			</ModalAlert>
			<ModalAlert
				isOpen={showConfirmModal}
				closeHandler={() => {
					closeConfirmModal()
					cb && cb()
				}}
				showAlertBtns='ok'
			>
				<Text textType='p'>
					A request has been sent to the system administrator. This skill will not be available
					until it has been approved.
				</Text>

				<Text textType='p'>
					When the skill is approved you will receive a notification that it's ready to use!
				</Text>
			</ModalAlert>
		</SkillRequestView>
	)
}
