import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { useAppDrawer } from '../../Drawer.app'

export const appDrawerInOverlayStyles = css`
	.main-content-container {
		transition: all ${props => props.theme.times.tranM};
		left: 0;
		overflow: hidden;
		max-width: 550px;
	}
	.drawer-container {
		display: flex;
		align-items: flex-start;
		position: fixed;
		margin-top: ${props => props.theme.sizes.header.mobile.val};
		transition: all ${props => props.theme.times.tranM};
		top: 0;
		right: -100vw;
		width: 100%;
		height: calc(100vh - ${props => props.theme.sizes.header.mobile.val});
		background: ${props => props.theme.colors.offWhite.val};
		box-shadow: -3.5px 0px 3.5px 0px ${props => props.theme?.colors?.midNeutral?.opacity(40).val};
		overflow-y: auto;

		.app-drawer-tray {
			margin: 0;
			max-width: 550px;
			width: 100%;
			padding-bottom: ${props => props.theme.sizes.footer.mobile.num + 80}px;
		}
	}
	&.__app-drawer-open {
		.main-content-container {
			left: -10%;
		}
		.drawer-container {
			right: 0;
			${props => props.theme.media.tablet} {
				right: -200px;
			}
		}
	}
`

const AppDrawerInOverlayView = styled.div`
	${appDrawerInOverlayStyles}
`

export type AppDrawerInOverlayProps = {
	children: ReactNode
}
export const AppDrawerInOverlay = ({ children }: AppDrawerInOverlayProps): JSX.Element => {
	const {
		isAppDrawerOpen,
		drawerContent, // content for the drawer components (desk and mobile drawers)
	} = useAppDrawer()

	return (
		<AppDrawerInOverlayView className={`${isAppDrawerOpen ? '__app-drawer-open' : ''}`}>
			<div className='main-content-container'>{children}</div>
			<div className='drawer-container'>{drawerContent}</div>
		</AppDrawerInOverlayView>
	)
}
