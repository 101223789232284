import { Btn, CandidateIntersect, CTA, ErrorBlock, Text, useModalCtrl } from '@merify/ui'
import { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useInviteUser } from '../../../api/api.admin'
import { ModalAlert } from '../../UI/Modal'
import { toaster } from '../../UI/Toaster'

export const inviteUsrBtnStyles = css`
	.invite-btn {
	}
`

const InviteUserBtnView = styled.div`
	${inviteUsrBtnStyles}
`

export type InviteUserBtnProps = {
	searchStr?: string
	cb?: (candidate: CandidateIntersect) => void
}
export const InviteUserBtn = ({ searchStr, cb }: InviteUserBtnProps): JSX.Element => {
	const [[showInviteModal, openInviteModal, closeInviteModal]] = useModalCtrl()
	const { mutateAsync, error } = useInviteUser()
	const isMounted = useRef(true)
	const inviteHandler = async () => {
		if (searchStr) {
			const newUser = await mutateAsync(searchStr.trim().toLowerCase())

			toaster.ok('Invitation sent!')
			if (cb) {
				// TODO: remove after upgrading to react 18
				setTimeout(() => {
					cb(newUser)
				}, 700)
			}
		}
	}
	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])
	if (!isMounted.current) return <></>
	return (
		<InviteUserBtnView>
			<ErrorBlock error={error} display='inline'>
				<CTA size='sm' className='invite-btn'>
					<Btn onClick={openInviteModal}>INVITE TO MERIFY</Btn>
				</CTA>
			</ErrorBlock>
			<ModalAlert
				isOpen={showInviteModal}
				closeHandler={closeInviteModal}
				asyncConfirmHandler={inviteHandler}
				confirmTxt='Send'
				showAlertBtns
			>
				<Text size='med'>Send {searchStr} an invitation to Merify?</Text>
			</ModalAlert>
		</InviteUserBtnView>
	)
}
