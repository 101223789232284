import { Circle, Icon, IncomingReviewRequest, parseNameFromUser, UserIntersect } from '@merify/ui'
import styled, { css, useTheme } from 'styled-components'
import { Avatar } from '../Avatar'
import { CandidateJobSummary } from '../Candidate.job.summary'

export const reviewJobCardStyles = css`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px ${props => props.theme.sizes.gutter.mobile.val};
	border-top: 1px solid ${props => props.theme.colors.paleGrey.val};
	background-color: ${props => props.theme.colors.white.val};
	text-align: left;
	overflow: hidden;

	.info {
		flex: 2;
		padding-left: 20px;
	}
	.reviewed-icon {
		color: ${props => props.theme.colors.primary.val};
	}
`

const ReviewJobCardView = styled.div`
	${reviewJobCardStyles}
`

export type ReviewJobCardProps = {
	reviewData: Pick<IncomingReviewRequest, 'job' | 'jobSkills' | 'person' | 'existingReview'>
}
export const ReviewJobCard = ({ reviewData }: ReviewJobCardProps): JSX.Element => {
	const { person, job, jobSkills, existingReview } = reviewData
	const { colors } = useTheme()
	const needsUpdate = existingReview?.needsUpdate

	const skillsForReview = jobSkills.filter(({ hasExistingSkillReview }) => !hasExistingSkillReview)

	return (
		<ReviewJobCardView className='review-job-card'>
			{/* @ts-ignore MIGRATION */}
			<Avatar size='m' user={person as UserIntersect} />
			<div className='info'>
				{parseNameFromUser(person as UserIntersect)}
				<CandidateJobSummary candidateJob={job as any} />
			</div>
			{skillsForReview.length ? (
				<Circle
					isSolid={false}
					value={skillsForReview.length}
					label='Skills'
					hasPlus={needsUpdate}
					valColor={needsUpdate ? colors.primary.val : undefined}
				/>
			) : (
				<Icon className='reviewed-icon' type='checkCircle' />
			)}
		</ReviewJobCardView>
	)
}
