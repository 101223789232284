import styled, { css } from 'styled-components'
import { Link } from '../UI/Link'

export const legalLinksStyles = css`
	padding: 1rem;

	li {
		padding: 0.3rem 0;
		span {
			color: ${props => props.theme.colors.darkNeutral.val};
		}
		a {
			color: ${props => props.theme.colors.secondary.val};
		}
	}
`

const LegalLinksView = styled.div`
	${legalLinksStyles}
`

export type LegalLinksProps = {}
export const LegalLinks = (): JSX.Element => {
	return (
		<LegalLinksView className='legal-links'>
			<ul>
				<li>
					<span>Legal</span>
				</li>
				<li>
					<Link
						to='https://ccvstorageaccount.blob.core.windows.net/documents/Merify%20Privacy%20Policy.pdf'
						title='Privacy policy information'
					>
						Privacy Policy
					</Link>
				</li>
				<li>
					<Link
						to='https://ccvstorageaccount.blob.core.windows.net/documents/Merify%20Terms%20Of%20Use.pdf'
						title='Terms of service information'
					>
						Terms of Service
					</Link>
				</li>
				<li>
					<span>Copyright © Merify, LLC {new Date().getFullYear()}</span>
				</li>
			</ul>
		</LegalLinksView>
	)
}
