import { CTA, Heading, MerifyIrmaReviewsSvg } from '@merify/ui'
import styled, { css } from 'styled-components'
import { Link } from '../../../UI/Link'

export const publicReviewAlreadyCompleteStyles = css`
	text-align: center;
	background: ${props => props.theme.colors.primary.val};
	color: ${props => props.theme.colors.white.val};
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	padding-top: 5vh;

	${props => props.theme.media.sdesk} {
		min-height: 80vh;
	}

	section {
		padding: 2rem 0;
	}

	h1 {
		font-size: 3rem;
		margin-bottom: 1rem;
	}

	svg.irma-reviews {
		color: ${props => props.theme.colors.secondary.val};
	}
`

const PublicReviewAlreadyCompleteView = styled.div`
	${publicReviewAlreadyCompleteStyles}
`

export type PublicReviewAlreadyCompleteProps = {
	isReviewerRegistered?: boolean
}
export const PublicReviewAlreadyComplete = ({
	isReviewerRegistered,
}: PublicReviewAlreadyCompleteProps): JSX.Element => {
	return (
		<PublicReviewAlreadyCompleteView>
			<section>
				<Heading hTag='h1' displayType='screen-heading'>
					Nice Work! 🎉
				</Heading>

				{/* //TODO swap this heading out for a text component */}
				<Heading hTag='h2'>You have already completed this review.</Heading>
			</section>
			<section>
				<CTA displayType='solid'>
					{isReviewerRegistered ? (
						<Link to='/reviews'>Go to my reviews</Link>
					) : (
						<Link to='/'>Join Merify!</Link>
					)}
				</CTA>
			</section>
			<section>
				<MerifyIrmaReviewsSvg />
			</section>
		</PublicReviewAlreadyCompleteView>
	)
}
