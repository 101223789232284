import { css } from 'styled-components'

export const modalStyles = css`
	html {
		color: inherit;
	}

	.react-modal {
		.modal-overlay {
			z-index: 100;
			position: fixed;
			transition: all ${props => props.theme.times.tranM};
			width: 100vw;
			height: 100vh;
			top: 0;
			left: 0;
			&.pic {
				background: ${props => props.theme.colors.black.tint(80).val};
			}
			&.ReactModal__Overlay--after-open {
				background: ${props => props.theme.colors.black.tint(60).val};
				.modal-wrap {
					opacity: 1;
					transform: translate3d(0, 0, 0);
				}
			}
			&.ReactModal__Overlay--before-close {
				background: ${props => props.theme.colors.black.tint(0).val};
				.modal-wrap {
					opacity: 0;
					transform: translate3d(0, 50px, 0);
				}
			}
			.modal {
				border: none;
				outline: none;
				box-shadow: none;
				max-width: 90vw;
				${props => props.theme.media.tablet} {
					max-width: initial;
				}
			}
		}

		.react-modal-content {
			height: 100%;
		}

		&.alert-modal {
			background: blue;
		}

		&.full-modal {
		}
	}
`
