import { css } from 'styled-components'

export const publicLayoutStyles = css`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	//max-height: 100vh;
	margin: 0 auto;
	position: relative;

	${props => props.theme.media.tablet} {
		min-height: 100vh;
		max-height: initial;
	}

	.page {
		display: flex;
		flex-direction: column;

		${props => props.theme.media.tablet} {
			flex-direction: row;
			align-items: stretch;
			height: 100%;
			flex-grow: 1;
		}
		& > aside {
			display: none;

			${props => props.theme.media.tablet} {
				display: flex;
				flex-direction: column;
				background: ${props => props.theme.colors.white.val};

				.legal-links {
					margin-top: auto;
				}
			}
		}

		& > main {
			flex-grow: 1;
			overflow-y: auto;
			overflow-x: hidden;
			padding-bottom: ${props => props.theme.sizes.footer.mobile.val};

			${props => props.theme.media.tablet} {
				padding-bottom: 0;
				margin: 0 3vw;
			}

			${props => props.theme.media.sdesk} {
				display: flex;
				justify-content: center;
				margin: 0;
			}

			& > .inner-wrap {
				max-width: 550px;
				margin: 0 auto;
				overflow-x: hidden;
				/* max-height: calc(100vh - ${props => props.theme.sizes.header.mobile.val}); */
				padding: 0;

				${props => props.theme.media.sdesk} {
					overflow-x: unset;
					background: ${props => props.theme.colors.offWhite.val};
					padding: 2rem 0;
					width: 550px;
				}
			}
		}

		.bg-m {
			display: none;
			${props => props.theme.media.sdesk} {
				display: block;
				width: 100vw;
				height: calc(100vh - ${props => props.theme.sizes.header.mobile.val});
				position: absolute;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				overflow: hidden;
			}
			.merify-m {
				width: 120%;
				height: auto;
				margin: 0 auto;
				opacity: 0.1;
				color: ${props => props.theme.colors.white.val};
				//position: absolute;
				position: fixed;
				pointer-events: none;
				transform: translate(-7%, 13%);
			}
		}
	}

	.app-footer {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);

		${props => props.theme.media.tablet} {
			display: none;
		}
	}
`
