import { Btn, Heading, Icon, Skill, Text, useModalCtrl } from '@merify/ui'
import styled, { css } from 'styled-components'
import { SkillAddOptions } from '.'
import { useGetAllSoftSkills } from '../../../api/api.admin'
import { ModalAlert } from '../../UI/Modal'
import { SkillAddSelectProps } from './Skill.add.select'

const MAX_SKILLS = 40

export const skillAddSoftSkillsStyles = css`
	padding: ${props => props.theme.sizes.gutter.mobile.val};
	position: relative;

	.disable-add-skills {
		box-shadow: none;
		border: none;
		outline: none;
		background: ${props => props.theme.colors.offWhite.opacity(70).val};
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		z-index: 100;
	}

	h5 {
		/* color: ${props => props.theme.colors.secondary.val}; */
	}

	/* .accordion {
		.ctrl {
			font-size: 1.4rem;
			color: ${props => props.theme.colors.secondary.val};
			width: 100%;
			display: flex;
			align-items: center;
			background: ${props => props.theme.colors.white.val};
			padding: 0.3em 0.5em;
			margin-bottom: 1em;
			.triangle-wrap {
				margin-left: auto;
			}
		}
	} */
	.soft-skill-btn {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 0.4em 0;
		color: ${props => props.theme.colors.text.val};
		.icon {
			margin-left: auto;
			color: ${props => props.theme.colors.secondary.val};
		}

		.already-added {
			margin-left: 0.5em;
			text-decoration: none;
		}

		&:disabled {
			.skill-name {
				text-decoration: line-through;
			}
			.icon {
				display: none;
			}
		}
	}
`

const SkillAddSoftSkillsView = styled.div`
	${skillAddSoftSkillsStyles}
`

export type SkillAddSoftSkillsProps = SkillAddOptions & SkillAddSelectProps & {}
export const SkillAddSoftSkills = ({
	onSelect,
	previouslySelectedSkills,
}: SkillAddSoftSkillsProps): JSX.Element => {
	const isAtMax = (previouslySelectedSkills?.length || 0) >= MAX_SKILLS
	const { data } = useGetAllSoftSkills()

	const [[showModal, openModal, closeModal]] = useModalCtrl()

	const existingSkillIds = previouslySelectedSkills?.map(({ skillId }) => skillId)
	return (
		<SkillAddSoftSkillsView>
			<Heading hTag='h5' displayType='block-heading' color='darkGrey'>
				Select a soft skill
			</Heading>
			<div className='soft-skills-list'>
				{isAtMax ? <button onClick={openModal} className='disable-add-skills' /> : null}
				<ModalAlert isOpen={showModal} closeHandler={closeModal} showAlertBtns='ok'>
					<Text size='med'>You have reached the maximum of {MAX_SKILLS} skills allowed.</Text>
				</ModalAlert>
				{data?.length
					? data.map(skill => {
							const exists = existingSkillIds?.includes(skill.skillId)
							return (
								<Btn
									className={`soft-skill-btn`}
									key={skill.skillId}
									onClick={() => onSelect(skill as Skill)}
									disabled={exists}
								>
									<span className='skill-name'>{skill.name}</span>
									{exists ? (
										<Text textType='italic' className='already-added'>
											(already added)
										</Text>
									) : null}
									<Icon type='plusCircle' />
								</Btn>
							)
					  })
					: null}
			</div>
		</SkillAddSoftSkillsView>
	)
}
