import { useState } from '@merify/ui'
import React, { HTMLProps, ReactElement, ReactNode, useRef } from 'react'
import {
	LeadingActions,
	SwipeableListItem,
	SwipeAction,
	TrailingActions,
} from 'react-swipeable-list'
import { SwipeListItemDelete } from './SwipeList.delete'

export type SwipeListItemActionCb = (val: number | any) => void

type ClickableProps = Omit<HTMLProps<HTMLButtonElement>, 'type' | 'onClick'> & {
	onClick: () => void
}

export type SwipeListItemChildProps = {
	Clickable?: React.FC<ClickableProps>
}

type SwipeListItemAction = {
	txt?: ReactNode
	cb: SwipeListItemActionCb
}

export type SwipeListItemProps = {
	content: ReactElement
	val: number | any
	leading?: SwipeListItemAction
	trailing?: SwipeListItemAction
	disabled?: boolean
	isDeletion?: boolean
}
export const SwipeListItem = ({
	content,
	leading,
	val,
	trailing,
	disabled,
	isDeletion,
	...props
}: SwipeListItemProps): JSX.Element => {
	const isSwipe = useRef(false)
	const [clicked, setClicked] = useState(false, 'clicked')

	const mouseDown = () => {
		setClicked(true)
	}

	const mouseUp = (onClick?: Function) => {
		if (isSwipe.current === false && clicked && onClick) onClick()
	}

	const Clickable = ({ className, onClick, ...props }: ClickableProps): JSX.Element => (
		<button
			className={`swipe-list-clickable-item${className ? ` ${className}` : ''}`}
			onMouseDown={mouseDown}
			onMouseUp={() => mouseUp(onClick)}
			{...props}
		/>
	)

	return (
		<SwipeableListItem
			onSwipeStart={() => {
				isSwipe.current = true
			}}
			onSwipeEnd={() => {
				setTimeout(() => {
					isSwipe.current = false
				}, 10)
			}}
			blockSwipe={disabled}
			leadingActions={
				leading ? (
					<LeadingActions>
						<SwipeAction onClick={() => leading.cb(val)} Tag='div'>
							{leading.txt}
						</SwipeAction>
					</LeadingActions>
				) : null
			}
			trailingActions={
				trailing ? (
					<TrailingActions>
						<SwipeAction onClick={() => trailing.cb(val)} destructive={isDeletion} Tag='div'>
							{isDeletion ? <SwipeListItemDelete /> : trailing.txt || null}
						</SwipeAction>
					</TrailingActions>
				) : null
			}
			{...props}
		>
			{React.cloneElement(content, {
				...content.props,
				Clickable,
			} as SwipeListItemChildProps)}
		</SwipeableListItem>
	)
}
