import { CandidateJob, formatUTC, Heading } from '@merify/ui'
import styled, { css } from 'styled-components'
import { SizeLarge, SizeSmall } from '../../../types/var.types'

export const candidateCandidateJobSummaryStyles = css`
	& > h5 {
		margin: 0;
	}

	&.__size-sm {
		h5 {
			font-size: 1rem;
			line-height: 1.4rem;
			color: ${props => props.theme.colors.text.val};
			//font-weight: bold;
		}
	}

	&.__size-lg {
		h5 {
			line-height: 1.5rem;
			color: ${props => props.theme.colors.secondary.val};
			line-height: 2rem;
		}
	}

	.candidate-job-project {
		font-style: italic;
	}
`

const CandidateJobSummaryView = styled.div`
	${candidateCandidateJobSummaryStyles}
`

export type CandidateJobSummaryProps = {
	candidateJob: Pick<
		CandidateJob,
		'jobTitle' | 'employer' | 'startDate' | 'endDate' | 'projectName'
	>
	size?: SizeSmall | SizeLarge
	className?: string
}
export const CandidateJobSummary = ({
	candidateJob,
	size = 'sm',
	className,
}: CandidateJobSummaryProps): JSX.Element => {
	const { jobTitle, employer, projectName, startDate, endDate } = candidateJob
	return (
		<CandidateJobSummaryView
			className={`candidate-job-summary${className ? ` ${className}` : ''}  __size-${size}`}
		>
			{jobTitle ? <Heading hTag='h5'>{jobTitle}</Heading> : null}
			{employer ? (
				<Heading hTag='h5' className='candidate-job-employer'>
					{employer}
				</Heading>
			) : null}
			{projectName ? (
				<Heading hTag='h5' className='candidate-job-project'>
					{projectName}
				</Heading>
			) : null}
			{startDate ? (
				<Heading hTag='h5' className='candidate-job-dates'>
					{formatUTC(startDate)}
					{endDate ? ` - ${formatUTC(endDate)}` : ''}
				</Heading>
			) : null}
		</CandidateJobSummaryView>
	)
}
