import {
	Btn,
	CandidateJobAndSkills,
	CandidateSkillList,
	CTA,
	Ctas,
	ErrorBlock,
	Icon,
	Spinner,
	useState,
} from '@merify/ui'
import styled, { css } from 'styled-components'
import { useAddSkillsToJob } from '../../../api/api.jobs'
import { useGetSkillsWithScores } from '../../../api/api.skills'

export const candidateJobUpdateSkillsStyles = css`
	.select-all-btn {
		margin-left: 20px;
		margin-top: 10px;
		display: flex;
		align-items: center;

		.check {
			border: solid 1px ${props => props.theme.colors.lightGrey.val};
			background: ${props => props.theme.colors.white.val};
			margin-right: 1em;
			border-radius: 50%;
			height: 32.5px;
			width: 32.5px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`

const CandidateJobUpdateSkillsView = styled.div`
	${candidateJobUpdateSkillsStyles}
`

export type CandidateJobUpdateSkillsProps = {
	jobWithSkills: CandidateJobAndSkills
	cb: () => void
}
export const CandidateJobUpdateSkills = ({
	cb,
	jobWithSkills,
}: CandidateJobUpdateSkillsProps): JSX.Element => {
	const { candidateJob: job, candidateJobSkills: skills } = jobWithSkills

	const [skillIdsForSave, setSkillIdsForSave] = useState(
		skills.map(({ candidateSkillId }) => candidateSkillId),
		'skillIdsForSave'
	)

	const { data, isLoading, error } = useGetSkillsWithScores()

	const allSkillIds = data?.map(skill => skill.candidateSkillId)

	const {
		mutateAsync,
		error: editErr,
		isLoading: editLoading,
	} = useAddSkillsToJob(job.candidateJobId)

	const onSubmit = async () => {
		try {
			await mutateAsync(skillIdsForSave)
			cb()
		} catch (error) {}
	}
	return (
		<CandidateJobUpdateSkillsView>
			<ErrorBlock error={editErr} display='inline' />

			<Btn
				className='select-all-btn'
				onClick={() => {
					if (allSkillIds) {
						if (skillIdsForSave.length === allSkillIds.length) setSkillIdsForSave([])
						else setSkillIdsForSave(allSkillIds)
					}
				}}
			>
				<div className='check'>
					{skillIdsForSave.length === allSkillIds?.length ? (
						<Icon type='checkCircle' />
					) : (
						<div className='circle' />
					)}
				</div>
				<span className='text'>
					{skillIdsForSave.length === allSkillIds?.length ? 'Deselect All' : 'Select All'}
				</span>
			</Btn>

			<CandidateSkillList
				skills={data}
				isLoading={isLoading}
				error={error}
				onSelectSkill={skill => {
					setSkillIdsForSave(state => {
						const matchIdx = state.findIndex(id => skill.candidateSkillId === id)
						const newSkillIds = [...state]
						if (matchIdx >= 0) newSkillIds.splice(matchIdx, 1)
						else newSkillIds.push(skill.candidateSkillId)

						return newSkillIds
					})
				}}
				selectedSkillIds={skillIdsForSave}
			/>
			<section>
				{editLoading ? <Spinner /> : null}
				<Ctas>
					<CTA pad={false}>
						<Btn onClick={() => cb()}>Cancel</Btn>
					</CTA>
					<CTA pad={false} displayType='solid'>
						<Btn onClick={onSubmit} disabled={skillIdsForSave.length === 0}>
							{skills?.length ? 'Update Skills' : 'Add Skills'}
						</Btn>
					</CTA>
				</Ctas>
			</section>
		</CandidateJobUpdateSkillsView>
	)
}
