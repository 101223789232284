import styled from 'styled-components'

export const ReviewsScreenView = styled.div`
	.reviews-guide {
		padding: 10px 0;
	}
	.review-screen-header {
		padding: 1em ${props => props.theme.sizes.gutter.mobile.val};
		h4 {
			margin: 0 auto;
			color: ${props => props.theme.colors.secondary.val};
		}
		.cta-wrap {
			padding: 0;
		}

		.request-review-cta {
			display: flex;
			width: 100%;
			justify-content: center;
		}
	}

	.no-reviews {
		padding: ${props => props.theme.sizes.gutter.mobile.val};
	}
	.no-ignored-reviews {
		padding: 10px 20px;
	}

	.incoming-reviews,
	.ignored-reviews {
		.ctrl .heading {
			padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
		}
	}

	.incoming-reviews {
		& > .heading {
			margin: 20px auto;
			padding: 0 ${props => props.theme.sizes.gutter.mobile.val};

			&.ignored-title {
				margin: 20px 0;
			}
		}
	}
`
