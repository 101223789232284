import React, { ReactNode } from 'react'
import RModal from 'react-modal'

/**
 *  React modal docs: http://reactcommunity.org/react-modal/
 * */

RModal.setAppElement('#root')

export type ReactModalProps = {
	isOpen: boolean
	onAfterOpen?: () => void
	onAfterClose?: () => void
	onRequestClose?: () => void
	closeTimeoutMS?: number
	contentLabel?: string
	portalClassName?: string
	overlayClassName?: string
	className?: string
	bodyOpenClassName?: string
	htmlOpenClassName?: string
	ariaHideApp?: boolean
	shouldFocusAfterRender?: boolean
	shouldCloseOnOverlayClick?: boolean
	shouldCloseOnEsc?: boolean
	shouldReturnFocusAfterClose?: boolean
	role?: string
	preventScroll?: boolean
	parentSelector?: () => HTMLElement
	aria?: {
		labelledby: string
		describeby: string
	}
	data?: {
		[x: string]: string
	}
	testId?: string
	// overlayRef?: any
	// contentRef?: any
	// overlayElement?: React.FC
	// contentElement?: React.FC
}

export type ModalProps = {
	isOpen: boolean
	onOpen?: () => void
	onClose?: () => void
	onClickToClose?: () => void
	canClickBg?: boolean
	className?: string
	children?: ReactNode
}
export const Modal = ({
	isOpen,
	onOpen,
	onClose,
	onClickToClose,
	className,
	children,
}: ModalProps): JSX.Element => {
	const modalProps: ReactModalProps = {
		isOpen,
		onAfterOpen: onOpen,
		onAfterClose: onClose,
		onRequestClose: onClickToClose,
		portalClassName: `react-modal${className ? ` ${className}` : ''}`,
		overlayClassName: 'modal-overlay',
		className: `react-modal-content`,
	}

	return <RModal {...modalProps}>{children}</RModal>
}
