import { CTA, useState } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetReviewRequestByToken, usePostPublicReview } from '../../../api/api.reviews'
import { ReviewsGuide } from '../../screens/reviews/Reviews.guide'
import { Link } from '../../UI/Link'
import { ReviewProvider } from '../Review.provider'
import { ReviewProviderSuccess } from '../Review.provider/Review.provider.success'
import { ReviewSkills } from './Review.skills'

export const publicReviewStyles = css`
	overflow: hidden;

	.reviews-guide {
		padding-bottom: 20px;
	}
`

const PublicReviewView = styled.div`
	${publicReviewStyles}
`

export type PublicReviewProps = {
	reviewToken: string
}
export const PublicReview = ({ reviewToken }: PublicReviewProps): JSX.Element => {
	const results = useGetReviewRequestByToken(reviewToken)

	const { mutateAsync: reviewMut, isLoading, error } = usePostPublicReview(reviewToken)

	const isUpdate = !!results.data?.existingReview?.needsUpdate
	const isPreviouslyCompleted =
		!isUpdate &&
		!!results?.data?.jobSkills.every(({ hasExistingSkillReview }) => hasExistingSkillReview)

	const [showSuccess, setShowSuccess] = useState(isPreviouslyCompleted, 'showSuccess')

	return (
		<PublicReviewView>
			<ReviewsGuide />
			{isPreviouslyCompleted || showSuccess ? (
				<ReviewProviderSuccess
					previouslyCompleted={isPreviouslyCompleted}
					cta={
						<CTA color='white'>
							<Link to='/'>Join Merify!</Link>
						</CTA>
					}
				/>
			) : (
				<ReviewProvider
					reviewFetchRequest={results}
					publicReviewMut={reviewMut}
					mutLoading={isLoading}
					mutError={error}
					onAfterSuccess={() => setShowSuccess(true)}
				>
					<ReviewSkills />
				</ReviewProvider>
			)}
		</PublicReviewView>
	)
}
