import { Btn, CTA } from '@merify/ui'
import styled, { css } from 'styled-components'
import { WelcomeVideoScreen } from '../../../global/Welcome.video.screen/Welcome.video.screen'

export const jobsScreenWelcomeStyles = css``

const JobsScreenWelcomeView = styled.div`
	${jobsScreenWelcomeStyles}
`

export type JobsScreenWelcomeProps = {
	cb: () => void
}
export const CandidateJobsScreenWelcome = ({ cb }: JobsScreenWelcomeProps): JSX.Element => {
	return (
		<JobsScreenWelcomeView>
			<WelcomeVideoScreen
				icon='jobs'
				title='Your Jobs'
				text={`Now it's time to add the jobs where you've learned and/or are learning your skills. Here's a short video to explain the process.`}
				video={{
					url: 'https://youtu.be/mix-9VRe1-4',
					youTubeVidId: 'mix-9VRe1-4',
				}}
			>
				<CTA padTop>
					<Btn onClick={cb}>Begin</Btn>
				</CTA>
			</WelcomeVideoScreen>
		</JobsScreenWelcomeView>
	)
}
