import {
	Avatar,
	AvatarProps,
	Btn,
	ReviewJob,
	Text,
	Trunc,
	formatUTC,
	parseNameFromUser,
} from '@merify/ui'
import styled, { css } from 'styled-components'

const ReviewJobLinkStyles = css`
	flex-grow: 1;
	transition: all ${props => props.theme.times.tranM};
	overflow: hidden;
	padding: 1em 1.3em;
	border: solid 2px ${props => props.theme.colors.primary.val};
	border-radius: 15px;
	z-index: 1;
	width: 100%;
	min-width: 90px;

	.job {
		cursor: default;
		&.__clickable {
			cursor: pointer;
		}
		.top {
			display: flex;
			align-items: center;
			color: ${props => props.theme.colors.primary.val};

			.job-title {
				margin-left: 5px;
			}
		}

		.bottom {
			transition: all ${props => props.theme.times.tranS};
			overflow: hidden;
			text-align: left;
			.info {
				color: ${props => props.theme.colors.primary.val};
				padding: 1em;
			}
			.dates {
				color: inherit;
			}
		}
	}

	&.__expanded {
		min-width: 80%;
	}

	&.__collapsed {
		.job {
			width: 53px;
		}
	}
`

const ReviewJobLinkView = styled.div`
	${ReviewJobLinkStyles}
`

export type ReviewAndKudosJob = Pick<
	ReviewJob,
	'jobTitle' | 'employer' | 'startDate' | 'endDate'
> & {
	projectName?: string | null
}
export type ReviewJobLinkProps = {
	className?: string
	isExpanded: boolean
	isCollapsed: boolean
	user: AvatarProps['user']
	job: ReviewAndKudosJob
	onClick?: () => void
}
export const ReviewJobLink = ({
	className,
	isExpanded,
	isCollapsed,
	user,
	job,
	onClick,
}: ReviewJobLinkProps): JSX.Element => {
	return (
		<ReviewJobLinkView
			className={`review-job-link${className ? ` ${className}` : ''}${
				isExpanded ? ' __expanded' : isCollapsed ? ' __collapsed' : ''
			}`}
			style={{ maxWidth: isCollapsed ? '90px' : '50%' }}
		>
			<Btn className={`job${onClick ? ` __clickable` : ''}`} onClick={onClick}>
				<div className='btn-content'>
					<div className='top'>
						<Avatar user={user} />
						{isCollapsed ? null : (
							<Text className='job-title'>
								{isExpanded ? job.jobTitle : <Trunc>{job.jobTitle}</Trunc>}
							</Text>
						)}
					</div>
					<div className='bottom' style={{ maxHeight: isExpanded ? '100px' : '0px' }}>
						<div className='info'>
							<Text className='name'>{parseNameFromUser(user)}</Text>
							<br />
							<Text className='employer'>
								{job.employer} {job.projectName ? ` - ${job.projectName}` : null}
							</Text>
							<div className='dates'>
								<Text>
									{job.endDate
										? `${formatUTC(job.startDate)} - ${formatUTC(job.endDate)}`
										: `${formatUTC(job.startDate)} - Present`}
								</Text>
							</div>
						</div>
					</div>
				</div>
			</Btn>
		</ReviewJobLinkView>
	)
}
