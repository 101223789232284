import { Heading } from '@merify/ui'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

export const successScreenStyles = css`
	background: ${props => props.theme.colors.primary.val};
	min-height: 100vh;
	height: 100%;
	padding: 0 ${props => props.theme.sizes.gutter.mobile.val};

	/* ${props => props.theme.media.tablet} {
		background: none;
	} */

	& > header {
		padding-top: 1em;
	}

	h1 {
		text-align: center;
		padding: 0.5em 0;
		font-size: 3.5rem;
		font-weight: 700;
	}
`

const SuccessScreenView = styled.div`
	${successScreenStyles}
`

export type SuccessScreenProps = {
	animation: ReactNode
	cta?: ReactNode
	titleText: string
	children?: ReactNode
	className?: string
}
export const SuccessScreen = ({
	children,
	animation,
	cta,
	titleText,
	className,
}: SuccessScreenProps): JSX.Element => {
	return (
		<SuccessScreenView className={`success-screen${className ? ` ${className}` : ''}`}>
			<header>
				<Heading hTag='h1' displayType='screen-heading' dir='center' color='white'>
					{titleText}
				</Heading>
			</header>
			<main>
				<section className='animation'>{animation}</section>
				<section className='content'>{children}</section>
				{cta ? <section className='btns'>{cta}</section> : null}
			</main>
		</SuccessScreenView>
	)
}
