import { btnStyles, isExternalLink } from '@merify/ui'
import { Link as RRLink } from '@reach/router'
import { HTMLProps } from 'react'
import styled, { css } from 'styled-components'

// FIXME: This is a hack to support yarn linking
const _btnStyles: any = btnStyles

export const linkStyles = css`
	display: inline-block;
	${_btnStyles}
	color: inherit;
	text-decoration: none;
	height: 100%;
	width: 100%;
	&.__flex {
		display: inline-flex;
		align-items: center;
	}
`

const StyledRRLink = styled(RRLink)`
	${linkStyles}
`

const StyledHref = styled.a`
	${linkStyles}
`

export type LinkProps = Pick<
	HTMLProps<HTMLAnchorElement>,
	'title' | 'alt' | 'className' | 'children'
> & {
	to: string
	asFlex?: boolean
}
export const Link = ({ to, className, asFlex, children, ...props }: LinkProps): JSX.Element => {
	const isExt = isExternalLink(to)

	const dynamicClasses = `${className ? ` ${className}` : ''}${asFlex ? ' __flex' : ''}`

	return isExt ? (
		<StyledHref href={to} target='_blank' className={`link ext-link${dynamicClasses}`}>
			{children}
		</StyledHref>
	) : (
		<StyledRRLink className={`link${dynamicClasses}`} to={to} {...props}>
			{children}
		</StyledRRLink>
	)
}
