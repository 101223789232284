import { ReviewSkill, Wizard } from '@merify/ui'
import { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { GiveKudosReview } from './Give.kudos.review'
import { GiveKudosSelectJob } from './Give.kudos.select.job'
import { GiveKudosSelectSkills } from './Give.kudos.select.skills'

const GiveKudosStyles = css``

const GiveKudosView = styled.div`
	${GiveKudosStyles}
`

type GiveKudosProps = {
	className?: string
	candidateId: number
	cb?: () => void
}
export const GiveKudos = ({ className, candidateId, cb }: GiveKudosProps): JSX.Element => {
	const [skillsForReview, setSkills] = useState<ReviewSkill[]>([])
	const [candidateJobId, setJobId] = useState<number | null>(null)
	const [readyToReview, setReadyToReview] = useState<boolean>(false)

	const selectSkill = useCallback(
		(skill: ReviewSkill) => {
			setSkills(state => {
				const stateSkills = [...state] as ReviewSkill[]
				if (stateSkills.find(s => s.skillId === skill.skillId)) {
					return stateSkills.filter(s => s.skillId !== skill.skillId)
				} else {
					return [...stateSkills, skill]
				}
			})
		},
		[setSkills]
	)

	return (
		<GiveKudosView className={`give-kudos${className ? ` ${className}` : ''}`}>
			{readyToReview && candidateJobId && skillsForReview.length ? (
				<GiveKudosReview
					cancelHandler={() => cb && cb()}
					candidateId={candidateId}
					candidateJobId={candidateJobId}
					skillsForReview={skillsForReview}
				/>
			) : (
				<Wizard includeDots scrollToTop isLazyMount>
					<GiveKudosSelectSkills
						cancelHandler={() => cb && cb()}
						candidateId={candidateId}
						skillsForReview={skillsForReview}
						selectSkill={selectSkill}
					/>
					<GiveKudosSelectJob
						candidateId={candidateId}
						candidateJobId={candidateJobId}
						setJobId={setJobId}
						continueHandler={() => setReadyToReview(true)}
					/>
				</Wizard>
			)}
		</GiveKudosView>
	)
}
