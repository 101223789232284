import { Async, Btn, Card, CTA, Ctas, Heading, useModalCtrl, WizardStepProps } from '@merify/ui'
import { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useGetJobs } from '../../../api/api.jobs'
import { useGetReviewRequest } from '../../../api/api.reviews'
import { Container } from '../../UI/Container'
import { ModalFull } from '../../UI/Modal'
import { CreateCandidateJob } from '../Candidate.job.create'
import { CandidateJobsList } from '../Candidate.jobs.list'
import { useReview } from '../Review.provider/Review.provider'
import { ReviewInfo } from './Review.info'

export const reviewSelectJobStyles = css`
	header {
		h4 {
			font-weight: 700;
			padding: 10px 0;
		}
	}

	.skip-job-btn {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.jobs {
		h5 {
			padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
			color: ${props => props.theme.colors.secondary.val};
		}
	}
`

const ReviewSelectJobView = styled.div`
	${reviewSelectJobStyles}
`

export type ReviewSelectJobProps = {}
export const ReviewSelectJob = (stepProps: ReviewSelectJobProps): JSX.Element => {
	const { reviewId, ignore, reviewerJobId, setReviewerJobId } = useReview()

	const { nextStep } = stepProps as WizardStepProps
	const results = useGetReviewRequest(reviewId)
	const { data } = useGetJobs()
	const reviewData = results.data
	const [[showCreateJobModal, openCreateJobModal, closeCreateJobModal]] = useModalCtrl()

	const isMounted = useRef(true)

	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])

	if (!isMounted.current) {
		// TODO: check on memory leak after upgrading to react 18
		return <></>
	}

	return (
		<ReviewSelectJobView className='review-select-job'>
			<Async fetchResults={[results]}>
				{results.data ? (
					<Container isStickyFooter>
						<header>
							<Heading hTag='h4' displayType='dialog-heading' dir='center'>
								Link a Job
							</Heading>
						</header>
						<section className='review-details'>
							{reviewData ? <ReviewInfo reviewData={reviewData} /> : null}
						</section>
						<section className='btns'>
							<Ctas padBot>
								<CTA displayType='no-border' size='sm'>
									<Btn onClick={ignore}>Ignore Review</Btn>
								</CTA>
								<CTA size='sm' displayType={reviewerJobId ? 'solid' : 'normal'}>
									<Btn onClick={reviewerJobId ? nextStep : openCreateJobModal}>
										{reviewerJobId ? 'Continue Review' : 'Link to a new job?'}{' '}
									</Btn>
								</CTA>
							</Ctas>
							<CTA size='sm' className='skip-job-btn' mBot>
								<Btn
									onClick={() => {
										setReviewerJobId(null)
										nextStep()
									}}
								>
									Skip this step
								</Btn>
							</CTA>
						</section>
						<section className='jobs'>
							<Heading hTag='h5' displayType='block-heading' mBot>
								Or select one of your jobs to link it to:
							</Heading>
							<Card>
								<CandidateJobsList
									candidateJobs={data}
									onSelect={job => setReviewerJobId(job.candidateJob.candidateJobId)}
									selectedJobIds={reviewerJobId ? [reviewerJobId] : undefined}
									showSelectRadios
								/>
							</Card>
						</section>
					</Container>
				) : null}
			</Async>
			<ModalFull
				headerContent='Add a new job'
				isOpen={showCreateJobModal}
				closeHandler={closeCreateJobModal}
			>
				{showCreateJobModal ? (
					<CreateCandidateJob
						skipSuccessScreen
						cb={j => {
							if (j) setReviewerJobId(j.candidateJobId)
							closeCreateJobModal()
						}}
					/>
				) : null}
			</ModalFull>
		</ReviewSelectJobView>
	)
}
