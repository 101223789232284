import { Async, Card, CTA, NotificationType } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetNotificationByIdAndType } from '../../../api/api.notifications'
import { Link } from '../../UI/Link'
import { DeleteNotificationCta } from '../Notification.cta.delete'
import { DynamicNotificationParams } from '../Notification.detail'
import { NotificationInfo } from '../Notification.detail.info'
import { notificationStyles } from '../Notification.styles'

export const SystemLevelChangeNotificationDetailStyles = css`
	${notificationStyles}
	.delete-notification-cta {
	}
`

const SystemLevelChangeNotificationDetailView = styled.div`
	${SystemLevelChangeNotificationDetailStyles}
`

export type SystemLevelChangeNotificationDetailProps = DynamicNotificationParams
export const SystemLevelChangeNotificationDetail = ({
	id,
	cb,
}: SystemLevelChangeNotificationDetailProps): JSX.Element => {
	const type: NotificationType = 'candidateSystemLevelChangeNotifications'

	const results = useGetNotificationByIdAndType({
		notificationId: id,
		notificationType: type,
	})

	const notification = results.data

	return (
		<SystemLevelChangeNotificationDetailView>
			<Async fetchResults={results}>
				<Card hasGutter>
					{notification ? <NotificationInfo notification={notification} /> : null}

					<CTA mTop size='sm'>
						<Link to={`/skills/${notification?.candidateSkillId}`}>Go to skill</Link>
					</CTA>
				</Card>
				<DeleteNotificationCta notificationId={id} notificationType={type} cb={cb} />
			</Async>
		</SystemLevelChangeNotificationDetailView>
	)
}
