import { AnyCandidate, Btn, CandidateSkillList, CTA, useModalCtrl } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetPublicUserById } from '../../../api/api.candidate'
import { useStoreState } from '../../../store'
import { CandidateProfileInfo } from '../../screens/candidates/candidate.profile.screen/Candidate.profile.info'
import { ModalFull } from '../../UI/Modal'
import { ReviewRequestFromProfile } from '../Review.request/Review.request.fromProfile'
import { SkillsHeader } from '../Skills.header/Skills.header'

export const candidatePublicProfileStyles = css`
	.candidate-profile-info {
		padding-bottom: 0;
	}
	.skills-header {
		padding: 0;
		margin-bottom: ${props => props.theme.sizes.gutter.mobile.val};
		padding-bottom: 15px;
		border-bottom: 1px solid ${props => props.theme.colors.lightGrey.val};
	}
`

const CandidatePublicProfileView = styled.div`
	${candidatePublicProfileStyles}
`

type CandidatePublicProfileProps = {
	candidateId: number
	view?: 'public' | 'private' | 'preview'
	suppressReviewCta?: boolean
}
export const CandidatePublicProfile = ({
	candidateId,
	view,
	suppressReviewCta,
}: CandidatePublicProfileProps): JSX.Element => {
	const { user } = useStoreState(state => state.candidate)
	const results = useGetPublicUserById({ uid: candidateId })
	const isMyProfile = (user && user?.candidateId === candidateId) || undefined

	const { data: candidateData } = results

	const { candidate, skills } = candidateData || {}

	const [[showReviewReq, openReviewReq, closeReviewReq]] = useModalCtrl()

	return (
		<CandidatePublicProfileView>
			<CandidateProfileInfo
				view={view}
				candidateData={candidate as AnyCandidate}
				isMyProfile={isMyProfile}
			/>
			<SkillsHeader>Public Skills</SkillsHeader>
			<CandidateSkillList skills={skills} view='public' noResultsText='No public skills yet...' />
			{view === 'public' && !isMyProfile ? (
				<>
					{suppressReviewCta ? null : (
						<CTA>
							<Btn onClick={openReviewReq}>Request Review</Btn>
						</CTA>
					)}
					<ModalFull isOpen={showReviewReq} closeHandler={closeReviewReq}>
						{candidate ? (
							<ReviewRequestFromProfile candidate={candidate as AnyCandidate} cb={closeReviewReq} />
						) : null}
					</ModalFull>
				</>
			) : null}
		</CandidatePublicProfileView>
	)
}
