import { themeConfig } from '@merify/ui'
import {
	createContext,
	ReactNode,
	Reducer,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react'

type WindowState = {
	width: number
	height: number
	scrollTop: number
	isMobile: boolean
	isTablet: boolean
	isSdesk: boolean
	isLdesk: boolean
}

const calcScreenBools = (): Pick<WindowState, 'isMobile' | 'isTablet' | 'isSdesk' | 'isLdesk'> => {
	const width = typeof window !== 'undefined' ? window.innerWidth : 0
	const { tablet, sdesk, ldesk } = themeConfig.breaks || {}

	const tabletWidth = tablet?.num ?? 767
	const sdeskWidth = sdesk?.num ?? 1112
	const ldeskWidth = ldesk?.num ?? 1480

	return {
		isMobile: width < tabletWidth,
		isTablet: width >= tabletWidth && width < sdeskWidth,
		isSdesk: width >= sdeskWidth && width < ldeskWidth,
		isLdesk: width >= ldeskWidth,
	}
}

const initialCtx: WindowState = {
	width: window?.innerWidth,
	height: window?.innerHeight,
	scrollTop: window?.scrollY,
	...calcScreenBools(),
}
const context = createContext(initialCtx)

const Provider = context.Provider

export const useWindow = () => useContext(context)

type ReducerState = Omit<WindowState, 'scrollTop'>

const reducer: Reducer<ReducerState, boolean> = (state, shouldUpdate) =>
	shouldUpdate && typeof window !== 'undefined'
		? {
				width: window.innerWidth,
				height: window.innerHeight,
				...calcScreenBools(),
		  }
		: state

type WindowProviderProps = {
	children: ReactNode
}
export const WindowProvider = ({ children }: WindowProviderProps): JSX.Element => {
	const [scrollTop, setScrollTop] = useState(window?.scrollY)
	const [dimensions, dispatch] = useReducer(reducer, {
		width: window?.innerWidth,
		height: window?.innerHeight,
		...calcScreenBools(),
	})

	useEffect(() => {
		const updateDimensions = () => dispatch(true)
		const updateScroll = () => setScrollTop(window?.scrollY ?? 0)

		if (typeof window !== 'undefined') {
			window.addEventListener('resize', updateDimensions)
			window.addEventListener('scroll', updateScroll)
		}

		return () => {
			window.removeEventListener('resize', updateDimensions)
			window.removeEventListener('scroll', updateScroll)
		}
	}, [])

	return (
		<Provider
			value={{
				scrollTop,
				...dimensions,
			}}
		>
			{children}
		</Provider>
	)
}
