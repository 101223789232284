import { Async, Card, NotificationType } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetNotificationByIdAndType } from '../../../api/api.notifications'
import { DeleteNotificationCta } from '../Notification.cta.delete'
import { DynamicNotificationParams } from '../Notification.detail'
import { NotificationInfo } from '../Notification.detail.info'
import { notificationStyles } from '../Notification.styles'

export const candidateAnnouncementNotificationDetailStyles = css`
	${notificationStyles}
	.delete-notification-cta {
	}
`

const CandidateAnnouncementNotificationDetailView = styled.div`
	${candidateAnnouncementNotificationDetailStyles}
`

export type CandidateAnnouncementNotificationDetailProps = DynamicNotificationParams
export const CandidateAnnouncementNotificationDetail = ({
	id,
	cb,
}: CandidateAnnouncementNotificationDetailProps): JSX.Element => {
	const type: NotificationType = 'candidateAnnouncementNotifications'

	const results = useGetNotificationByIdAndType({
		notificationId: id,
		notificationType: type,
	})

	const notification = results.data

	return (
		<CandidateAnnouncementNotificationDetailView>
			<Async fetchResults={results}>
				<Card hasGutter>
					{notification ? <NotificationInfo notification={notification} /> : null}
				</Card>
				<DeleteNotificationCta notificationId={id} notificationType={type} cb={cb} />
			</Async>
		</CandidateAnnouncementNotificationDetailView>
	)
}
