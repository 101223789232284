import styled, { css } from 'styled-components'
import { SuccessScreen, SuccessScreenProps } from '../../screens/Success'
import { SuccessAnimation } from '../SuccessAnimation'

export const reviewProviderSuccessStyles = css``

const ReviewProviderSuccessView = styled.div`
	${reviewProviderSuccessStyles}
`

export type ReviewProviderSuccessProps = {
	cta?: SuccessScreenProps['cta']
	reviewType?: 'review' | 'kudos'
	previouslyCompleted?: boolean
}
export const ReviewProviderSuccess = ({
	cta,
	reviewType,
	previouslyCompleted,
}: ReviewProviderSuccessProps): JSX.Element => {
	return (
		<ReviewProviderSuccessView>
			<SuccessScreen
				titleText={
					reviewType === 'kudos'
						? 'Kudos Submitted!'
						: reviewType === 'review' && previouslyCompleted
						? 'Review has already been completed!'
						: reviewType === 'review'
						? 'Review Completed!'
						: ''
				}
				animation={<SuccessAnimation type='review' />}
				cta={cta}
			>
				{/* <P>
							You worked together at the same job; want to send them a review request now?
						</P> */}
			</SuccessScreen>
		</ReviewProviderSuccessView>
	)
}
