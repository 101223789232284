import { Async, Btn, Card, CTA, NotificationType, useModalCtrl } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetNotificationByIdAndType } from '../../../api/api.notifications'
import { CandidateProfileEditForm } from '../../screens/candidates/candidate.profile.screen/Candidate.profile.edit.form'
import { ModalFull } from '../../UI/Modal'
import { DeleteNotificationCta } from '../Notification.cta.delete'
import { DynamicNotificationParams } from '../Notification.detail'
import { NotificationInfo } from '../Notification.detail.info'
import { notificationStyles } from '../Notification.styles'

export const candidateRegisteredNotificationDetailStyles = css`
	${notificationStyles}
	.delete-notification-cta {
	}
`

const CandidateRegisteredNotificationDetailView = styled.div`
	${candidateRegisteredNotificationDetailStyles}
`

export type CandidateRegisteredNotificationDetailProps = DynamicNotificationParams
export const CandidateRegisteredNotificationDetail = ({
	id,
	cb,
}: CandidateRegisteredNotificationDetailProps): JSX.Element => {
	const type: NotificationType = 'candidateRegisteredNotifications'

	const results = useGetNotificationByIdAndType({
		notificationId: id,
		notificationType: type,
	})

	const notification = results.data

	const [[showProfileModal, openProfileModal, closeProfileModal]] = useModalCtrl()

	return (
		<CandidateRegisteredNotificationDetailView>
			<Async fetchResults={results}>
				<Card hasGutter>
					{notification ? <NotificationInfo notification={notification} /> : null}

					<CTA mTop size='sm'>
						<Btn onClick={openProfileModal}>Add personal details</Btn>
					</CTA>
				</Card>
				<DeleteNotificationCta notificationId={id} notificationType={type} cb={cb} />

				<ModalFull isOpen={showProfileModal} closeHandler={closeProfileModal}>
					<CandidateProfileEditForm cb={closeProfileModal} />
				</ModalFull>
			</Async>
		</CandidateRegisteredNotificationDetailView>
	)
}
