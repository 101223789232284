import { Btn, CTA, Heading, Icon, Text, useModalCtrl, YouTubeThumb } from '@merify/ui'
import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from '../../UI/Link'
import { ModalAlert, ModalFull } from '../../UI/Modal'

const Span = styled.span`
	display: flex;
	align-items: center;
	column-gap: 0.5rem;
`

export const welcomeSlideoutStyles = css`
	.welcome-slideout-ctrl-group {
		transition: all ${props => props.theme.times.tranS};
		transform: translateX(-100%);
		position: fixed;
		left: 0;
		bottom: ${props => props.theme.sizes.footer.mobile.val};
		z-index: 2;
	}

	.welcome-slideout-content {
		color: ${props => props.theme.colors.white.val};
		padding: 10px ${props => props.theme.sizes.gutter.mobile.val};
		padding-top: 15px;
		padding-right: 45px;
		background: ${props => props.theme.colors.black.opacity(80).val};
		max-width: 80vw;
		width: 350px;
		${props => props.theme.media.tablet} {
			margin-left: 200px;
		}
	}

	.close-icon {
		position: absolute;
		top: 3px;
		right: 3px;
	}

	button {
		display: flex;
		color: inherit;
	}
	h4 {
		color: inherit;
		text-align: left;
	}
	.yt-thumb {
		width: 150px;
		img {
			width: 100%;
		}
	}
`

const WelcomeSlideoutView = styled.div`
	${welcomeSlideoutStyles}
`

export type WelcomeSlideoutProps = {
	videoId: string
	videoUrl: string
	thumbTitle: string
	className?: string
	WelcomeScreen: React.FC<{ cb: () => void }>
	show?: boolean

	handleViewed: () => void
	closeHandler: () => void
}
export const WelcomeSlideout = ({
	show,
	videoId,
	videoUrl,
	thumbTitle,
	className,
	WelcomeScreen,
	handleViewed,
	closeHandler,
}: WelcomeSlideoutProps): JSX.Element => {
	const [[showWelcomeModal, openWelcomeModal, closeWelcomeModal]] = useModalCtrl()
	const [[showVidAlert, openVidAlert, closeVidAlert]] = useModalCtrl()

	return (
		<WelcomeSlideoutView className={`welcome-slideout${className ? ` ${className}` : ''}`}>
			<div
				className='welcome-slideout-ctrl-group'
				style={{
					transform: `translateX(${show ? '0' : '-100%'})`,
				}}
			>
				<div className='welcome-slideout-content'>
					<Btn
						className='close-icon'
						onClick={() => {
							handleViewed()
							openVidAlert()
							closeHandler()
						}}
					>
						<Icon type='close' />
					</Btn>

					<Btn
						onClick={() => {
							handleViewed()
							openWelcomeModal()
							closeHandler()
						}}
					>
						<Heading hTag='h4' displayType='block-heading'>
							{thumbTitle}
						</Heading>
						<YouTubeThumb
							className='thumb'
							video={{
								youTubeVidId: videoId,
								url: videoUrl,
							}}
						/>
					</Btn>
				</div>
			</div>

			<ModalAlert
				isOpen={showVidAlert}
				closeHandler={closeVidAlert}
				showAlertBtns
				className='video-tut-alert'
			>
				<Text size='med'>
					You can access tutorial videos any time by visiting the{' '}
					<CTA size='sm'>
						<Link to='/video-tutorials'>
							<Span>
								<Icon type='question' /> Help
							</Span>
						</Link>
					</CTA>{' '}
					tab from the main menu.
				</Text>
			</ModalAlert>

			<ModalFull isOpen={showWelcomeModal} closeHandler={closeWelcomeModal}>
				<WelcomeScreen cb={closeWelcomeModal} />
			</ModalFull>
		</WelcomeSlideoutView>
	)
}
