import { Btn, Icon } from '@merify/ui'
import styled, { css } from 'styled-components'

const emailListItemStyles = css`
	display: flex;
	align-items: center;
	height: 50px;
	background: ${props => props.theme.colors.white.val};
	width: 100%;

	.email-list-item-remove-btn {
		color: ${props => props.theme.colors.err.val};
		margin-right: 0.5em;
	}

	.email {
		font-weight: 700;
	}
`

const EmailListItemView = styled.div`
	${emailListItemStyles}
`

export type EmailListItemProps = {
	email: string
	onDelete?: () => void
	className?: string
}
export const EmailListItem = ({ email, onDelete, className }: EmailListItemProps): JSX.Element => {
	return (
		<EmailListItemView className={`email-list-item${className ? ` ${className}` : ''}`}>
			{onDelete ? (
				<Btn onClick={() => onDelete()} className='email-list-item-remove-btn'>
					<Icon type='minus' />
				</Btn>
			) : null}
			<div className='email'>{email}</div>
		</EmailListItemView>
	)
}
