import { NotificationType } from '@merify/ui'
import { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useNotificationViewed } from '../../api/api.notifications'
import { CandidateAnnouncementNotificationDetail } from './Notification.details/Notification.detail.candidateAnnouncement'
import { CandidateRegisteredNotificationDetail } from './Notification.details/Notification.detail.candidateRegistered'
import { SystemLevelChangeNotificationDetail } from './Notification.details/Notification.detail.candidateSystemLevelChangeNotification'
import { EmployerMatchNotificationDetail } from './Notification.details/Notification.detail.employerMatch'
import { RequestedReviewNotificationDetail } from './Notification.details/Notification.detail.requestedReview'
import { SkillApprovedNotificationDetail } from './Notification.details/Notification.detail.skillApproved'
import { SkillDisapprovedNotificationDetail } from './Notification.details/Notification.detail.skillDisapproved'
import { SkillVerificationNotificationDetail } from './Notification.details/Notification.detail.skillVerification'
import { SkillVerificationDeadlineReachedNotificationDetail } from './Notification.details/Notification.detail.skillVerificationDeadlineReached'
import { SkillVerificationDeadlineUpdateNotificationDetail } from './Notification.details/Notification.detail.skillVerificationDeadlineUpdate'
import { SkillVerificationReminderNotificationDetail } from './Notification.details/Notification.detail.skillVerificationReminder'
import { SkillVerificationUpdateNotificationDetail } from './Notification.details/Notification.detail.skillVerificationUpdate'

export const notificationDetailStyles = css``

const NotificationDetailView = styled.div`
	${notificationDetailStyles}
`

export type DynamicNotificationParams = Pick<DynamicNotificationProps, 'id' | 'cb'>

export type DynamicNotificationProps = {
	id: number
	type: NotificationType
	cb?: () => void
}
export const DynamicNotification = ({ type, ...props }: DynamicNotificationProps): JSX.Element => {
	return type === 'requestedReviewNotifications' ? (
		<RequestedReviewNotificationDetail {...props} />
	) : type === 'skillDisapprovedNotifications' ? (
		<SkillDisapprovedNotificationDetail {...props} />
	) : type === 'skillApprovedNotifications' ? (
		<SkillApprovedNotificationDetail {...props} />
	) : type === 'candidateSkillVerificationNotifications' ? (
		<SkillVerificationNotificationDetail {...props} />
	) : type === 'candidateSkillVerificationSkillsUpdateNotifications' ? (
		<SkillVerificationUpdateNotificationDetail {...props} />
	) : type === 'candidateSkillVerificationReminderNotifications' ? (
		<SkillVerificationReminderNotificationDetail {...props} />
	) : type === 'candidateSkillVerificationDeadlineUpdateNotifications' ? (
		<SkillVerificationDeadlineUpdateNotificationDetail {...props} />
	) : type === 'candidateSkillVerificationDeadlineReachedNotifications' ? (
		<SkillVerificationDeadlineReachedNotificationDetail {...props} />
	) : type === 'employerMatchResponseNotifications' ? (
		<EmployerMatchNotificationDetail {...props} />
	) : type === 'candidateAnnouncementNotifications' ? (
		<CandidateAnnouncementNotificationDetail {...props} />
	) : type === 'candidateRegisteredNotifications' ? (
		<CandidateRegisteredNotificationDetail {...props} />
	) : type === 'candidateSystemLevelChangeNotifications' ? (
		<SystemLevelChangeNotificationDetail {...props} />
	) : (
		<></>
	)
}

export type NotificationDetailProps = Pick<DynamicNotificationProps, 'cb'> & {
	notificationId: DynamicNotificationProps['id']
	notificationType: DynamicNotificationProps['type']
}
export const NotificationDetail = ({
	notificationId: id,
	notificationType: type,
	cb,
}: NotificationDetailProps): JSX.Element => {
	const { mutate } = useNotificationViewed(id, type)

	const uniqStr = `${id}${type}`

	useEffect(() => {
		mutate(true)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uniqStr])

	const dynamicProps: DynamicNotificationProps = {
		id,
		type,
		cb,
	}
	return (
		<NotificationDetailView>
			<DynamicNotification {...dynamicProps} />
		</NotificationDetailView>
	)
}
