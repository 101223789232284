import { BaseNotification, formatUTC, Heading, Text } from '@merify/ui'
import styled, { css } from 'styled-components'

export const notificationInfoStyles = css``

const NotificationInfoView = styled.div`
	${notificationInfoStyles}
`

export type NotificationInfoProps = {
	notification: Pick<BaseNotification, 'title' | 'notificationEffectiveDate' | 'description'>
}
export const NotificationInfo = ({ notification }: NotificationInfoProps): JSX.Element => {
	const { title, description, notificationEffectiveDate } = notification

	return (
		<NotificationInfoView>
			<Heading displayType='block-heading' mBot>
				{title}
			</Heading>
			<Heading displayType='p-heading'>{formatUTC(notificationEffectiveDate)}</Heading>
			<Text textType='p'>{description}</Text>
		</NotificationInfoView>
	)
}
