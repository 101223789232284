import { Async, SelectField, SelectFieldProps, SelectOption } from '@merify/ui'
import { UseQueryResult } from 'react-query'
import styled, { css } from 'styled-components'

export const selectApiFieldStyles = css`
	min-height: 1em;
`

const SelectApiFieldView = styled.div`
	${selectApiFieldStyles}
`

export type SelectApiFieldProps = Omit<SelectFieldProps, 'options'> & {
	fetchHook: () => UseQueryResult<any, unknown>
	children: (data: any) => SelectOption[]
}
export const SelectApiField = ({
	fetchHook: useFetch,
	children,
	...props
}: SelectApiFieldProps) => {
	const results = useFetch()

	const options = results.data ? children(results.data) : null

	return (
		<SelectApiFieldView>
			<Async fetchResults={results}>
				{options ? <SelectField {...props} options={options} /> : null}
			</Async>
		</SelectApiFieldView>
	)
}
