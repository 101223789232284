import { Btn, CTA, EmailField, ErrorBlock, isValidEmail, Spinner, Text, useState } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useInviteUser } from '../../../api/api.admin'

export const inviteUserModalContentStyles = css`
	padding: ${props => props.theme.sizes.gutter.mobile.val};
	.success-text {
		text-align: center;
	}
	.success-email {
		word-break: break-all;
	}
`

const InviteUserFormView = styled.div`
	${inviteUserModalContentStyles}
`

export type InviteUserFormProps = {
	cb?: () => void
}
export const InviteUserForm = ({ cb }: InviteUserFormProps): JSX.Element => {
	const [email, setEmal] = useState('')
	const { isLoading, isSuccess, mutate, error } = useInviteUser()
	const sanatizedEmail = email.trim().toLowerCase()
	const inviteHandler = () => {
		mutate(sanatizedEmail)
	}

	return (
		<InviteUserFormView>
			{isSuccess ? (
				<div className='success-text'>
					<Text size='med' dir='center'>
						An invite has been sent to <br />
					</Text>
					<Text size='med' dir='center' className='success-email'>
						{sanatizedEmail}
					</Text>

					{cb ? (
						<CTA displayType='solid' mTop>
							<Btn onClick={cb}>Okay</Btn>
						</CTA>
					) : null}
				</div>
			) : (
				<>
					<EmailField
						name='email'
						label='Email address'
						onChange={(event: any) => setEmal(event.currentTarget.value)}
					/>
					<ErrorBlock error={error} display='inline'>
						<CTA>
							<Btn onClick={inviteHandler} disabled={!isValidEmail(email) || isLoading}>
								{isLoading ? <Spinner height='sm' /> : null}
								INVITE TO MERIFY
							</Btn>
						</CTA>
					</ErrorBlock>
				</>
			)}
		</InviteUserFormView>
	)
}
