import { FormikDate, FormikRadio, FormikText, FormRequiredNotice } from '@merify/ui'
import { useFormikContext } from 'formik'
import styled, { css } from 'styled-components'
import * as Yup from 'yup'

export const candidateJobFieldsStyles = css``

const CandidateJobFieldsView = styled.div`
	${candidateJobFieldsStyles}
`

export const sanitizeCandidateJobDataForSave = ({ isCurrentlyWorking, endDate, ...vals }: any) => {
	const candidateJob = { ...vals } as any

	if (isCurrentlyWorking === 'no') candidateJob.endDate = endDate

	return candidateJob
}

export const CandidateJobInfoSchema = Yup.object().shape({
	jobTitle: Yup.string()
		.max(100, 'Job Title must be at most 100 characters')
		.required('Please enter a job title'),
	employer: Yup.string()
		.max(100, 'Job Title must be at most 100 characters')
		.required('Please enter an employer / client'),
	startDate: Yup.date()
		.max(Yup.ref('endDate'), 'Start date cannot be later than end date')
		.max(new Date(), 'Start date cannot be later than today')
		.required('Please enter a start date'),
	endDate: Yup.date().when('isCurrentlyWorking', {
		is: (val: string) => val === 'no',
		then: Yup.date()
			.min(Yup.ref('startDate'), 'End date cannot be earlier than start date')
			.max(new Date(), 'End date cannot be later than today')
			.required('Please enter an end date'),
		otherwise: Yup.date(),
	}),
})

export type CandidateJobFieldsProps = {}
export const CandidateJobFields = (): JSX.Element => {
	const { values } = useFormikContext<{
		startDate: string
		endDate: string
		isCurrentlyWorking: string
	}>()
	const { startDate, isCurrentlyWorking, endDate } = values
	return (
		<CandidateJobFieldsView>
			<FormRequiredNotice />
			<div className='fields'>
				<FormikText
					name='jobTitle'
					config={{ label: 'Job Title', spaceBottom: true, isReq: true }}
				/>
				<FormikText
					name='employer'
					config={{ label: 'Employer / Client', spaceBottom: true, isReq: true }}
				/>
				<FormikText name='projectName' config={{ label: 'Project', spaceBottom: true }} />
				<FormikDate
					className='date'
					name='startDate'
					config={{ label: 'Start Date', spaceBottom: true, isReq: true }}
				/>
				{startDate ? (
					<div className='end-date'>
						<FormikRadio
							name='isCurrentlyWorking'
							defaultVal={endDate ? 'no' : 'yes'}
							// @ts-ignore MIGRATION
							config={{
								label: 'Are you sill working here?',
							}}
							options={[
								{
									txt: 'Yes',
									val: 'yes',
								},
								{
									txt: 'No',
									val: 'no',
								},
							]}
							// onChange={val => {
							// 	if (val === 'yes') {
							// 		setTempDate(endDate)
							// 		setFieldValue('endDate', '')
							// 		setHasEndDate(false)
							// 	} else {
							// 		setFieldValue('endDate', tempDate)
							// 		setHasEndDate(true)
							// 	}
							// }}
						/>
						{isCurrentlyWorking === 'no' ? (
							<FormikDate
								className='date'
								name='endDate'
								config={{ label: 'End Date', spaceBottom: true, isReq: true }}
							/>
						) : null}
					</div>
				) : null}
			</div>
		</CandidateJobFieldsView>
	)
}
