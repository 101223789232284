import { Heading, P } from '@merify/ui'
import styled, { css } from 'styled-components'

export const ReferenceGuideStyles = css`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;

	.step-section {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		text-align: center;
		padding: 50px;

		.heading {
			color: ${props => props.theme.colors.black.val};
			font-size: 3rem;
			line-height: 3.2rem;
			width: 100%;
			margin-bottom: 20px;
		}

		.step-number {
			color: ${props => props.theme.colors.white.val};
			font-size: 6rem;
			font-weight: 600;
			padding: 30px;
			margin: 20px auto;
			border-radius: 100px;
			width: 130px;
			height: 130px;
			text-align: center;
			border: 2px solid ${props => props.theme.colors.black.val};
			background: ${props => props.theme.colors.primary.val};
		}
	}
`

const ReferenceGuideView = styled.div`
	${ReferenceGuideStyles}
`

export const ReferenceGuide = (): JSX.Element => {
	return (
		<ReferenceGuideView>
			<div className='step-section'>
				<Heading hTag='h3' displayType='p-heading' dir='center'>
					Add skills to your profile
				</Heading>
				<div className='step-number'>1</div>
				<P isBig>
					Add skills to your profile by searching for them or selecting from our list of soft
					skills. If you can't find a skill, just suggest it to the Merify team!
				</P>
				<P isBig>
					Once a skill has been selected, provide a simple self assessment of that skill from
					Beginner to Expert. You can always change it later!
				</P>
			</div>
			<div className='step-section'>
				<Heading hTag='h3' displayType='p-heading' dir='center'>
					Group those skills in your work history
				</Heading>
				<div className='step-number'>2</div>
				<P isBig>
					Add entries for your work history, both past and current. Provide the dates associated
					with the work, and which skills you used. That's it!
				</P>
				<P isBig>
					Merify only uses work entries as a grouping of skills used over a specific time period.
					Setting one up takes seconds, and is practically text-free.
				</P>
			</div>
			<div className='step-section'>
				<Heading hTag='h3' displayType='p-heading' dir='center'>
					Request reviews for your work history
				</Heading>
				<div className='step-number'>3</div>
				<P isBig>
					Request reviews from people you worked with. All reviews are text-free, so they take just
					minutes. And all reviews are anonymous to encourage honesty.
				</P>
				<P isBig>
					Find your reviewers by searching for their names. If they're not in the system, just
					search using their email address and you can invite them to Merify.
				</P>
			</div>
			<div className='step-section'>
				<Heading hTag='h3' displayType='p-heading' dir='center'>
					View and adjust your profile
				</Heading>
				<div className='step-number'>4</div>
				<P isBig>
					Check on your progress. For each skill, see what actions you can take to meet all of our
					vetting metrics.
				</P>
				<P isBig>
					Merify encourages you to react to your community's view of your talents! If you're
					completely vetted for a skill, try moving your self assessment up a level!
				</P>
			</div>
		</ReferenceGuideView>
	)
}
