import { IncomingReviewRequest, useState } from '@merify/ui'
import styled, { css } from 'styled-components'
import { ReviewsListItem } from './Reviews.list.item'

export const reviewListStyles = css``

const ReviewsListView = styled.div`
	${reviewListStyles}
`

type ReviewsListProps = {
	reviews?: IncomingReviewRequest[] | null
	onSelect?: (review: IncomingReviewRequest) => void
}
export const ReviewsList = ({ reviews, onSelect }: ReviewsListProps): JSX.Element => {
	const [lastSelectedId, setLastSelected] = useState<number | null>(null, 'lastSelectedId')
	return (
		<ReviewsListView>
			{reviews?.length
				? reviews.map(review => (
						<ReviewsListItem
							key={review.requestedReview.requestedReviewId}
							reviewData={review}
							isSelected={review.requestedReview.requestedReviewId === lastSelectedId}
							onSelect={() => {
								onSelect && onSelect(review)
								setLastSelected(review.requestedReview.requestedReviewId)
							}}
						/>
				  ))
				: null}
		</ReviewsListView>
	)
}
