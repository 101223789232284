import { Btn, CTA, Wizard } from '@merify/ui'
import styled, { css } from 'styled-components'
import { SuccessScreen } from '../../screens/Success/Success.screen'

import { SuccessAnimation } from '../SuccessAnimation'
import { ReviewRequestAddUsers } from './Review.request.addUsers'
import { ReviewRequestProvider, useReviewRequest } from './Review.request.provider'
import { ReviewRequestSelectJob } from './Review.request.selectJob'

export const reviewRequestStyles = css``

const ReviewRequestView = styled.div`
	${reviewRequestStyles}
`

export type ReviewRequestProps = {
	cb?: () => void
}

export const ReviewRequest = ({ ...reviewReqProps }: ReviewRequestProps): JSX.Element => {
	return (
		<ReviewRequestProvider>
			<ReviewRequestForm {...reviewReqProps} />
		</ReviewRequestProvider>
	)
}

const ReviewRequestForm = ({ cb }: ReviewRequestProps) => {
	const { isSuccessful, candidateJobId } = useReviewRequest()

	return (
		<ReviewRequestView>
			{isSuccessful ? (
				<SuccessScreen
					titleText='Review Requested!'
					animation={<SuccessAnimation type='requestReview' />}
					cta={
						cb ? (
							<CTA>
								<Btn onClick={cb}>Back to incoming reviews</Btn>
							</CTA>
						) : undefined
					}
				/>
			) : (
				<Wizard includeDots scrollToTop>
					<ReviewRequestSelectJob cancel={cb} />
					{candidateJobId ? <ReviewRequestAddUsers /> : <div />}
				</Wizard>
			)}
		</ReviewRequestView>
	)
}
