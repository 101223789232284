import {
	Accordion,
	Async,
	Btn,
	Card,
	CTA,
	ErrorBlock,
	Heading,
	Icon,
	MerifyIrmaSkillsSvg,
	Spinner,
	Text,
	useModalCtrl,
} from '@merify/ui'
import { Redirect } from '@reach/router'
import styled, { css } from 'styled-components'
import { useDeleteJob, useGetJob } from '../../../api/api.jobs'
import { useSetAppHeader } from '../../../utils/useSetAppHeader'
import { Page404Redirect } from '../../screens/Page404.redirect'
import { ModalAlert, ModalFull } from '../../UI/Modal'
import { toaster } from '../../UI/Toaster'
import { BackBtn } from '../Back.btn/Back.btn'
import { CandidateJobEdit } from '../Candidate.job.edit'
import { CandidateJobSummary } from '../Candidate.job.summary'
import { CandidateJobUpdateSkills } from '../Candidate.job.updateSkills/Candidate.job.updateSkills'
import { ReviewRequestFromJob } from '../Review.request/Review.request.fromJob'
import { CandidateJobReviews } from './Candidate.job.reviews'
import { CandidateJobSkills } from './Candidate.job.skills'

export const jobStyles = css`
	.back-btn {
		z-index: 10;
		top: ${props => props.theme.sizes.gutter.mobile.val};
		left: ${props => props.theme.sizes.gutter.mobile.val};
	}

	.candidate-job-info {
		padding: 40px 0 ${props => props.theme.sizes.gutter.mobile.val} 0;
		.edit-candidate-job-btn {
			width: 100%;
			display: flex;
			text-align: left;
			align-items: center;

			.icon {
				margin-left: auto;
				color: ${props => props.theme.colors.secondary.val};
			}
		}
	}
	h5 {
		margin: 5px 0;
	}

	.edit-skills-btn {
		width: 100%;
		h5 {
			padding: 40px ${props => props.theme.sizes.gutter.mobile.val} 0
				${props => props.theme.sizes.gutter.mobile.val};
			display: flex;
			align-items: center;
			width: 100%;
			.icon {
				margin-left: auto;
				svg {
					height: 27px;
				}
			}
		}
	}

	.past-review-requests {
		padding-bottom: 40px;
		.ctrl {
			padding-left: 20px;
		}
	}

	.no-skills {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: ${props => props.theme.colors.secondary.val};

		h3 {
			color: inherit;
		}
		svg {
			margin: 1rem 0;
		}
	}
`

const CandidateJobView = styled.div`
	${jobStyles}
`

export type CandidateJobProps = {
	candidateJobId: number
	hideBackBtn?: boolean
	onAfterDelete?: () => void
}
export const CandidateJob = ({
	candidateJobId,
	hideBackBtn,
	onAfterDelete,
}: CandidateJobProps): JSX.Element => {
	useSetAppHeader('default')

	const results = useGetJob(candidateJobId)
	const { candidateJob } = results.data || {}
	const [[showEditSkillsModal, openEditSkillsModal, closeEditSkillsModal]] = useModalCtrl()
	const [[showRequestReviewModal, openRequestReviewModal, closeRequestReviewModal]] = useModalCtrl()
	const [[showDeleteAlertModal, openDeleteAlertModal, closeDeleteAlertModal]] = useModalCtrl()
	const [[showEditJobModal, openEditJobModal, closeEditJobModal]] = useModalCtrl()

	const {
		mutateAsync: deleteJob,
		error: deleteErr,
		isLoading: deleteLoading,
		isSuccess,
	} = useDeleteJob(candidateJobId)

	return !onAfterDelete && isSuccess && !showDeleteAlertModal ? (
		<Redirect to='/work' noThrow />
	) : (
		<CandidateJobView>
			{deleteLoading ? <Spinner /> : null}
			<Async fetchResults={results} isScreen Page404={Page404Redirect}>
				{hideBackBtn ? null : <BackBtn />}
				<section className='candidate-job-info'>
					{candidateJob ? (
						<Card hasGutter>
							<Btn className='edit-candidate-job-btn' onClick={openEditJobModal}>
								<CandidateJobSummary size='sm' candidateJob={candidateJob} />
								<Icon type='pencil' />
							</Btn>
						</Card>
					) : null}
				</section>

				<section className='skills-list'>
					<Btn className='edit-skills-btn' onClick={openEditSkillsModal}>
						<Heading hTag='h5' displayType='dialog-heading'>
							Linked Skills <Icon type='pencil' />
						</Heading>
					</Btn>

					{results.data?.candidateJobSkills?.length ? (
						<CandidateJobSkills candidateJobId={candidateJobId} job={results.data} />
					) : null}
					{results.data?.candidateJobSkills?.length ? null : (
						<Btn onClick={openEditSkillsModal} className='no-skills'>
							<Heading hTag='h3' displayType='block-heading'>
								LET'S ADD SOME SKILLS!
							</Heading>
							<MerifyIrmaSkillsSvg />
						</Btn>
					)}
				</section>

				<section className='past-review-requests'>
					<Accordion ctrl='Past Review Requests'>
						<CandidateJobReviews candidateJobId={candidateJobId} />
					</Accordion>
				</section>
				<section className='btns'>
					{deleteErr ? <ErrorBlock error={deleteErr} display='inline' /> : null}
					<CTA layout='vert'>
						{results.data?.candidateJobSkills?.length ? (
							<Btn onClick={openRequestReviewModal}>Request a Review</Btn>
						) : null}
						<CTA pad={false} displayType='no-border' color='err'>
							<Btn onClick={openDeleteAlertModal}>Delete Job</Btn>
						</CTA>
					</CTA>
				</section>
			</Async>

			<ModalFull
				isOpen={showEditJobModal}
				closeHandler={closeEditJobModal}
				headerContent='Edit Job'
			>
				<CandidateJobEdit candidateJobId={candidateJobId} cb={closeEditJobModal} />
			</ModalFull>
			<ModalFull
				isOpen={showEditSkillsModal}
				closeHandler={closeEditSkillsModal}
				headerContent={
					results.data?.candidateJobSkills?.length ? `Update Skills on Job` : `Add Skills to Job`
				}
			>
				{results.data ? (
					<CandidateJobUpdateSkills cb={closeEditSkillsModal} jobWithSkills={results.data} />
				) : null}
			</ModalFull>
			<ModalFull
				isOpen={showRequestReviewModal}
				closeHandler={closeRequestReviewModal}
				headerContent='Request a New Review'
			>
				<ReviewRequestFromJob candidateJobId={candidateJobId} cb={closeRequestReviewModal} />
			</ModalFull>
			<ModalAlert
				isOpen={showDeleteAlertModal}
				closeHandler={closeDeleteAlertModal}
				asyncConfirmHandler={async () => {
					try {
						await deleteJob()
						toaster.ok(
							`${
								candidateJob?.jobTitle ? `'${candidateJob.jobTitle}'` : 'Job'
							} successfully deleted`
						)
						if (onAfterDelete) {
							onAfterDelete()
						}
					} catch (err) {
						throw err
					}
				}}
				showAlertBtns
				confirmTxt='Yes'
				cancelTxt='No'
				defaultBtn='cancel'
			>
				<Text size='normal' textType='p'>
					Are you sure you want to delete this job?
				</Text>
			</ModalAlert>
		</CandidateJobView>
	)
}
