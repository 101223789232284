import { Async, Card, NotificationType } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetNotificationByIdAndType } from '../../../api/api.notifications'
import { DynamicNotificationParams } from '../Notification.detail'
import { NotificationDetailAddSkillsCta } from '../Notification.detail.addSkills.cta'
import { NotificationInfo } from '../Notification.detail.info'
import { NotificationDetailSkillsList } from '../Notification.detail.skillsList'
import { notificationStyles } from '../Notification.styles'

export const skillVerificationUpdateNotificationDetailStyles = css`
	${notificationStyles}

	.delete-notification-cta {
	}
`

const SkillVerificationUpdateNotificationDetailView = styled.div`
	${skillVerificationUpdateNotificationDetailStyles}
`

export type SkillVerificationUpdateNotificationDetailProps = DynamicNotificationParams

export const SkillVerificationUpdateNotificationDetail = ({
	id,
	cb,
}: SkillVerificationUpdateNotificationDetailProps): JSX.Element => {
	const type: NotificationType = 'candidateSkillVerificationSkillsUpdateNotifications'

	const notificationsRes = useGetNotificationByIdAndType({
		notificationId: id,
		notificationType: type,
	})

	const notification = notificationsRes.data
	const { addedSkills: skills } = notification || {}

	return (
		<SkillVerificationUpdateNotificationDetailView>
			<Async fetchResults={[notificationsRes]}>
				<Card>
					{notification ? <NotificationInfo notification={notification} /> : null}
					{skills ? (
						<NotificationDetailSkillsList skills={skills as any} heading='Additional Skills:' />
					) : null}
				</Card>
				<NotificationDetailAddSkillsCta
					skills={skills as any}
					deleteProps={{ notificationId: id, notificationType: type, cb }}
				/>
			</Async>
		</SkillVerificationUpdateNotificationDetailView>
	)
}
