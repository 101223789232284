import { Btn, CTA } from '@merify/ui'
import styled, { css } from 'styled-components'
import { WelcomeVideoScreen } from '../../../global/Welcome.video.screen/Welcome.video.screen'

export const reviewsScreenWelcomeStyles = css``

const ReviewsScreenWelcomeView = styled.div`
	${reviewsScreenWelcomeStyles}
`

export type ReviewsScreenWelcomeProps = {
	cb: () => void
}
export const ReviewsScreenWelcome = ({ cb }: ReviewsScreenWelcomeProps): JSX.Element => {
	return (
		<ReviewsScreenWelcomeView>
			<WelcomeVideoScreen
				icon='reviews'
				title='Your Reviews'
				text={`Now it's time to request feedback on your professional skills from your current and former coworkers. Here's a video to explain the process.`}
				video={{
					url: 'https://youtu.be/bXHM6dIJpjc',
					youTubeVidId: 'bXHM6dIJpjc',
				}}
			>
				<CTA padTop>
					<Btn onClick={cb}>Start</Btn>
				</CTA>
			</WelcomeVideoScreen>
		</ReviewsScreenWelcomeView>
	)
}
