import { Heading, Shield, useState } from '@merify/ui'
import { round } from 'lodash'
import { memo } from 'react'
import styled, { css } from 'styled-components'
import { ModalFull } from '../../UI/Modal'

import { TutSkillVetting } from '../Tut.skillVetting'

export const skillVettingStyles = css`
	.vetting-wrapper {
		display: flex;
		justify-content: center;
		align-items: stretch;
		cursor: pointer;
		.shield-wrap {
			h5 {
				line-height: initial;
				margin: 0;
				font-size: 18px;
				color: ${props => props.theme.colors.secondary.val};
				text-align: center;
				font-weight: 600;
			}
			.percentage {
				font-weight: 600;
			}
		}
		.shield {
			height: auto;
			.icon {
				width: 65px;
				height: auto;
				svg {
					width: 100%;
					height: auto;
				}
			}
		}
		.vetting-detail {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		.vetting-detail-bar {
			margin-left: 1rem;

			&:last-child {
				margin-bottom: 0;
			}

			.bar-wrap {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.bar {
				width: 100%;
				background: ${props => props.theme.colors.secondary.light(6).val};
				height: 12px;
				.bar-content {
					display: block;
					height: 100%;
					transition: all ${props => props.theme.times.tranL};
					width: 100%;
					background: ${props => props.theme.colors.secondary.val};
				}
			}
			.percent {
				color: ${props => props.theme.colors.secondary.val};
				font-weight: 700;
				white-space: nowrap;
				margin-left: 0.5rem;
			}
		}
	}

	&.__invert {
		.vetting-wrapper {
			.bar-title {
				color: ${props => props.theme.colors.white.val};
			}
			.shield-wrap {
				h5 {
					color: ${props => props.theme.colors.white.val};
				}
			}
			.shield {
				color: ${props => props.theme.colors.white.val};

				.icon {
					color: inherit;

					&.shieldStar {
						color: ${props => props.theme.colors.primary.val};
					}
				}
				.percentage {
					color: inherit;
				}
			}
			.percent {
				color: ${props => props.theme.colors.white.val};
			}
		}
	}
`

const SkillVettingView = styled.div`
	${skillVettingStyles}
`
type VettingDetailBarProps = {
	value: number
	title: 'Total reviews' | 'Reviewer network' | 'Review alignment'
}
const VettingDetailBar = memo(({ value, title }: VettingDetailBarProps): JSX.Element => {
	const percentage = value * 100
	return (
		<div className='vetting-detail-bar'>
			<span className='bar-title'>{title}</span>
			<div className='bar-wrap'>
				<div className='bar'>
					<div className='bar-content' style={{ maxWidth: `${percentage}%` }} />
				</div>
				<div className='percent'>{round(percentage)}%</div>
			</div>
		</div>
	)
})

export type VettingNumbers = {
	overallVetting: number
	totalReviewVetting: number
	reviewerPoolDiversityVetting: number
	levelVetting: number
}

export type SkillVettingProps = VettingNumbers & {
	isInverted?: boolean
	isLoading?: boolean
}
export const SkillVetting = ({
	isInverted,
	isLoading,
	...vettingNumbers
}: SkillVettingProps): JSX.Element => {
	const { overallVetting, totalReviewVetting, reviewerPoolDiversityVetting, levelVetting } =
		vettingNumbers
	const [showModal, setShowModal] = useState(false, 'showModal')
	return (
		<SkillVettingView className={`skill-vetting${isInverted ? ' __invert' : ''}`}>
			<div className='vetting-wrapper' onClick={() => setShowModal(true)}>
				<div className='shield-wrap'>
					<Shield value={overallVetting || null} isLoading={isLoading} />
					<Heading hTag='h5' displayType='block-heading'>
						{overallVetting === 1 ? 'Merified™' : 'Vetting'}
					</Heading>
				</div>
				<div className='vetting-detail'>
					<VettingDetailBar value={totalReviewVetting || 0} title='Total reviews' />
					<VettingDetailBar value={reviewerPoolDiversityVetting || 0} title='Reviewer network' />
					<VettingDetailBar value={levelVetting || 0} title='Review alignment' />
				</div>
			</div>
			<ModalFull
				headerContent='Skill Vetting Guide'
				headerAlign='center'
				isOpen={showModal}
				closeHandler={() => setShowModal(false)}
			>
				<TutSkillVetting {...vettingNumbers} />
			</ModalFull>
		</SkillVettingView>
	)
}
