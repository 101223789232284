import { Avatar, Icon, IconType } from '@merify/ui'
import { useMemo } from 'react'
import { SelectPicker, SelectPickerProps } from 'rsuite'
import styled, { css } from 'styled-components'
import { useGetCandidatesForKudos } from '../../../api/api.reviews'

const SelectKudosCandidateStyles = css`
	&.__has-icon {
		.rs-picker {
			color: inherit !important;
			.rs-picker-toggle {
				color: inherit !important;
				background: none !important;
				border: none;
				padding: 0;
				outline: none;
				box-shadow: none;
				.rs-picker-toggle-textbox {
					border: none;
					background: none !important;
				}
				.rs-btn-close {
					display: none;
				}
			}
		}
	}
`

const SelectKudosCandidateView = styled.div`
	${SelectKudosCandidateStyles}
`

const MenuTitle = styled.h4`
	font-size: 14px;
	font-weight: 700;
	padding: 10px 15px;
`

const MenuItem = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
`

type SelectKudosCandidateProps = {
	className?: string
	onSelect?: (value: number | null) => void
	value?: number | null
	ctrlIcon?: IconType
	placeholder?: string
	placement?: 'autoVerticalEnd' | 'autoVerticalStart'
	block?: boolean
	menuTitle?: string
}
export const SelectKudosCandidate = ({
	className,
	onSelect,
	value,
	ctrlIcon,
	placeholder,
	placement,
	block,
	menuTitle,
}: SelectKudosCandidateProps): JSX.Element => {
	const results = useGetCandidatesForKudos()
	const candidates = results.data

	const data = useMemo(() => {
		return candidates?.map(user => ({
			value: user.candidateId,
			label: (
				<MenuItem>
					<Avatar user={user as any} size='sm' />
					<div className='name'>
						{`${user.firstName}${user.lastName ? ` ${user.lastName}` : ''}`}
					</div>
				</MenuItem>
			),
		}))
	}, [candidates])

	const pickerProps: SelectPickerProps<number> = {
		data: data || [],
		loading: results.isLoading,
		disabled: results.isError,
		searchable: candidates?.length && candidates?.length > 10 ? true : false,
		appearance: ctrlIcon ? 'subtle' : 'default',
		onChange: onSelect,
		placeholder: ctrlIcon ? <Icon type={ctrlIcon} /> : placeholder,
		menuStyle: {
			maxWidth: '70vw',
		},
		value,
		caretAs: ctrlIcon ? () => <></> : undefined,
		renderValue: ctrlIcon ? () => <Icon type={ctrlIcon} /> : ({ label }: any) => label,
		placement,
		block,
		renderMenu: menuTitle
			? (menu: any) => {
					return (
						<>
							<MenuTitle className='menu-title'>{menuTitle}</MenuTitle>
							{menu}
							{/* <div className='rs-picker-menu-header'>
            <span className='rs-picker-menu-header-title'>{menuTitle}</span>
          </div>
          <div className='rs-picker-menu-body'>{menu}</div> */}
						</>
					)
			  }
			: undefined,
	}

	return (
		<SelectKudosCandidateView
			className={`select-kudos-candidate${className ? ` ${className}` : ''}${
				ctrlIcon ? ' __has-icon' : ''
			}`}
		>
			<SelectPicker {...pickerProps} />
		</SelectKudosCandidateView>
	)
}
