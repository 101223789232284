import {
	Btn,
	CandidateSkillWithScore,
	CTA,
	Ctas,
	ErrorBlock,
	SkillLevelId,
	skillLevelsWithInfo,
	Spinner,
	useModalCtrl,
} from '@merify/ui'
import { Reducer, useEffect, useReducer } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { useGetSkillLevelPreview, useUpdateSkillLevel } from '../../../api/api.skills'
import { ModalFull } from '../../UI/Modal'
import { toaster } from '../../UI/Toaster'
import { ScoreCircle } from '../Score.circle'
import { RatingsGuideContent } from '../Tut.ratingsGuide/Ratings.guide.content'
import { SkillLevelSet } from './Skill.levelSet'
import { SkillVetting, VettingNumbers } from './Skill.vetting'

export const skillScoreStyles = css`
	//border-bottom: solid 1px ${props => props.theme.colors.lightGrey.val};
	transition: all ${props => props.theme.times.tranM};
	& > section {
		padding: 1.4rem ${props => props.theme.sizes.gutter.mobile.val};
	}
	.vetting-section {
	}

	.score-section {
		border-top: solid 1px ${props => props.theme.colors.lightGrey.val};
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 20px;
		padding-bottom: 0;
		.ratings-guide-btn {
			position: absolute;
			right: ${props => props.theme.sizes.gutter.mobile.val};
			top: 1.5em;
		}
	}
	.level-section {
		padding-top: 1.5em;
	}
	.btns-section {
		padding-top: 0;
		display: flex;
		justify-content: center;

		.save-btns {
			width: auto;
		}
	}
	.rating-circle {
		margin-bottom: 1rem;
	}

	&.__editing {
	}
`

const SkillScoreView = styled.div`
	${skillScoreStyles}
`

type ReducerState = {
	enableLevelSet: boolean
	enableEditView: boolean
	levelId: number
	score: number
	vettingNumbers: VettingNumbers
}

type ReducerAction = {
	action: 'static' | 'editing' | 'saving' | 'updateOnly'
	levelId?: number
	score?: number
	skill?: CandidateSkillWithScore
}

const skillScoreReducer: Reducer<ReducerState, ReducerAction> = (state, actionOpts) => {
	const { action, levelId, score, skill } = actionOpts

	const newState = { ...state }

	if (typeof levelId === 'number') newState.levelId = levelId
	if (typeof score === 'number') newState.score = score

	if (skill) {
		newState.vettingNumbers = {
			overallVetting: skill.overallVetting,
			totalReviewVetting: skill.totalReviewVetting,
			reviewerPoolDiversityVetting: skill.reviewerPoolDiversityVetting,
			levelVetting: skill.levelVetting,
		}
	}

	if (action === 'editing') {
		newState.enableEditView = true
		newState.enableLevelSet = true
	}

	if (action === 'saving') {
		newState.enableEditView = true
		newState.enableLevelSet = false
	}

	if (action === 'static') {
		newState.enableEditView = false
		newState.enableLevelSet = false
	}

	return newState
}

export type SkillScoreProps = {
	skill: CandidateSkillWithScore
	className?: string
}
export const SkillScore = ({ skill, className }: SkillScoreProps): JSX.Element => {
	const hasExistingScore = !!skill?.score

	const [{ levelId, score, enableLevelSet, enableEditView, vettingNumbers }, dispatch] = useReducer(
		skillScoreReducer,
		{
			enableLevelSet: false,
			enableEditView: false,
			levelId: skill.currentSkillLevelId,
			score: skill.score,
			vettingNumbers: {
				overallVetting: skill.overallVetting,
				totalReviewVetting: skill.totalReviewVetting,
				reviewerPoolDiversityVetting: skill.reviewerPoolDiversityVetting,
				levelVetting: skill.levelVetting,
			},
		}
	)

	const [[showGuideModal, openGuideModal, closeGuideModal]] = useModalCtrl()

	const {
		data: previewSkill,
		isLoading: previewLoading,
		error: previewError,
	} = useGetSkillLevelPreview(skill.skillId, levelId, {
		disable: !enableEditView || !hasExistingScore,
	})

	const levelInfo = skillLevelsWithInfo.find(level => level.levelId === levelId)

	const { mutateAsync, error, isLoading: saveLoading } = useUpdateSkillLevel(skill.candidateSkillId)
	const saveLevelHandler = async () => {
		try {
			dispatch({
				action: 'saving',
			})
			await mutateAsync(levelId)

			dispatch({
				action: 'static',
			})
			// toaster.ok('Skill level saved!')
		} catch (err) {
			toaster.err('Unable to save level - Please try again later...')
		}
	}

	useEffect(() => {
		dispatch({
			action: 'static',
			levelId: skill.currentSkillLevelId,
			score: skill.score,
			skill,
		})
	}, [skill])

	useEffect(() => {
		if (!previewLoading && previewSkill)
			dispatch({
				action: 'updateOnly',
				score: previewSkill.score,
				skill: previewSkill,
			})
	}, [previewSkill, previewLoading])

	useEffect(() => {
		if (previewError) {
			dispatch({
				action: 'static',
				levelId: skill.currentSkillLevelId,
				score: skill.score,
				skill,
			})
			toaster.err('Oops, something went wrong - Please try again later...')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [previewError])

	const isCircleSolid = previewSkill?.overallVetting
		? previewSkill.overallVetting === 1
		: skill?.overallVetting === 1

	const { colors } = useTheme()
	return (
		<SkillScoreView
			className={`skill-score${className ? ` ${className}` : ''}${
				enableEditView ? ' __editing' : ''
			}`}
			style={{
				background: enableEditView ? levelInfo?.color : 'none',
				color: enableEditView ? colors.white.val : levelInfo?.color,
			}}
		>
			<ErrorBlock error={error} display='inline' />
			<section className='vetting-section'>
				<SkillVetting {...vettingNumbers} isInverted={enableEditView} isLoading={previewLoading} />
			</section>
			<section className='rating-wrap'>
				<section className='score-section' onClick={openGuideModal}>
					<ScoreCircle
						levelId={levelId}
						rating={score}
						isSolid={isCircleSolid}
						isInverted={enableEditView}
						isLoading={previewLoading}
					/>
				</section>
				<section className='level-section'>
					<SkillLevelSet
						levelId={levelId || 1}
						enabled={enableLevelSet}
						invert={enableEditView}
						onChange={levelId =>
							dispatch({
								action: 'updateOnly',
								levelId,
							})
						}
					/>
				</section>
			</section>
			<section className='btns-section'>
				{saveLoading ? <Spinner bgColor={levelInfo?.color} color={colors.white.val} /> : null}
				{enableEditView ? (
					<Ctas className='save-btns'>
						<CTA size='sm' bgColor='white'>
							<Btn
								disabled={saveLoading}
								onClick={() => {
									dispatch({
										action: 'static',
										levelId: skill.currentSkillLevelId,
										score: skill.score,
										skill,
									})
								}}
							>
								Cancel
							</Btn>
						</CTA>
						<CTA size='sm' displayType='solid'>
							<Btn
								disabled={
									saveLoading ||
									previewLoading ||
									(enableEditView && levelId === skill.currentSkillLevelId)
								}
								onClick={saveLevelHandler}
							>
								Save
							</Btn>
						</CTA>
					</Ctas>
				) : (
					<CTA size='sm'>
						<Btn
							onClick={() => {
								dispatch({
									action: 'editing',
								})
								window.scrollTo({ top: 0 })
							}}
						>
							Adjust Level
						</Btn>
					</CTA>
				)}
			</section>
			<ModalFull
				headerContent='Ratings Guide'
				headerAlign='left'
				isOpen={showGuideModal}
				closeHandler={closeGuideModal}
			>
				<RatingsGuideContent score={score} levelId={levelId as SkillLevelId} />
			</ModalFull>
		</SkillScoreView>
	)
}
