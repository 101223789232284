import React, { ReactChild } from 'react'
import styled, { css } from 'styled-components'
import { ToastType } from '.'

export const toastedStyles = css`
	align-items: center;
	padding-right: 0.5em;
	.toast-icon {
		align-items: center;
		padding-right: 0.5em;
	}
	.toast-content {
		align-items: center;
	}
	.icon {
		height: 20px;
	}
`

const ToastedView = styled.div`
	${toastedStyles}
`

export type ToastedProps = {
	type?: ToastType
	children?: ReactChild
	className?: string
}
export const Toasted = ({ type, children, className }: ToastedProps): JSX.Element => {
	return (
		<ToastedView className={`toast-contents flex${className ? ` ${className}` : ''}`}>
			<div className='toast-content flex'>{children}</div>
		</ToastedView>
	)
}
