import { log } from '@merify/ui'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useEffect } from 'react'
import { useAuth } from 'react-oidc-context'

type LogoutRouteProps = RouteComponentProps & {}
export const LogoutRoute = (_: LogoutRouteProps): JSX.Element => {
	const { clearStaleState, isAuthenticated, signoutRedirect, removeUser, revokeTokens } = useAuth()
	const goTo = useNavigate()

	console.log('navigated to logout')

	useEffect(() => {
		if (isAuthenticated) {
			clearStaleState()
			// removeUser()
			// revokeTokens()

			// NOTE: Unable to logout user directly from the client - we have to use the signoutRedirect method
			console.info('logging out...')

			signoutRedirect()
		} else {
			log(' User is not authenticated - redirecting to login...')

			goTo('/login')
		}
	}, [isAuthenticated, clearStaleState, signoutRedirect, removeUser, revokeTokens, goTo])

	return <></>
}
