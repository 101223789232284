import { isStrAnInt } from '@merify/ui'
import { RouteComponentProps } from '@reach/router'
import { useEffect } from 'react'
import styled from 'styled-components'
import { useSetAppHeader } from '../../../../utils/useSetAppHeader'
import { useAppDrawer } from '../../../global/Drawer.app'
import { Skill } from '../../../global/Skill'
import { Page404Redirect } from '../../Page404.redirect'

const SkillScreenView = styled.div``

type SkillScreenProps = RouteComponentProps & {
	candidateSkillId?: string
}
export const SkillScreen = ({ candidateSkillId }: SkillScreenProps): JSX.Element => {
	useSetAppHeader('default')

	const isValidId = isStrAnInt(candidateSkillId)

	const { setDisplayType } = useAppDrawer()
	useEffect(() => {
		setDisplayType('one-col')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return isValidId ? (
		<SkillScreenView>
			{candidateSkillId ? (
				<Skill candidateSkillId={parseInt(candidateSkillId)} />
			) : (
				<Page404Redirect />
			)}
		</SkillScreenView>
	) : (
		<Page404Redirect />
	)
}
