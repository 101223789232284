import { CombinedNotification, Heading, Icon, Spinner } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetMyNotifications } from '../../../api/api.notifications'
import { formatNotification } from '../../../utils/notification.utils'
import { Link } from '../../UI/Link'

export const notificationsBtnStyles = css`
	padding-right: 8px;

	.spinner {
		width: 27.59px;
		background: none !important;
	}

	.notification-link {
		a {
			position: relative;
			display: block;
		}
	}
	.qty {
		display: flex;
		align-items: center;
		justify-content: center;
		background: ${props => props.theme.colors.err.val};
		border-radius: 50%;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		position: absolute;
		right: -10px;
		z-index: 1;
		border: 2px solid ${props => props.theme.colors.white.val};

		&.__loading {
			background: ${props => props.theme.colors.white.val};
		}

		&.__small {
			h5 {
				font-size: 0.8rem;
			}
		}
		&.__hidden {
			display: none;
		}
		h5 {
			color: ${props => props.theme.colors.white.val};
			font-size: 1rem;
		}
	}
	.icon {
		z-index: 0;
	}
`

const NotificationsBtnView = styled.div`
	${notificationsBtnStyles}
`

export type NotificationsBtnProps = {}
export const NotificationsBtn = (): JSX.Element => {
	const results = useGetMyNotifications()
	const { data, isLoading } = results

	const totalNotViewed = data
		? Object.values(data)
				.flat()
				.filter(notification => {
					const { viewed, notificationType } = formatNotification(
						notification as CombinedNotification
					)
					if (viewed) return false

					return (
						notificationType === 'requestedReviewNotifications' ||
						notificationType === 'skillDisapprovedNotifications' ||
						notificationType === 'skillApprovedNotifications' ||
						notificationType === 'candidateSkillVerificationNotifications' ||
						notificationType === 'candidateSkillVerificationSkillsUpdateNotifications' ||
						notificationType === 'candidateSkillVerificationReminderNotifications' ||
						notificationType === 'candidateSkillVerificationDeadlineUpdateNotifications' ||
						notificationType === 'candidateSkillVerificationDeadlineReachedNotifications' ||
						notificationType === 'employerMatchResponseNotifications' ||
						notificationType === 'candidateAnnouncementNotifications' ||
						notificationType === 'candidateRegisteredNotifications'
					)
				}).length
		: 0

	// NOTE: We do not want to handle http errors in this component
	return (
		<NotificationsBtnView>
			<Link to='/notifications' className='notification-link'>
				<div
					className={`qty${
						isLoading
							? ' __loading'
							: totalNotViewed > 9
							? ' __small'
							: totalNotViewed === 0
							? ' __hidden'
							: ''
					}`}
				>
					{isLoading ? (
						<Spinner type='circle' height='sm' isOverlay={false} />
					) : (
						<Heading hTag='h5'>{totalNotViewed < 100 ? totalNotViewed : '99+'}</Heading>
					)}
				</div>
				<Icon type='bell' />
			</Link>
		</NotificationsBtnView>
	)
}
