import { AnyCandidate, FormikText } from '@merify/ui'
import { useFormikContext } from 'formik'
import styled from 'styled-components'
import { LocationCityField } from './Location.field.city.field'

const LocationFieldView = styled.div`
	position: relative;
	input[name='city'] {
		text-transform: capitalize;
	}
	input[name='stateOrProvince'],
	input[name='country'] {
		text-transform: uppercase;
	}
`

export type LocationFieldProps = {}

type Values = Pick<
	AnyCandidate,
	'city' | 'stateOrProvince' | 'country' | 'postalCode' | 'placeId' | 'longitude' | 'latitude'
>

export const LocationField = (): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<Values>()

	return (
		<LocationFieldView className='address-fields'>
			{/* dummy button hack to fix issue with city field enter key event bubbling up */}
			<button
				style={{ display: 'none' }}
				onClick={e => {
					e.preventDefault()
					e.stopPropagation()
				}}
			/>
			<LocationCityField
				value={values.city as string}
				onChange={e => {
					const target = e.target as HTMLInputElement

					setFieldValue('city', target.value)
				}}
				onPlaceSelected={res => {
					const { city, state, country, postalCode, longitude, latitude, placeId } = res || {}

					if (city?.long_name) setFieldValue('city', city?.long_name)
					if (state?.short_name) setFieldValue('stateOrProvince', state?.short_name)
					if (country?.short_name) setFieldValue('country', country?.short_name)
					if (postalCode?.short_name) setFieldValue('postalCode', postalCode?.short_name)
					if (longitude) setFieldValue('longitude', longitude)
					if (latitude) setFieldValue('latitude', latitude)
					if (placeId) setFieldValue('placeId', placeId)
				}}
				fieldProps={{}}
				name='city'
				label='City'
				placeholder=''
			/>
			<FormikText name='stateOrProvince' config={{ label: 'State' }} />
			<FormikText name='postalCode' config={{ label: 'Postal Code' }} />
			<FormikText name='country' config={{ label: 'Country' }} />
		</LocationFieldView>
	)
}
