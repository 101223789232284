import { CandidateJobAndSkills, useState } from '@merify/ui'
import styled, { css } from 'styled-components'
import { CandidateJobItem, CandidateJobItemProps } from './Candidate.job.item'

export const candidateJobsListStyles = css`
	text-align: center;
	h5 {
		button {
			padding: 10px ${props => props.theme.sizes.gutter.mobile.val};
			color: ${props => props.theme.colors.primary.val};
		}
	}
	.candidate-job-item {
		border-bottom: 1px solid ${props => props.theme.colors.paleGrey.val};
		text-align: left;
		&:first-child {
			border-top: 1px solid ${props => props.theme.colors.paleGrey.val};
		}
	}
`

const CandidateJobsListView = styled.div`
	${candidateJobsListStyles}
`

type CandidateJobsListProps = {
	candidateJobs?: CandidateJobAndSkills[] | null
	selectedJobIds?: number[]
	onSelect?: (job: CandidateJobAndSkills) => void
	showSelectRadios?: CandidateJobItemProps['showSelectRadios']
	optionBtn?: CandidateJobItemProps['option']
	calculateDisabled?: (candidateJob: CandidateJobAndSkills) => boolean
}
export const CandidateJobsList = ({
	candidateJobs,
	selectedJobIds = [],
	onSelect,
	showSelectRadios,
	optionBtn,
	calculateDisabled,
}: CandidateJobsListProps): JSX.Element => {
	const [lastSelectedId, setLastSelected] = useState<number | null>(null)
	// NOTE: array.sort mutates array so we create a new array
	const candidateJobsForRender = candidateJobs?.length
		? [...candidateJobs].sort((a, b) => {
				// sort criteria: If there's no end date it's at the top in chronological order by start date
				// If there is an end date then sort chronologically on end date (latest end dates at the top)
				// If end dates are the same then sort on start date

				const aStart = a.candidateJob.startDate
				const bStart = b.candidateJob.startDate
				const aEnd = a.candidateJob.endDate || ''
				const bEnd = b.candidateJob.endDate || ''

				// If not end date it goes to the top
				if (aEnd && !bEnd) return +1
				else if (!aEnd && bEnd) return -1
				else if (aEnd && bEnd) {
					// Both have end dates
					if (aEnd > bEnd) return -1
					else if (aEnd < bEnd) return +1
					// If both end dates are the same we sort on start date
					else if (aStart > bStart) return -1
					else if (aStart < bStart) return +1
					else return 0
				} else if (!aEnd && !bEnd) {
					if (aStart > bStart) return -1
					else if (aStart < bStart) return +1
					else return 0
				} else return 0
		  })
		: undefined

	return (
		<CandidateJobsListView className='candidateJobs-list'>
			<div className='candidateJobs'>
				{candidateJobsForRender?.length
					? candidateJobsForRender.map(job => (
							<CandidateJobItem
								key={job.candidateJob.candidateJobId}
								job={job}
								isDisabled={calculateDisabled ? calculateDisabled(job) : false}
								onSelect={
									onSelect
										? () => {
												onSelect(job)
												setLastSelected(job.candidateJob.candidateJobId)
										  }
										: undefined
								}
								showSelectRadios={showSelectRadios}
								isSelected={
									selectedJobIds.includes(job.candidateJob.candidateJobId) ||
									job.candidateJob.candidateJobId === lastSelectedId
								}
								option={
									optionBtn
										? {
												...optionBtn,
												onClick: () => {
													optionBtn.onClick(job.candidateJob.candidateJobId)
													setLastSelected(job.candidateJob.candidateJobId)
												},
										  }
										: undefined
								}
							/>
					  ))
					: null}
			</div>
		</CandidateJobsListView>
	)
}
