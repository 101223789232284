import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

export const smallCtasStyles = css<{ maxW: string }>`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 0.5em;
	padding: 1em;
	max-width: ${p => p.maxW};
	margin: 0 auto;

	& > * {
		&.__size-sm {
			button,
			a {
				width: 100%;
			}
		}
	}
`

const SmallCtasView = styled.div`
	${smallCtasStyles}
`

export type SmallCtasProps = {
	children: [ReactNode, ReactNode]
	maxW?: string
	className?: string
}
export const SmallCtas = ({
	children,
	maxW = 'initial',
	className,
}: SmallCtasProps): JSX.Element => {
	return (
		<SmallCtasView className={`small-ctas${className ? ` ${className}` : ''}`} maxW={maxW}>
			{children}
		</SmallCtasView>
	)
}
