import { FormikSelectProps } from '@merify/ui'
import { useField } from 'formik'
import styled, { css } from 'styled-components'
import { SelectApiField, SelectApiFieldProps } from '../Select.Api.field'

export const formikSelectApiStyles = css``

const FormikSelectApiView = styled.div`
	${formikSelectApiStyles}
`

export type FormikSelectApiProps = Pick<FormikSelectProps, 'defaultTxt' | 'config' | 'name'> &
	Pick<SelectApiFieldProps, 'fetchHook' | 'children' | 'className'> & {}
export const FormikSelectApi = ({
	config,
	name,
	className,
	...selectApiProps
}: FormikSelectApiProps): JSX.Element => {
	const [{ value }, { touched }, { setValue, setTouched }] = useField({ name })
	return (
		<FormikSelectApiView className={`formik-select-api${className ? ` ${className}` : ''}`}>
			<SelectApiField
				{...config}
				{...selectApiProps}
				onSelect={(val: any) => {
					setValue(val)
					if (!touched) setTouched(true)
				}}
				value={value}
			/>
		</FormikSelectApiView>
	)
}
