import {
	CandidateSkill,
	IncomingPublicReviewRequest,
	IncomingReviewRequest,
	OutgoingReviewRequest,
	PrivateCandidate,
} from '@merify/ui'
import { useMutation, useQueryClient } from 'react-query'
import { authenticatedRequest } from '../utils/api.utils'
import { useCurrentUser } from '../utils/useCurrentUser'
import { useAuthedFetch, useFetchOptions, useUnauthedFetch } from '../utils/useFetch'
import { getMyNotificationsKey } from './api.notifications'
import { fetch } from './fetch'

export type KudosCandidate = Pick<
	PrivateCandidate,
	| 'candidateId'
	| 'firstName'
	| 'lastName'
	| 'industryId'
	| 'title'
	| 'bio'
	| 'pronouns'
	| 'formerName'
	| 'city'
	| 'stateOrProvince'
	| 'country'
	| 'registered'
	| 'createdUtc'
	| 'industryName'
	| 'imageUrl'
	| 'thumbnailImageUrl'
>

type KudosCandidateSkill = Pick<
	CandidateSkill,
	| 'skillId'
	| 'skillName'
	| 'candidateSkillId'
	| 'candidateId'
	| 'createdUtc'
	| 'currentSkillLevelId'
	| 'visible'
>

type KudosReview = {
	kudosReviewId: number
	kudosCandidateId: number
	kudosCandidateJobId: number
	reviewerCandidateId: number
	kudosEffectiveDate: string
	comments: string
	createdUtc: string
}

type KudosReviewedSkill = {
	kudosSkillReviewId: number
	kudosReviewId: number
	skillId: number
	skillLevelId: number
	rating: number
	skillComments: string
	createdUtc: string
}

export type PostKudosReviewResponse = {
	kudosReview: KudosReview
	kudosSkillReviews: KudosReviewedSkill[]
}

/*
 =================================================
	QUERIES
 =================================================
* */
/**
 *  GET ALL REVIEWS BY UID
 * */
const getReviewsKey = (uid: number, params?: UseGetReviewsParams) =>
	JSON.stringify({
		name: 'reviews',
		uid,
		params,
	})

type UseGetReviewsParams = {
	pendingOnly?: boolean
}
export const useGetReviewRequests = (params?: UseGetReviewsParams) => {
	const { uid, token } = useCurrentUser()

	const { pendingOnly = true } = params || {}

	return useAuthedFetch<IncomingReviewRequest[]>(getReviewsKey(uid), () =>
		authenticatedRequest({ uid, token }, () =>
			fetch(
				'GET',
				`/Reviews/ClientUtilities/Candidates/${uid}/RequestedReviews/Incoming/Detail`,
				token,
				{
					params: {
						pendingOnly,
					},
				}
			)
		)
	)
}

/**
 *  GET SINGLE REVIEW BY ID
 * */
const getReviewKey = (uid: number, reviewId: number) =>
	JSON.stringify({
		uid,
		reviewId,
		name: 'review',
	})

export const useGetReviewRequest = (reviewId: number, options?: useFetchOptions) => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<IncomingReviewRequest>(
		getReviewKey(uid, reviewId),
		() =>
			authenticatedRequest({ uid, token }, () =>
				fetch(
					'GET',
					`/Reviews/ClientUtilities/Candidates/${uid}/RequestedReviews/Incoming/${reviewId}/Detail`,
					token
				)
			),
		options
	)
}

/**
 *  GET ALL REVIEWS BY JOB ID
 * */
const getReviewsByJobKey = (uid: number, candidateJobId: number) =>
	JSON.stringify({
		uid,
		candidateJobId,
		name: 'reviewsByJob',
	})

export const useGetReviewsByJob = (candidateJobId: number, options?: useFetchOptions) => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<OutgoingReviewRequest[]>(
		getReviewsByJobKey(uid, candidateJobId),
		() =>
			authenticatedRequest({ uid, token }, () =>
				fetch(
					'GET',
					`/Reviews/ClientUtilities/Candidates/${uid}/RequestedReviews/Job/${candidateJobId}`,
					token
				)
			),
		options
	)
}

/**
 *  GET REVIEW REQUEST BY TOKEN
 *  (Public Reviews)
 * */

export const keyGetReviewRequestByToken = (token: string) => ['getCandidateById', token]

export const getPublicReviewRequestByToken = (token: string) => () =>
	fetch<IncomingPublicReviewRequest>(
		'GET',
		`/Reviews/ClientUtilities/Public/Candidates/RequestedUnregisteredReview`,
		'',
		{
			contentType: null,
			noCreds: true,
			params: {
				Token: token,
			},
		}
	)

export const useGetReviewRequestByToken = (token: string) => {
	const results = useUnauthedFetch<IncomingPublicReviewRequest>(
		keyGetReviewRequestByToken(token),
		getPublicReviewRequestByToken(token)
	)

	return results
}

/**
 *  GET CANDIDATES FOR KUDOS
 * */

export const keyGetCandidatesForKudos = (uid: number) => ['getCandidatesForKudos', uid]

export const useGetCandidatesForKudos = () => {
	const { uid, token } = useCurrentUser()

	const results = useAuthedFetch<KudosCandidate[]>(keyGetCandidatesForKudos(uid), () =>
		authenticatedRequest({ uid, token }, () =>
			fetch('GET', `/Candidates/${uid}/Kudos/CandidateOptions`, token)
		)
	)

	return results
}

/**
 *  GET SKILLS ON KUDOS CANDIDATE
 * */

export const keyGetSkillsOnKudosCandidate = (uid: number, candidateId: number) => [
	'getSkillsOnKudosCandidate',
	uid,
	candidateId,
]

export const useGetSkillsOnKudosCandidate = (candidateId: number) => {
	const { uid, token } = useCurrentUser()

	const results = useAuthedFetch<KudosCandidateSkill[]>(
		keyGetSkillsOnKudosCandidate(uid, candidateId),
		() =>
			authenticatedRequest({ uid, token }, () =>
				fetch(
					'GET',
					`/Candidates/${uid}/Kudos/CandidateOptions/${candidateId}/PublicSkillOptions`,
					token
				)
			)
	)

	return results
}

// END QUERIES

/*
 =================================================
	MUTATIONS
 =================================================
* */

/**
 *  POST UNAUTHED REVIEW
 * */

export type UsePostPublicReviewInputs = {
	skillReviews: ReviewedSkillInput[]
}

export const usePostPublicReview = (token: string) => {
	return useMutation((data: UsePostPublicReviewInputs) =>
		fetch('POST', `/Reviews/Public/Candidates/UnregisteredReview`, '', {
			data,
			noCreds: true,
			params: {
				Token: token,
			},
		})
	)
}

/**
 *  SEND REVIEW REQUEST
 * */

export type UseRequestReviewInputs = {
	candidateJobId: number
	data: {
		invitedCandidateEmailAddresses: string[]
		existingCandidateIds: number[]
	}
}

export const useRequestReview = () => {
	const { uid, token } = useCurrentUser()

	return useMutation(({ data, candidateJobId }: UseRequestReviewInputs) =>
		authenticatedRequest({ uid, token }, () =>
			fetch('POST', `/Candidates/${uid}/Invite/RequestJobReview/${candidateJobId}`, token, {
				data,
			})
		)
	)
}

/**
 *  RESPOND TO A REVIEW REQUEST
 * */

export type ReviewedSkillInput = {
	skillId: number
	skillLevelId: number | null
	rating: number | null
	skillComments?: string | null
}

export type UsePostReviewInputs = {
	requestedReviewId: number
	reviewerJobId: number | null
	reviewerRelationshipId: number
	wouldWorkWithAgain?: boolean
	comments?: string
	skillReviews: ReviewedSkillInput[]
}

export const usePostReview = (reviewId: number) => {
	const { uid, token } = useCurrentUser()
	const queryClient = useQueryClient()

	return useMutation(
		(data: UsePostReviewInputs) =>
			authenticatedRequest({ uid, token }, () =>
				fetch('POST', `/Reviews/Candidates/${uid}/OutgoingReviews`, token, {
					data,
				})
			),
		{
			onSettled: () => {
				queryClient.invalidateQueries(getReviewsKey(uid))
				queryClient.invalidateQueries(getReviewKey(uid, reviewId))
				queryClient.invalidateQueries(getMyNotificationsKey(uid))
			},
		}
	)
}

export const useIgnoreReview = (reviewId: number) => {
	const { uid, token } = useCurrentUser()
	const queryClient = useQueryClient()

	return useMutation(
		(isIgnored: boolean) =>
			authenticatedRequest({ uid, token }, () =>
				fetch(
					'PUT',
					`/Reviews/Candidates/${uid}/RequestedReviews/Incoming/${reviewId}/Ignore`,
					token,
					{
						params: {
							isIgnored,
						},
					}
				)
			),
		{
			onSettled: () => {
				queryClient.invalidateQueries(getReviewsKey(uid))
			},
		}
	)
}

/**
 *  CREATE KUDOS
 * */

export type KudosSkillReviewInput = {
	skillId: number
	skillLevelId: number
	rating: number
	skillComments: string
}

export type PostKudosReviewInputs = {
	kudosCandidateId: number
	kudosCandidateJobId: number
	comments: string
	kudosSkillReviews: KudosSkillReviewInput[]
}

export const usePostKudosReview = () => {
	const { uid, token } = useCurrentUser()
	// const queryClient = useQueryClient()

	return useMutation(
		({ comments, kudosSkillReviews, ...data }: PostKudosReviewInputs) =>
			authenticatedRequest({ uid, token }, () =>
				fetch('POST', `/Reviews/Candidates/${uid}/OutgoingKudos`, token, {
					data: {
						...data,
						Comments: comments,
						kudosSkillReviews: kudosSkillReviews.map(
							({ skillId, skillLevelId, skillComments, rating }) => ({
								SkillId: skillId,
								SkillLevelId: skillLevelId,
								SkillComments: skillComments,
								Rating: rating,
							})
						),
					},
				})
			),
		{
			onSettled: () => {},
		}
	)
}

// END MUTATIONS
