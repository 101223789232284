import styled, { css } from 'styled-components'

export const eMStyles = css<EMProps>`
	color: ${props => (props.isErr ? props.theme.colors.err.val : 'inherit')};
`

type EMProps = {
	isErr?: boolean
}
export const EM = styled.em<EMProps>`
	${eMStyles}
`
