import {
	Async,
	Btn,
	Clipboard,
	CTA,
	NoResults,
	parseSkillsByLevel,
	Text,
	ToggleField,
	useState,
} from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetSkillsWithScores } from '../../api/api.skills'
import { Link } from '../UI/Link'

export const skillsToClipboardStyles = css`
	padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
	padding-top: 5vh;
	text-align: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100%;

	.top-copy {
		padding-bottom: 2rem;
	}

	& > * {
		text-align: left;
	}

	a {
		color: ${props => props.theme.colors.secondary.light().val};
		text-decoration: underline;
	}

	.async {
		height: auto;
	}

	.clipboard-component {
		margin: 0 auto;
		p,
		ul,
		li,
		a {
			font-size: 1.3rem;
			line-height: 1.7rem;
		}
	}

	.cb-btn {
		margin-top: 5vh;
	}
`

const SkillsToClipboardView = styled.div`
	${skillsToClipboardStyles}
`

export type SkillsToClipboardProps = {
	cb?: () => void
}
export const SkillsToClipboard = ({ cb }: SkillsToClipboardProps): JSX.Element => {
	const results = useGetSkillsWithScores()
	const [includePrivateSkills, setIncludePrivateSkills] = useState(false, 'includePrivateSkills')

	const skillsByLevel = parseSkillsByLevel(results.data, {
		view: includePrivateSkills ? 'private' : 'public',
	})?.filter(({ vettedSkills }) => !!vettedSkills.length)

	// const user = useStoreState(state => state.candidate.user)

	// const fullname = parseNameFromUser(user)

	const unvettedPublicSkills = results.data?.filter(
		({ overallVetting, visible }) => overallVetting < 1 && (includePrivateSkills ? true : visible)
	)

	const [copied, setCopied] = useState(false, 'copied')

	return (
		<SkillsToClipboardView>
			<div className='top-copy'>
				<Text size='med'>
					Use this tool to quickly snag a formatted version of your public, Merified skills on
					Merify® for use in your resume.
				</Text>
				<ToggleField
					label='Include private skills'
					onChange={setIncludePrivateSkills}
					isOn={includePrivateSkills}
				/>
			</div>
			<Async fetchResults={results}>
				{skillsByLevel?.length ? (
					<>
						<Clipboard
							className='clipboard-component'
							height='200px'
							badgeTime={3000}
							btnDisplay='icon-only'
							onAfterCopy={() => setCopied(true)}
						>
							<>
								<Text>
									Skills generated and community-vetted using{' '}
									<Link to='https://www.merify.com/'>Merify.</Link>
								</Text>
								{/* <Text>
									View {`${fullname}`}'s full{' '}
									<Link to={`${HIRING_URL}/public/candidates/${user?.candidateId}`}>
										Merify Dynamic Resume™ here.
									</Link>
								</Text> */}
								<ul>
									{skillsByLevel.map(({ id, name, vettedSkills }) => (
										<li key={id}>
											{name} - {[...vettedSkills].map(({ skillName }) => skillName).join(', ')}
										</li>
									))}
									{unvettedPublicSkills?.length ? (
										<li>
											Additional -{' '}
											{unvettedPublicSkills.map(({ skillName }) => skillName).join(', ')}
										</li>
									) : null}
								</ul>
							</>
						</Clipboard>
					</>
				) : (
					<>
						<NoResults type='skills' titleText='No skills to share...' />
						<Text textType='bold' size='med' color='secondary'>
							Try making a skill or two public to share them!
						</Text>
					</>
				)}
				{cb ? (
					<CTA>
						<Btn className='cb-btn' onClick={cb}>
							{copied ? 'Done' : 'Cancel'}
						</Btn>
					</CTA>
				) : null}
			</Async>
		</SkillsToClipboardView>
	)
}
