import { Icon, IconType } from '@merify/ui'
import { useLocation } from '@reach/router'
import styled, { css } from 'styled-components'
import { Link } from '../../UI/Link'

type MenuItem = {
	txt: string
	icon: IconType
	activeIcon: IconType
	activeMatchStrs?: string[]
	to: string
}
const menuItems: MenuItem[] = [
	{
		txt: 'Skills',
		icon: 'skills',
		activeIcon: 'skillsSolid',
		to: '/skills',
	},
	{
		txt: 'Work',
		icon: 'work',
		activeIcon: 'workSolid',
		to: '/work',
	},
	{
		txt: 'Reviews',
		icon: 'reviews',
		activeIcon: 'reviewsSolid',
		to: '/reviews',
	},
	{
		txt: 'Help',
		icon: 'question',
		activeIcon: 'questionSolid',
		activeMatchStrs: ['help', 'video-tutorials', 'faq', 'how-to-guides'],
		to: '/help',
	},
]

export const appNavStyles = css`
	li {
		a {
			font-size: 1rem;
			color: ${props => props.theme.colors.secondary.val};
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			i {
				height: 34px;
				width: 34px;
				border-radius: 5px;
				border: solid 1px transparent;
				margin-bottom: 2px;
				svg {
					color: ${props => props.theme.colors.secondary.val};
				}
				&.active-ico {
					/* svg {
						color: ${props => props.theme.colors.secondary.val};
					} */
				}
				&.inactive-ico {
					//border: solid 1px ${props => props.theme.colors.secondary.val};
				}
			}
		}
		&.__active {
			a {
				/* color: ${props => props.theme.colors.primary.val}; */
			}
		}
	}
`

const AppNavView = styled.nav`
	${appNavStyles}
`

export type AppNavProps = {}
export const AppNav = (): JSX.Element => {
	const { pathname } = useLocation()
	return (
		<AppNavView>
			{menuItems.map(item => {
				const isActive = item.activeMatchStrs?.length
					? !!item.activeMatchStrs.find(matchStr => pathname.includes(matchStr))
					: pathname.includes(item.to)
				return (
					<li key={item.to} className={isActive ? '__active' : ''}>
						<Link to={item.to}>
							{isActive ? (
								<Icon type={item.activeIcon as IconType} className='active-ico' />
							) : (
								<Icon type={item.icon as IconType} className='inactive-ico' />
							)}
							<span>{item.txt}</span>
						</Link>
					</li>
				)
			})}
		</AppNavView>
	)
}
