import { Btn, CTA, Heading, Skill, useState } from '@merify/ui'
import styled, { css } from 'styled-components'
import { SkillAddOptions } from '.'
import { SkillAddHardSkills } from './Skill.add.hardSkills'
import { SkillAddSoftSkills } from './Skill.add.softSkills'

export const skillAddSelectStyles = css`
	& > header {
		padding-bottom: 1em;
		border-bottom: solid 1px ${props => props.theme.colors.lightGrey.val};

		.btns {
			display: flex;
			align-items: center;
			justify-content: center;
			& > button {
				min-width: 125px;
			}
		}
		.or {
			display: block;
			padding: 0 1em;
		}
	}
`

const SkillAddSelectView = styled.div`
	${skillAddSelectStyles}
`

export type SkillAddSelectProps = SkillAddOptions & {
	onSelect: (skill: Skill) => void
}
export const SkillAddSelect = ({
	onSelect,
	previouslySelectedSkills,
}: SkillAddSelectProps): JSX.Element => {
	const [skillsType, setSkillsType] = useState('all', 'skillsType')

	const selectHandler = (skill: Skill) => {
		onSelect(skill)
	}

	return (
		<SkillAddSelectView>
			<header className='skill-add-header'>
				<Heading hTag='h5' displayType='block-heading' color='text' dir='center' mBot mTop>
					What type of skill are you adding?
				</Heading>
				<div className='btns'>
					<CTA size='sm' displayType={skillsType === 'all' ? 'solid' : 'normal'}>
						<Btn onClick={() => setSkillsType('all')}>All Skills</Btn>
					</CTA>

					<span className='or'>OR</span>
					<CTA size='sm' displayType={skillsType === 'soft' ? 'solid' : 'normal'}>
						<Btn onClick={() => setSkillsType('soft')}>Soft Skills Only</Btn>
					</CTA>
				</div>
			</header>
			<main>
				{skillsType === 'all' ? (
					<SkillAddHardSkills
						onSelect={selectHandler}
						previouslySelectedSkills={previouslySelectedSkills}
					/>
				) : (
					<SkillAddSoftSkills
						onSelect={selectHandler}
						previouslySelectedSkills={previouslySelectedSkills}
					/>
				)}
			</main>
		</SkillAddSelectView>
	)
}
