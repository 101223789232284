import { Branding } from '@merify/ui'
import styled, { css } from 'styled-components'

export const logoFullStyles = css``

const LogoFullView = styled.div`
	${logoFullStyles}
`

export type LogoFullProps = {}
export const LogoFull = (): JSX.Element => {
	return (
		<LogoFullView>
			<Branding type='me-beta' />
		</LogoFullView>
	)
}
