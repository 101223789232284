import { Async, ErrorBlock, parseQueryParams } from '@merify/ui'
import { RouteComponentProps, useLocation, useNavigate } from '@reach/router'
import { useEffect } from 'react'
import styled from 'styled-components'
import { useGetReviewRequestByToken } from '../../../../api/api.reviews'
import { useAppDrawer } from '../../../global/Drawer.app'
import { PublicReview } from '../../../global/Review'
import { Page404Redirect } from '../../Page404.redirect'
import { PublicReviewAlreadyComplete } from './Review.public.alreadyComplete'

const PublicReviewScreenView = styled.div``

type PublicReviewScreenProps = RouteComponentProps & {}
export const PublicReviewScreen = (_: PublicReviewScreenProps): JSX.Element => {
	const goTo = useNavigate()

	const { search } = useLocation()

	const { token } = parseQueryParams(search)

	const results = useGetReviewRequestByToken(token)

	const { reviewerIsRegistered, reviewAlreadyComplete, requestedReview, ...reviewData } =
		results.data || {}

	if (reviewerIsRegistered && !reviewAlreadyComplete)
		goTo(`/reviews/${requestedReview?.requestedReviewId}`)

	const isInvalid = !token || (results.isSuccess && !reviewData)

	const { setDisplayType } = useAppDrawer()
	useEffect(() => {
		setDisplayType('one-col')
		document.documentElement.classList.add('public')
		return () => {
			document.documentElement.classList.remove('public')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return !token ? (
		<Page404Redirect />
	) : (
		<PublicReviewScreenView>
			{isInvalid ? (
				<ErrorBlock error={new Error('Invalid review data')} display='full' hideCta />
			) : (
				<Async fetchResults={results}>
					{reviewAlreadyComplete ? (
						<PublicReviewAlreadyComplete isReviewerRegistered={reviewerIsRegistered} />
					) : results.data ? (
						<PublicReview reviewToken={token} />
					) : null}
				</Async>
			)}
		</PublicReviewScreenView>
	)
}
