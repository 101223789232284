import {
	Accordion,
	Async,
	Btn,
	Card,
	CTA,
	Heading,
	Icon,
	IncomingReviewRequest,
	MerifyIrmaReviewsSvg,
	NoResults,
	Text,
	useModalCtrl,
	useState,
} from '@merify/ui'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useEffect } from 'react'
import { useGetMe, useUpdateNewUserProgress } from '../../../../api/api.candidate'
import { useGetJobs } from '../../../../api/api.jobs'
import { useGetReviewRequests } from '../../../../api/api.reviews'
import { useGetSkillsWithScores } from '../../../../api/api.skills'
import { useSetAppHeader } from '../../../../utils/useSetAppHeader'
import { AppDrawer, AppDrawerPlaceholder } from '../../../global/Drawer.app'
import { PrivateReview } from '../../../global/Review'
import { ReviewRequest } from '../../../global/Review.request/Review.request'
import { ReviewsList } from '../../../global/Reviews'
import { WelcomeSlideout } from '../../../global/Welcome.slideout'
import { Container } from '../../../UI/Container'
import { ModalAlert, ModalFull } from '../../../UI/Modal'
import { Page404Redirect } from '../../Page404.redirect'
import { ReviewsGuide } from '../Reviews.guide'
import { ReviewsScreenView } from './Reviews.screen.styles'
import { ReviewsScreenWelcome } from './Reviews.screen.welcome'

type ReviewsScreenProps = RouteComponentProps & {}
export const ReviewsScreen = (_: ReviewsScreenProps): JSX.Element => {
	useSetAppHeader('default')

	const results = useGetReviewRequests()
	const incomingReviews = results.data

	/**
	 *  Welcome Screen
	 * */
	const [[showWelcomeSlideout, openWelcomeSlidout, closeWelcomeSlideout]] = useModalCtrl()

	const meResults = useGetMe()
	useEffect(() => {
		if (meResults.data && !meResults.data?.reviewsViewed) openWelcomeSlidout()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meResults.data])

	const { mutate } = useUpdateNewUserProgress()
	const handleReviewsViewed = () => {
		mutate({
			reviewsViewed: true,
		})
	}
	// END Welcome Screen

	/**
	 *  Reviews data for render
	 * */
	const reviews = [] as IncomingReviewRequest[]
	const ignoredReviews = [] as IncomingReviewRequest[]

	incomingReviews?.forEach(review => {
		const hasSkillForReview = review.jobSkills?.some(
			({ hasExistingSkillReview }) => !hasExistingSkillReview
		)

		if ((!review.existingReview || hasSkillForReview) && !review.requestedReview.ignored)
			reviews.push(review)
		else if (review.requestedReview.ignored) ignoredReviews.push(review)
	})

	// END Reviews data for render

	/**
	 *  Request a review state/logic
	 * */

	const [[showRequestModal, openRequestModal, closeRequestModal]] = useModalCtrl()
	const { data: jobData } = useGetJobs()
	const { data: skillData } = useGetSkillsWithScores()

	// Does user have any skills yet?
	const userHasSkills = skillData ? !!skillData?.length : true // defaults to true until data comes in from request
	// Does user have any jobs yet?
	const userHasJobs = jobData ? !!jobData?.length : true // defaults to true until data comes in from request
	// Does user have at least one job with at least one skill?
	const userHasJobsWithSkills = jobData
		? !!jobData?.length && jobData.some(({ candidateJobSkills }) => !!candidateJobSkills.length)
		: true // defaults to true until data comes in from request

	const [[showNotReady, openNotReady]] = useModalCtrl()
	// END Request a review state/logic

	/**
	 *  Redirects
	 * */
	const [shouldNav, setNav] = useState(false, 'shouldNav')
	const navTo = useNavigate()

	useEffect(() => {
		if (shouldNav) navTo(userHasSkills ? '/work' : '/skills')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldNav])

	// END Redirects

	const [[isAppDrawerOpen, openAppDrawer, closeAppDrawer, currentReviewId]] = useModalCtrl<number>()

	return (
		<ReviewsScreenView>
			<Async fetchResults={results} isScreen Page404={Page404Redirect}>
				<Container isStickyFooter stickyOffsets={['footer']}>
					<ReviewsGuide />
					<header className='review-screen-header'>
						<CTA className='request-review-cta' size='sm' displayType='solid'>
							<Btn
								onClick={() => {
									if (userHasJobs && userHasSkills && userHasJobsWithSkills) openRequestModal()
									else openNotReady()
								}}
							>
								Request a Review <Icon type='reviews' />
							</Btn>
						</CTA>
					</header>

					<section className='incoming-reviews'>
						{reviews?.length ? (
							<>
								<Heading hTag='h4' displayType='block-heading'>
									Incoming Review Requests
								</Heading>
								<Card>
									<ReviewsList
										reviews={reviews}
										onSelect={({ requestedReview }) =>
											openAppDrawer(requestedReview.requestedReviewId)
										}
									/>
								</Card>
							</>
						) : (
							<NoResults
								className='no-reviews'
								type='reviews'
								titleText="You don't have any incoming review requests"
							/>
						)}
					</section>

					{ignoredReviews?.length ? (
						<section className='ignored-reviews'>
							<Accordion ctrl='Ignored Review Requests'>
								<Card>
									<ReviewsList reviews={ignoredReviews} />
								</Card>
							</Accordion>
						</section>
					) : null}
				</Container>
			</Async>

			<AppDrawer
				isOpen={isAppDrawerOpen}
				closeHandler={closeAppDrawer}
				placeholder={
					<AppDrawerPlaceholder heading='Select a review request' svg={<MerifyIrmaReviewsSvg />} />
				}
				displayType={reviews?.length ? 'two-col' : 'one-col'}
			>
				{currentReviewId ? (
					<PrivateReview reviewId={currentReviewId} cancel={closeAppDrawer} />
				) : null}
			</AppDrawer>

			<WelcomeSlideout
				show={showWelcomeSlideout}
				closeHandler={closeWelcomeSlideout}
				thumbTitle='Watch a quick video on how to request a review...'
				videoId='bXHM6dIJpjc'
				videoUrl='https://youtu.be/bXHM6dIJpjc'
				handleViewed={handleReviewsViewed}
				WelcomeScreen={ReviewsScreenWelcome}
			/>
			<ModalFull
				isOpen={showRequestModal}
				closeHandler={closeRequestModal}
				headerContent='Request a review'
			>
				<ReviewRequest cb={closeRequestModal} />
			</ModalFull>
			<ModalAlert isOpen={showNotReady} closeHandler={() => setNav(true)}>
				<Text size='med'>
					{!userHasSkills
						? `Looks like you need to add some skills first...`
						: !userHasJobs
						? `Let's add a job first...`
						: !userHasJobsWithSkills
						? `Let's add some skills to a job first...`
						: ''}
				</Text>
				<CTA>
					<Btn onClick={() => setNav(true)}>Okay</Btn>
				</CTA>
			</ModalAlert>
		</ReviewsScreenView>
	)
}
