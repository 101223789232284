import { isDev } from '@merify/ui'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useEffect } from 'react'
import { useAuth } from 'react-oidc-context'
import { getInitialRedirectPath, storeInitialRedirect } from '../utils/auth.utils'

type LoginRouteProps = RouteComponentProps & {}
export const LoginRoute = (_: LoginRouteProps): JSX.Element => {
	const { isAuthenticated, signinRedirect, clearStaleState } = useAuth()
	const goTo = useNavigate()

	useEffect(() => {
		if (isAuthenticated) {
			goTo(getInitialRedirectPath())
		} else {
			if (isDev) window.localStorage.setItem('login-route.action', 'triggering signin redirect...')

			storeInitialRedirect('/skills')
			clearStaleState()
			signinRedirect()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated])
	return <></>
}
