import styled, { css } from 'styled-components'
import { AppNavMobile } from '../Nav.app/Nav.mobile'

export const appFooterStyles = css`
	background: ${props => props.theme.colors.white.val};
	border-top: 2px solid ${props => props.theme.colors.lavender.val};
	z-index: 10;
	max-height: ${props => props.theme.sizes.footer.mobile.val};
`

const AppFooterView = styled.footer`
	${appFooterStyles}
`

export type AppFooterProps = {}
export const AppFooter = (): JSX.Element => {
	return (
		<AppFooterView className='app-footer'>
			<AppNavMobile />
		</AppFooterView>
	)
}
