import { MerifyMSvg } from '@merify/ui'
import { RouteComponentProps } from '@reach/router'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { PublicHeader } from '../global/Header.public/Header.public'
import { LegalLinks } from '../Legal/Legal.links'
import { publicLayoutStyles } from './Layout.public.styles'

const PublicLayoutView = styled.div`
	${publicLayoutStyles}
`

export type PublicLayoutProps = RouteComponentProps & {
	children?: ReactNode
}
export const PublicLayout = ({ children }: PublicLayoutProps): JSX.Element => {
	return (
		<PublicLayoutView>
			<PublicHeader />
			<div className='page'>
				<aside className='desk-menu'>
					<LegalLinks />
				</aside>
				<main className='screen-content'>
					<div className='bg-m'>
						<MerifyMSvg />
					</div>
					<div className='inner-wrap'>{children}</div>
				</main>
			</div>
		</PublicLayoutView>
	)
}
