import {
	AnyUser,
	Async,
	Btn,
	Card,
	CTA,
	Ctas,
	ErrorBlock,
	Heading,
	ReviewInternalPerson,
	Text,
	useModalCtrl,
	WizardStepProps,
} from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetReviewsByJob, useRequestReview } from '../../../api/api.reviews'
import { Container } from '../../UI/Container'
import { SelectUserField } from '../../UI/FormFields/Select.user.field'
import { ModalAlert } from '../../UI/Modal'
import { EmailList } from '../Email.list'
import { UserList } from '../User.list'
import { useReviewRequest } from './Review.request.provider'

export const reviewRequestAddUsersStyles = css`
	.add-reviewers {
		.selected-reviewers,
		.invited-reviewers {
			min-height: 100px;
			padding-bottom: 1rem;
		}
		.swipeable-list {
			//padding-top: 2em;
			background-color: ${props => props.theme.colors.white.val};
			.swipeable-list-item {
				padding: 0.5em ${props => props.theme.sizes.gutter.mobile.val};
			}
		}
	}
	.heading.has-gutter {
		padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
	}
	.invited-reviewers {
	}
	.previous-reviewers {
	}

	.follow-up-copy {
		padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
		padding-bottom: 1rem;
		display: block;
	}
`

type ExistingUser = AnyUser & {
	canReRequest: boolean
}

const ReviewRequestAddUsersView = styled.div`
	${reviewRequestAddUsersStyles}
`

type ReviewRequestAddUsersProps = {
	cancel?: () => void
}
export const ReviewRequestAddUsers = ({
	cancel,
	...stepProps
}: ReviewRequestAddUsersProps): JSX.Element => {
	const {
		candidateJobId,
		preselectedUsers,
		reviwers,
		inviteeEmails,
		setSuccessful,
		addReviewer,
		removeReviewer,
		addInvitee,
		removeInvitee,
	} = useReviewRequest()
	const results = useGetReviewsByJob(candidateJobId as number, { isLazy: !candidateJobId })
	const { previousStep, isActive } = stepProps as WizardStepProps

	const existingUsers =
		results.data?.map(review => {
			const { internalPerson, requestedReview } = review

			const canReRequest = requestedReview.canReRequest

			return {
				canReRequest,
				...internalPerson,
				candidateId: internalPerson.personId,
			} as ExistingUser
		}) || []

	existingUsers.sort((a, b) => {
		return a.canReRequest ? -1 : b.canReRequest ? +1 : 0
	})

	const existingUserIdsForExclusion = existingUsers
		.filter(({ canReRequest }) => !canReRequest)
		.map(({ candidateId }) => candidateId)

	const unregisteredUserEmails = existingUsers
		.filter(({ registered }) => !registered)
		.map(user => {
			const { email } = user as ReviewInternalPerson
			return email
		})

	const [[showModal, openModal, closeModal]] = useModalCtrl()
	const { error, mutateAsync } = useRequestReview()

	const preselectedUserIds = preselectedUsers?.map(({ candidateId }) => candidateId) || []

	const totalReviewers = (reviwers?.length ?? 0) + (inviteeEmails?.length ?? 0)

	return (
		<ReviewRequestAddUsersView>
			<Async fetchResults={results}>
				<Container isStickyFooter stickyOffsets={['wizardDots']} isInactiveWizardStep={!isActive}>
					<header>
						<Heading mBot hTag='h4' displayType='dialog-heading' dir='center'>
							Add Reviewers
						</Heading>
						<Ctas maxW='350px' mBot padBot>
							<CTA size='sm'>
								{previousStep ? (
									<Btn onClick={previousStep}>Back</Btn>
								) : (
									<Btn onClick={cancel}>Cancel</Btn>
								)}
							</CTA>
							<CTA displayType='solid' size='sm'>
								<Btn disabled={!totalReviewers} onClick={openModal}>
									Send Request
								</Btn>
							</CTA>
						</Ctas>
					</header>
					{error ? <ErrorBlock error={error} /> : null}
					<section className='job-info'>{/* TODO: add job info */}</section>
					<section className='add-reviewers'>
						<SelectUserField
							onSelect={addReviewer}
							excludeIds={[
								...existingUserIdsForExclusion,
								...preselectedUserIds,
								...reviwers?.map(({ candidateId }) => candidateId),
							]}
							showAddIcons
							onUserExists={str => `Looks like you have previously sent a review to: ${str}`}
							onIsCurrentUser={() => `You can't request a review from yourself`}
							excludedEmails={unregisteredUserEmails}
							onEmailSelect={addInvitee}
						/>
						<div className='selected-reviewers'>
							<UserList
								users={preselectedUsers ? [...preselectedUsers, ...reviwers] : reviwers}
								onDelete={({ candidateId }) => removeReviewer(candidateId)}
								disabledUserIds={preselectedUserIds}
							/>
							{inviteeEmails?.length ? (
								<EmailList emails={inviteeEmails} onDelete={removeInvitee} />
							) : null}
						</div>

						{existingUsers?.length ? (
							<div className='previous-reviewers'>
								<Heading
									className='has-gutter'
									hTag='h5'
									displayType='block-heading'
									color='darkGrey'
									mBot
								>
									Previous requests:
								</Heading>
								<Text className='follow-up-copy'>
									You can send a follow up review request to past reviewers of this work item every
									calendar month.
								</Text>
								<Card>
									<UserList
										users={existingUsers}
										onSelect={({ canReRequest, ...user }) => canReRequest && addReviewer(user)}
										disabledUserIds={existingUsers
											.filter(({ canReRequest }) => !canReRequest)
											.map(({ candidateId }) => candidateId)}
									/>
								</Card>
							</div>
						) : null}
					</section>
				</Container>
			</Async>
			<ModalAlert
				isOpen={showModal}
				closeHandler={closeModal}
				showAlertBtns
				asyncConfirmHandler={async () => {
					if (candidateJobId)
						await mutateAsync({
							candidateJobId,
							data: {
								existingCandidateIds: reviwers.map(({ candidateId }) => candidateId),
								invitedCandidateEmailAddresses: inviteeEmails,
							},
						})
					setSuccessful(true)
				}}
			>
				<Text size='med'>
					Send review request to {totalReviewers} colleague
					{totalReviewers > 1 ? 's' : ''}?
				</Text>
			</ModalAlert>
		</ReviewRequestAddUsersView>
	)
}
