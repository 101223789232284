import { CTA, MerifyMMarkSvg, MerifyWordmarkSvg } from '@merify/ui'
import styled, { css } from 'styled-components'
import { Link } from '../../UI/Link'

export const publicHeaderStyles = css`
	height: ${props => props.theme.sizes.header.mobile.val};
	background: ${props => props.theme.colors.white.val};
	display: flex;
	align-items: center;
	padding: 0 ${props => props.theme.sizes.gutter.mobile.val};

	.merify-logo {
		width: unset;
	}

	.merify-cta {
		margin-left: auto;
		width: auto;
		text-transform: none;
		padding: 0;
		max-height: 60%;
		white-space: nowrap;
		max-width: 150px;

		svg {
			height: 1.8rem;
			margin-left: 0.5em;
		}
	}
`

const PublicHeaderView = styled.header`
	${publicHeaderStyles}
`

export type PublicHeaderProps = {}
export const PublicHeader = (): JSX.Element => {
	return (
		<PublicHeaderView>
			<Link className='merify-logo' to='https://merify.com' asFlex>
				<MerifyWordmarkSvg />
			</Link>
			<CTA size='sm' displayType='solid'>
				<Link className='merify-cta' to='https://merify.com'>
					Learn more
					<MerifyMMarkSvg />
				</Link>
			</CTA>
		</PublicHeaderView>
	)
}
