import { Btn, useState } from '@merify/ui'
import styled, { css } from 'styled-components'
import { CircleQuestionMark } from '../../UI/CircleQuestionMark'
import { ModalFull } from '../../UI/Modal'
import { RatingsGuideContent } from './Ratings.guide.content'

export const ratingsGuideButtonStyles = css`
	.btn {
		margin-right: 0.5em;
	}
`

const RatingsGuideButtonView = styled.div`
	${ratingsGuideButtonStyles}
`

type RatingsGuideButtonProps = {}
export const RatingsGuideButton = (_: RatingsGuideButtonProps): JSX.Element => {
	const [showModal, setShowModal] = useState(false, 'showModal')
	return (
		<RatingsGuideButtonView className='ratings-guide-btn'>
			<Btn onClick={() => setShowModal(true)}>
				<CircleQuestionMark />
			</Btn>
			<ModalFull
				headerContent='Ratings Guide'
				headerAlign='left'
				isOpen={showModal}
				closeHandler={() => setShowModal(false)}
			>
				<RatingsGuideContent />
			</ModalFull>
		</RatingsGuideButtonView>
	)
}
