import { Icon, ReviewSkill } from '@merify/ui'
import styled, { css } from 'styled-components'

const GiveKudosSkillItemStyles = css`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 1em;
	border-bottom: 1px solid ${props => props.theme.colors.lightNeutral.val};

	input[type='checkbox'] {
		opacity: 0;
		position: absolute;
		border: 1px solid red;
		z-index: 20;
		height: 32.5px;
		width: 32.5px;
	}

	.skill-name {
		font-size: 1.5em;
		line-height: 1.7em;
		color: ${props => props.theme.colors.black.val};
		cursor: pointer;
	}

	.checkbox {
		border: solid 1px ${props => props.theme.colors.midNeutral.val};
		background: ${props => props.theme.colors.white.val};
		margin-right: 1em;
		border-radius: 50%;
		height: 32.5px;
		width: 32.5px;
		display: flex;
		align-items: center;
		justify-content: center;

		.icon {
			opacity: 0;
		}
	}

	&.__selected {
		.checkbox {
			.icon {
				opacity: 1;
			}
		}
	}
`

const GiveKudosSkillItemView = styled.div`
	${GiveKudosSkillItemStyles}
`

type GiveKudosSkillItemProps = {
	className?: string
	skill: ReviewSkill
	isChecked: boolean
	onChange: () => void
}
export const GiveKudosSkillItem = ({
	className,
	skill,
	isChecked,
	onChange,
}: GiveKudosSkillItemProps): JSX.Element => {
	return (
		<GiveKudosSkillItemView
			className={`give-kudos-skill-item${className ? ` ${className}` : ''}${
				isChecked ? ' __selected' : ''
			}`}
		>
			<input
				name={skill.skillName}
				id={skill.skillName}
				type='checkbox'
				checked={isChecked}
				onChange={onChange}
			/>

			<div className='checkbox'>
				<Icon type='checkCircle' />
			</div>

			<label className='skill-name' htmlFor={skill.skillName}>
				{skill.skillName}
			</label>
		</GiveKudosSkillItemView>
	)
}
