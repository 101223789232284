import { Field, FieldProps } from '@merify/ui'
import { HTMLProps, ReactNode } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import styled from 'styled-components'

const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY

const LocationCityFieldView = styled.div`
	position: relative;
	input[name='city'] {
		text-transform: capitalize;
	}
	input[name='stateOrProvince'],
	input[name='country'] {
		text-transform: uppercase;
	}
`

type Location = {
	long_name: string
	short_name: string
	types: string[]
}

type GooglePlace = {
	address_components: Location[]
	formatted_address: string
	geometry: {
		location: {
			lat: () => number
			lng: () => number
		}
	}
	place_id: string
}

export type CityResults = {
	place: GooglePlace
	city: Location
	state: Location
	county: Location
	country: Location
	postalCode: Location
	longitude: number
	latitude: number
	placeId: string
}

export type LocationCityFieldProps = HTMLProps<HTMLInputElement> & {
	fieldProps?: FieldProps
	label?: ReactNode
	onPlaceSelected: (place: CityResults) => void
}
export const LocationCityField = ({
	fieldProps,
	name,
	label,
	placeholder = 'City...',
	onPlaceSelected,
	value,
	onChange,
}: LocationCityFieldProps): JSX.Element => {
	const { ref } = usePlacesWidget({
		apiKey: GOOGLE_MAPS_KEY,
		onPlaceSelected: (place: GooglePlace) => {
			const { address_components: a } = place

			const results = {
				place,
				city: a.find(({ types }) => types.includes('locality')) as Location,
				county: a.find(({ types }) => types.includes('administrative_area_level_2')) as Location,
				state: a.find(({ types }) => types.includes('administrative_area_level_1')) as Location,
				country: a.find(({ types }) => types.includes('country')) as Location,
				postalCode: a.find(({ types }) => types.includes('postal_code')) as Location,
				longitude: place.geometry.location.lng(),
				latitude: place.geometry.location.lat(),
				placeId: place.place_id,
			}
			onPlaceSelected(results)
		},
	})

	return (
		<LocationCityFieldView className='city-field'>
			<Field name={name} label={label} {...fieldProps}>
				<input
					value={value}
					onChange={onChange}
					type='text'
					name={name}
					ref={ref as React.MutableRefObject<null>}
					placeholder={placeholder}
				/>
			</Field>
		</LocationCityFieldView>
	)
}
