import { Btn, CTA, Ctas, SimpleSkill, Text, useModalCtrl } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetSkillsWithScores } from '../../api/api.skills'
import { ModalFull } from '../UI/Modal'
import { AddSkillsFromNotification } from '../global/Add.skills.from.notifiction/Add.skills.from.notifiction'
import { DeleteNotificationCta, DeleteNotificationCtaProps } from './Notification.cta.delete'

export const notificationDetailAddSkillsCtaStyles = css``

const NotificationDetailAddSkillsCtaView = styled.div`
	${notificationDetailAddSkillsCtaStyles}
`

const Titles = styled.div`
	padding-left: ${props => props.theme.sizes.gutter.mobile.val};
	padding-right: ${props => props.theme.sizes.gutter.mobile.val};
`

export type NotificationDetailAddSkillsCtaProps = {
	skills?: SimpleSkill[]
	deleteProps: DeleteNotificationCtaProps
}
export const NotificationDetailAddSkillsCta = ({
	skills,
	deleteProps,
}: NotificationDetailAddSkillsCtaProps): JSX.Element => {
	const skillRes = useGetSkillsWithScores()
	const currentSkills = skillRes.data
	const currentSkillIds = currentSkills?.map(({ skillId }) => skillId)
	const allSkillsAlreadyAdded = skills?.every(({ skillId }) => !!currentSkillIds?.includes(skillId))

	const [[showAddSkills, openAddSkills, closeAddSkills]] = useModalCtrl()

	return (
		<NotificationDetailAddSkillsCtaView>
			{skills ? (
				<>
					{allSkillsAlreadyAdded ? (
						<div className='single-cta'>
							<DeleteNotificationCta {...deleteProps} />
						</div>
					) : (
						<Ctas>
							<CTA size='sm'>
								<Btn onClick={() => openAddSkills()}>Add Skill{skills.length > 1 ? 's' : ''}</Btn>
							</CTA>
							<DeleteNotificationCta {...deleteProps} />
						</Ctas>
					)}
				</>
			) : (
				<></>
			)}

			<ModalFull isOpen={showAddSkills} closeHandler={closeAddSkills} canClickBg={false}>
				<Titles>
					<Text mBot size='big' textType='p'>
						Add skill{(skills?.length || 0) > 1 ? 's' : ''} to your profile
					</Text>
					<Text mBot textType='p'>
						Select your level of proficiency for each skill below
					</Text>
				</Titles>
				{skills && <AddSkillsFromNotification skills={skills} cancel={closeAddSkills} />}
			</ModalFull>
		</NotificationDetailAddSkillsCtaView>
	)
}
