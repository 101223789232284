export const defaultCandidateProfileValues = {
	bio: '',
	city: '',
	country: '',
	email: '',
	firstName: '',
	formerName: '',
	industryId: '',
	industryName: '',
	lastName: '',
	latitude: 0,
	longitude: 0,
	placeId: '',
	postalCode: '',
	pronouns: '',
	stateOrProvince: '',
	title: '',
	viewed: true,
}

export const defaultCandidateDemographicValues = {
	ethnicityOptionId: '',
	genderIdentityOptionId: '',
	sexualOrientationOptionId: '',
	disabilityOptionId: '',
	veteranStatusOptionId: '',
}
