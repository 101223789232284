import { Spinner } from '@merify/ui'
import { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

export const loadingScreenStyles = css`
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 100000;
	background: ${props => props.theme.colors.primary.val};
	/* transition: all ${props => props.theme.times.tranL}; */
	.spinner {
		background: none;

		span {
			background-color: ${props => props.theme.colors.secondary.val};
			border-color: ${props => props.theme.colors.secondary.val};
			border-bottom-color: ${props => props.theme.colors.secondary.opacity(60).val};
			border-bottom-color: transparent;
		}
	}
`

const LoadingScreenView = styled.div`
	${loadingScreenStyles}
`

type LoadingScreenProps = HTMLAttributes<HTMLDivElement> & {}

export const LoadingScreen = ({ ...props }: LoadingScreenProps): JSX.Element => {
	return (
		<LoadingScreenView {...props}>
			<Spinner type='circle' height='lg' />
		</LoadingScreenView>
	)
}
