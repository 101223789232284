import { HTMLProps } from 'react'
import styled, { css } from 'styled-components'

const TextareaFieldStyles = css`
	label {
		font-size: 1.3rem;
	}
	textarea {
		border: none;
		outline: none;
		box-shadow: none;
		border: none;
		width: 100%;
		padding: 0.5em;
		font-size: 1.2rem;
		border-bottom: solid 1px ${props => props.theme.colors.midNeutral.light(0).val};
		color: ${props => props.theme.colors.secondary.val};
		background: none;
		width: 100%;
		text-align: left;
		border-radius: 0;
		background: ${props => props.theme.colors.white.val};
	}
`

const TextareaFieldView = styled.div`
	${TextareaFieldStyles}
`

type TextareaFieldProps = Pick<
	HTMLProps<HTMLTextAreaElement>,
	'title' | 'onChange' | 'placeholder' | 'className' | 'rows'
> & {
	value?: string
	label?: string
}
export const TextareaField = ({
	className,
	rows,
	value,
	onChange,
	placeholder,
	label,
}: TextareaFieldProps): JSX.Element => {
	return (
		<TextareaFieldView className={`textarea-field${className ? ` ${className}` : ''}`}>
			<label htmlFor={label}>{label}</label>
			<textarea
				rows={rows}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				id={label}
			/>
		</TextareaFieldView>
	)
}
