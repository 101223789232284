import { Btn, Icon, useModalCtrl } from '@merify/ui'
import styled, { css } from 'styled-components'
import { ModalFull } from '../../UI/Modal'
import { InviteUserForm } from './Invite.user.form'

export const inviteIconBtnStyles = css``

const InviteIconBtnView = styled.div`
	${inviteIconBtnStyles}
`

export type InviteIconBtnProps = {}
export const InviteIconBtn = (): JSX.Element => {
	const [[showModal, openModal, closeModal]] = useModalCtrl()
	return (
		<InviteIconBtnView>
			<Btn onClick={openModal}>
				<Icon type='personPlus' />
			</Btn>
			<ModalFull
				headerContent='Invite User'
				headerAlign='center'
				isOpen={showModal}
				closeHandler={closeModal}
			>
				<InviteUserForm cb={closeModal} />
			</ModalFull>
		</InviteIconBtnView>
	)
}
