import { Btn, CTA, Ctas } from '@merify/ui'
import styled, { css } from 'styled-components'

export const reviewSubmitBtnStyles = css`
	.single-btn {
		display: flex;
		width: 100%;
		justify-content: center;
	}
`

const ReviewSubmitBtnsView = styled.div`
	${reviewSubmitBtnStyles}
`

export type ReviewSubmitBtnsProps = {
	previousStep?: () => void
	nextStep?: () => void
	currentStep?: number
	totalSteps?: number
	cancel?: () => void
	submitHandler: () => void
	disable: boolean
	reviewType: string
}
export const ReviewSubmitBtns = ({
	previousStep,
	nextStep,
	currentStep,
	totalSteps,
	cancel,
	submitHandler,
	disable,
	reviewType,
}: ReviewSubmitBtnsProps): JSX.Element => {
	return (
		<ReviewSubmitBtnsView>
			{reviewType === 'review' ? (
				previousStep || cancel ? (
					<Ctas>
						<CTA size='sm'>
							{previousStep ? (
								<Btn onClick={previousStep}>Back</Btn>
							) : (
								<Btn onClick={cancel}>Cancel</Btn>
							)}
						</CTA>

						<CTA displayType='solid' size='sm'>
							<Btn onClick={submitHandler} disabled={disable}>
								Submit Review
							</Btn>
						</CTA>
					</Ctas>
				) : (
					<CTA displayType='solid' size='sm' className='single-btn'>
						<Btn onClick={submitHandler} disabled={disable}>
							Submit Review
						</Btn>
					</CTA>
				)
			) : (
				<Ctas>
					<CTA size='sm'>
						{previousStep ? (
							<Btn onClick={previousStep}>Back</Btn>
						) : (
							<Btn onClick={cancel}>Cancel</Btn>
						)}
					</CTA>
					{currentStep === totalSteps ? (
						<CTA displayType='solid' size='sm' className='single-btn'>
							<Btn onClick={submitHandler} disabled={disable}>
								Submit Kudos
							</Btn>
						</CTA>
					) : (
						<CTA displayType='solid' size='sm'>
							<Btn onClick={nextStep} disabled={disable}>
								Continue
							</Btn>
						</CTA>
					)}
				</Ctas>
			)}
		</ReviewSubmitBtnsView>
	)
}
