import { Icon, useState } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetJob } from '../../../api/api.jobs'
import { useGetReviewRequest } from '../../../api/api.reviews'
import { useStoreState } from '../../../store'
import { ReviewAndKudosJob, ReviewJobLink, ReviewJobLinkProps } from './Review.job.link'

export const reviewLinkedJobsStyles = css`
	display: flex;
	justify-content: center;
	align-items: center;

	.review-job-link {
		&:first-child {
			margin-right: 8px;
		}

		&:last-child {
			margin-left: 8px;
			text-align: right;

			.top {
				flex-direction: row-reverse;

				.job-title {
					margin-left: 0;
					margin-right: 5px;
				}
			}

			.bottom {
				text-align: right;
			}
		}
	}

	.link {
		z-index: 2;
		width: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		.plate {
			background: ${props => props.theme.colors.white.val};
			padding: 0.3em;
			color: ${props => props.theme.colors.primary.val};
		}

		.icon {
			width: 40px;
		}
	}
`

const ReviewLinkedJobsView = styled.div`
	${reviewLinkedJobsStyles}
`

export type ReviewLinkedJobsProps = {
	reviewId: number
	linkedJobId: number
}
export const ReviewLinkedJobs = ({ reviewId, linkedJobId }: ReviewLinkedJobsProps): JSX.Element => {
	const [openedJob, setOpened] = useState<'linked' | 'review' | ''>('', 'openedJob')
	const me = useStoreState(state => state.candidate.user)

	const reviewResults = useGetReviewRequest(reviewId)
	const linkedResults = useGetJob(linkedJobId)

	const reviewJob = reviewResults?.data?.job as ReviewAndKudosJob
	const reviewee = reviewResults?.data?.person as ReviewJobLinkProps['user']

	const linkedJob = linkedResults.data?.candidateJob

	return (
		<ReviewLinkedJobsView className='linked-jobs'>
			{reviewJob && reviewee ? (
				<ReviewJobLink
					onClick={() => setOpened(state => (state === 'review' ? '' : 'review'))}
					className='review'
					isExpanded={openedJob === 'review'}
					isCollapsed={openedJob === 'linked'}
					job={reviewJob}
					user={reviewee}
				/>
			) : null}
			<div className='link'>
				<div className='plate'>
					<Icon type='link' />
				</div>
			</div>
			{linkedJob ? (
				<ReviewJobLink
					onClick={() => setOpened(state => (state === 'linked' ? '' : 'linked'))}
					className='linked'
					isExpanded={openedJob === 'linked'}
					isCollapsed={openedJob === 'review'}
					// @ts-ignore MIGRATION
					user={me}
					job={linkedJob}
				/>
			) : null}
		</ReviewLinkedJobsView>
	)
}
