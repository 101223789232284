import {
	useModalCtrl,
	Video,
	VideoList as LibVidList,
	VideoListProps as LibVidListProps,
	YouTubeVid,
} from '@merify/ui'
import { ModalFull } from '../../UI/Modal'

export type VideoListProps = Pick<LibVidListProps, 'videos'> & {
	onSelect: LibVidListProps['onClickVid']
}

export const VideoList = ({ onSelect, ...props }: VideoListProps): JSX.Element => {
	const [[showModal, openModal, closeModal, modalVid]] = useModalCtrl<YouTubeVid>()
	return (
		<>
			<LibVidList
				{...props}
				onClickVid={video => (onSelect ? onSelect(video) : openModal(video))}
			/>

			<ModalFull isOpen={showModal} closeHandler={closeModal}>
				{modalVid ? <Video yTVideo={modalVid} /> : null}
			</ModalFull>
		</>
	)
}
