import { Btn, CTA, Heading, MerifyIrmaWelcomeBadgeSvg, Text, WizardStepProps } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useUpdateNewUserProgress } from '../../../api/api.candidate'
import { SmallCtas } from '../../UI/SmallCtas'
import { welcomeScreenStepStyles } from './Welcome.screen.step.styles'

export const welcomeScreenStepThreeStyles = css`
	${welcomeScreenStepStyles}
`

const WelcomeScreenStepThreeView = styled.div`
	${welcomeScreenStepThreeStyles}
`

export type WelcomeScreenStepThreeProps = {}
export const WelcomeScreenStepThree = ({
	...stepProps
}: WelcomeScreenStepThreeProps): JSX.Element => {
	const { previousStep, isActive } = stepProps as WizardStepProps
	const { mutate } = useUpdateNewUserProgress()

	return (
		<WelcomeScreenStepThreeView className={`step step-3 ${isActive ? ' __active' : ''}`}>
			<div className='inner-wrap'>
				<div className='content'>
					<MerifyIrmaWelcomeBadgeSvg />
					<div className='text'>
						<Heading hTag='h2' displayType='screen-heading' dir='center'>
							Get Merified™
						</Heading>
						<Text size='med' color='secondary' dir='center' mTop>
							Share your peer-vetted skills with hiring professionals to show what you’re good at
							and track your career growth.
						</Text>
					</div>
					<SmallCtas>
						<CTA size='sm'>
							<Btn onClick={previousStep}>Back</Btn>
						</CTA>
						<CTA size='sm'>
							<Btn onClick={() => mutate({ profileViewed: true })}>Let's go!</Btn>
						</CTA>
					</SmallCtas>
				</div>
			</div>
		</WelcomeScreenStepThreeView>
	)
}
