import { Async, Card, ReviewSkill, Wizard } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetPublicUserById } from '../../../api/api.candidate'
import { useGetKudosJobForCandidate } from '../../../api/api.jobs'
import { usePostKudosReview } from '../../../api/api.reviews'
import { ReviewProvider } from '../Review.provider'
import { ReviewJobLinkProps } from '../Review/Review.job.link'
import { ReviewSkills } from '../Review/Review.skills'
import { ReviewSubmit } from '../Review/Review.submit'
import { KudosLinkedJob } from './Kudos.linked.job'

const GiveKudosReviewStyles = css`
	.kudos-candidate-card {
		font-size: 1.5em;

		.card-inner {
			padding: 10px;
		}
	}
`

const GiveKudosReviewView = styled.div`
	${GiveKudosReviewStyles}
`

type GiveKudosReviewProps = {
	className?: string
	candidateJobId: number
	candidateId: number
	skillsForReview: ReviewSkill[]
	cancelHandler: () => void
}
export const GiveKudosReview = ({
	className,
	candidateId,
	candidateJobId,
	skillsForReview,
	cancelHandler,
}: GiveKudosReviewProps): JSX.Element => {
	const { isLoading, error, mutateAsync } = usePostKudosReview()

	const candidateData = useGetPublicUserById({ uid: candidateId })
	const candidate = candidateData?.data?.candidate as ReviewJobLinkProps['user']

	const candidateJob = useGetKudosJobForCandidate(candidateId, candidateJobId)
	const job = candidateJob?.data as ReviewJobLinkProps['job']

	return (
		<GiveKudosReviewView className={`give-kudos-review${className ? ` ${className}` : ''}`}>
			<Card className='kudos-candidate-card'>
				<Async fetchResults={candidateJob}>
					<KudosLinkedJob job={job} user={candidate} />
				</Async>
			</Card>

			<ReviewProvider
				kudosSkills={skillsForReview}
				kudosMut={mutateAsync}
				mutLoading={isLoading}
				mutError={error}
				kudosCandidateId={candidateId}
				kudosCandidateJobId={candidateJobId}
				cancel={cancelHandler}
			>
				<Wizard isLazyMount>
					<ReviewSkills />
					<ReviewSubmit />
				</Wizard>
			</ReviewProvider>
		</GiveKudosReviewView>
	)
}
