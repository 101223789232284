import { Text } from '@merify/ui'
import styled, { css } from 'styled-components'

export const reviewProviderRescindedStyles = css``

const ReviewProviderRescindedView = styled.div`
	${reviewProviderRescindedStyles}
`

export type ReviewProviderRescindedProps = {}
export const ReviewProviderRescinded = (): JSX.Element => {
	return (
		<ReviewProviderRescindedView>
			<Text size='normal' textType='p'>
				Looks like this review request has been rescinded by the reviewee.
			</Text>
		</ReviewProviderRescindedView>
	)
}
