import { AnyUser, Btn, CTA, isValidEmail, Text, UserIntersect, useState } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetUsersBySearch } from '../../../../api/api.candidate'
import { useStoreState } from '../../../../store'
import { InviteUserBtn } from '../../../global/Invite.user.btn/Invite.user.btn'
import { UserList } from '../../../global/User.list'
import { UserListItemUser } from '../../../global/User.list/User.item'
import { SearchDropdown } from '../../SearchDropdown'

export const selectUserFieldStyles = css`
	.ctrl {
		background-color: ${props => props.theme.colors.white.val};
		.field-wrap {
			padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
			margin-bottom: 0;
		}
	}
	.ctrl-group {
		background-color: ${props => props.theme.colors.white.val};
	}
	.user {
		display: block;
	}
	.not-in-merify-text {
		padding: 1em 0;
		text-align: center;
	}

	.user-exists,
	.no-results {
		line-height: 1.5em;
	}

	.invite-btn {
		display: flex;
		width: 100;
		justify-content: center;
	}
`

const SelectUserFieldView = styled.div`
	${selectUserFieldStyles}
`

export type SelectUserFieldProps = {
	onSelect: (user: UserListItemUser) => void
	onEmailSelect?: (email: string) => void
	onInvitedUser?: (user: UserIntersect) => void
	excludeIds?: number[]
	showAddIcons?: boolean
	includeCurrentUser?: boolean
	onUserExists?: (str: string) => string
	onIsCurrentUser?: (str: string) => string
	excludedEmails?: string[]
}
export const SelectUserField = ({
	onSelect,
	onEmailSelect,
	onInvitedUser,
	excludeIds,
	showAddIcons,
	includeCurrentUser,
	onUserExists,
	onIsCurrentUser,
	excludedEmails = [],
}: SelectUserFieldProps): JSX.Element => {
	const [searchStr, setSearchStr] = useState('', 'searchStr')
	const currentUid = useStoreState(state => state.candidate.user?.candidateId)

	const results = useGetUsersBySearch({ searchStr: searchStr || '' })

	const { data } = results

	const users = currentUid
		? data?.filter(
				({ candidateId }) =>
					(!includeCurrentUser ? candidateId !== currentUid : true) &&
					!excludeIds?.includes(candidateId)
		  )
		: data

	let existingUserMatchOnEmail = false
	let currentUserMatchOnEmail = false

	if (isValidEmail(searchStr))
		data?.forEach(({ candidateId }) => {
			if (!includeCurrentUser && candidateId === currentUid) currentUserMatchOnEmail = true
			if (excludeIds?.includes(candidateId)) existingUserMatchOnEmail = true
		})

	return (
		<SelectUserFieldView>
			<SearchDropdown
				onChange={setSearchStr}
				value={searchStr}
				placeholder='Type name or email address here...'
				suppressClickOutsideToClose
			>
				{users?.length ? (
					<UserList
						users={users as AnyUser[]}
						onSelect={user => {
							onSelect(user)
							setSearchStr('')
						}}
						showAddIcons={showAddIcons}
					/>
				) : isValidEmail(searchStr) &&
				  !existingUserMatchOnEmail &&
				  !currentUserMatchOnEmail &&
				  !excludedEmails.includes(searchStr) ? (
					<>
						<div className='not-in-merify-text'>
							<Text size='med'>{searchStr} doesn’t seem to be in our system yet...</Text>
						</div>
						{onEmailSelect ? (
							<CTA size='sm' className='invite-btn'>
								<Btn
									onClick={() => {
										onEmailSelect(searchStr)
										setSearchStr('')
									}}
								>
									Invite to Merify
								</Btn>
							</CTA>
						) : null}
						{onInvitedUser ? (
							<InviteUserBtn
								searchStr={searchStr}
								cb={user => {
									onInvitedUser(user as UserIntersect)
									setSearchStr('')
								}}
							/>
						) : null}
					</>
				) : existingUserMatchOnEmail && onUserExists ? (
					<Text textType='italic' className='user-exists'>
						{onUserExists(searchStr)}
					</Text>
				) : currentUserMatchOnEmail && onIsCurrentUser ? (
					<Text textType='italic' className='user-exists'>
						{onIsCurrentUser(searchStr)}
					</Text>
				) : (
					<Text textType='italic' className='no-results'>
						Sorry, no one by that name or email...
					</Text>
				)}
			</SearchDropdown>
		</SelectUserFieldView>
	)
}
