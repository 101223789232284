import { Btn, Icon } from '@merify/ui'
import styled, { css } from 'styled-components'
import { SwipeList, SwipeListProps } from '../../UI/SwipeList'
import { UserItem, UserListItemUser } from './User.item'

export const userListStyles = css`
	.preselected-user {
		padding: 0.5em ${props => props.theme.sizes.gutter.mobile.val};
		background: ${props => props.theme.colors.white.val};
	}

	li {
		display: block;

		&:last-child {
			margin-bottom: 0;
			button {
				border-bottom: none;
			}
		}

		&.__disabled {
			background: ${props => props.theme.colors.lightGrey.val};

			&:last-child {
				button {
					border-bottom: none;
				}
			}

			button {
				&:disabled {
					&:hover {
						border-bottom: 1px solid ${props => props.theme.colors.paleGrey.val};
					}
				}
				.avatar {
					opacity: 0.4;
				}
				.name {
					color: ${props => props.theme.colors.grey.val};
				}
				.icon.plusCircle {
					display: none;
				}
			}
		}

		&:last-child {
			button {
				border-bottom: none;
			}
		}

		button {
			padding: 0.5em ${props => props.theme.sizes.gutter.mobile.val};
			border-bottom: 1px solid ${props => props.theme.colors.paleGrey.val};

			&.user {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				.icon {
					color: ${props => props.theme.colors.secondary.val};
				}
			}
		}
	}
`

const UserListView = styled.div`
	${userListStyles}
`
export type UserListItemHandler<T> = (user: T) => void
export type UserListProps<T> = {
	users?: T[]
	onSelect?: UserListItemHandler<T>
	onDelete?: UserListItemHandler<T>
	disabledUserIds?: number[]
	disabledText?: string
	displayAsDisabled?: boolean
	showAddIcons?: boolean
}
export function UserList<T>({
	users: incomingUsers,
	onSelect,
	onDelete,
	disabledUserIds,
	disabledText,
	displayAsDisabled,
	showAddIcons,
}: UserListProps<T>): JSX.Element {
	const users = incomingUsers as unknown as UserListItemUser[]
	const usersForRender = disabledUserIds?.length ? [] : users || []
	const preselectedUsers = [] as UserListItemUser[]

	if (disabledUserIds?.length)
		users?.forEach(user => {
			if (disabledUserIds.includes(user.candidateId)) preselectedUsers.push(user)
			else usersForRender.push(user)
		})

	const swipeProps: SwipeListProps = {
		handlerTrigger: 'swipe',
		items: usersForRender?.map(user => ({
			content: <UserItem key={user.candidateId} user={user} onDelete={onDelete} />,
			key: user.candidateId,
			val: user,
		})),
	}

	if (onDelete) swipeProps.deleteHandler = onDelete
	return (
		<UserListView>
			{onDelete ? (
				<>
					{preselectedUsers?.length
						? preselectedUsers.map(user => (
								<UserItem className='preselected-user' user={user} key={user.candidateId} />
						  ))
						: null}
					<SwipeList {...swipeProps} />
				</>
			) : (
				<ul>
					{users?.length
						? users.map(user => {
								const previouslySelected = disabledUserIds?.includes(user.candidateId)
								return (
									<li
										key={user.candidateId}
										className={`item${
											previouslySelected || displayAsDisabled ? ' __disabled' : ''
										}`}
									>
										<Btn
											disabled={!onSelect || previouslySelected}
											className='user'
											onClick={() => (onSelect ? onSelect(user as unknown as T) : undefined)}
										>
											<UserItem key={user.candidateId} user={user} />
											{previouslySelected && disabledText ? disabledText : null}
											{onSelect && showAddIcons ? <Icon type='plusCircle' /> : null}
										</Btn>
									</li>
								)
						  })
						: null}
				</ul>
			)}
		</UserListView>
	)
}
