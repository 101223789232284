import { Btn, CTA } from '@merify/ui'
import styled, { css } from 'styled-components'
import { WelcomeVideoScreen } from '../../../global/Welcome.video.screen/Welcome.video.screen'

export const skillsScreenWelcomeStyles = css``

const SkillsSreenWelcomeView = styled.div`
	${skillsScreenWelcomeStyles}
`

export type SkillsSreenWelcomeProps = {
	cb: () => void
}
export const SkillsSreenWelcome = ({ cb }: SkillsSreenWelcomeProps): JSX.Element => {
	return (
		<SkillsSreenWelcomeView>
			<WelcomeVideoScreen
				icon='skills'
				title='Your Skills'
				text={`Next, select the skills you would like feedback on from your professional community. Here's a short video about the process.`}
				video={{
					url: 'https://www.youtube.com/watch?v=nV_9A13CWyc',
					youTubeVidId: 'nV_9A13CWyc',
				}}
			>
				<CTA padTop>
					<Btn onClick={cb}>Get into it</Btn>
				</CTA>
			</WelcomeVideoScreen>
		</SkillsSreenWelcomeView>
	)
}
