import React from 'react'
import styled, { css } from 'styled-components'

export const hrStyles = css``

const HRView = styled.hr`
	${hrStyles}
`

export type HRProps = {}
export const HR = (): JSX.Element => <HRView />
