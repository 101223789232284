import {
	Async,
	Heading,
	MerifyIrmaWelcomeProfileSvg,
	useModalCtrl,
	Video,
	YouTubeVid,
} from '@merify/ui'
import { RouteComponentProps } from '@reach/router'
import styled, { css } from 'styled-components'
import { useGetAllTutorialVideos } from '../../../api/api.admin'
import { useSetAppHeader } from '../../../utils/useSetAppHeader'
import { BackBtn } from '../../global/Back.btn/Back.btn'
import { AppDrawer, AppDrawerPlaceholder } from '../../global/Drawer.app'
import { VideoList } from '../../global/Video.list'

export const videosScreenStyles = css`
	.back-btn {
		padding-left: 20px;
		margin-top: 20px;
	}
	h4 {
		padding: 20px;
	}
`

const VideosScreenView = styled.div`
	${videosScreenStyles}
`

type VideosScreenProps = RouteComponentProps & {}

export const VideosScreen = (_: VideosScreenProps): JSX.Element => {
	useSetAppHeader('default')

	const results = useGetAllTutorialVideos()

	const [[isAppDrawerOpen, openAppDrawer, closeAppDrawer, currentVid]] = useModalCtrl<YouTubeVid>()

	return (
		<VideosScreenView>
			<Async fetchResults={results}>
				<BackBtn />
				<Heading hTag='h4' displayType='block-heading' dir='center'>
					Video Tutorials
				</Heading>
				<VideoList videos={results.data} onSelect={openAppDrawer} />
			</Async>

			<AppDrawer
				isOpen={isAppDrawerOpen}
				closeHandler={closeAppDrawer}
				placeholder={
					<AppDrawerPlaceholder heading='Select a video' svg={<MerifyIrmaWelcomeProfileSvg />} />
				}
				displayType='two-col'
			>
				{currentVid ? <Video yTVideo={currentVid} /> : null}
			</AppDrawer>
		</VideosScreenView>
	)
}
