import { Card, WizardStepProps } from '@merify/ui'
import styled, { css } from 'styled-components'
import { TextareaField } from '../../UI/FormFields/Textarea.field'
import { useReview } from '../Review.provider'
import { ReviewSubmitBtns } from './Review.submitBtns'

const ReviewSubmitStyles = css`
	.top-btns {
		margin-bottom: 1.7em;
	}
	.review-submit-card {
		background-color: ${props => props.theme.colors.lightNeutral.val};

		.comments {
			margin-top: 1.5rem;
		}
	}
`

const ReviewSubmitView = styled.div`
	${ReviewSubmitStyles}
`

type ReviewSubmitProps = {
	className?: string
}
export const ReviewSubmit = ({ className, ...stepProps }: ReviewSubmitProps): JSX.Element => {
	const { previousStep } = stepProps as WizardStepProps

	const { comments, setComments, submitReview, isAllRated, isSubmitting, reviewType, cancel } =
		useReview()

	return (
		<ReviewSubmitView className={`review-submit${className ? ` ${className}` : ''}`}>
			<section className='cta-btns top-btns'>
				<ReviewSubmitBtns
					reviewType={reviewType}
					previousStep={previousStep}
					cancel={cancel}
					submitHandler={submitReview}
					disable={!isAllRated || isSubmitting}
				/>
			</section>

			<Card hasGutter background='none' className='review-submit-card'>
				<div className='comments'>
					<TextareaField
						label='Comments'
						rows={10}
						value={comments || ''}
						placeholder='Any final thoughts or comments?'
						onChange={(e: any) => {
							setComments(e.target.value)
						}}
					/>
				</div>
			</Card>
		</ReviewSubmitView>
	)
}
