import { AccordionConsumer, AccordionProvider } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useReview } from '../Review.provider'
import { ReviewSkill } from './Review.skill'
import { RateReviewSkill } from './Review.skill.rate'

export const rateReviewSkillsStyles = css``

const RateReviewSkillsView = styled.div`
	${rateReviewSkillsStyles}
`

export type RateReviewSkillsProps = {
	isLastStep?: boolean
	nextStep?: () => void
}
export const RateReviewSkills = ({ isLastStep, nextStep }: RateReviewSkillsProps): JSX.Element => {
	const { skillsToRate: skills } = useReview()

	return (
		<RateReviewSkillsView>
			{skills.length ? (
				<AccordionProvider initialId={skills[0].skillId} initialOpenDelay={700}>
					{skills.map((skill, index) => {
						const nextSkillId =
							// First we see if there are any skills after this skill that need to be scored
							skills.find(
								({ skipped, rating, levelId }, idx) =>
									idx > index && !skipped && !rating && !levelId
							)?.skillId ??
							// If not, let's grab the first one that still needs to be scored
							skills.find(({ skipped, rating, levelId }) => !skipped && !rating && !levelId)
								?.skillId ??
							// If there are no other skills to be scored we return this skill's id
							skill.skillId

						return (
							<AccordionConsumer
								key={skill.skillId}
								id={skill.skillId}
								ctrl={toggle => <ReviewSkill onClick={() => toggle()} skill={skill} />}
							>
								<RateReviewSkill
									skill={skill}
									nextSkillId={nextSkillId}
									isLastStep={isLastStep}
									nextStep={nextStep}
								/>
							</AccordionConsumer>
						)
					})}
				</AccordionProvider>
			) : null}
		</RateReviewSkillsView>
	)
}
