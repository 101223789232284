import {
	AccordionConsumer,
	AccordionProvider,
	Btn,
	CTA,
	Ctas,
	SimpleSkill,
	SkillLevelId,
} from '@merify/ui'
import { useNavigate } from '@reach/router'
import { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { useAddSkillsMutation, useGetSkillsWithScores } from '../../../api/api.skills'
import { toaster } from '../../UI/Toaster'
import { ReviewSkill } from '../Review/Review.skill'
import { AddSkillLevel } from './Add.skill.level'

const AddSkillsFromNotificationStyles = css``

const AddSkillsFromNotificationView = styled.div`
	${AddSkillsFromNotificationStyles}
`

type LevelSkill = SimpleSkill & {
	skillName: string
	levelId: SkillLevelId | null
}

type AddSkillsFromNotificationProps = {
	className?: string
	skills: SimpleSkill[]
	cancel?: () => void
}
export const AddSkillsFromNotification = ({
	className,
	skills,
	cancel,
}: AddSkillsFromNotificationProps): JSX.Element => {
	const goTo = useNavigate()
	const skillRes = useGetSkillsWithScores()
	const currentSkills = skillRes.data

	const skillsNotInCurrent = useMemo(() => {
		return skills
			.filter(({ skillId }) => !currentSkills?.some(s => s.skillId === skillId))
			.map(({ skillId, skillName }) => ({
				skillName,
				skillId,
				levelId: null,
			})) as LevelSkill[]
	}, [currentSkills, skills])

	const [skillsForSave, setSkillsForSave] = useState<LevelSkill[]>(skillsNotInCurrent)

	useEffect(() => {
		if (!skillsForSave?.length && skillsNotInCurrent?.length) {
			setSkillsForSave(skillsNotInCurrent)
		}
	}, [skillsForSave, skillsNotInCurrent])

	const { mutateAsync } = useAddSkillsMutation()
	const addSkills = async () => {
		try {
			const res = await mutateAsync(
				skillsForSave.map(({ skillId, levelId }) => ({
					skillId,
					currentSkillLevelId: levelId as number,
				}))
			)

			if (res.error) {
				toaster.err('Unable to add skills, try again later.')
			} else {
				if ((skills?.length || 0) > 1) {
					toaster.ok('Skills added!')
				} else toaster.ok('Skill added!')

				goTo('/skills')
			}
		} catch (err) {}
	}

	return (
		<AddSkillsFromNotificationView
			className={`add-skills-from-notification${className ? ` ${className}` : ''}`}
		>
			{skillsForSave?.length ? (
				<AccordionProvider initialId={skillsForSave[0].skillId} initialOpenDelay={700}>
					{skillsForSave.map(skill => {
						return (
							<AccordionConsumer
								key={skill.skillId}
								id={skill.skillId}
								ctrl={toggle => <ReviewSkill onClick={() => toggle()} skill={skill} />}
							>
								<AddSkillLevel
									skill={skill}
									onSelect={levelId => {
										setSkillsForSave(state =>
											state.map(s => (s.skillId === skill.skillId ? { ...s, levelId } : s))
										)
									}}
								/>
							</AccordionConsumer>
						)
					})}
				</AccordionProvider>
			) : null}
			<Ctas>
				<CTA>
					<Btn onClick={addSkills} disabled={skillsForSave.some(({ levelId }) => !levelId)}>
						ADD SKILLS
					</Btn>
				</CTA>
				{cancel ? (
					<CTA>
						<Btn onClick={() => cancel()}>Cancel</Btn>
					</CTA>
				) : null}
			</Ctas>
		</AddSkillsFromNotificationView>
	)
}
