import { Btn, calcScoreForDisplay, Lottie, skillLevelsWithInfo, Spinner } from '@merify/ui'
import styled, { css } from 'styled-components'
import mAnimationFull from '../../../animations/m-level-rating.json'

type ScoreCircleStyleProps = {
	color?: string
}
const SIZE = '130px'
const M_SIZE = '200px'
export const scoreCircleStyles = css<ScoreCircleStyleProps>`
	width: ${SIZE};
	height: ${SIZE};
	margin: 0 auto;

	${props => props.theme.media.tablet} {
		max-width: 270px;
		max-height: 270px;
	}

	.lottie {
		min-height: ${M_SIZE};
		min-width: ${M_SIZE};
		max-height: ${M_SIZE};
		max-width: ${M_SIZE};
		z-index: 2;
	}

	.center-btn {
		z-index: 2;
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border: solid 2px;
		border-radius: 50%;
		overflow: hidden;
	}

	.rating {
		font-size: ${props => props.theme.sizes.font.h2.val};
		font-weight: 700;
		line-height: 3rem;
		font-size: 60px;
		color: inherit;
		line-height: initial;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&.__hollow {
		.center-btn {
			background: ${p => p.theme.colors.offWhite.val};
			color: ${p => p.theme.color(p.color || p.theme.colors.text.val).opacity(30).val};
			border-color: ${p => p.color};
		}
	}

	&.__solid {
		.center-btn {
			background: ${p => p.color};
			color: ${p => p.theme.colors.white.val};
			border-color: ${p => p.color};
		}
	}

	&.__no-data {
		.center-btn {
			border: none;
			overflow: visible;
		}
	}

	&.__inverted {
		&.__hollow {
			.center-btn {
			}
		}

		&.__solid {
			.center-btn {
				background: ${props => props.theme.colors.offWhite.val};
				color: ${p => p.color};
				border-color: ${props => props.theme.colors.offWhite.val};
			}
		}
	}
`

const ScoreCircleView = styled.div`
	${scoreCircleStyles}
`

export type ScoreCircleProps = {
	rating?: number | null
	levelId?: number | null
	onClick?: () => void
	isMounted?: boolean
	isSolid?: boolean
	isInverted?: boolean
	isLoading?: boolean
}
export const ScoreCircle = ({
	rating,
	levelId,
	onClick,
	isMounted = true,
	isSolid,
	isInverted,
	isLoading,
}: ScoreCircleProps): JSX.Element => {
	const { color } = skillLevelsWithInfo.find(l => l.levelId === levelId) || {}
	const hasNoData = !levelId && typeof rating !== 'number'

	return (
		<ScoreCircleView
			className={`score-circle${isSolid ? ' __solid' : ' __hollow'}${
				isInverted ? ' __inverted' : ''
			}${hasNoData ? ' __no-data' : ''}`}
			color={color}
		>
			<Btn
				onClick={() => {
					if (onClick) onClick()
				}}
				className='center-btn'
			>
				{isLoading ? <Spinner type='circle' height='lg' /> : null}
				{hasNoData ? (
					isMounted ? (
						<Lottie loop={true} file={mAnimationFull} />
					) : null
				) : (
					<div className='rating'>{rating ? calcScoreForDisplay(rating) : null}</div>
				)}
			</Btn>
		</ScoreCircleView>
	)
}
