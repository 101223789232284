import { isStrAnInt } from '@merify/ui'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useEffect } from 'react'
import styled from 'styled-components'
import { useSetAppHeader } from '../../../../utils/useSetAppHeader'
import { useAppDrawer } from '../../../global/Drawer.app'
import { PrivateReview } from '../../../global/Review'
import { Page404Redirect } from '../../Page404.redirect'

const ReviewScreenView = styled.div``

type ReviewScreenProps = RouteComponentProps & {
	reviewId?: string
}

export const ReviewScreen = ({ reviewId }: ReviewScreenProps): JSX.Element => {
	useSetAppHeader('default')
	const goTo = useNavigate()

	const isValidId = isStrAnInt(reviewId)

	const { setDisplayType } = useAppDrawer()
	useEffect(() => {
		setDisplayType('one-col')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return isValidId ? (
		<ReviewScreenView>
			{reviewId ? (
				<PrivateReview reviewId={parseInt(reviewId)} cancel={() => goTo('/reviews')} isRoute />
			) : (
				<Page404Redirect />
			)}
		</ReviewScreenView>
	) : (
		<Page404Redirect />
	)
}
