import { ErrorBlockErr, PrivateCandidate, UserIdentity } from '@merify/ui'
import { Action, action, createStore, createTypedHooks } from 'easy-peasy'
import { AppHeaderColType } from '../components/global/Header.app'

export type StoreModel = {
	view: ViewStore
	candidate: CandidateStore
}

type HeaderColsType = {
	col1: AppHeaderColType[]
	col2: AppHeaderColType[]
}

export type HeaderColsInput = {
	col1?: AppHeaderColType[]
	col2?: AppHeaderColType[]
}

type ViewStore = {
	header: HeaderColsType
	pathname: string
	appError: ErrorBlockErr | null
	setAppError: Action<ViewStore, ErrorBlockErr | null>

	setHeaderCol: Action<
		ViewStore,
		{
			col: '1' | '2'
			cols: AppHeaderColType[]
		}
	>
	setHeader: Action<ViewStore, HeaderColsInput>
	setPathname: Action<ViewStore, string>
}

type CandidateStore = {
	// isLoggedIn: boolean
	user: PrivateCandidate | null
	oidcUser: UserIdentity | null
	// oidcError: Error | null

	// setIsLoggedIn: Action<CandidateStore, boolean>
	setUser: Action<CandidateStore, PrivateCandidate | null>
	setOidcUser: Action<CandidateStore, UserIdentity | null>
	// setOidcError: Action<CandidateStore, Error | null>
	clearUser: Action<CandidateStore>
}

const view: ViewStore = {
	header: {
		col1: [],
		col2: ['invite', 'notifications'],
	},
	pathname: '',

	setHeaderCol: action((state, colData) => {
		const { col, cols } = colData || {}
		if (!col || !cols?.length) return

		const newState = state.header
		newState[`col${col}`] = cols

		state.header = newState
	}),
	setHeader: action((state, headerData) => {
		if (!headerData) return
		state.header = {
			...state.header,
			...headerData,
		}
	}),
	setPathname: action((state, path) => {
		if (state.pathname !== path) state.pathname = path
	}),

	appError: null,
	setAppError: action((state, err) => {
		if (state.appError && !err) state.appError = null
		else if (err) state.appError = err
	}),
}

const candidate: CandidateStore = {
	// isLoggedIn: false,
	user: null,
	oidcUser: null,

	// setIsAuthenticated: action((state, override) => {
	// 	if (override === state.isAuthenticated) return
	// 	else state.isAuthenticated = override
	// }),
	// setIsLoggedIn: action((state, override) => {
	// 	if (override === state.isLoggedIn) return
	// 	else state.isLoggedIn = override
	// }),
	setOidcUser: action((state, user) => {
		if (!user || !!state.oidcUser) return
		state.oidcUser = user
	}),
	// setOidcError: action((state, error) => {
	// 	if (!error || !!state.oidcError) return
	// 	state.oidcError = error
	// }),
	setUser: action((state, user) => {
		if (!user) return
		state.user = user
	}),
	clearUser: action(state => {
		if (state.user) state.user = null
		if (state.oidcUser) state.oidcUser = null
		// if (state.isAuthenticated) state.isAuthenticated = false
		// if (state.isLoggedIn) state.isLoggedIn = false
	}),
}

export const store = createStore({
	view,
	candidate,
})

const typedHooks = createTypedHooks<StoreModel>()

export const useStoreActions = typedHooks.useStoreActions
export const useStoreState = typedHooks.useStoreState
export const useStoreDispatch = typedHooks.useStoreDispatch
