import React from 'react'
import styled, { css } from 'styled-components'
import { AppNav } from './Nav.app'

export const appNavDeskStyles = css`
	li {
		min-width: 200px;
		display: flex;
		padding: 0.5rem 0;
		padding-left: ${props => props.theme.sizes.gutter.tablet.val};

		${props => props.theme.media.sdesk} {
			padding-left: ${props => props.theme.sizes.gutter.sdesk.val};
		}

		button,
		a {
			flex-direction: row;
			justify-content: flex-start;
			font-size: 1.3rem;

			.icon {
				margin-right: 1em;
			}
		}
	}
`

const AppNavDeskView = styled.div`
	${appNavDeskStyles}
`

export type AppNavDeskProps = {}
export const AppNavDesk = (): JSX.Element => {
	return (
		<AppNavDeskView>
			<AppNav />
		</AppNavDeskView>
	)
}
