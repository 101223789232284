import React from 'react'
import styled, { css } from 'styled-components'

export const errorFallbackStyles = css``

const ErrorFallbackScreenView = styled.div`
	${errorFallbackStyles}
`
type ErrorFallbackScreenProps = {
	error: Error
	info: React.ErrorInfo
	clearError: () => void
}
export const ErrorFallbackScreen = ({ error, info }: ErrorFallbackScreenProps): JSX.Element => {
	return (
		<ErrorFallbackScreenView>
			<h1>Uh oh...</h1>
			<h3>An unexpected error has occured.</h3>
			<h5>{error.message}</h5>
			<pre>{info}</pre>
		</ErrorFallbackScreenView>
	)
}
