import { UID, log } from '@merify/ui'
import { useNavigate } from '@reach/router'
import { AxiosError } from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { QueryFunction, QueryKey, UseQueryOptions, useQuery } from 'react-query'
import { APIToken } from '../api/fetch'
import { useStoreActions } from '../store'

export type FetchMethodGenerator = (...args: any) => FetchMethod

export type FetchMethod = <T>(config: FetchMethodConfig) => QueryFunction<T>

export type FetchMethodConfig = {
	uid?: UID
	token?: APIToken
}

export type useFetchOptions = UseQueryOptions & {
	isLazy?: boolean
	uid?: UID
	token?: string
}

const useFetch = <T>(key: QueryKey, fetchMethod: () => Promise<T>, options?: useFetchOptions) => {
	const { isLazy, uid: optionUid, token: optionToken, ...queryOptions } = options || {}
	const useQueryOpts = queryOptions as UseQueryOptions<T>
	const isMounted = useRef(true)

	const query = useQuery<T>(key, fetchMethod, {
		...useQueryOpts,
		enabled: isLazy !== true,
	})

	const { refetch } = query

	const [state, setState] = useState(query)

	useEffect(() => {
		if (isMounted.current) setState(query)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query.data, query.error, query.isLoading, query.isSuccess, query.isFetchedAfterMount])

	useEffect(() => {
		if (!isLazy) refetch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLazy])

	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])

	return state
}

export const useUnauthedFetch = <T>(
	key: QueryKey,
	fetchMethod: () => Promise<T>,
	options?: useFetchOptions
) => useFetch(key, fetchMethod, options)

export const useAuthedFetch = <T>(
	key: QueryKey,
	fetchMethod: () => Promise<T>,
	options?: useFetchOptions
) => {
	const { error: authErr, signinSilent, user, signoutRedirect } = useAuth()

	const state = useFetch(key, fetchMethod, options)

	const { error } = state

	const navTo = useNavigate()

	const accessToken = user?.access_token
	const setAppErr = useStoreActions(actions => actions.view.setAppError)

	useEffect(() => {
		const err = error as AxiosError
		const httpErr = err?.response?.status
		const httpMethod = err?.config?.method
		const isGet = httpMethod === 'get' || httpMethod === 'GET'

		if (httpErr === 401 && isGet) {
			// Probably token has expired
			if (accessToken) {
				signinSilent && signinSilent()
			} else {
				// If redirect is bad logic, we should set a new global state to render a component that performs a test query - if test query comes back with 403 we then log them out.
				signoutRedirect()
			}
		} else if (httpErr === 403 && isGet) {
			// Probably they are logged into the wrong app

			signoutRedirect()
		} else if (err?.response && !err.response.status) {
			// We don't know what sort of error happened here
			log('useAuthedFetch', 'Unknown error', err)

			setAppErr(err)
		} else {
			setAppErr(null)
		}
	}, [error, authErr, signinSilent, navTo, setAppErr, accessToken, signoutRedirect])

	return state
}
