import { Heading } from '@merify/ui'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

export const appDrawerPlaceholderStyles = css`
	background: ${props => props.theme.colors.white.opacity(40).val};
	border: 2px dashed ${props => props.theme.colors.midNeutral.val};
	border-radius: 100px;
	min-height: 50vh;
	min-width: 20vw;
	padding: 3em;
	display: flex;
	justify-content: center;
	align-items: center;

	${props => props.theme.media.ldesk} {
		min-width: 30vw;
		padding: 3em;
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.heading {
		font-size: 4rem;
		line-height: 7rem;
		text-align: center;
		opacity: 0.15;
		text-transform: uppercase;
		${props => props.theme.media.ldesk} {
			font-size: 5rem;
		}
	}

	.svg-wrap {
		display: none;

		${props => props.theme.media.sdesk} {
			filter: grayscale();
			display: block;
		}

		& > * {
			${props => props.theme.media.sdesk} {
				opacity: 0.15;
				max-width: 300px;
				height: auto;
			}
		}
	}
`

const AppDrawerPlaceholderView = styled.div`
	${appDrawerPlaceholderStyles}
`

export type AppDrawerPlaceholderProps = {
	heading: string
	svg?: ReactNode
}
export const AppDrawerPlaceholder = ({ heading, svg }: AppDrawerPlaceholderProps): JSX.Element => {
	return (
		<AppDrawerPlaceholderView>
			<div className='content'>
				<Heading color='neutral' displayType='section-heading'>
					{heading}
				</Heading>
				{svg ? <div className='svg-wrap'>{svg}</div> : null}
			</div>
		</AppDrawerPlaceholderView>
	)
}
