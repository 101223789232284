import styled, { css } from 'styled-components'

export const selectedStyles = css`
	border-radius: 50%;
	min-height: 1.5em;
	max-height: 1.5em;
	min-width: 1.5em;
	max-width: 1.5em;
	border: solid 1px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	background-color: ${props => props.theme.colors.paleGrey.val};
	&:before {
		transition: opacity ${props => props.theme.times.tranM};
		opacity: 0;
		display: block;
		background: currentColor;
		border-radius: 50%;
		height: 8px;
		width: 8px;
		content: '';
	}

	&.__selected {
		&:before {
			opacity: 1;
		}
	}
`

const SelectedIcoView = styled.span`
	${selectedStyles}
`

export type SelectedIcoProps = {
	isSelected: boolean
	className?: string
}
export const SelectedIco = ({ isSelected, className }: SelectedIcoProps): JSX.Element => {
	return (
		<SelectedIcoView
			className={`selected-ico${className ? ` ${className}` : ''}${
				isSelected ? ' __selected' : ''
			}`}
		/>
	)
}
