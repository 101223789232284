import { Btn, Icon, useModalCtrl } from '@merify/ui'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { ModalAlert } from '../../UI/Modal'
import { ShareProfile } from '../Share.btn/Share.profile'

export const userMenuShareProfileStyles = css``

const UserMenuShareProfileView = styled(Btn)`
	${userMenuShareProfileStyles}
`

export type UserMenuShareProfileProps = {
	children?: ReactNode
}
export const UserMenuShareProfile = ({ children }: UserMenuShareProfileProps): JSX.Element => {
	const [[showModal, openModal, closeModal]] = useModalCtrl()
	return (
		<>
			<UserMenuShareProfileView onClick={openModal}>
				<Icon type='share' /> {children}
			</UserMenuShareProfileView>
			<ModalAlert isOpen={showModal} closeHandler={closeModal}>
				<ShareProfile cb={closeModal} />
			</ModalAlert>
		</>
	)
}
