import Bugsnag from '@bugsnag/js'
import { canPrint, isDev, unset } from '@merify/ui'
import axios, { AxiosRequestConfig, AxiosRequestHeaders, Method } from 'axios'

const API_URI = process.env.REACT_APP_API_URI

export type APIToken = string

export interface FetchOptions<T> {
	data?: any
	noCreds?: boolean
	noPrint?: boolean
	transformCb?: (data: any) => T
	contentType?: 'application/json' | 'text/plain' | null
	file?: File
	params?: {
		[x: string]: string | number | boolean
	}
}

export const fetch = async <T>(
	method: Method,
	path: string,
	token?: APIToken,
	options?: FetchOptions<T>
): Promise<T> => {
	const {
		data: incomingData,
		noCreds,
		noPrint,
		transformCb,
		contentType,
		params,
		file,
	} = options || {}
	try {
		if (!token && noCreds !== true) {
			const error = new Error('Invalid token')
			throw error
		}

		const data = file ? new FormData() : incomingData

		if (file) data.append('file', file)

		const url = `${API_URI}${path}`

		/**
		 *  SET HEADERS
		 * */
		const headers: AxiosRequestHeaders = {
			'Content-Type': contentType || 'application/json',
		}

		if (!noCreds && token) headers['Authorization'] = `Bearer ${token}`

		if (file || contentType === null) unset(headers, 'Content-Type')

		if (method === 'GET') headers['Content-Type'] = 'text/plain'

		const reqConfig: AxiosRequestConfig = {
			method,
			url,
			data,
			params,
			headers,
			// headers: {
			// 	'Authorization': !noCreds && token ? `Bearer ${token}` : undefined,
			// 	// 'Accept': 'application/json',
			// 	'Content-Type': file
			// 		? null
			// 		: contentType
			// 		? contentType
			// 		: method === 'GET'
			// 		? 'text/plain'
			// 		: 'application/json',
			// },
		}

		if (!noPrint && canPrint)
			console.log(
				`%cAPI ${method} to [ ${path} ] about to request with settings: `,
				`color:#FFF; background-color:#758B9A; padding: 4px;`,
				reqConfig
			)

		const response = await axios(reqConfig)

		if (!noPrint && canPrint)
			console.log(
				`%cAPI ${method} to [ ${path} ] returned with results: `,
				`color:#FFF; background-color:#89a989; padding: 4px;`,
				response
			)

		const results = transformCb ? transformCb(response.data) : response.data

		return results
	} catch (err: any) {
		if (!isDev) Bugsnag.notify(err)

		if (!noPrint && canPrint) {
			if (err.response?.data)
				console.log(
					`%cAPI ${method} to [ ${path} ] error response: `,
					`color:#FFF; background-color:#dc3300; padding: 4px;`,
					err.response?.data
				)
			else
				console.log(
					`%cAPI ${method} to [ ${path} ] error returned without useful message:`,
					`color:#FFF; background-color:#dc3300; padding: 4px;`,
					err.response
				)

			// console.dir(err)
		}

		throw err
	}
}
