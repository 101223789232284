import { Async, Btn, CTA, Ctas, Heading, ReviewSkill, WizardStepProps } from '@merify/ui'
import { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useGetSkillsOnKudosCandidate } from '../../../api/api.reviews'
import { Container } from '../../UI/Container'
import { GiveKudosSkillItem } from './Give.kudos.skill.item'

const GiveKudosSelectSkillsStyles = css`
	.skills {
		background: ${props => props.theme.colors.white.val};
		margin-top: 2rem;
	}
`

const GiveKudosSelectSkillsView = styled.div`
	${GiveKudosSelectSkillsStyles}
`

type GiveKudosSelectSkillsProps = {
	className?: string
	candidateId: number
	skillsForReview: ReviewSkill[]
	selectSkill: (skill: ReviewSkill) => void
	cancelHandler: () => void
}
export const GiveKudosSelectSkills = ({
	className,
	candidateId,
	skillsForReview,
	selectSkill,
	cancelHandler,
	...stepProps
}: GiveKudosSelectSkillsProps): JSX.Element => {
	const { nextStep, isActive } = stepProps as WizardStepProps

	const results = useGetSkillsOnKudosCandidate(candidateId)

	const skills: ReviewSkill[] | undefined = useMemo(
		() =>
			results.data?.map(
				({ skillId, skillName }) =>
					({
						hasExistingSkillReview: false,
						skillId,
						skillName,
					} as ReviewSkill)
			),
		[results.data]
	)

	return (
		<GiveKudosSelectSkillsView
			className={`give-kudos-select-skills${className ? ` ${className}` : ''}`}
		>
			<Container isStickyFooter stickyOffsets={['wizardDots']} isInactiveWizardStep={!isActive}>
				<Heading mBot hTag='h4' displayType='dialog-heading' dir='center'>
					Select the skills to give kudos for
				</Heading>
				<Async fetchResults={results}>
					<header>
						<Ctas maxW='350px' mTop>
							<CTA size='sm'>
								<Btn onClick={cancelHandler}>Cancel</Btn>
							</CTA>
							<CTA displayType='solid' size='sm'>
								<Btn
									onClick={nextStep}
									disabled={skillsForReview && skillsForReview.length >= 1 ? false : true}
								>
									Next
								</Btn>
							</CTA>
						</Ctas>
					</header>
					<div className='skills'>
						{skills &&
							skills.map(skill => (
								<div className='skill' key={skill.skillId}>
									<GiveKudosSkillItem
										skill={skill}
										isChecked={
											skillsForReview.find(s => s.skillId === skill.skillId) ? true : false
										}
										onChange={() => {
											selectSkill(skill)
										}}
									/>
								</div>
							))}
					</div>
				</Async>
			</Container>
		</GiveKudosSelectSkillsView>
	)
}
