import { BaseNotification, CombinedNotification, FormattedNotificationFields } from '@merify/ui'

export type FormattedNotification = FormattedNotificationFields & BaseNotification

export function formatNotification(notification: CombinedNotification): FormattedNotification {
	const obj = {
		...notification,
	} as any

	if (notification.skillApprovedNotificationId) {
		obj.notificationId = notification.skillApprovedNotificationId
		obj.notificationType = 'skillApprovedNotifications'
	} else if (notification.skillDisapprovedNotificationId) {
		obj.notificationId = notification.skillDisapprovedNotificationId
		obj.notificationType = 'skillDisapprovedNotifications'
	} else if (notification.requestedReviewNotificationId) {
		obj.notificationId = notification.requestedReviewNotificationId
		obj.notificationType = 'requestedReviewNotifications'
	} else if (notification.candidateSkillVerificationNotificationId) {
		obj.notificationId = notification.candidateSkillVerificationNotificationId
		obj.notificationType = 'candidateSkillVerificationNotifications'
	} else if (notification.candidateSkillVerificationReminderNotificationId) {
		obj.notificationId = notification.candidateSkillVerificationReminderNotificationId
		obj.notificationType = 'candidateSkillVerificationReminderNotifications'
	} else if (notification.candidateSkillVerificationDeadlineUpdateNotificationId) {
		obj.notificationId = notification.candidateSkillVerificationDeadlineUpdateNotificationId
		obj.notificationType = 'candidateSkillVerificationDeadlineUpdateNotifications'
	} else if (notification.candidateSkillVerificationSkillsUpdateNotificationId) {
		obj.notificationId = notification.candidateSkillVerificationSkillsUpdateNotificationId
		obj.notificationType = 'candidateSkillVerificationSkillsUpdateNotifications'
	} else if (notification.candidateSkillVerificationDeadlineReachedNotificationId) {
		obj.notificationId = notification.candidateSkillVerificationDeadlineReachedNotificationId
		obj.notificationType = 'candidateSkillVerificationDeadlineReachedNotifications'
	} else if (notification.candidateAnnouncementNotificationId) {
		obj.notificationId = notification.candidateAnnouncementNotificationId
		obj.notificationType = 'candidateAnnouncementNotifications'
	} else if (notification.candidateRegisteredNotificationId) {
		obj.notificationId = notification.candidateRegisteredNotificationId
		obj.notificationType = 'candidateRegisteredNotifications'
	} else if (notification.candidateSystemLevelChangeNotificationId) {
		obj.notificationId = notification.candidateSystemLevelChangeNotificationId
		obj.notificationType = 'candidateSystemLevelChangeNotifications'
	}

	return obj
}
