import { Btn, Card, CTA, FormikSubmitBtn, WizardStepProps } from '@merify/ui'
import { useFormikContext } from 'formik'
import styled, { css } from 'styled-components'
import { Container } from '../../UI/Container'
import { SmallCtas } from '../../UI/SmallCtas'
import { CandidateJobFields } from '../Candidate.job/Candidate.job.fields'

export const createCandidateJobInfoStyles = css`
	.fields {
		padding: ${props => props.theme.sizes.gutter.mobile.val};
		width: 100%;
	}
	.dates {
		display: flex;
		.date {
			flex-grow: 1;
			&:first-child {
				margin-right: 2em;
			}
		}
	}
`

const CreateCandidateJobInfoView = styled.div`
	${createCandidateJobInfoStyles}
`

type CreateJobInfoProps = {
	onCancel: () => void
	isSubmit?: boolean
}
export const CreateCandidateJobInfo = ({
	onCancel,
	isSubmit,
	...stepProps
}: CreateJobInfoProps): JSX.Element => {
	const { nextStep, isActive } = stepProps as WizardStepProps
	const { isValid } = useFormikContext()

	return (
		<CreateCandidateJobInfoView>
			<Container isStickyFooter isInactiveWizardStep={!isActive}>
				<SmallCtas>
					<CTA mTop mBot size='sm'>
						<Btn onClick={() => onCancel()}>Cancel</Btn>
					</CTA>

					{isSubmit ? (
						<FormikSubmitBtn ctaConfig={{ size: 'sm', mTop: true, mBot: true }}>
							Save
						</FormikSubmitBtn>
					) : (
						<CTA mTop mBot size='sm'>
							<Btn onClick={() => nextStep()} disabled={isValid === false}>
								Add Skills
							</Btn>
						</CTA>
					)}
				</SmallCtas>
				<Card>
					<CandidateJobFields />
				</Card>

				{/* <CTA padTop>
					<Btn onClick={() => onCancel()}>Cancel</Btn>
					{isSubmit ? (
						<FormikSubmitBtn>Save</FormikSubmitBtn>
					) : (
						<Btn onClick={() => nextStep()} disabled={isValid === false}>
							Add Skills
						</Btn>
					)}
				</CTA> */}
			</Container>
		</CreateCandidateJobInfoView>
	)
}
