import { Async, Card, Heading, Icon, IncomingReviewRequest, ReviewPublicPerson } from '@merify/ui'
import React from 'react'
import styled, { css } from 'styled-components'
import { useGetJobWithCandidate } from '../../../api/api.jobs'
import { ReviewJobCard } from './Review.job.card'

export const reviewInfoStyles = css`
	h4.reviewee-name {
		margin-bottom: 1rem;
	}
	.reviewee {
		width: 100%;
		display: flex;
		align-items: center;

		.card-inner {
			width: 100%;
		}

		p {
			padding-left: 1rem;
			line-height: 1.8rem;
		}
	}

	.linked-text {
		padding-left: ${props => props.theme.sizes.gutter.mobile.val};
		margin-bottom: 10px;
		.icon {
			width: 30px;
			height: auto;
			margin-right: 0.3em;
		}
	}

	.skills {
		.skill {
			border-top: 1px solid ${props => props.theme.colors.white.val};
			border-bottom: 1px solid ${props => props.theme.colors.white.val};
			padding: 1rem 0;
		}
	}
`

const ReviewInfoView = styled.div`
	${reviewInfoStyles}
`
type LinkedJobProps = { candidateJobId: number }

const LinkedJob = ({ candidateJobId }: LinkedJobProps): JSX.Element => {
	const results = useGetJobWithCandidate(candidateJobId)
	const { candidateJob, candidateJobSkills, candidate } = results.data || {}
	const hasData = candidateJob && candidateJobSkills && candidate

	return (
		<Async fetchResults={results}>
			{hasData ? (
				<ReviewJobCard
					reviewData={{
						job: {
							...candidateJob,
							jobId: candidateJob.candidateJobId,
						},
						jobSkills: candidateJobSkills.map(skill => ({
							...skill,
							hasExistingSkillReview: false,
						})),
						person: {
							...candidate,
							personId: candidate.candidateId,
							personTypeId: 1,
						} as ReviewPublicPerson,
						existingReview: null,
					}}
				/>
			) : null}
		</Async>
	)
}

export type ReviewInfoProps = {
	reviewData: Pick<IncomingReviewRequest, 'job' | 'jobSkills' | 'person'>
	linkedJobId?: number
}
export const ReviewInfo: React.FC<ReviewInfoProps> = ({ reviewData, linkedJobId }) => {
	return (
		<ReviewInfoView>
			{/* <Heading hTag='h4' className='reviewee-name'>
				{parseNameFromUser(person)}
			</Heading> */}
			<Card className='reviewee'>
				<ReviewJobCard reviewData={reviewData} />
			</Card>
			{linkedJobId ? (
				<>
					<Heading hTag='h4' displayType='block-heading' className='linked-text'>
						<Icon type='link' />
						Linked to
					</Heading>
					<Card>
						<LinkedJob candidateJobId={linkedJobId} />
					</Card>
				</>
			) : null}
		</ReviewInfoView>
	)
}
