/**
 *  Fetches and sets the current database user
 * 	NOTE: This should only render if the user is already authenticated and the oidc user is set
 * */
import { ReactNode, useEffect } from 'react'
import { useGetMe } from '../../api/api.candidate'
import { LoadingScreen } from '../../components/screens/loading/Loading.screen'
import { useStoreActions, useStoreState } from '../../store'

export type AuthUserProps = {
	children?: ReactNode
}
export const AuthUser = ({ children }: AuthUserProps): JSX.Element => {
	const { user } = useStoreState(state => state.candidate)
	const { setUser } = useStoreActions(actions => actions.candidate)
	const { data } = useGetMe()

	useEffect(() => {
		if (data) {
			setUser(data)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	console.log('AuthUser', { user, data })

	return <>{user ? children : <LoadingScreen />}</>
}
