import { css } from 'styled-components'

type modalFullProps = {}

export const modalFullStyles = css<modalFullProps>`
	height: 100%;
	background: ${props => props.theme.colors.offWhite.val};
	padding: 0;
	transition: all 150ms ease-in-out;
	${props => props.theme.media.tablet} {
		background: ${props => props.theme.colors.offWhite.dark().val};
	}
	.bg-m {
		display: none;
		${props => props.theme.media.sdesk} {
			z-index: 0;
			display: block;
			width: 100vw;
			height: 100vh;
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			overflow: hidden;
		}
		.merify-m {
			width: 120%;
			height: auto;
			margin: 0 auto;
			opacity: 0.1;
			color: ${props => props.theme.colors.white.val};
			position: absolute;
			transform: translate(-7%, 13%);
		}
	}

	.modal-header {
		display: flex;
		align-items: center;
		padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
		height: ${props => props.theme.sizes.header.mobile.val};
		background-color: ${props => props.theme.colors.offWhite.val};

		h4 {
			color: inherit;
			text-align: center;
			padding: 0 40px; // To accommodate for the modal close btn
			&.__small-text {
				font-size: 1.2rem;
				line-height: 1.5rem;
			}
		}

		&.__border {
			border-bottom: 2px solid ${props => props.theme.colors.lavender.val};
		}

		&.__floating {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 2;
		}

		&.__left {
			.modal-header-content {
				color: ${props => props.theme.colors.text.val};
				h4 {
					font-size: 1.6rem;
					line-height: 1.8rem;
					color: ${props => props.theme.colors.secondary.val};
					text-align: left;
					padding: 0 40px 0 0;
					${props => props.theme.media.tablet} {
						text-align: center;
					}
				}
			}
		}

		&.__center {
			.modal-header-content {
				h4 {
					color: ${props => props.theme.colors.secondary.val};
					font-weight: 400;
					text-align: center;
				}
			}
		}

		&.__white-close-btn {
			color: ${props => props.theme.colors.white.val};
			.modal-header-content {
				color: inherit;
				& > * {
					color: inherit;
				}
			}
			.close-modal-btn {
				color: inherit;
			}
		}
		&.__text-primary {
			color: ${props => props.theme.colors.primary.val};
			.modal-header-content {
				color: inherit;
				& > * {
					color: inherit;
				}
			}
		}

		&.__text-secondary {
			color: ${props => props.theme.colors.secondary.val};
			.modal-header-content {
				color: inherit;
				& > * {
					color: inherit;
				}
			}
		}

		&.__text-white {
			color: ${props => props.theme.colors.white.val};
			.modal-header-content {
				color: inherit;
				h4 {
					color: inherit;
				}
				& > * {
					color: inherit;
				}
			}
		}

		.close-modal-btn {
			position: absolute;
			z-index: 10;
			top: 50%;
			transform: translateY(-50%);
			right: ${props => props.theme.sizes.gutter.mobile.val};

			${props => props.theme.media.tablet} {
				right: 45px;
			}
		}
		.modal-header-content {
			font-size: ${props => props.theme.sizes.font.h4.val};
			font-weight: 400;
			line-height: 2.5rem;
			color: ${props => props.theme.colors.secondary.val};
			flex-grow: 1;
			z-index: 1;
		}
	}

	.modal-scroll-container {
		overflow-y: auto;
		overflow-x: hidden;
		height: calc(100vh - ${props => props.theme.sizes.header.mobile.val});
		//padding-bottom: 100px;

		.modal-content {
			padding-bottom: 100px;
			height: auto;
			${props => props.theme.media.tablet} {
				overflow: hidden;
				max-width: 550px;
				margin: 0 auto;
				padding: 0 0 2vh 0;
				background: ${props => props.theme.colors.offWhite.val};
				min-height: calc(100vh - ${props => props.theme.sizes.header.mobile.val});
				height: auto;
			}
		}
	}

	/* .__sticky-feet {
		display: block;
	} */
`
