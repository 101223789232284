import { skillLevelsWithInfo, skillRatingWithInfo, Text, useAccordion } from '@merify/ui'
import { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { RatingFieldBtn } from '../../UI/FormFields/Rating.field/Rating.field.btn'
import { TextareaField } from '../../UI/FormFields/Textarea.field'
import { useReview } from '../Review.provider'
import { RatedSkill } from '../Review.provider/useReviewProviderSkills'
import { RateReviewSkillToolbar } from './Review.skill.rate.toolbar'

export const rateReviewSkillStyles = css`
	.rating-inputs {
		padding: 1em 0;
		background-color: ${props => props.theme.colors.lightNeutral.val};
	}

	.level-btns,
	.rating-btns {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 1em ${props => props.theme.sizes.gutter.mobile.val};
		padding-bottom: 0;
		${props => props.theme.media.tablet} {
			padding: 1em 7em;
			gap: 0.5em;
		}
	}

	.level-info,
	.rating-info {
		transition: all ${props => props.theme.times.tranS};
		overflow: hidden;
		display: block;
		padding-top: 1em;
	}

	.comments {
		padding: 1em;
	}
`

const RateReviewSkillView = styled.div`
	${rateReviewSkillStyles}
`

export type RateReviewSkillProps = {
	skill: RatedSkill
	nextSkillId: number
	isLastStep?: boolean
	nextStep?: () => void
}
export const RateReviewSkill = ({
	skill,
	nextSkillId,
	isLastStep,
	nextStep,
}: RateReviewSkillProps): JSX.Element => {
	const { displayRatingVerbiage, rateSkill, addCommentsToSkill, reviewType } = useReview()
	const { skillId, skillName, rating, levelId, skipped, skillComments } = skill
	const levelInfo = skillLevelsWithInfo.find(l => l.levelId === skill.levelId)
	const ratingInfo = skillRatingWithInfo.find(r => r.raw === skill.rating)
	const { toggle } = useAccordion()

	const isKudos = reviewType === 'kudos'

	const nextHandler = useCallback(() => {
		toggle(nextSkillId)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextSkillId])

	const skipHandler = useCallback(() => {
		rateSkill({
			skillId,
			skillName,
			rating: null,
			levelId: null,
			skipped: true,
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [skillId, skillName])

	return (
		<RateReviewSkillView className='review-skill-rate'>
			<div className='rating-inputs'>
				<Text
					className='level-info'
					size='med'
					color={'neutral'}
					dir='center'
					style={{ maxHeight: displayRatingVerbiage ? '40px' : '0' }}
				>
					{levelInfo?.shortDesc || 'Choose a level'}
				</Text>

				<div className='level-btns'>
					{skillLevelsWithInfo.map(l => (
						<RatingFieldBtn
							key={l.levelId}
							onClick={() => {
								rateSkill({
									skillId,
									skillName,
									levelId: l.levelId,
									rating: 0,
									skipped: false,
								})
							}}
							className={`level-btn level-${l.levelId}`}
							label={l.name}
							isSelected={skill.levelId === l.levelId}
							color={l.color}
						/>
					))}
				</div>

				<Text
					className='rating-info'
					size='med'
					color={'neutral'}
					dir='center'
					style={{ maxHeight: displayRatingVerbiage ? '40px' : '0' }}
				>
					{ratingInfo?.description || 'Select a score'}
				</Text>

				<div className='rating-btns'>
					{skillRatingWithInfo.map(r => (
						<RatingFieldBtn
							key={r.rating}
							onClick={() =>
								rateSkill({
									skillId,
									skillName,
									levelId: skill.levelId,
									rating: r.raw,
									skipped: false,
								})
							}
							className={`rating-btn rating-${r.rating}`}
							isSelected={skill.rating === r.raw}
							isDisabled={!skill.levelId}
							color={levelInfo?.color}
						>
							{r.rating}
						</RatingFieldBtn>
					))}
				</div>

				{isKudos ? (
					<div className='comments'>
						<TextareaField
							label='Comments'
							rows={10}
							value={skillComments || ''}
							placeholder={`Comments on ${skillName}`}
							onChange={(e: any) => {
								addCommentsToSkill(skillId, e.target.value)
							}}
						/>
					</div>
				) : null}
			</div>
			<RateReviewSkillToolbar
				skillId={skillId}
				skillName={skillName}
				skipHandler={skipHandler}
				nextHandler={nextHandler}
				isRated={!!rating && !!levelId}
				skipped={!!skipped}
				isLastStep={isLastStep}
				nextStep={nextStep}
			/>
		</RateReviewSkillView>
	)
}
