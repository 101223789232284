import { Async, Card, NotificationType } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetNotificationByIdAndType } from '../../../api/api.notifications'
import { DeleteNotificationCta } from '../Notification.cta.delete'
import { DynamicNotificationParams } from '../Notification.detail'
import { NotificationInfo } from '../Notification.detail.info'
import { notificationStyles } from '../Notification.styles'

export const employerNotificationDetailStyles = css`
	${notificationStyles}
`

const EmployerMatchNotificationDetailView = styled.div`
	${employerNotificationDetailStyles}
`

export type EmployerMatchNotificationDetailProps = DynamicNotificationParams
export const EmployerMatchNotificationDetail = ({
	id,
	cb,
}: EmployerMatchNotificationDetailProps): JSX.Element => {
	const type: NotificationType = 'employerMatchResponseNotifications'

	const results = useGetNotificationByIdAndType({
		notificationId: id,
		notificationType: type,
	})

	const notification = results.data

	return (
		<EmployerMatchNotificationDetailView>
			<Async fetchResults={results}>
				<Card hasGutter>
					{notification ? <NotificationInfo notification={notification} /> : null}
				</Card>
				<div className='single-cta'>
					<DeleteNotificationCta notificationId={id} notificationType={type} cb={cb} />
				</div>
			</Async>
		</EmployerMatchNotificationDetailView>
	)
}
