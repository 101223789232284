import { BaseNotification, formatUTC } from '@merify/ui'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { SwipeListItemChildProps } from '../../UI/SwipeList/SwipeList.item'

export const notificationItemStyles = css`
	button,
	a {
		display: flex;
		align-items: center;
		height: 60px;
		color: ${props => props.theme.colors.secondary.val};
	}
	.date {
		margin-left: auto;
	}

	&.__viewed {
		button {
			color: ${props => props.theme.colors.lightGrey.val};
		}
	}
`

const NotificationItemView = styled.div`
	${notificationItemStyles}
`

type NotificationItemProps = SwipeListItemChildProps & {
	notification: Pick<BaseNotification, 'viewed' | 'notificationEffectiveDate'>
	onClick: () => void
	title: ReactNode
}
export const NotificationItem = ({
	notification,
	title,
	Clickable,
	onClick,
}: NotificationItemProps): JSX.Element => {
	const { viewed, notificationEffectiveDate } = notification

	return (
		<NotificationItemView className={`notification-item${viewed ? ' __viewed' : ''}`}>
			{Clickable ? (
				<Clickable onClick={onClick}>
					<span className='title'>{title}</span>
					<span className='date'>{formatUTC(notificationEffectiveDate, 'MMM dd')}</span>
				</Clickable>
			) : null}
		</NotificationItemView>
	)
}
