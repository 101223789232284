import {
	Async,
	Btn,
	CTA,
	Ctas,
	formatDateForHtmlDateField,
	FormikForm,
	FormikSubmitBtn,
} from '@merify/ui'
import { UseQueryResult } from 'react-query'
import styled, { css } from 'styled-components'
import { JobInputs, useEditJob, useGetJob } from '../../../api/api.jobs'
import {
	CandidateJobFields,
	CandidateJobInfoSchema,
	sanitizeCandidateJobDataForSave,
} from '../Candidate.job/Candidate.job.fields'

export const candidateJobEditStyles = css`
	padding: ${props => props.theme.sizes.gutter.mobile.val};
`

const CandidateJobEditView = styled.div`
	${candidateJobEditStyles}
`

export type CandidateJobEditProps = {
	candidateJobId: number
	cb?: () => void
}
export const CandidateJobEdit = ({ candidateJobId, cb }: CandidateJobEditProps): JSX.Element => {
	const results = useGetJob(candidateJobId)

	const initialVals = results.data?.candidateJob

	const { jobTitle, projectName, jobSummary, startDate, endDate, employer } = initialVals || {}

	const { mutateAsync, isLoading, error } = useEditJob(candidateJobId)
	const submitHandler = async (vals: JobInputs) => {
		try {
			const job = sanitizeCandidateJobDataForSave(vals)
			await mutateAsync(job)
			if (cb) cb()
		} catch (err) {}
	}
	return (
		<CandidateJobEditView>
			<Async fetchResults={[results, { isLoading, error } as UseQueryResult]}>
				{initialVals ? (
					<FormikForm
						validationSchema={CandidateJobInfoSchema}
						initialValues={{
							jobTitle: jobTitle || '',
							projectName: projectName || '',
							jobSummary: jobSummary || '',
							startDate: startDate ? formatDateForHtmlDateField(startDate) : '',
							endDate: endDate ? formatDateForHtmlDateField(endDate) : '',
							employer: employer || '',
							isCurrentlyWorking: endDate ? 'no' : 'yes',
						}}
						onSubmit={submitHandler}
					>
						<CandidateJobFields />
						<Ctas padTop>
							{cb ? (
								<CTA>
									<Btn onClick={cb}>Cancel</Btn>
								</CTA>
							) : null}
							<FormikSubmitBtn ctaConfig={{ displayType: 'solid' }} />
						</Ctas>
					</FormikForm>
				) : null}
			</Async>
		</CandidateJobEditView>
	)
}
