import { Heading, Icon, IconType, Text, YouTubeEmbed, YouTubeVid } from '@merify/ui'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

export const welcomeVideoScreenStyles = css`
	display: flex;
	flex-direction: column;
	justify-content: 'center';
	text-align: center;
	background: ${props => props.theme.colors.secondary.val};
	color: ${props => props.theme.colors.white.val};
	padding: 2rem ${props => props.theme.sizes.gutter.mobile.val};
	min-height: calc(100vh - ${p => p.theme.sizes.header.mobile.val});

	${props => props.theme.media.tablet} {
		min-height: calc(100vh - ${p => p.theme.sizes.header.tablet.val});
	}

	${props => props.theme.media.sdesk} {
		min-height: calc(100vh - ${p => p.theme.sizes.header.sdesk.val});
	}
	& > section {
		margin-bottom: 1em;
	}
	.icon-section {
		.icon {
			width: 78px;
		}
	}
	.cta-wrap {
		padding-bottom: 5vh;
		color: inherit;
		button {
			border-color: ${props => props.theme.colors.white.val};
			color: inherit;
		}
	}
`

const WelcomeVideoScreenView = styled.div`
	${welcomeVideoScreenStyles}
`

export type WelcomeVideoScreenProps = {
	icon: IconType
	title: string
	text: string
	video: YouTubeVid
	children?: ReactNode
}
export const WelcomeVideoScreen = ({
	icon,
	title,
	text,
	video,
	children,
}: WelcomeVideoScreenProps): JSX.Element => {
	return (
		<WelcomeVideoScreenView>
			<section className='icon-section'>
				<Icon type={icon} />
			</section>
			<section className='title-section'>
				<Heading hTag='h2' displayType='section-heading'>
					{title}
				</Heading>
			</section>
			<section className='text-section'>
				<Text size='med'>{text}</Text>
			</section>
			<section className='video-section'>
				<YouTubeEmbed video={video} />
			</section>
			<section className='bottom'>{children}</section>
		</WelcomeVideoScreenView>
	)
}
