import { Btn, Icon, useModalCtrl } from '@merify/ui'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { SkillsToClipboard } from '../../SkillsToClipboard/SkillsToClipboard'
import { ModalFull } from '../../UI/Modal'

export const userMenuSkillsToClipboardStyles = css``

const UserMenuShareSkillsView = styled(Btn)`
	${userMenuSkillsToClipboardStyles}
`

export type UserMenuShareSkillsProps = {
	children?: ReactNode
}
export const UserMenuShareSkills = ({ children }: UserMenuShareSkillsProps): JSX.Element => {
	const [[isOpen, openModal, closeModal]] = useModalCtrl()
	return (
		<>
			<UserMenuShareSkillsView onClick={openModal}>
				<Icon type='skills' />
				{children}
			</UserMenuShareSkillsView>

			<ModalFull isOpen={isOpen} closeHandler={closeModal}>
				<SkillsToClipboard cb={closeModal} />
			</ModalFull>
		</>
	)
}
