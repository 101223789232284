import {
	Async,
	Card,
	CombinedNotification,
	FormattedNotificationFields,
	Heading,
	Icon,
	MerifyIrmaHeadSvg,
	reduce,
	Text,
	useModalCtrl,
} from '@merify/ui'
import { RouteComponentProps } from '@reach/router'
import styled, { css } from 'styled-components'
import { useDeleteNotification, useGetMyNotifications } from '../../../api/api.notifications'
import { formatNotification, FormattedNotification } from '../../../utils/notification.utils'
import { useSetAppHeader } from '../../../utils/useSetAppHeader'
import { AppDrawer, AppDrawerPlaceholder } from '../../global/Drawer.app'
import { NotificationDetail } from '../../Notification.detail'
import { SwipeList, SwipeListItemType } from '../../UI/SwipeList'
import { NotificationItem } from './Notification.item'

export const notificationsScreenStyles = css`
	& > header {
		padding: ${props => props.theme.sizes.gutter.mobile.val};
		.icon {
			width: 25px;
		}
		h4 {
			display: flex;
			align-items: center;

			.qty {
				margin: 0 0.5em;
			}
		}
	}

	.swipe-list {
		.swipeable-list-item {
			.title {
				padding-right: ${props => props.theme.sizes.gutter.mobile.val};
				text-align: left;
			}
			.date {
				min-width: 50px;
				text-align: right;
			}
		}
	}

	.notification-item {
		width: 100%;
		padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
		button {
			width: 100%;
		}
	}
`

const NotificationsScreenView = styled.div`
	${notificationsScreenStyles}
`

const sortNotificationByDate = (a: SwipeListItemType, b: SwipeListItemType) => {
	const aDate = a.val.notificationEffectiveDate
	const bDate = b.val.notificationEffectiveDate
	if (aDate > bDate) return -1
	else if (aDate < bDate) return 1
	else return 0
}

type NotificationsScreenProps = RouteComponentProps
export const NotificationsScreen = (_: NotificationsScreenProps): JSX.Element => {
	useSetAppHeader({
		col1: ['back'],
		col2: [],
	})

	const results = useGetMyNotifications()
	const { data, isLoading } = results

	const notifications = reduce(
		data,
		(arry, val) => [
			...arry,
			...(val
				?.map(notification => formatNotification(notification as CombinedNotification))
				.filter(({ notificationId }: FormattedNotification) => !!notificationId) ?? []),
		],
		[] as FormattedNotification[]
	)

	const { mutate } = useDeleteNotification()
	// const totalUnread = notifications.filter(({viewed}) => !viewed).length
	// const totalRead = notifications.filter(({viewed}) => viewed).length
	const totalQty = notifications.filter(({ viewed }) => !viewed).length

	const handleDelete = async ({
		notificationId,
		notificationType,
	}: FormattedNotificationFields) => {
		mutate({
			notificationId,
			notificationType,
		})
	}

	const [[isAppDrawerOpen, openAppDrawer, closeAppDrawer, currentNotification]] =
		useModalCtrl<FormattedNotification>()

	const notificationsArry: SwipeListItemType[] = notifications
		?.map(notification => ({
			content: (
				<NotificationItem
					notification={notification}
					onClick={() => openAppDrawer(notification)}
					title={notification.title}
				/>
			),
			val: notification,
			key: `${notification.notificationType}-${notification.notificationId}`,
		}))
		.sort(sortNotificationByDate)

	const arryForRender = [...notificationsArry]

	// useEffect(() => {
	// 	return () => {
	// 		closeAppDrawer()
	// 	}
	// }, [])

	return (
		<NotificationsScreenView>
			<header>
				<Heading hTag='h4' displayType='block-heading'>
					<Icon type='bell' />
					<span className='qty'>{totalQty}</span> New Notification{totalQty === 1 ? '' : 's'}
				</Heading>
			</header>
			<div className='notifications-list'>
				<Async fetchResults={results}>
					<Card>
						{notifications?.length ? (
							<SwipeList items={arryForRender} isLoading={isLoading} deleteHandler={handleDelete} />
						) : (
							<Text textType='italic'>You don't have any notifications right now.</Text>
						)}
					</Card>
				</Async>
			</div>
			<AppDrawer
				isOpen={isAppDrawerOpen}
				closeHandler={closeAppDrawer}
				placeholder={
					<AppDrawerPlaceholder heading='Select a notification' svg={<MerifyIrmaHeadSvg />} />
				}
				displayType={arryForRender?.length ? 'two-col' : 'one-col'}
			>
				{currentNotification ? (
					<NotificationDetail
						notificationId={currentNotification.notificationId}
						notificationType={currentNotification.notificationType}
						cb={closeAppDrawer}
					/>
				) : null}
			</AppDrawer>
		</NotificationsScreenView>
	)
}
