import { Accordion, Text } from '@merify/ui'
import styled, { css } from 'styled-components'

export const tutDemographicInfoStyles = css`
	padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
`

const TutDemographicInfoView = styled.div`
	${tutDemographicInfoStyles}
`

export type TutDemographicInfoProps = {}
export const TutDemographicInfo = (): JSX.Element => {
	return (
		<TutDemographicInfoView>
			<Accordion ctrl='Who can see my demographic data?'>
				<Text size='normal' textType='p'>
					The only demographic information shown publicly is displayed through your pronouns on your
					Public Profile, should you choose to provide them.
				</Text>
			</Accordion>

			<Accordion ctrl='Why is Merify asking for this?'>
				<Text size='normal' textType='p'>
					Merify works hard to ensure all users have a fair and equitable experience. To achieve
					this, it’s important for us to understand key demographic information of our users. This
					data helps us assess the experience our users are having with Merify.
				</Text>
			</Accordion>
			<Accordion ctrl='How is my demographic information used?'>
				<Text size='normal' textType='p'>
					Merify endeavors to represent its users authentically and accurately to ensure we get it
					right. If you choose to provide your personal demographic information, it may be used in
					the following ways:
				</Text>
				<ul>
					<li>
						<Text size='normal' textType='p'>
							<strong>For research and development:</strong> To review our products and conduct
							other research with the goal of removing unfair bias from our products and introducing
							new product features that help us achieve our vision.
						</Text>
					</li>
					<li>
						<Text size='normal' textType='p'>
							<strong>For aggregate insights for our members and customers:</strong> Your data may
							be combined with other users’ information to provide aggregate insights to help
							employers and hiring managers in their efforts to identify more diverse candidates.
							For example, Merify may provide aggregate insights to help companies understand the
							diversity of candidates appearing in candidate searches. Your specific demographic
							information will never be disclosed to companies in any of these features or as part
							of candidate search results.
						</Text>
					</li>
					<li>
						<Text size='normal' textType='p'>
							<strong>For aggregate insights to deliver data on global workforce trends:</strong> To
							measure and improve equality, we will provide insights in labor market reports, and
							share insights on what actions users and customers can take for greater equality of
							opportunity.
						</Text>
					</li>
				</ul>
				<Text size='normal' textType='p'>
					You may update your information or remove it at any time in the Edit Profile setting. If
					you remove your data, we will delete it.
				</Text>
			</Accordion>
		</TutDemographicInfoView>
	)
}
