import {
	CandidateDemographics,
	CandidateSkill,
	PrivateCandidate,
	PublicCandidate,
	PublicCandidateWithSkills,
} from '@merify/ui'
import { useCallback, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { authenticatedRequest } from '../utils/api.utils'
import { useCurrentUser } from '../utils/useCurrentUser'
import { useAuthedFetch, useUnauthedFetch } from '../utils/useFetch'
import { fetch } from './fetch'

type SharedProfileSettings = {
	candidateSharedProfileId: number
	candidateId: number
	optedIn: boolean
	token: string
	validFrom: string
	validTo: string
}

/*
 =================================================
	QUERIES
 =================================================
* */

const getMeKey = (uid: number) => `${uid}-candidate`

export const useGetMe = (isLazy?: boolean) => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<PrivateCandidate>(
		getMeKey(uid),
		async () =>
			authenticatedRequest({ uid, token }, async () => fetch('GET', `/Candidates/${uid}`, token)),
		{
			retry: false,
			isLazy,
		}
	)
}

const getPublicUserByIdKey = (uid: number) => `${uid}-candidate-public`

export const useGetPublicUserById = ({ uid }: { uid: number }) => {
	return useAuthedFetch<PublicCandidateWithSkills>(getPublicUserByIdKey(uid), () =>
		fetch('GET', `/Candidates/Public/Candidates/${uid}`, undefined, { noCreds: true })
	)
}

export const useGetUsersBySearch = ({ searchStr }: { searchStr: string }) => {
	const { uid, token } = useCurrentUser()

	const key = `${uid}-users-by-search-${searchStr}`

	const fetchMethod = useCallback(
		() =>
			fetch('POST', `/Candidates/Public/Search`, token, {
				data: {
					SearchString: searchStr,
				},
			}),
		[token, searchStr]
	)

	const results = useAuthedFetch<PublicCandidate[]>(
		key,
		() => authenticatedRequest({ uid, token }, fetchMethod),
		{
			isLazy: true,
		}
	)

	const { isLoading, refetch } = results

	useEffect(() => {
		if (searchStr && searchStr?.length > 1 && !isLoading) refetch()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchStr, isLoading])

	return results
}

const getUserDemographicKey = (uid: number) => `${uid}-demographics`

export const useGetUserDemographic = () => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<CandidateDemographics>(getUserDemographicKey(uid), () =>
		authenticatedRequest({ uid, token }, () =>
			fetch('GET', `/Candidates/${uid}/Demographic`, token)
		)
	)
}

const getSharedProfileKey = (uid: number) => `${uid}-shared-profile`

export const useGetSharedProfileToken = () => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<SharedProfileSettings>(getSharedProfileKey(uid), () =>
		authenticatedRequest({ uid, token }, () =>
			fetch('GET', `/Candidates/${uid}/SharedProfileSettings`, token)
		)
	)
}

const getPublicCandidateKey = (token: string) => `${token}-public-candidate`

export const useGetPublicCandidate = ({ token }: { token: string }) => {
	return useUnauthedFetch<{
		candidate: PublicCandidate
		skills: CandidateSkill[]
	}>(getPublicCandidateKey(token), () =>
		fetch('GET', `/Candidates/Public/Candidates`, undefined, { noCreds: true, params: { token } })
	)
}

// END QUERIES

/*
 =================================================
	MUTATIONS
 =================================================
* */

export type UseUpdateNewUserProgressOptions = {
	profileViewed?: boolean
	skillsViewed?: boolean
	jobsViewed?: boolean
	reviewsViewed?: boolean
}

export const useUpdateNewUserProgress = () => {
	const queryClient = useQueryClient()
	const { uid, token } = useCurrentUser()
	return useMutation(
		`${uid}-update-new-user-progress`,
		(params: UseUpdateNewUserProgressOptions) =>
			authenticatedRequest({ uid, token }, () =>
				fetch('PUT', `/Candidates/${uid}/Viewed`, token, {
					params,
				})
			),
		{
			onSettled: () => queryClient.invalidateQueries(getMeKey(uid)),
		}
	)
}

export type UseUpdateDemographicsArgs = {
	// NOTE: these are actually integers but passed in as strings according to the postman example request
	EthnicityOptionId?: string
	GenderIdentityOptionId?: string
	//SexualOrientationOptionId ?: string
	DisabilityOptionId?: string
	VeteranStatusOptionId?: string
}

export const useUpdateDemographics = () => {
	const queryClient = useQueryClient()
	const { uid, token } = useCurrentUser()
	return useMutation(
		`${uid}-update-demographics`,
		(data: UseUpdateDemographicsArgs) =>
			authenticatedRequest({ uid, token }, () =>
				fetch('POST', `/Candidates/${uid}/Demographic`, token, {
					data,
				})
			),
		{
			onSettled: () => queryClient.invalidateQueries(getUserDemographicKey(uid)),
		}
	)
}

export type UseUpdateProfileArgs = {
	firstName: string
	lastName: string
	industryId: number
	title?: string | null
	bio?: string | null
	pronouns?: string | null
	formerName?: string | null
	city?: string | null
	stateOrProvince?: string | null
	postalCode?: string | null
	country?: string | null
	placeId?: string | null
	latitude: number
	longitude: number
}

export const useUpdateProfile = () => {
	const queryClient = useQueryClient()
	const { uid, token } = useCurrentUser()
	return useMutation(
		`${uid}-update-profile`,
		(data: UseUpdateProfileArgs) =>
			authenticatedRequest({ uid, token }, () =>
				fetch('PUT', `/Candidates/${uid}`, token, {
					data,
				})
			),
		{
			onSettled: () => {
				queryClient.invalidateQueries(getMeKey(uid))
			},
		}
	)
}

export const useUpdateProfilePic = () => {
	const queryClient = useQueryClient()
	const { uid, token } = useCurrentUser()
	return useMutation(
		`${uid}-update-profile-pic`,
		(file: File) =>
			authenticatedRequest({ uid, token }, () =>
				fetch('POST', `/Candidates/${uid}/Image`, token, {
					file,
				})
			),
		{
			onSettled: () => {
				queryClient.invalidateQueries(getMeKey(uid))
				queryClient.invalidateQueries(getPublicUserByIdKey(uid))
			},
			retry: 3,
		}
	)
}

export const useDeleteProfilePic = () => {
	const queryClient = useQueryClient()
	const { uid, token } = useCurrentUser()
	return useMutation(
		`${uid}-delete-profile-pic`,
		() =>
			authenticatedRequest({ uid, token }, () =>
				fetch('DELETE', `/Candidates/${uid}/Image`, token)
			),
		{
			onSettled: () => queryClient.invalidateQueries(getMeKey(uid)),
		}
	)
}

export const useDeleteMyProfile = () => {
	// const queryClient = useQueryClient()
	const { uid, token } = useCurrentUser()
	return useMutation(`${uid}-delete-my-profile`, () =>
		authenticatedRequest({ uid, token }, () =>
			fetch('DELETE', `/Candidates/Internal/${uid}/Deactivate`, token)
		)
	)
}

export const useShareProfile = () => {
	const { uid, token } = useCurrentUser()
	const queryClient = useQueryClient()

	return useMutation(
		`${uid}-share-profile`,
		({ optedIn = true }: { optedIn?: boolean }) =>
			authenticatedRequest({ uid, token }, () =>
				fetch('PUT', `/Candidates/${uid}/SharedProfileSettings`, token, { data: { optedIn } })
			),
		{
			onSettled: () => {
				queryClient.invalidateQueries(getSharedProfileKey(uid))
			},
		}
	)
}

// END MUTATIONS
