import { useEffect } from 'react'
import { HeaderColsInput, useStoreActions } from '../store'

const getHeaderData = (type: HeaderPresets): HeaderColsInput =>
	type === 'default'
		? {
				col1: ['avatar'],
				col2: ['kudos', 'invite', 'notifications'],
		  }
		: {
				col1: [],
				col2: [],
		  }

type HeaderPresets = 'default' | 'public'

export const useSetAppHeader = (header: HeaderColsInput | HeaderPresets) => {
	const { setHeader } = useStoreActions(actions => actions.view)
	const data = typeof header === 'string' ? getHeaderData(header) : header
	useEffect(() => {
		setHeader(data)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
}
