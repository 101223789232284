import {
	Async,
	Btn,
	CandidateSkillIntersect,
	Icon,
	Skill,
	Text,
	useModalCtrl,
	useState,
} from '@merify/ui'
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useGetSkillsBySearch } from '../../../../api/api.skills'
import { SkillRequest } from '../../../global/Skilll.request/Skill.request'
import { ModalAlert } from '../../Modal'
import { SearchDropdown } from '../../SearchDropdown'

const MAX_SKILLS = 40

export const selectSkillFieldStyles = css`
	.disable-add-skills {
		box-shadow: none;
		border: none;
		outline: none;
		background: ${props => props.theme.colors.white.opacity(80).val};
		width: 100%;
		height: 36px;
		position: absolute;
		left: 0;
		z-index: 100;
	}
	.results {
		display: block;
		position: relative;
		//min-height: 20vh;
		width: 100%;
		list-style: none;
		padding-top: 1em;
		margin-bottom: 4em;
		li {
			padding: 0.5em;
			border-bottom: 1px solid ${props => props.theme.colors.lightGrey.val};

			.btn {
				width: 100%;
				text-align: left;
				padding: 0.5em 0;
				.skill-info {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.skill-name {
						padding-right: 1em;
					}

					.icon {
						margin-left: auto;
						color: ${props => props.theme.colors.secondary.val};
						min-width: 27px;
						max-width: 27px;
					}
				}
			}

			&.__excluded {
				.btn {
					&:disabled {
						.skill-name {
							color: ${props => props.theme.colors.lightGrey.val};
						}
					}
				}
			}
		}
	}
`

const SelectSkillFieldView = styled.div`
	${selectSkillFieldStyles}
`

export type SelectSkillFieldProps = {
	onSelect: (skill: Skill) => void
	onNoResults?: React.Dispatch<string>
	previouslySelectedSkills?: CandidateSkillIntersect[]
}
export const SelectSkillField = ({
	onSelect,
	onNoResults,
	previouslySelectedSkills,
}: SelectSkillFieldProps): JSX.Element => {
	const [searchStr, setSearchStr] = useState('', 'searchStr')

	const results = useGetSkillsBySearch({ searchStr })
	const { data, isLoading, error } = results

	const skills = data

	const excludedSkillIds = previouslySelectedSkills?.map(({ skillId }) => skillId)
	const isAtMax = (previouslySelectedSkills?.length || 0) >= MAX_SKILLS
	const [[showModal, openModal, closeModal]] = useModalCtrl()

	useEffect(() => {
		if (onNoResults && searchStr && !isLoading && !error && skills?.length === 0)
			setTimeout(() => {
				onNoResults(searchStr)
			}, 1000)
		else if (onNoResults && (skills?.length || !searchStr)) onNoResults('')

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchStr, skills, isLoading, error])

	return (
		<SelectSkillFieldView>
			{isAtMax ? <button onClick={openModal} className='disable-add-skills' /> : null}
			<ModalAlert isOpen={showModal} closeHandler={closeModal} showAlertBtns='ok'>
				<Text size='med'>You have reached the maximum of {MAX_SKILLS} skills allowed.</Text>
			</ModalAlert>
			<SearchDropdown
				onChange={setSearchStr}
				value={searchStr}
				disabled={isAtMax}
				placeholder='Enter skill name…'
				suppressClickOutsideToClose
			>
				<Async fetchResults={results}>
					<ul className='results'>
						{skills?.length ? (
							skills.map(skill => {
								const isExcluded = excludedSkillIds?.includes(skill.skillId)
								return (
									<li className={isExcluded ? '__excluded' : ''} key={skill.skillId}>
										<Btn disabled={isExcluded} onClick={() => onSelect(skill)}>
											<div className='skill-info'>
												<span className='skill-name'>{skill.name}</span>
												{isExcluded ? <Icon type='checkCircle' /> : <Icon type='plusCircle' />}
											</div>
										</Btn>
									</li>
								)
							})
						) : onNoResults ? null : (
							<Text textType='italic'>No skills found...</Text>
						)}
					</ul>
					<SkillRequest skillStr={searchStr} cb={() => setSearchStr('')} />
				</Async>
			</SearchDropdown>
		</SelectSkillFieldView>
	)
}
