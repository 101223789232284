import {
	Async,
	Btn,
	CTA,
	ErrorBlockErr,
	IncomingReviewRequest,
	isDev,
	ReviewSkill,
	Text,
	useModalCtrl,
	useState,
} from '@merify/ui'
import { createContext, ReactNode, useCallback, useContext, useEffect, useRef } from 'react'
import { UseMutateAsyncFunction, UseQueryResult } from 'react-query'
import {
	KudosSkillReviewInput,
	PostKudosReviewInputs,
	UsePostPublicReviewInputs,
	UsePostReviewInputs,
} from '../../../api/api.reviews'
import { useStoreState } from '../../../store'
import { Page404Redirect } from '../../screens/Page404.redirect'
import { Link } from '../../UI/Link'
import { ModalAlert, ModalFull } from '../../UI/Modal'
import { toaster } from '../../UI/Toaster'
import { ReviewProviderConfirm } from './Review.provider.confirm'
import { ReviewProviderRescinded } from './Review.provider.rescinded'
import { ReviewProviderSuccess } from './Review.provider.success'
import {
	AddCommentsToSkillHandler,
	RatedSkill,
	RateSkillHandler,
	useReviewProviderSkills,
} from './useReviewProviderSkills'

/*
 =================================================
  REVIEW STATE TYPES
 =================================================
* */

type ReviewState = {
	reviewId: number
	reviewType: 'review' | 'kudos'

	skillsToRate: RatedSkill[]
	prevRatedSkills: RatedSkill[]
	isAllRated: boolean
	isLastSkill: boolean // is true when all but one skill have been scored/skipped

	comments: string

	includeJobSelect: boolean
	reviewerJobId: number | null

	isUpdate: boolean
	isPublicReview: boolean

	isSubmitting: boolean
	submitError?: ErrorBlockErr
	submitSuccess: boolean
	reviewRescinded: boolean
	reviewPreviouslyCompleted: boolean

	displayRatingVerbiage: boolean

	toggleRatingVerbiage: () => void
	setReviewerJobId: (jobId: number | null) => void
	rateSkill: RateSkillHandler
	addCommentsToSkill: AddCommentsToSkillHandler
	submitReview: () => void
	cancel?: () => void
	ignore: () => void

	setComments: (comments: string) => void
}

/*
 =================================================
  REVIEW CONTEXT
 =================================================
* */

const initialState: ReviewState = {
	reviewId: 0,
	reviewType: 'review',
	skillsToRate: [],
	prevRatedSkills: [],
	isAllRated: false,
	isLastSkill: false,

	comments: '',

	includeJobSelect: true,
	reviewerJobId: null,

	isUpdate: false,
	isPublicReview: false,

	isSubmitting: false,
	submitSuccess: false,
	reviewRescinded: false,
	reviewPreviouslyCompleted: false,

	displayRatingVerbiage: true,

	toggleRatingVerbiage: () => {},
	setReviewerJobId: () => {},
	rateSkill: () => {},
	addCommentsToSkill: () => {},
	submitReview: () => {},
	ignore: () => {},
	setComments: () => {},
}

const context = createContext(initialState)

const Provider = context.Provider

/*
 =================================================
  REVIEW HOOK
 =================================================
* */

export const useReview = () => useContext(context)

/*
 =================================================
  REVIEW PROVIDER
 =================================================
* */
export type ReviewProviderProps = {
	children?: ReactNode
	reviewFetchRequest?: UseQueryResult<any, unknown>

	reviewMut?: UseMutateAsyncFunction<any, unknown, UsePostReviewInputs, unknown>
	publicReviewMut?: UseMutateAsyncFunction<unknown, unknown, UsePostPublicReviewInputs, unknown>
	kudosMut?: UseMutateAsyncFunction<unknown, unknown, PostKudosReviewInputs, unknown>

	kudosCandidateId?: number
	kudosCandidateJobId?: number
	kudosSkills?: ReviewSkill[]

	mutLoading: boolean
	mutError?: ErrorBlockErr

	ignoreMut?: UseMutateAsyncFunction<unknown, unknown, boolean, unknown>

	cancel?: ReviewState['cancel']
	onAfterSuccess?: () => void
}

export const ReviewProvider = ({
	children,
	reviewFetchRequest,

	reviewMut,
	publicReviewMut,
	kudosMut,

	kudosCandidateId,
	kudosCandidateJobId,
	kudosSkills,

	mutLoading,
	mutError,

	ignoreMut,

	cancel,
	onAfterSuccess,
}: ReviewProviderProps): JSX.Element => {
	const { isSuccess = true } = reviewFetchRequest || {}
	const reviewData = reviewFetchRequest?.data as IncomingReviewRequest
	const reviewId = reviewData?.requestedReview?.requestedReviewId as number
	const existingReview = reviewData?.existingReview
	const existingReviewerJobId = existingReview?.reviewerJobId
	const isUpdate = !!existingReview?.needsUpdate
	const reviewType = kudosMut ? 'kudos' : 'review'

	const { user } = useStoreState(state => state.candidate)

	/**
	 *  SKILLS
	 * */
	const jobSkills = kudosSkills || reviewData?.jobSkills
	const {
		skillsToRate,
		prevRatedSkills,
		isAllRated,
		isLastSkill,
		rateSkill,
		addCommentsToSkill,
		ratedSkills,
	} = useReviewProviderSkills(jobSkills, reviewId)

	/**
	 *  REVIEWER JOB
	 * */

	const [includeJobSelect, setIncludeJobSelect] = useState(true, 'includeJobSelect')
	const [reviewerJobId, setReviewerJobId] = useState<number | null>(null, 'reviewerJobId')

	/**
	 *  COMMENTS
	 * */

	const [comments, setComments] = useState('', 'comments')

	useEffect(() => {
		if (existingReviewerJobId && isUpdate) {
			setReviewerJobId(existingReviewerJobId)
			setIncludeJobSelect(false)
		} else {
			setReviewerJobId(null)
			setIncludeJobSelect(true)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUpdate, existingReviewerJobId])

	/**
	 *  SUBMIT REVIEW
	 * */

	const [[showSuccessModal, openSuccessModal, closeSuccessModal]] = useModalCtrl()
	const [submitSuccess, setSubmitSuccess] = useState(false, 'submitSuccess')

	const submitHandler = useCallback(async () => {
		try {
			isDev && console.log('submit initiated')
			if ((reviewId && (reviewMut || publicReviewMut)) || kudosMut) {
				isDev && console.log('submitting...')
				if (reviewMut) {
					await reviewMut({
						requestedReviewId: reviewId,
						reviewerJobId,
						reviewerRelationshipId: 1, // is this correct?
						comments,
						skillReviews: ratedSkills,
					})
				} else if (publicReviewMut) {
					isDev && console.log('public review')
					await publicReviewMut({
						skillReviews: ratedSkills,
					})
				} else if (kudosMut && kudosCandidateId && kudosCandidateJobId) {
					isDev && console.log('kudos')
					await kudosMut({
						kudosCandidateId,
						kudosCandidateJobId,
						comments,
						kudosSkillReviews: ratedSkills as KudosSkillReviewInput[],
					})
				}
				setSubmitSuccess(true)
				if (onAfterSuccess) onAfterSuccess()
				else openSuccessModal()
			}
		} catch (err) {}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		reviewId,
		reviewerJobId,
		ratedSkills,
		comments,
		kudosMut,
		kudosCandidateId,
		kudosCandidateJobId,
	])
	const [[showSubmitConfirm, submitReview, closeSubmitConfirm]] = useModalCtrl()

	const closeSuccess = () => {
		closeSuccessModal()
		cancel && cancel()
	}

	/**
	 *  IGNORE REVIEW
	 * */
	const [[showIgnoreModal, ignore, closeIgnoreModal]] = useModalCtrl()

	const ignoreHandler = useCallback(async () => {
		try {
			ignoreMut && (await ignoreMut(true))

			cancel && cancel()
		} catch (err) {
			toaster.err('Error ingoring review')
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	/**
	 *  REVIEW RESCINDED/PREVIOUSLY COMPLETED LOGIC
	 * */

	const [reviewRescinded, setRescinded] = useState(false, 'reviewRescinded')
	const [reviewPreviouslyCompleted, setPreviouslyCompleted] = useState(
		false,
		'reviewPreviouslyCompleted'
	)

	useEffect(() => {
		// reviewee had recinded the review
		if (isSuccess && !reviewData && reviewType === 'review') setRescinded(true)
		// review has been previously completed
		else if (
			isSuccess &&
			!isUpdate &&
			!!jobSkills?.every(({ hasExistingSkillReview }) => hasExistingSkillReview)
		) {
			setPreviouslyCompleted(true)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess, reviewData, jobSkills, isUpdate, reviewType])

	/**
	 *  RATING VERBIAGE
	 * */
	const [displayRatingVerbiage, setRatingVerbiage] = useState(true, 'displayRatingVerbiage')
	const toggleRatingVerbiage = useCallback(() => setRatingVerbiage(state => !state), [])

	/**
	 *  PROVIDER STATE
	 * */
	const providerState: ReviewState = {
		reviewType,

		reviewId,

		skillsToRate,
		prevRatedSkills,
		isAllRated,
		isLastSkill,

		comments,

		includeJobSelect,
		reviewerJobId,

		isUpdate,

		isPublicReview: !!publicReviewMut && !reviewMut,

		isSubmitting: mutLoading,
		submitError: mutError,
		submitSuccess,
		reviewRescinded,
		reviewPreviouslyCompleted,

		displayRatingVerbiage,

		toggleRatingVerbiage,
		setReviewerJobId,
		rateSkill,
		addCommentsToSkill,
		submitReview: reviewType === 'review' ? submitReview : submitHandler,
		cancel,
		ignore,
		setComments,
	}

	/**
	 *  CLEAN UP
	 * */
	const isMounted = useRef(true)
	useEffect(() => {
		return () => {
			isMounted.current = false
			setReviewerJobId(null)
		}
	}, [])
	if (!isMounted.current) {
		return <></>
	}

	return (
		<Provider value={providerState}>
			{!showSuccessModal && reviewRescinded ? (
				<ReviewProviderRescinded />
			) : reviewFetchRequest ? (
				<Async fetchResults={reviewFetchRequest} isScreen Page404={Page404Redirect}>
					{reviewType === 'review' ? (reviewId ? children : null) : children}
				</Async>
			) : (
				<>{reviewType === 'review' ? (reviewId ? children : null) : children}</>
			)}

			<ModalFull isOpen={showSuccessModal} closeHandler={closeSuccess}>
				<ReviewProviderSuccess
					reviewType={reviewType}
					cta={
						user ? (
							reviewType === 'review' ? (
								<CTA color='white'>
									<Btn onClick={closeSuccess}>Back to incoming reviews</Btn>
								</CTA>
							) : (
								<CTA color='white'>
									<Btn onClick={closeSuccess}>Okay</Btn>
								</CTA>
							)
						) : (
							<CTA color='white'>
								<Link to='/'>Join Merify!</Link>
							</CTA>
						)
					}
				/>
			</ModalFull>

			<ReviewProviderConfirm
				isOpen={reviewType === 'review' && showSubmitConfirm}
				closeHandler={choice => {
					if (choice) submitHandler()
					closeSubmitConfirm()
				}}
				name={reviewData?.person?.name}
				employer={reviewData?.job?.employer}
			/>

			<ModalAlert
				isOpen={showIgnoreModal}
				closeHandler={closeIgnoreModal}
				asyncConfirmHandler={ignoreHandler}
				showAlertBtns
			>
				{showIgnoreModal ? (
					<Text size='med'>Are you sure you want to ignore this Review Request?</Text>
				) : null}
			</ModalAlert>
		</Provider>
	)
}
