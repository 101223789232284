import { Accordion, Text } from '@merify/ui'
import styled, { css } from 'styled-components'
import { Link } from '../../UI/Link'

export const publicProfileExplainerContent = css`
	padding: ${props => props.theme.sizes.gutter.mobile.val};
`

const PublicProfileExplainerContentView = styled.div`
	${publicProfileExplainerContent}
`

type PublicProfileExplainerContentProps = {}
export const PublicProfileExplainerContent = (
	_: PublicProfileExplainerContentProps
): JSX.Element => {
	return (
		<PublicProfileExplainerContentView>
			<Accordion ctrl='What is my Public Profile?'>
				<Text textType='p' size='small'>
					This is a high level overview of the personal details and skills data you'd like to share
					with the Merify community. Only Merified skills, or skills that are 100% vetted by our
					system, will have their scores visible to the public. You are always in control of what is
					visible on this page.
				</Text>
				<Text textType='p' size='small'>
					This is a high level overview of the personal details and skills data you'd like to share
					with the Merify community. Only Merified skills, or skills that are 100% vetted by our
					system, will have their scores visible to the public. You are always in control of what is
					visible on this page.
				</Text>
			</Accordion>

			<Accordion ctrl='How is my Public Profile used?'>
				<Text textType='p' size='small'>
					This is how your current and former colleagues will know it’s you when you request a
					review from them. It will also help your colleagues find you to ask you to rate them on
					their skills. That’s it. We do not do anything with your personal data outside of the
					Merify system.
				</Text>
			</Accordion>

			<Accordion ctrl='Who can see my Public Profile?'>
				<Text textType='p' size='small'>
					Merify is a public platform, so anyone with a link to your Merify page can view your
					Public Profile. Share the link on resumes, job applications, or social media accounts as a
					way of showcasing your Merified Skills to potential employers! With the release of our
					next product, recruiters and hiring managers will be able to view your Public Profile when
					your skills match the job they’re seeking to fill.
				</Text>
			</Accordion>

			<Text textType='bold'>
				<Text textType='italic'>Other questions? Ask us!</Text>
			</Text>
			<Link to='https://us1.list-manage.com/contact-form?u=53fe19947e4eab86555bf77d1&form_id=bfba5d5a084cc667bafa7c28aabada12'>
				Click here to Contact Us.
			</Link>
		</PublicProfileExplainerContentView>
	)
}
