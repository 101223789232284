import { Btn } from '@merify/ui'
import styled, { css } from 'styled-components'

export const swipeListItemDeleteStyles = css`
	background: ${props => props.theme.colors.err.val};
	color: ${props => props.theme.colors.white.val};
	button {
		display: flex;
		align-items: center;
		height: 100%;
		padding: 1em;
		color: inherit;
		background: inherit;
	}
`

const SwipeListItemDeleteView = styled.div`
	${swipeListItemDeleteStyles}
`

export type SwipeListItemDeleteProps = {}
export const SwipeListItemDelete = (props: SwipeListItemDeleteProps): JSX.Element => {
	return (
		<SwipeListItemDeleteView {...props}>
			<Btn>DELETE</Btn>
		</SwipeListItemDeleteView>
	)
}
