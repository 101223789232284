import { Btn, CTA, ErrorBlock, Heading, Icon, Spinner, useState } from '@merify/ui'
import { AxiosError } from 'axios'
import { ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Modal, ModalProps } from '../Modal'
import { modalAlertStyles } from './Modal.alert.styles'

const ModalAlertView = styled.div`
	${modalAlertStyles}
`

export type ModalAlertProps = ModalProps & {
	closeHandler: (choice: boolean) => void
	asyncConfirmHandler?: () => Promise<any>
	showAlertBtns?: boolean | 'ok' | 'cancel'
	confirmTxt?: ReactNode
	cancelTxt?: ReactNode
	defaultBtn?: 'ok' | 'cancel'
	reverseBtns?: boolean
	className?: string
	noPad?: boolean
	hideCloseBtn?: boolean
	titleTxt?: ReactNode
	children?: ReactNode
}
export const ModalAlert = ({
	children,
	closeHandler,
	asyncConfirmHandler,
	showAlertBtns,
	confirmTxt,
	cancelTxt,
	canClickBg,
	className,
	reverseBtns,
	defaultBtn = 'ok',
	titleTxt,
	noPad,
	hideCloseBtn,
	...props
}: ModalAlertProps): JSX.Element => {
	const isMounted = useRef(true)

	const [{ error, isLoading }, setAsyncData] = useState<{
		error: null | AxiosError
		isLoading: boolean
	}>(
		{
			error: null,
			isLoading: false,
		},
		'asyncData'
	)

	const showBtns = !!showAlertBtns || !!confirmTxt || !!cancelTxt

	const [shouldShow, setShouldShow] = useState(false, 'shouldShow')

	useEffect(() => {
		if (props.isOpen)
			setTimeout(() => {
				setShouldShow(true)
			}, 100)
		else setShouldShow(false)
	}, [props.isOpen])

	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])

	return isMounted ? (
		<Modal {...props} canClickBg={!asyncConfirmHandler} className='alert-modal'>
			<ModalAlertView
				className={`alert-modal${className ? ` ${className}` : ''}${
					reverseBtns ? ' __reverse-btns' : ''
				}${noPad ? ' __no-pad' : ''}${titleTxt ? ' __has-title' : ''}`}
				style={{
					opacity: shouldShow ? 1 : 0,
					transform: `translateY(${shouldShow ? '0' : '10%'})`,
				}}
			>
				{canClickBg ? (
					<div className='alert-modal-overlay' onClick={() => closeHandler(false)} />
				) : null}
				<div className='alert-modal-inner-wrap'>
					<header className='alert-modal-header'>
						{titleTxt ? (
							<div className='title-text'>
								{typeof titleTxt === 'string' ? (
									<Heading hTag='h5'>{titleTxt}</Heading>
								) : (
									titleTxt || null
								)}
							</div>
						) : null}
						{!hideCloseBtn ? (
							<Btn onClick={() => closeHandler(false)} disabled={isLoading}>
								<Icon type='close' />
							</Btn>
						) : null}
					</header>
					<div className='modal-content'>
						{isLoading ? <Spinner isOverlay height='half' /> : children}
					</div>

					<ErrorBlock error={error} display='inline' hideCta />

					{showBtns ? (
						<CTA className='alert-btns'>
							{showAlertBtns === true || showAlertBtns === 'ok' ? (
								<CTA
									pad={false}
									displayType={
										defaultBtn === 'ok' ? 'solid' : defaultBtn === 'cancel' ? 'no-border' : 'normal'
									}
								>
									<Btn
										disabled={isLoading}
										className='confirm-btn'
										onClick={async e => {
											e.preventDefault()
											if (asyncConfirmHandler) {
												try {
													setAsyncData({ error: null, isLoading: true })
													await asyncConfirmHandler()
													setAsyncData({ error: null, isLoading: false })
													closeHandler(true)
												} catch (err: any) {
													setAsyncData({ error: err, isLoading: false })
												}
											} else {
												closeHandler(true)
											}
										}}
									>
										{confirmTxt || 'Okay'}
									</Btn>
								</CTA>
							) : null}
							{showAlertBtns === true || showAlertBtns === 'cancel' ? (
								<CTA
									pad={false}
									displayType={
										defaultBtn === 'cancel' ? 'solid' : defaultBtn === 'ok' ? 'no-border' : 'normal'
									}
								>
									<Btn
										disabled={isLoading}
										className='cancel-btn'
										onClick={e => {
											e.preventDefault()
											closeHandler(false)
										}}
									>
										{cancelTxt || 'Cancel'}
									</Btn>
								</CTA>
							) : null}
						</CTA>
					) : null}
				</div>
			</ModalAlertView>
		</Modal>
	) : (
		<></>
	)
}
