import { Btn, CandidateJob, CTA, ErrorBlock, FormikForm, Wizard } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useAddJob, useAddJobWithSkills } from '../../../api/api.jobs'
import { SuccessScreen } from '../../screens/Success/Success.screen'
import {
	CandidateJobInfoSchema,
	sanitizeCandidateJobDataForSave,
} from '../Candidate.job/Candidate.job.fields'
import { SuccessAnimation } from '../SuccessAnimation'
import { CreateCandidateJobInfo } from './Candidate.job.create.info'
import { CreateCandidateJobSkills } from './Candidate.job.create.skills'

export const createCandidateJobStyles = css``

const CreateCandidateJobView = styled.div`
	${createCandidateJobStyles}
`

type CreateCandidateJobProps = {
	cb?: (job: CandidateJob | null) => void
	includeSkillSelect?: boolean
	skipSuccessScreen?: boolean
}
export const CreateCandidateJob = ({
	cb,
	includeSkillSelect,
	skipSuccessScreen,
}: CreateCandidateJobProps): JSX.Element => {
	const { mutateAsync: createJobWithSkills, ...jobWithSkillsRes } = useAddJobWithSkills()
	const { mutateAsync: createJob, ...jobRes } = useAddJob()

	const isSuccess = jobWithSkillsRes.isSuccess || jobRes.isSuccess

	const newJob: CandidateJob = jobWithSkillsRes.data || jobRes.data

	const initialVals = {
		jobTitle: '',
		projectName: '',
		employer: '',
		jobSummary: '',
		startDate: '',
		isCurrentlyWorking: 'yes',
		endDate: '',
		candidateSkillIds: [],
	}
	return (
		<CreateCandidateJobView>
			<FormikForm
				validationSchema={CandidateJobInfoSchema}
				initialValues={initialVals}
				onSubmit={async ({ candidateSkillIds, ...vals }: typeof initialVals) => {
					try {
						const job = sanitizeCandidateJobDataForSave(vals)
						let newJob
						if (candidateSkillIds?.length)
							newJob = await createJobWithSkills({
								...job,
								candidateSkillIds,
							})
						else newJob = await createJob(job)
						if (cb) cb(newJob)
					} catch (err) {}
				}}
			>
				<>
					<ErrorBlock error={jobRes.error || jobWithSkillsRes.error} display='inline' />

					{isSuccess && !skipSuccessScreen ? (
						<SuccessScreen
							titleText='Job Added!'
							animation={<SuccessAnimation type='addJob' />}
							cta={
								<CTA>
									<Btn onClick={() => (cb ? cb(newJob) : undefined)}>Back to jobs</Btn>
								</CTA>
							}
						/>
					) : includeSkillSelect ? (
						<Wizard>
							<CreateCandidateJobInfo onCancel={() => (cb ? cb(null) : undefined)} />
							<CreateCandidateJobSkills />
						</Wizard>
					) : (
						<CreateCandidateJobInfo onCancel={() => (cb ? cb(null) : undefined)} isSubmit />
					)}
				</>
			</FormikForm>
		</CreateCandidateJobView>
	)
}
