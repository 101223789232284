import { Async, CandidateJobAndSkills, CandidateSkillList, useModalCtrl } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetSkillsWithScores } from '../../../api/api.skills'
import { ModalFull } from '../../UI/Modal'
import { Skill } from '../Skill'

const candidateJobSkillStyles = css``

const CandidateJobSkillsView = styled.div`
	${candidateJobSkillStyles}
`

export type CandidateJobSkillsProps = {
	candidateJobId: number
	job: CandidateJobAndSkills
}
export const CandidateJobSkills = ({ job }: CandidateJobSkillsProps): JSX.Element => {
	const results = useGetSkillsWithScores()

	const skills = results.data?.filter(
		({ skillId }) => !!job.candidateJobSkills.find(skill => skill.skillId === skillId)
	)

	const [[showSkillModal, openSkillModal, closeSkillModal, selectedSkillId]] =
		useModalCtrl<number>()

	return (
		<CandidateJobSkillsView>
			<Async fetchResults={results}>
				{skills ? (
					<CandidateSkillList
						onNavToSkill={candidateSkillId => openSkillModal(candidateSkillId)}
						skills={skills}
					/>
				) : null}
			</Async>
			<ModalFull isOpen={showSkillModal} closeHandler={closeSkillModal}>
				{selectedSkillId ? (
					<Skill candidateSkillId={selectedSkillId} hideBackBtn hideDeleteBtn hideLinkedJobs />
				) : null}
			</ModalFull>
		</CandidateJobSkillsView>
	)
}
