import { Async, Card, CTA, Ctas, NotificationType } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetNotificationByIdAndType } from '../../../api/api.notifications'
import { Link } from '../../UI/Link'
import { DeleteNotificationCta } from '../Notification.cta.delete'
import { DynamicNotificationParams } from '../Notification.detail'
import { NotificationInfo } from '../Notification.detail.info'
import { notificationStyles } from '../Notification.styles'

export const requestedReviewNotificationDetailStyles = css`
	${notificationStyles}

	.delete-notification-cta {
	}
`

const RequestedReviewNotificationDetailView = styled.div`
	${requestedReviewNotificationDetailStyles}
`

export type RequestedReviewNotificationDetailProps = DynamicNotificationParams

export const RequestedReviewNotificationDetail = ({
	id,
	cb,
}: RequestedReviewNotificationDetailProps): JSX.Element => {
	const type: NotificationType = 'requestedReviewNotifications'

	const results = useGetNotificationByIdAndType({
		notificationId: id,
		notificationType: type,
	})

	const notification = results.data
	const { requestedReviewId } = notification || {}

	return (
		<RequestedReviewNotificationDetailView>
			<Async fetchResults={results}>
				<Card>{notification ? <NotificationInfo notification={notification} /> : null}</Card>
				<Ctas>
					<CTA size='sm'>
						<Link to={`/reviews/${requestedReviewId}`}>Go To Review</Link>
					</CTA>
					<DeleteNotificationCta notificationId={id} notificationType={type} cb={cb} />
				</Ctas>
			</Async>
		</RequestedReviewNotificationDetailView>
	)
}
