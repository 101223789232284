import { CandidateSkillIntersect, Skill, useModalCtrl } from '@merify/ui'
import styled, { css } from 'styled-components'
import { ModalAlert } from '../../UI/Modal'
import { SkillAddSelect } from './Skill.add.select'
import { SkillAddSetLevel } from './Skill.add.setLevel'

export const skillAddStyles = css``

const SkillAddView = styled.div`
	${skillAddStyles}
`
export type SkillAddOptions = {
	previouslySelectedSkills?: CandidateSkillIntersect[]
}

export type SkillAddProps = SkillAddOptions & {
	cb: (candidateSkillId: number) => void
}
export const SkillAdd = ({ cb, previouslySelectedSkills }: SkillAddProps): JSX.Element => {
	const [[showModal, openModal, closeModal, skill]] = useModalCtrl<Skill>()

	return (
		<SkillAddView>
			<SkillAddSelect onSelect={openModal} previouslySelectedSkills={previouslySelectedSkills} />
			<ModalAlert
				isOpen={showModal}
				closeHandler={closeModal}
				canClickBg={false}
				noPad
				titleTxt='Set skill level'
				hideCloseBtn
			>
				<SkillAddSetLevel cb={closeModal} cancel={closeModal} skill={skill} />
			</ModalAlert>
		</SkillAddView>
	)
}
