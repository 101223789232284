import { skillLevelsWithInfo } from '@merify/ui'
import 'flickity/css/flickity.css'
import { useEffect, useRef } from 'react'
import Flickity from 'react-flickity-component'
import styled, { css } from 'styled-components'
import { SkillLevelInfo } from './Skill.level.info'

export const skillLevelSetStyles = css`
	color: inherit;
	text-align: center;

	.skill-level-info {
		width: 100vw;

		&.static {
			width: 100%;
		}
	}

	&.__disabled {
		.flickity-button {
			display: none;
		}
	}

	.flickity-button:disabled {
		// disables swiping over disabled buttons - Patch for: MCAT-188
		pointer-events: unset;
	}
`

const SkillLevelSetView = styled.div`
	${skillLevelSetStyles}
`
type SliderProps = Pick<SkillLevelSetProps, 'levelId' | 'onChange'>

const Slider = ({ levelId, onChange }: SliderProps): JSX.Element => {
	const isMounted = useRef(true)
	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])

	const items = skillLevelsWithInfo

	return isMounted.current ? (
		<Flickity
			options={{
				pageDots: false,
				selectedAttraction: 0.04,
				friction: 0.5,
				initialIndex: levelId - 1,
			}}
			disableImagesLoaded
			flickityRef={ref => {
				ref.on('change', () => {
					onChange(ref.selectedIndex + 1)
				})
			}}
		>
			{items.map(level => (
				<SkillLevelInfo key={level.levelId} className='level' levelId={level.levelId} invert />
			))}
		</Flickity>
	) : (
		<></>
	)
}

export type SkillLevelSetProps = {
	levelId: number
	onChange: (levelId: number) => void
	enabled?: boolean
	invert?: boolean
}
export const SkillLevelSet = ({
	levelId,
	enabled,
	invert,
	onChange,
}: SkillLevelSetProps): JSX.Element => {
	return (
		<SkillLevelSetView className={`skill-level-set${!enabled ? ' __disabled' : ''}`}>
			{enabled ? (
				<Slider levelId={levelId} onChange={onChange} />
			) : (
				<SkillLevelInfo className='static' levelId={levelId} invert={invert} />
			)}
		</SkillLevelSetView>
	)
}
