import { ErrorBlock } from '@merify/ui'
import React from 'react'
import styled, { css } from 'styled-components'
import { useGetMe } from '../../api/api.candidate'
import { LoadingScreen } from '../../components/screens/loading/Loading.screen'
import { WelcomeScreen } from '../../components/screens/welcome/Welcome.screen'

export const authWelcomeStyles = css``

const AuthWelcomeView = styled.div`
	${authWelcomeStyles}
`

type AuthWelcomeProps = {}
export const AuthWelcome: React.FC<AuthWelcomeProps> = ({ children }) => {
	const { data, error, isLoading } = useGetMe()
	const { profileViewed } = data || {}
	const notViewed = data && !profileViewed

	return isLoading || !data ? (
		<LoadingScreen />
	) : error ? (
		<ErrorBlock error={error} />
	) : notViewed ? (
		<AuthWelcomeView>
			<WelcomeScreen />
		</AuthWelcomeView>
	) : (
		<>{children}</>
	)
}
