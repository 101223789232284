import { Btn, CTA, Text, useModalCtrl } from '@merify/ui'
import { useCallback } from 'react'
import { useDeleteNotification } from '../../api/api.notifications'
import { ModalAlert } from '../UI/Modal'
import { toaster } from '../UI/Toaster'
import { NotificationDetailProps } from './Notification.detail'

export type DeleteNotificationCtaProps = NotificationDetailProps & {}
export const DeleteNotificationCta = ({
	notificationId,
	notificationType,
	cb,
}: DeleteNotificationCtaProps): JSX.Element => {
	const { mutateAsync } = useDeleteNotification()

	const handleDelete = useCallback(async () => {
		try {
			const res = await mutateAsync({
				notificationId,
				notificationType,
			})
			if (res.error) toaster.err('Unable to delete. Try again later')
			else {
				toaster.ok('Notification deleted!')
				cb && cb()
			}
		} catch (err) {}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notificationId, notificationType])

	const [[show, open, close]] = useModalCtrl()

	return (
		<CTA className='delete-notification-cta' displayType='no-border' color='err' size='sm'>
			<Btn onClick={() => open()}>Delete Notification</Btn>

			<ModalAlert
				isOpen={show}
				closeHandler={close}
				asyncConfirmHandler={handleDelete}
				showAlertBtns
			>
				<Text size='med'>Delete Notification?</Text>
			</ModalAlert>
		</CTA>
	)
}
