import { SimpleSkill, SkillLevelId, skillLevelsWithInfo, Text } from '@merify/ui'
import styled, { css } from 'styled-components'
import { RatingFieldBtn } from '../../UI/FormFields/Rating.field/Rating.field.btn'

export const rateReviewSkillStyles = css`
	.rating-inputs {
		padding: 1em 0;
	}

	.level-btns {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 1em ${props => props.theme.sizes.gutter.mobile.val};
		padding-bottom: 0;
		${props => props.theme.media.tablet} {
			padding: 1em 7em;
			gap: 0.5em;
		}
	}

	.level-info {
		transition: all ${props => props.theme.times.tranS};
		overflow: hidden;
		display: block;
		padding-top: 1em;
	}

	.comments {
		padding: 1em;
	}
`

const AddSkillLevelView = styled.div`
	${rateReviewSkillStyles}
`

export type AddSkillLevelProps = {
	skill: SimpleSkill & { levelId: SkillLevelId | null }
	onSelect: (levelId: SkillLevelId) => void
}
export const AddSkillLevel = ({ skill, onSelect }: AddSkillLevelProps): JSX.Element => {
	// const { skillId, skillName, levelId } = skill
	const levelInfo = skillLevelsWithInfo.find(l => l.levelId === skill.levelId)

	return (
		<AddSkillLevelView className='add-skill-level'>
			<div className='rating-inputs'>
				<Text className='level-info' size='med' color={'neutral'} dir='center'>
					{levelInfo?.shortDesc || 'Choose a level'}
				</Text>

				<div className='level-btns'>
					{skillLevelsWithInfo.map(l => (
						<RatingFieldBtn
							key={l.levelId}
							onClick={() => {
								onSelect(l.levelId)
							}}
							className={`level-btn level-${l.levelId}`}
							label={l.name}
							isSelected={skill.levelId === l.levelId}
							color={l.color}
						/>
					))}
				</div>
			</div>
		</AddSkillLevelView>
	)
}
