import { Icon } from '@merify/ui'
import styled, { css } from 'styled-components'
import { Link } from '../../UI/Link'
import { HR } from '../../UI/Typography'
import { UserMenuDeleteProfile } from './UserMenu.deleteProfile'
import { UserMenuEditProfile } from './UserMenu.editProfile'
import { UserMenuShareProfile } from './UserMenu.shareProfile'
import { UserMenuShareSkills } from './UserMenu.shareSkills'

export const userMenuStyles = css`
	.btn,
	.link {
		display: flex;
		align-items: center;
		white-space: nowrap;
		padding: 0.5em 0;
		color: ${props => props.theme.colors.secondary.val};
		width: 100%;

		.icon {
			width: 2em;
			height: auto;
			margin-right: 0.5em;
		}
	}
`

const UserMenuView = styled.menu`
	${userMenuStyles}
`

export type UserMenuProps = {}
export const UserMenu = (): JSX.Element => {
	return (
		<UserMenuView>
			<UserMenuEditProfile>Edit Profile</UserMenuEditProfile>
			{/* <UserMenuPublicProfile>Preview Public Profile</UserMenuPublicProfile> */}
			<UserMenuShareProfile>Share Public Profile</UserMenuShareProfile>
			<UserMenuShareSkills>Generate Skills for Resume</UserMenuShareSkills>
			<Link to='/logout'>
				<Icon type='logout' /> Logout
			</Link>
			<HR />
			<UserMenuDeleteProfile>Delete My Profile</UserMenuDeleteProfile>
		</UserMenuView>
	)
}
