import { Heading, MerifyIrma404Svg } from '@merify/ui'
import { RouteComponentProps } from '@reach/router'
import styled from 'styled-components'
import { useSetAppHeader } from '../utils/useSetAppHeader'

const Page404View = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	min-height: 70vh;
	justify-content: center;
	align-items: center;

	.irma-404 {
		color: ${props => props.theme.colors.secondary.val};
		width: 90%;
		max-width: 400px;
		height: auto;
		opacity: 0.3;
	}
`
type Page404Props = RouteComponentProps & {}
export const Page404 = (_: Page404Props): JSX.Element => {
	useSetAppHeader('default')
	return (
		<Page404View>
			<Heading hTag='h1' displayType='screen-heading' dir='center' mBot>
				404 Page not found
			</Heading>
			<MerifyIrma404Svg />
		</Page404View>
	)
}
