import { Btn, CTA, Heading, MerifyIrmaWelcomeSkillsSvg, Text, WizardStepProps } from '@merify/ui'
import styled, { css } from 'styled-components'
import { welcomeScreenStepStyles } from './Welcome.screen.step.styles'

export const welcomeScreenStepOneStyles = css`
	${welcomeScreenStepStyles}
`

const WelcomeScreenStepOneView = styled.div`
	${welcomeScreenStepOneStyles}
`

export type WelcomeScreenStepOneProps = {}
export const WelcomeScreenStepOne = ({ ...stepProps }: WelcomeScreenStepOneProps): JSX.Element => {
	const { nextStep, isActive } = stepProps as WizardStepProps
	return (
		<WelcomeScreenStepOneView className={`step step-1 ${isActive ? ' __active' : ''}`}>
			<div className='inner-wrap'>
				<div className='content'>
					<MerifyIrmaWelcomeSkillsSvg />
					<div className='text'>
						<Heading hTag='h2' displayType='screen-heading' dir='center'>
							Welcome to Merify!
						</Heading>
						<Text size='med' color='secondary' dir='center' mTop>
							A new way to grow professionally and share your skill-based work history.
						</Text>
					</div>
					<CTA size='sm' mTop>
						<Btn onClick={nextStep}>Continue</Btn>
					</CTA>
				</div>
			</div>
		</WelcomeScreenStepOneView>
	)
}
