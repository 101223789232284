import { useModalCtrl } from '@merify/ui'
import styled, { css } from 'styled-components'
import { ModalFull } from '../../UI/Modal'
import { SelectKudosCandidate } from '../../UI/Select.kudos.candidate'
import { GiveKudos } from '../Give.kudos/Give.kudos'

const GiveKudosBtnStyles = css``

const GiveKudosBtnView = styled.div`
	${GiveKudosBtnStyles}
`

type GiveKudosBtnProps = {
	className?: string
}
export const GiveKudosBtn = ({ className }: GiveKudosBtnProps): JSX.Element => {
	const [[showModal, openModal, closeModal, candidateId]] = useModalCtrl<number>()

	return (
		<GiveKudosBtnView className={`give-kudos-btn${className ? ` ${className}` : ''}`}>
			<SelectKudosCandidate
				onSelect={val => (val ? openModal(val) : undefined)}
				ctrlIcon='kudosThumb'
				placement='autoVerticalEnd'
				block
				value={null}
				menuTitle={`Give kudos to someone you've previously reviewed`}
			/>
			<ModalFull isOpen={showModal} closeHandler={closeModal} headerContent='Give Kudos'>
				{candidateId ? <GiveKudos candidateId={candidateId} cb={closeModal} /> : null}
			</ModalFull>
		</GiveKudosBtnView>
	)
}
