import {
	Btn,
	CTA,
	Ctas,
	ErrorBlock,
	Icon,
	parseNameFromUser,
	Spinner,
	Text,
	useModalCtrl,
	UserIntersect,
	useState,
} from '@merify/ui'
import styled, { css } from 'styled-components'
import { useDeleteProfilePic, useUpdateProfilePic } from '../../../api/api.candidate'

import { ModalAlert } from '../Modal'
import { toaster } from '../Toaster'

export const imageUploaderStyles = css`
	padding: 20px;
	.upload-pane {
		display: flex;
		align-items: stretch;
		margin-bottom: 2em;

		.preview {
			min-width: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-right: 1rem;
			.icon {
				color: ${props => props.theme.colors.lightGrey.val};
				width: 100%;
				max-width: 40px;
			}
			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}
		.upload-input {
			display: flex;
			align-items: center;
			padding-left: 1rem;
		}
	}
`

const ImageUploaderView = styled.div`
	${imageUploaderStyles}
`

export type ImageUploaderProps = {
	user?: UserIntersect | null
	cb?: () => void
}
export const ImageUploader = ({ user, cb }: ImageUploaderProps): JSX.Element => {
	const { imageUrl } = user || {}
	const [image, setImage] = useState<File | null>(null, 'image')
	const updatePic = useUpdateProfilePic()
	const deletePic = useDeleteProfilePic()
	const [[showDelete, openDelete, closeDelete]] = useModalCtrl()

	const imgType = image?.type
	const invalidImageType =
		imgType && imgType !== 'image/png' && imgType !== 'image/jpeg'
			? 'Unsupported image file. Please select a PNG or JPEG.'
			: null

	const handleImgUpload = async () => {
		try {
			if (image) {
				await updatePic.mutateAsync(image)
				toaster.ok('Image saved!')
			}
			if (cb) cb()
		} catch (err) {}
	}
	const handleDeleteImg = async () => {
		try {
			await deletePic.mutateAsync()
		} catch (err) {}
	}
	return (
		<ImageUploaderView>
			{updatePic.isLoading || deletePic.isLoading ? <Spinner /> : null}

			<ErrorBlock error={updatePic.error || deletePic.error} display='inline' />
			{invalidImageType ? (
				<Text textType='p' color='err'>
					{invalidImageType}
				</Text>
			) : null}
			<div className='upload-pane'>
				<aside className='preview'>
					{(imageUrl || image) && !invalidImageType ? (
						<img
							src={image ? URL.createObjectURL(image) : imageUrl || ''}
							alt={parseNameFromUser(user)}
						/>
					) : (
						<Icon type='magnifyingGlass' />
					)}
				</aside>
				<div className='upload-input'>
					<input
						type='file'
						onChange={e => {
							const file = e.target.files?.length ? e.target.files[0] : null

							if (file) setImage(file)
						}}
					/>
				</div>
			</div>
			<Ctas>
				<CTA pad={false} displayType='solid'>
					<Btn onClick={handleImgUpload} disabled={!image}>
						Save
					</Btn>
				</CTA>

				<Btn onClick={cb}>Cancel</Btn>
			</Ctas>

			{imageUrl ? (
				<CTA displayType='no-border' color='err'>
					<Btn onClick={openDelete}>Remove</Btn>
				</CTA>
			) : null}

			<ModalAlert
				isOpen={showDelete}
				closeHandler={remove => {
					if (remove) handleDeleteImg()
					closeDelete()
				}}
				showAlertBtns
				confirmTxt='Delete'
			>
				<Text size='med'>Are you sure you want to remove your profile pic?</Text>
			</ModalAlert>
		</ImageUploaderView>
	)
}
