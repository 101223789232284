import { useState } from '@merify/ui'
import { createContext, ReactNode, useCallback, useContext } from 'react'
import { UserListItemUser } from '../User.list/User.item'
type Reviewer = UserListItemUser
type ReviewRequestState = {
	candidateJobId: number | null
	reviwers: Reviewer[]
	preselectedUsers: Reviewer[]
	inviteeEmails: string[]
	isSuccessful: boolean
	setJobId: (candidateJobId: number) => void
	setSuccessful: (state: boolean) => void
	addReviewer: (user: Reviewer) => void
	removeReviewer: (candidateId: number) => void
	addInvitee: (email: string) => void
	removeInvitee: (email: string) => void
}

const context = createContext({
	candidateJobId: null,
	reviwers: [],
	preselectedUsers: [],
	inviteeEmails: [],
	isSuccessful: false,
	setJobId: () => {},
	setSuccessful: () => {},
	addReviewer: () => {},
	removeReviewer: () => {},
	addInvitee: () => {},
	removeInvitee: () => {},
} as ReviewRequestState)

const Provider = context.Provider

export const useReviewRequest = () => useContext(context)

type ReviewRequestProviderProps = {
	children: ReactNode
	candidateJobId?: number
	preselectedUsers?: Reviewer[]
}

export const ReviewRequestProvider = ({
	children,
	candidateJobId: jobId,
	preselectedUsers: users,
}: ReviewRequestProviderProps): JSX.Element => {
	const [candidateJobId, setJobId] = useState<number | null>(jobId || null)
	const [reviwers, setReviewers] = useState<Reviewer[]>([])
	const [preselectedUsers] = useState<Reviewer[]>(users || [])
	const [inviteeEmails, setInviteeEmails] = useState<string[]>([])
	const [isSuccessful, setSuccessful] = useState(false)

	const addReviewer = useCallback(
		(u: Reviewer) =>
			setReviewers(state => {
				const newArry = [...state]
				const idx = newArry.findIndex(({ candidateId }) => candidateId === u.candidateId)
				if (idx < 0) newArry.push(u)

				return newArry
			}),
		[]
	)

	const removeReviewer = useCallback((candidateId: number) => {
		setReviewers(state => {
			const newState = [...state]
			const idx = state.findIndex(stateUser => candidateId === stateUser.candidateId)

			if (idx >= 0) newState.splice(idx, 1)

			return newState
		})
	}, [])

	const addInvitee = useCallback(
		(emailStr: string) =>
			setInviteeEmails(state => {
				const email = emailStr.trim().toLowerCase()

				if (state.includes(email)) return state
				else return [...state, email]
			}),
		[]
	)

	const removeInvitee = useCallback(
		(emailStr: string) =>
			setInviteeEmails(state => {
				const email = emailStr.trim().toLowerCase()

				if (state.includes(email)) return state.filter(str => str !== email)
				else return state
			}),
		[]
	)

	return (
		<Provider
			value={{
				candidateJobId,
				reviwers,
				inviteeEmails,
				isSuccessful,
				preselectedUsers,
				setJobId,
				addReviewer,
				removeReviewer,
				addInvitee,
				removeInvitee,
				setSuccessful,
			}}
		>
			{children}
		</Provider>
	)
}
