import { Btn, useState } from '@merify/ui'
import styled, { css } from 'styled-components'
import { CircleQuestionMark } from '../../UI/CircleQuestionMark'
import { ModalFull } from '../../UI/Modal'
import { PublicProfileExplainerContent } from './Tut.publicProfileExplainerContent'
export const publicProfileExplainerStyles = css`
	position: relative;
	//right: ${props => props.theme.sizes.gutter.mobile.val};
`

const PublicProfileExplainerView = styled.div`
	${publicProfileExplainerStyles}
`

type PublicProfileExplainerProps = {}
export const PublicProfileExplainer = (_: PublicProfileExplainerProps): JSX.Element => {
	const [showModal, setShowModal] = useState(false, 'showModal')
	return (
		<PublicProfileExplainerView className='ratings-guide-btn'>
			<Btn onClick={() => setShowModal(true)}>
				<CircleQuestionMark />
			</Btn>
			<ModalFull
				headerContent='Public Profile FAQ'
				headerAlign='left'
				isOpen={showModal}
				closeHandler={() => setShowModal(false)}
			>
				<PublicProfileExplainerContent />
			</ModalFull>
		</PublicProfileExplainerView>
	)
}
