import { CandidateSkillWithScore, Skill, SkillReviewHistory, useState } from '@merify/ui'
import { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { authenticatedRequest } from '../utils/api.utils'
import { useCurrentUser } from '../utils/useCurrentUser'
import { useAuthedFetch } from '../utils/useFetch'
import { fetch } from './fetch'

/**
 *  GET ALL SKILLS WITH SCORES
 * */
const getSkillsWithScoresKey = ({ uid }: { uid: number }) => `${uid}-skills-with-scores`
export const useGetSkillsWithScores = () => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<CandidateSkillWithScore[]>(getSkillsWithScoresKey({ uid }), () =>
		authenticatedRequest({ uid, token }, () =>
			fetch('GET', `/Candidates/${uid}/Skills/Scores`, token)
		)
	)
}

/**
 *  GET SINGLE SKILL WITH SCORE DATA
 * */
const getSkillWithScoresKey = (uid: number, candidateSkillId: number) =>
	`skillWithScores-uid-${uid}-candidateSkillId-${candidateSkillId}`

export const useGetSkillWithScores = (candidateSkillId: number, userId?: number) => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<CandidateSkillWithScore>(
		getSkillWithScoresKey(userId || uid, candidateSkillId),
		() =>
			authenticatedRequest({ uid, token }, () =>
				fetch('GET', `/Candidates/${userId || uid}/Skills/${candidateSkillId}/Scores`, token)
			)
	)
}

/**
 *  GET All SKILLS BY JOB ID
 * */

// NOTE: This does not work yet - skill levelId is missing from payload

// const getSkillsByJobKey = (uid: number, candidateJobId: number) => `${uid}-skills-by-job-${candidateJobId}`
// export const useGetSkillsByJob = (candidateJobId: number) => {
// 	const { uid, token } = useCurrentUser()

// 	return useAuthedFetch<SkillWithScoreType[]>(getSkillsByJobKey(uid, candidateJobId), () =>
// 		authenticatedRequest({ uid, token }, () =>
// 			fetch('GET', `/Candidates/${uid}/JobAndSkills/${candidateJobId}/Scores`, token, {
// 				transformCb: ({ candidateJobSkillsWithScores }: JobSkillsAndScoresType) => {
// 					const skills = candidateJobSkillsWithScores?.map(({ candidateJobSkill, score }) => ({
// 						skillId: candidateJobSkill.skillId,
// 						skillName: candidateJobSkill.skillName,
// 						...score,
// 					}))
// 					console.log('skills: ', skills)
// 					return skills
// 				},
// 			})
// 		)
// 	)
// }

/**
 *  SKILL LEVEL UPDATE PREVIEW
 * */
type UseGetSkillLevelPreviewArgs = {
	skillId: number
	scoreAsSkillLevel: number
}

type GetSkillLevelPreviewOptions = {
	disable?: boolean
}

const getSkillLevelPreviewKey = (uid: number, skillId: number, levelId: number) =>
	`${uid}-skill-level-preview-${skillId}-level-${levelId}`

export const useGetSkillLevelPreview = (
	skillId: number,
	levelId: number,
	options?: GetSkillLevelPreviewOptions
) => {
	const { disable } = options || {}
	const { uid, token } = useCurrentUser()

	const [args, setArgs] = useState({ skillId, scoreAsSkillLevel: levelId }, 'args')

	const { mutate, isLoading, ...results } = useMutation(
		getSkillLevelPreviewKey(uid, args.skillId, args.scoreAsSkillLevel),
		(newData?: UseGetSkillLevelPreviewArgs) =>
			authenticatedRequest({ uid, token }, () =>
				fetch('POST', `/Reviews/Candidates/${uid}/Scores/PeekSkillLevelChange`, token, {
					data: newData || args,
				})
			)
	)

	const data = results.data as CandidateSkillWithScore

	useEffect(() => {
		if (!isLoading && (args.skillId !== skillId || args.scoreAsSkillLevel !== levelId)) {
			setArgs({
				skillId,
				scoreAsSkillLevel: levelId,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [skillId, levelId, args.skillId, args.scoreAsSkillLevel, isLoading])

	useEffect(() => {
		if (args.scoreAsSkillLevel && args.skillId && !disable) {
			mutate(args)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [args.skillId, args.scoreAsSkillLevel])

	return {
		...results,
		isLoading,
		data,
	}
}

/**
 *  GET SKILL HISTORY (previous ratings for the same skill on other reviews)
 * */
const getSkillHistoryKey = (uid: number, skillId: number) =>
	`skillHistory-uid-${uid}-skillId-${skillId}`

export const useGetSkillHistory = (skillId: number) => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<SkillReviewHistory[]>(getSkillHistoryKey(uid, skillId), () =>
		authenticatedRequest({ uid, token }, () =>
			fetch(
				'GET',
				`/Reviews/ClientUtilities/Candidates/${uid}/OutgoingReviews/History/Skills/${skillId}`,
				token
			)
		)
	)
}

/**
 *  GET SKILLS VIA SEARCH STRING
 * */
export const useGetSkillsBySearch = ({ searchStr }: { searchStr: string | null }) => {
	const { uid, token } = useCurrentUser()

	const key = `${uid}-skills-by-search-${searchStr}`

	const fetchMethod = () =>
		fetch('POST', `/Admin/Skills/Search`, token, {
			data: {
				PersonId: uid,
				SearchString: searchStr,
			},
		})

	const results = useAuthedFetch<Skill[]>(
		key,
		() => authenticatedRequest({ uid, token }, fetchMethod),
		{
			isLazy: true,
		}
	)

	const { isLoading, refetch } = results

	useEffect(() => {
		if (searchStr && !isLoading) refetch()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchStr, isLoading])

	return results
}

/**
 *  ADD SKILLS TO CANDIDATE
 * */
type useAddSkillsMutationArgs = {
	skillId: number
	currentSkillLevelId: number
}[]
export const useAddSkillsMutation = () => {
	const { uid, token } = useCurrentUser()
	const queryClient = useQueryClient()

	return useMutation(
		`${uid}-add-skills`,
		(data: useAddSkillsMutationArgs) =>
			authenticatedRequest({ uid, token }, () =>
				fetch('POST', `/Candidates/${uid}/Skills`, token, {
					data,
				})
			),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(getSkillsWithScoresKey({ uid }))
			},
		}
	)
}

/**
 *  REQUEST NEW SKILL
 * */
type UseRequestNewSkillArgs = {
	name: string
}
export const useRequestNewSkill = () => {
	const { uid, token, userTypeId } = useCurrentUser()

	return useMutation(({ name }: UseRequestNewSkillArgs) =>
		authenticatedRequest({ uid, token }, () =>
			fetch('POST', `/Admin/Skills`, token, {
				data: {
					name,
					creatorPersonId: uid,
					creatorPersonTypeId: userTypeId,
				},
			})
		)
	)
}

/**
 *  MAKE SKILL PUBLIC / PRIVATE
 * */
export const useSetSkillVisibility = (candidateSkillId: number) => {
	const { uid, token } = useCurrentUser()
	const queryClient = useQueryClient()

	return useMutation(
		(isPublic: boolean) =>
			authenticatedRequest({ uid, token }, () =>
				fetch('PUT', `/Candidates/${uid}/Skills/${candidateSkillId}/Visible`, token, {
					params: {
						isPublic,
					},
				})
			),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(getSkillWithScoresKey(uid, candidateSkillId))
				queryClient.invalidateQueries(getSkillsWithScoresKey({ uid }))
			},
		}
	)
}
/**
 *  CHANGE SKILL LEVEL
 * */
export const useUpdateSkillLevel = (candidateSkillId: number) => {
	const { uid, token } = useCurrentUser()
	const queryClient = useQueryClient()
	// let levelId: number

	return useMutation(
		(skillLevelId: number) => {
			// levelId = skillLevelId
			return authenticatedRequest({ uid, token }, () =>
				fetch('PUT', `/Candidates/${uid}/Skills/${candidateSkillId}/Level`, token, {
					params: {
						skillLevelId,
					},
				})
			)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(getSkillWithScoresKey(uid, candidateSkillId))

				queryClient.invalidateQueries(getSkillsWithScoresKey({ uid }))

				// TODO: Leaving this here for posterity - rip out later as necessary
				/**
				 *  Manually update state without another roundtrip to the server
				 * */
				// queryClient.setQueryData(getSkillsWithScoresKey({ uid }), data => {
				// 	const skills = data as SkillWithScoreType[]

				// 	if (skills?.length) {
				// 		const skillIdx = skills.findIndex(s => s.candidateSkillId === candidateSkillId)
				// 		if (skillIdx >= 0) {
				// 			skills[skillIdx].currentSkillLevelId = levelId
				// 		}
				// 	}

				// 	return skills
				// })
			},
		}
	)
}

/**
 *  DELETE SKILL
 * */
export const useDeleteSkill = (candidateSkillId: number) => {
	const { uid, token } = useCurrentUser()
	const queryClient = useQueryClient()

	return useMutation(
		() =>
			authenticatedRequest({ uid, token }, () =>
				fetch('DELETE', `/Candidates/${uid}/Skills/${candidateSkillId}`, token)
			),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(getSkillsWithScoresKey({ uid }))
				queryClient.invalidateQueries(getSkillWithScoresKey(uid, candidateSkillId))
			},
		}
	)
}
