import { addSecondsToDate, isBrowser, log, toNumber, UID, UserIdentity } from '@merify/ui'
import { useAuth } from 'react-oidc-context'

export type OIDCUserCreds = {
	uid: UID
	token: string
	userTypeId: number
}
export const getCredsFromOidcUser = (userData?: Partial<UserIdentity> | null) => {
	const { profile, access_token } = (userData as UserIdentity) || ({} as UserIdentity)

	log(false, 'getCredsFromOidcUser - userData:', userData)

	const results = {
		token: access_token,
		uid: toNumber(profile?.personid),
		userTypeId: toNumber(profile?.persontypeid),
	}

	// log('getCredsFromOidcUser | results: ', results)

	return results as OIDCUserCreds
}

export const isOidcUrl = (): boolean => {
	if (isBrowser) {
		const location = window.location
		const searchParams = new URLSearchParams(location.search)
		const hashParams = new URLSearchParams(location.hash.replace('#', '?'))

		return Boolean(
			searchParams.get('code') ||
				searchParams.get('id_token') ||
				searchParams.get('session_state') ||
				hashParams.get('code') ||
				hashParams.get('id_token') ||
				hashParams.get('session_state')
		)
	} else return false
}

export const sanitizeOidcUrl = () => {
	if (isOidcUrl()) {
		// log('sanitizing url...')
		window.history.replaceState(
			{},
			window.document.title,
			`${window.location.origin}${window.location.pathname}`
		)
	}
}

const REDIRECT_KEY = 'r-path'

export const storeInitialRedirect = (path?: string) => {
	if (isBrowser) {
		const redirect = path || window.location.pathname

		localStorage.setItem(REDIRECT_KEY, redirect === '/' ? '/skills' : redirect)
	}
}

export const getInitialRedirectPath = () => {
	let path = '/skills'
	if (isBrowser)
		path =
			localStorage.getItem(REDIRECT_KEY) ||
			process.env.REACT_APP_DEFAULT_LOGIN_REDIRECT ||
			'/skills'

	return path
}

export const clearInitialRedirectPath = () => {
	localStorage.removeItem(REDIRECT_KEY)
}

type GetTokenInfoOptions = {
	printDate?: boolean
	printSec?: boolean
	printMin?: boolean
}
export const useGetTokenExpirationInfo = (options: GetTokenInfoOptions) => {
	const { printDate, printMin, printSec } = options || {}
	const { user } = useAuth()

	const jan1970 = new Date('1970-01-01T00:00:00Z')
	const expirationDate = user?.expires_at ? addSecondsToDate(jan1970, user?.expires_at) : null
	const expiresInSec = user?.expires_in ? `${user?.expires_in} seconds` : null
	const expiresInMin = user?.expires_in ? `${user.expires_in / 60} min` : null

	if (user) {
		printDate && log('AccessToken expires at: ', expirationDate)
		printSec && log('AccessToken expires in: ', expiresInSec)
		printMin && log('AccessToken expires in: ', expiresInMin)
	}

	return {
		expirationDate,
		expiresInSec,
		expiresInMin,
	}
}
