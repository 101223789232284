import { isBrowser } from '@merify/ui'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useEffect } from 'react'
import { clearInitialRedirectPath, getInitialRedirectPath } from '../utils/auth.utils'

type RouterRedirectsProps = RouteComponentProps & {}
export const RouterRedirects = (_: RouterRedirectsProps): JSX.Element => {
	const navigate = useNavigate()

	const path = getInitialRedirectPath()

	navigate(path || '/skills', { replace: true })

	useEffect(() => {
		// Accommodates a race condition where the router might not resolve the redirect.
		setTimeout(() => {
			clearInitialRedirectPath()
			if (isBrowser) window.location.reload()
		}, 200)
		return () => {}
	}, [])
	return <></>
}
