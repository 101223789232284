import { OIDCUserCreds } from './auth.utils'

export const authenticatedRequest = (
	{ uid, token }: Pick<OIDCUserCreds, 'uid' | 'token'>,
	method: () => Promise<any>
): Promise<any> =>
	!uid || !token
		? Promise.reject(
				new Error(
					`Unauthorized request - missing creds | uid: ${uid || 'missing'}, token: ${
						token ? 'found' : 'missing'
					}`
				)
		  )
		: method()
