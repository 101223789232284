import { Lottie } from '@merify/ui'
import styled, { css } from 'styled-components'
import successAnimation from '../../../animations/success.lottie-1.json'

export const successAnimationStyles = css`
	padding: ${props => props.theme.sizes.gutter.mobile.val};
	color: ${props => props.theme.colors.secondary.val};
`

const SuccessAnimationView = styled.div`
	${successAnimationStyles}
`

export type SuccessAnimationProps = {
	type: 'requestReview' | 'review' | 'addJob'
}
export const SuccessAnimation = (_: SuccessAnimationProps): JSX.Element => {
	return (
		<SuccessAnimationView>
			<Lottie file={successAnimation} />
		</SuccessAnimationView>
	)
}
