import { Btn, IncomingReviewRequest, Text, useModalCtrl } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useIgnoreReview } from '../../../api/api.reviews'
import { ModalAlert } from '../../UI/Modal'
import { ReviewJobCard } from '../Review/Review.job.card'

export const reviewsListItemStyles = css`
	transition: all ${props => props.theme.times.tranS};

	&.__selected,
	&:hover {
		background: ${props => props.theme.colors.primary.opacity(5).val};
	}

	& > button {
		width: 100%;
	}
	&.__ignored {
		background: ${props => props.theme.colors.lightGrey.val};
		.circle {
			border-color: ${props => props.theme.colors.secondary.opacity(40).val};
			.value {
				color: ${props => props.theme.colors.secondary.opacity(40).val};
			}
		}
		.label {
			color: ${props => props.theme.colors.secondary.opacity(40).val};
		}
	}

	.review-job-card {
		background: none;
	}
`

const ReviewsListItemView = styled.div`
	${reviewsListItemStyles}
`

type ReviewsListItemProps = {
	reviewData: IncomingReviewRequest
	onSelect?: (review: IncomingReviewRequest) => void
	isSelected?: boolean
}
export const ReviewsListItem = ({
	reviewData,
	onSelect,
	isSelected,
}: ReviewsListItemProps): JSX.Element => {
	const { requestedReview } = reviewData
	const { jobSkills } = reviewData

	const [[showIgnoreModal, openIgnoreModal, closeIgnoreModal]] = useModalCtrl()
	const { mutateAsync } = useIgnoreReview(requestedReview.requestedReviewId)

	const hasSkillForReview = jobSkills.some(({ hasExistingSkillReview }) => !hasExistingSkillReview)
	const needsUpdate = reviewData.existingReview?.needsUpdate

	return (
		<ReviewsListItemView
			className={`review-list-item${requestedReview.ignored ? ' __ignored' : ''}${
				isSelected ? ' __selected' : ''
			}`}
		>
			<Btn
				onClick={() => {
					if (requestedReview.ignored) openIgnoreModal()
					else if (onSelect) onSelect(reviewData)
				}}
				disabled={!hasSkillForReview && !needsUpdate}
			>
				<ReviewJobCard reviewData={reviewData} />
			</Btn>
			<ModalAlert
				isOpen={showIgnoreModal}
				closeHandler={closeIgnoreModal}
				asyncConfirmHandler={async () => mutateAsync(false)}
				showAlertBtns
			>
				<Text size='normal' textType='p'>
					This Review Request must be un-ignored before you can respond to it.
				</Text>
				<Text size='normal' textType='p'>
					Would you like to do that now?
				</Text>
			</ModalAlert>
		</ReviewsListItemView>
	)
}
