import { Btn } from '@merify/ui'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

export const ratingFieldBtnStyles = css<{ color?: string }>`
	button {
		color: ${p => p.color};
	}
	.circle {
		color: inherit;
		height: 60px;
		width: 60px;
		border-radius: 60px;
		border: solid 3px;
		border-color: ${p => p.color};
		display: flex;
		align-items: center;
		justify-content: center;
		background: none;
		transition: all ${p => p.theme.times.tranS};
		font-size: 2.5rem;
	}

	.label {
		padding-bottom: 0.4em;
		display: block;
		color: inherit;
	}

	&.__selected {
		.circle {
			background: ${p => p.color};
			border-color: ${p => p.color};
			color: ${p => p.theme.colors.white.val};
		}
	}

	&.__disabled {
		button {
			color: ${props => props.theme.colors.blueGrey.val};
			&:hover,
			&:focus {
				color: ${p => p.theme.colors.blueGrey.val};
			}
		}
	}
`

const RatingFieldBtnView = styled.div`
	${ratingFieldBtnStyles}
`

export type RatingFieldBtnProps = {
	onClick: () => void
	label?: string
	color?: string
	isSelected: boolean
	isDisabled?: boolean
	className?: string
	children?: ReactNode
}
export const RatingFieldBtn = ({
	label,
	color,
	isSelected,
	isDisabled,
	children,
	onClick,
	className,
}: RatingFieldBtnProps): JSX.Element => {
	return (
		<RatingFieldBtnView
			className={`rating-field-btn${isSelected ? ' __selected' : ''}${
				className ? ` ${className}` : ''
			}${isDisabled ? ' __disabled' : ''}`}
			color={color}
		>
			<Btn onClick={onClick} disabled={isDisabled}>
				{label ? <span className='label'>{label}</span> : null}
				<div className='circle'>{children}</div>
			</Btn>
		</RatingFieldBtnView>
	)
}
