/**
 *  Authenticates and sets the current oidc user
 *  NOTE: This must render before the Auth.user component
 * */

import { isDev, log, useState } from '@merify/ui'
import { useNavigate } from '@reach/router'
import { ReactNode, useEffect, useRef } from 'react'
import { useAuth } from 'react-oidc-context'
import {
	DELETE_PROFILE_KEY,
	DELETE_PROFILE_VAL,
} from '../../components/global/UserMenu/UserMenu.deleteProfile'
import { LoadingScreen } from '../../components/screens/loading/Loading.screen'
import { useStoreActions, useStoreState } from '../../store'
import { storeInitialRedirect, useGetTokenExpirationInfo } from '../../utils/auth.utils'

export type AuthOidcUserProps = {
	children?: ReactNode
}
export const AuthOidcUser = ({ children }: AuthOidcUserProps): JSX.Element => {
	const { oidcUser } = useStoreState(state => state.candidate)
	const { setOidcUser } = useStoreActions(actions => actions.candidate)
	const {
		isLoading,
		user,
		signinRedirect,
		isAuthenticated,
		error,
		signinSilent,
		clearStaleState,
		removeUser,
		revokeTokens,
	} = useAuth()

	const { expirationDate } = useGetTokenExpirationInfo({ printSec: false })

	const tokenExpireStr = expirationDate?.toString() || 'no token found'

	const [refreshTokenErr, setRefreshErr] = useState<null | Error>(null, 'refreshTokenErr')

	const navTo = useNavigate()

	const revoking = useRef(false)
	useEffect(() => {
		const sendUserToLogout = async () => {
			/**
			 *  This does not actually invalidate their tokens on the identity server
			 *  Essentially all we're doing is making sure their session data is cleaned out
			 *  from this url before we redirect them over to match
			 * */
			revoking.current = true
			window.localStorage.removeItem(DELETE_PROFILE_KEY)
			await clearStaleState()
			await removeUser()
			await revokeTokens() // ! This does not seem to result in any request being made against the identity server

			// window.location.replace(`${process.env.REACT_APP_OIDC_AUTHORITY}/Identity/Account/Logout`)
			navTo('/logout', { replace: true })
		}

		if (user?.profile?.persontypeid === '2' && !revoking.current) {
			sendUserToLogout()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.profile?.persontypeid, navTo])

	useEffect(() => {
		const reinitiateLogin = async () => {
			// Persist redirect path to local storage
			storeInitialRedirect()

			// purge stale auth data?
			clearStaleState()

			if (isDev) {
				// For debugging purposes
				window.localStorage.setItem('Auth-oidc.action', 'triggering sign in redirect...')
			}

			return signinRedirect()
		}

		const authenticate = () => {
			const isProfileDeleted =
				window.localStorage.getItem(DELETE_PROFILE_KEY) === DELETE_PROFILE_VAL

			if (isProfileDeleted) {
				window.localStorage.removeItem(DELETE_PROFILE_KEY)
				clearStaleState()
				removeUser()
				revokeTokens()
				signinRedirect()
				return
			}

			if (error) {
				// We are not currently attempting to authenticate
				// AND the auth client threw an error
				// NOTE: It's unclear what errors will end up here - most methods seem to throw directly in their promises rather than putting them here

				if (isDev) window.localStorage.setItem('Auth-oidc.auth-err', error.message)

				log.err('Auth.oidc.user - auth error: ', error)

				log('Auth.oidc.user - Reinitiating authentication after user after auth error...')
				reinitiateLogin()
			} else if (refreshTokenErr) {
				// We are not currently attempting to authenticate
				// AND we attempted to refresh the token but it threw an error

				log('Auth.oidc.user - Reinitiating authentication after token refresh error...')
				reinitiateLogin()
			} else if (user && user.expired) {
				// We are not currently attempting to authenticate
				// AND the oidc session has expired

				// We attempt to renew expired token
				if (isDev) {
					window.localStorage.setItem('Auth-oidc.action', 'renewing token...')
					window.localStorage.setItem('Auth-oidc.token-expiration', tokenExpireStr)
				}
				signinSilent().catch(err => setRefreshErr(err))
			} else if (!isAuthenticated) {
				// We are not currently attempting to authenticate
				// AND we are not authenticated

				if (!user) {
					// no user (or token) is available at all

					log('Auth.oidc.user - Authenticating user as normal...')
					signinSilent().catch(err => setRefreshErr(err))
				} else {
					// we have a user but are somehow not authenticated

					signinSilent().catch(err => setRefreshErr(err))
				}
			} else if (user && !oidcUser) {
				// We are not currently attempting to authenticate
				// AND we have a valid oidc session that has not expired

				// This refresh forces the server to add a session cookie
				// For some reason the server is not adding a cookie on the initial login
				signinSilent().catch(err => {
					log.warn('Auth.oidc.user - Error while doing prememptive token refresh: ', err)
				})

				// We set the oidc data to global state
				setOidcUser(user as any)
			}
		}

		if (!isLoading) authenticate()
	}, [
		user,
		isLoading,
		isAuthenticated,
		error,
		tokenExpireStr,
		refreshTokenErr,
		navTo,
		clearStaleState,
		oidcUser,
		removeUser,
		revokeTokens,
		setOidcUser,
		signinRedirect,
		signinSilent,
	])

	return <>{isAuthenticated ? children : <LoadingScreen />}</>
}
