import styled, { css } from 'styled-components'
import { SkillAddOptions } from '.'
import { SelectSkillField } from '../../UI/FormFields/Select.skills.field'
import { SkillAddSelectProps } from './Skill.add.select'

export const skillAddHardSkillsStyles = css`
	padding: ${props => props.theme.sizes.gutter.mobile.val};
	.ctrl-group {
		position: relative;
		transform: unset;
	}
`

const SkillAddHardSkillsView = styled.div`
	${skillAddHardSkillsStyles}
`

export type SkillAddHardSkillsProps = SkillAddOptions & SkillAddSelectProps & {}
export const SkillAddHardSkills = ({
	onSelect,
	previouslySelectedSkills,
}: SkillAddHardSkillsProps): JSX.Element => {
	return (
		<SkillAddHardSkillsView>
			<SelectSkillField onSelect={onSelect} previouslySelectedSkills={previouslySelectedSkills} />
		</SkillAddHardSkillsView>
	)
}
