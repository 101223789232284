import {
	Async,
	Btn,
	Card,
	formatUTC,
	parseNameFromUser,
	PersonIntersect,
	ReviewPublicPerson,
	Text,
	useModalCtrl,
	UserIntersect,
} from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetReviewsByJob } from '../../../api/api.reviews'
import { ModalFull } from '../../UI/Modal'
import { Avatar } from '../Avatar'
import { CandidatePublicProfile } from '../Candidate.public.profile'

export const candidateJobReviewsStyles = css`
	.buttons {
		.btn {
			width: 100%;
			padding: 0.5em ${props => props.theme.sizes.gutter.mobile.val};
			display: flex;
			align-items: center;
			border-bottom: solid 1px ${props => props.theme.colors.lightGrey.light(0).val};
			:last-child {
				border: none;
			}
		}
		.text {
			span {
				display: block;
				text-align: left;
				padding: 0.3em 1em;
			}
			.name {
				font-weight: bold;
			}
		}
	}
	.no-requests-text {
		display: block;
		padding: 1em ${props => props.theme.sizes.gutter.mobile.val};
	}
`

const CandidateJobReviewsView = styled.div`
	${candidateJobReviewsStyles}
`

export type CandidateJobReviewsProps = {
	candidateJobId: number
}
export const CandidateJobReviews = ({ candidateJobId }: CandidateJobReviewsProps): JSX.Element => {
	const results = useGetReviewsByJob(candidateJobId)
	const reviews = results.data

	const [[showModal, openModal, closeModal, modalPerson]] = useModalCtrl<ReviewPublicPerson>()

	const sortedReviews = reviews?.length ? [...reviews] : reviews

	sortedReviews?.sort((a, b) => {
		const aDate = a.requestedReview.createdUtc
		const bDate = b.requestedReview.createdUtc

		return aDate > bDate ? -1 : +1
	})

	return (
		<CandidateJobReviewsView>
			<Async fetchResults={results}>
				<Card>
					{sortedReviews?.length ? (
						<div className='buttons'>
							{sortedReviews?.map(({ internalPerson, requestedReview }) => (
								<Btn key={internalPerson?.personId} onClick={() => openModal(internalPerson)}>
									{/* @ts-ignore MIGRATION */}
									<Avatar user={internalPerson as UserIntersect} />
									<div className='text'>
										<span className='name'>
											{parseNameFromUser(internalPerson as UserIntersect, { useEmail: true })}
										</span>
										<span className='date'>{formatUTC(requestedReview.createdUtc)}</span>
									</div>
								</Btn>
							))}
						</div>
					) : (
						<Text className='no-requests-text'>
							You haven't sent any review requests out for this job yet...
						</Text>
					)}
				</Card>
			</Async>
			<ModalFull
				isOpen={showModal}
				closeHandler={closeModal}
				headerAlign='left'
				headerContent={parseNameFromUser(modalPerson as PersonIntersect)}
			>
				{modalPerson ? (
					<CandidatePublicProfile
						candidateId={modalPerson.personId}
						view='public'
						suppressReviewCta
					/>
				) : null}
			</ModalFull>
		</CandidateJobReviewsView>
	)
}
