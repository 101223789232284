import {
	ErrorBlock,
	FormikForm,
	FormikSelect,
	FormikSubmitBtn,
	FormikTextarea,
	Heading,
	Text,
} from '@merify/ui'
import { RouteComponentProps } from '@reach/router'
import styled, { css } from 'styled-components'
import * as Yup from 'yup'
import { useSubmitFeedback } from '../../../api/api.admin'
import { useSetAppHeader } from '../../../utils/useSetAppHeader'
import { BackBtn } from '../../global/Back.btn/Back.btn'

export const feedbackScreenStyles = css`
	padding: 0 ${props => props.theme.sizes.gutter.mobile.val};

	& > header {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1.5em 0;
		margin-bottom: 2em;

		.back-btn {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		h1 {
			font-size: 1.8rem;
			color: ${props => props.theme.colors.secondary.val};
			font-weight: bold;
		}
	}

	.success-block {
		padding: 2em 0;
		text-align: center;
	}

	form {
		.field-wrap {
			padding-bottom: 1em;
		}
		label {
			color: ${props => props.theme.colors.secondary.val};
			font-size: 1rem;
			padding-bottom: 5px;
		}
		select,
		textarea {
			background: white;
		}
		textarea {
			resize: none;
			min-height: 200px;
		}
	}
`

const topicOptions = [
	{
		val: '',
		txt: 'Select a topic...',
	},
	{
		val: 'Editing skills',
	},
	{
		val: 'Editing jobs',
	},
	{
		val: 'Requesting a review',
	},
	{
		val: 'Responding to a review',
	},
	{
		val: 'Editing profile',
	},
	{
		val: 'Sharing profile',
	},
	{
		val: 'Login/logout',
	},
	{
		val: 'Notifications/emails',
	},
	{
		val: 'How do I meet Irma?',
	},
	{
		val: 'Other issue',
	},
]

const schema = Yup.object().shape({
	topic: Yup.string().required('Please select a topic'),
	message: Yup.string().required('Please let us know how we can improve'),
})

const FeedbackScreenView = styled.div`
	${feedbackScreenStyles}
`

type FeedbackScreenProps = RouteComponentProps & {}

export const FeedbackScreen = (_: FeedbackScreenProps): JSX.Element => {
	useSetAppHeader('default')
	const { mutateAsync, isSuccess, error } = useSubmitFeedback()
	return (
		<FeedbackScreenView>
			<header className='feedback-screen-header'>
				<BackBtn />
				<Heading hTag='h1' displayType='dialog-heading'>
					Send Feedback
				</Heading>
			</header>
			{isSuccess ? (
				<div className='success-block'>
					<Text size='med'>
						Thanks for the feedback! We're aiming for Expert - 5, and every bit of input from our
						community helps us get there!
					</Text>
				</div>
			) : (
				<FormikForm
					validationSchema={schema}
					initialValues={{
						topic: '',
						message: '',
					}}
					onSubmit={async data => {
						try {
							await mutateAsync(data)
						} catch (err) {}
					}}
				>
					<ErrorBlock error={error} display='inline' hideCta />
					<FormikSelect
						name='topic'
						options={topicOptions}
						defaultTxt='Select Topic...'
						config={{}}
					/>
					<FormikTextarea name='message' config={{ label: `What's up?` }} />
					<FormikSubmitBtn>Send</FormikSubmitBtn>
				</FormikForm>
			)}
		</FeedbackScreenView>
	)
}
