import styled, { css } from 'styled-components'
import { useAppDrawer } from '../../Drawer.app'

export const appDrawerInColDrawerStyles = css`
	height: calc((100vh + 10px) - ${props => props.theme.sizes.header.ldesk.val});
	max-width: 550px;
	width: 100%;
	transition: all ${props => props.theme.times.tranM};
	left: -20px;

	&.__closed {
		opacity: 0;
		* {
			pointer-events: none;
		}
	}
	&.__open {
		opacity: 1;
		left: 0;
	}
`

const AppDrawerInColDrawerView = styled.div`
	${appDrawerInColDrawerStyles}
`

export type AppDrawerInColDrawerProps = {}
export const AppDrawerInColDrawer = (): JSX.Element => {
	const { isAppDrawerOpen, drawerContent } = useAppDrawer()

	return (
		<AppDrawerInColDrawerView className={`app-drawer${isAppDrawerOpen ? ' __open' : ' __closed'}`}>
			{drawerContent}
		</AppDrawerInColDrawerView>
	)
}
