import { Async, Btn, Card, FormikSelect, Heading, map, trimEnd, useModalCtrl } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetAllDemographics } from '../../../../api/api.admin'
import { TutDemographicInfo } from '../../../global/Tut.demographicInfo'
import { CircleQuestionMark } from '../../CircleQuestionMark'
import { ModalFull } from '../../Modal'

export const demographicsFieldsStyles = css`
	.card {
		.card-inner {
			background: ${props => props.theme.colors.lavender.opacity(25).val};
		}
	}

	h4 {
		color: ${props => props.theme.colors.secondary.val};
		font-size: 1.3rem;
		display: flex;
		align-items: center;
		button {
			margin-left: 10px;
			max-width: 30px;
			h5 {
				font-size: 1.7rem;
				color: ${props => props.theme.colors.secondary.val};
			}
		}
	}
`

const DemographicsFieldsView = styled.div`
	${demographicsFieldsStyles}
`

const parseLabel = (key: string) =>
	key === 'ethnicityOptions'
		? 'Ethnicity'
		: key === 'genderIdentityOptions'
		? 'Gender'
		: key === 'sexualOrientationOptions'
		? 'Sexual Orientation'
		: key === 'disabilityOptions'
		? 'Disability'
		: key === 'veteranStatusOptions'
		? 'Veteran Status'
		: ''

export type DemographicsFieldsProps = {}
export const DemographicsFields = (): JSX.Element => {
	const results = useGetAllDemographics()
	const [[showModal, openModal, closeModal]] = useModalCtrl()

	const { data } = results
	const selects =
		data &&
		map(data, (values, key) => ({
			key,
			name: `demographics.${trimEnd(key, 's')}Id`,
			options: values.map(({ optionId, optionName }) => ({
				val: optionId,
				txt: optionName,
			})),
			label: parseLabel(key),
		}))

	return (
		<DemographicsFieldsView className='demographic-fields'>
			<Heading hTag='h4' displayType='block-heading'>
				Demographic Info
				<Btn onClick={openModal}>
					<CircleQuestionMark />
				</Btn>
			</Heading>

			<Async fetchResults={results}>
				<Card hasGutter padBottom background='none'>
					{selects?.length
						? selects.map(({ label, ...item }) => (
								<FormikSelect defaultTxt {...item} config={{ label }} />
						  ))
						: null}
				</Card>
			</Async>

			<ModalFull
				isOpen={showModal}
				closeHandler={closeModal}
				headerContent='Demographic Info FAQ'
				headerAlign='left'
			>
				<TutDemographicInfo />
			</ModalFull>
		</DemographicsFieldsView>
	)
}
