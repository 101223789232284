import { Async, Btn, CTA, Icon, useModalCtrl } from '@merify/ui'
import { Link, RouteComponentProps } from '@reach/router'
import styled, { css } from 'styled-components'
import { useGetMe } from '../../../../api/api.candidate'
import { useSetAppHeader } from '../../../../utils/useSetAppHeader'
import { ModalFull } from '../../../UI/Modal'
import { CandidatePublicProfile } from '../../../global/Candidate.public.profile'
import { CandidateDemographics } from './Candidate.profile.demographics'
import { CandidateProfileEditForm } from './Candidate.profile.edit.form'
import { CandidateProfileInfo } from './Candidate.profile.info'

const URL = process.env.REACT_APP_OIDC_AUTHORITY

export const CandidateProfileScreenStyles = css`
	.settings-btn {
		position: absolute;
		right: ${props => props.theme.sizes.gutter.mobile.val};
		top: 20px;
		z-index: 10;
		color: ${props => props.theme.colors.secondary.val};
	}
	.row {
		margin: 20px 0;
	}
	p {
		margin: 0;
		&.label {
			font-size: 0.8rem;
			color: ${props => props.theme.colors.grey.val};
			margin: 0;
			line-height: 15px;
		}
	}
	.cta-wrap {
		padding-top: 3em;
	}
`

const CandidateProfileScreenView = styled.div`
	${CandidateProfileScreenStyles}
`

type CandidateProfileScreenProps = RouteComponentProps & {
	id?: string
}
export const CandidateProfileScreen = ({ id }: CandidateProfileScreenProps): JSX.Element => {
	const [[showEditModal, openEditModal, closeEditModal]] = useModalCtrl()
	const [[showPublicModal, openPublicModal, closePublicModal]] = useModalCtrl()
	const [[showSettingsModal, openSettingsModal, closeSettingsModal]] = useModalCtrl()

	const results = useGetMe()

	const { data: candidateData, isLoading: candidateLoading } = results

	// const { data: skillsData, error: skillsErr, isLoading: skillsLoading } = useGetSkillsWithScores()
	useSetAppHeader({
		col1: ['back'],
		col2: ['kudos', 'invite', 'notifications'],
	})
	return (
		<CandidateProfileScreenView>
			<Async fetchResults={results}>
				<Btn className='settings-btn' onClick={openSettingsModal}>
					<Icon type='gear' />
				</Btn>

				<CandidateProfileInfo view='private' candidateData={candidateData} />
				{id ? <CandidateDemographics candidateId={id} /> : null}

				{/* {skillsLoading ? null : <SkillsHeader />}
				<SkillsList skills={skillsData} error={skillsErr} isLoading={skillsLoading} /> */}
			</Async>
			<ModalFull
				headerContent=''
				headerAlign='center'
				isOpen={showSettingsModal}
				closeHandler={closeSettingsModal}
			>
				{candidateLoading ? null : (
					<CTA layout='vert'>
						<Btn
							onClick={() => {
								openEditModal()
								closeSettingsModal()
							}}
						>
							EDIT PROFILE
						</Btn>
						<Btn
							onClick={() => {
								openPublicModal()
								closeSettingsModal()
							}}
						>
							VIEW PUBLIC PROFILE
						</Btn>
						<Link to='/logout'>
							LOGOUT
							<Icon type='logout' />
						</Link>
						{/* // TODO: Put in correct url */}
						<CTA pad={false} displayType='no-border' color='err'>
							<Link to={`${URL}/Identity/Account/Manage/DeletePersonalData`}>DELETE PROFILE</Link>
						</CTA>
					</CTA>
				)}
			</ModalFull>
			<ModalFull
				isOpen={showPublicModal}
				closeHandler={closePublicModal}
				headerContent='Public profile'
			>
				{results.data ? (
					<CandidatePublicProfile view='preview' candidateId={results.data.candidateId} />
				) : null}
			</ModalFull>
			<ModalFull isOpen={showEditModal} closeHandler={closeEditModal} headerContent='Edit profile'>
				{id ? <CandidateProfileEditForm cb={closeEditModal} /> : null}
			</ModalFull>
		</CandidateProfileScreenView>
	)
}
