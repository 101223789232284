import { Btn, Heading, Icon, MerifyMSvg, useState } from '@merify/ui'
import { ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { Modal, ModalProps } from '../Modal'
import { modalFullStyles } from './Modal.full.styles'

const ModalFullView = styled.div`
	${modalFullStyles}
`

export type ModalFullProps = ModalProps & {
	closeHandler: () => void
	headerContent?: ReactNode
	headerAlign?: 'left' | 'center'
	headerColor?: 'primary' | 'white' | 'secondary'
	floatingHeader?: boolean
	invertCloseBtn?: boolean
	children?: ReactNode
}

export const ModalFull = ({
	children,
	closeHandler,
	headerContent,
	headerAlign = 'center',
	headerColor,
	floatingHeader,
	invertCloseBtn,
	...props
}: ModalFullProps): JSX.Element => {
	const [shouldShow, setShouldShow] = useState(false, 'shouldShow')

	useEffect(() => {
		if (props.isOpen)
			setTimeout(() => {
				setShouldShow(true)
			}, 100)
		else setShouldShow(false)
	}, [props.isOpen])

	return (
		<Modal {...props} canClickBg={false} className='full-modal'>
			<ModalFullView
				className='full-modal-content'
				style={{
					opacity: shouldShow ? 1 : 0,
					transform: `translateY(${shouldShow ? '0' : '15%'})`,
				}}
			>
				<div className='bg-m'>
					<MerifyMSvg />
				</div>
				<header
					className={`modal-header${
						headerAlign === 'left' ? ' __left' : headerAlign === 'center' ? ' __center' : ''
					}${floatingHeader ? ' __floating' : ''}${invertCloseBtn ? ' __white-close-btn' : ''}${
						headerColor === 'primary'
							? ' __text-primary'
							: headerColor === 'secondary'
							? ' __text-secondary'
							: headerColor === 'white'
							? ' __text-white'
							: ''
					}${!headerContent ? '' : ' __border'}`}
				>
					{headerContent ? (
						<div className={`modal-header-content`}>
							{typeof headerContent === 'string' ? (
								<Heading
									hTag='h4'
									displayType='block-heading'
									className={`${headerContent.length > 44 ? ' __small-text' : ''}`}
								>
									{headerContent}
								</Heading>
							) : (
								headerContent
							)}
						</div>
					) : null}
					<Btn className='close-modal-btn' onClick={closeHandler}>
						<Icon type='close' />
					</Btn>
				</header>
				<div className='modal-scroll-container'>
					<div className='modal-content'>{children}</div>
				</div>
			</ModalFullView>
		</Modal>
	)
}
