import { Btn, Heading, Icon, Text, useModalCtrl } from '@merify/ui'
import styled, { css } from 'styled-components'
import { ModalFull } from '../../UI/Modal'
import { useReview } from '../Review.provider'
import { RatedSkill } from '../Review.provider/useReviewProviderSkills'
import { ReviewSkillHistory } from './Review.skill.history'

export const rateReviewSkillToolbarStyles = css`
	display: flex;
	align-items: stretch;
	width: 100%;
	height: 38px;
	background-color: ${props => props.theme.colors.paleGrey.val};

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1em 2em;
		border: solid 1px ${props => props.theme.colors.midNeutral.val};
		border-right: none;
		color: ${props => props.theme.colors.primary.val};
		flex: 1;

		&:first-child {
			border-left: none;
		}

		&.next-btn {
			//flex-grow: 1;
		}

		&.info-btn,
		&.history-btn {
			.icon {
				width: 28px;
			}
		}

		&.skip-btn {
			.icon {
				margin-left: 0.5em;
			}
		}

		&.next-btn {
			.icon {
				margin-left: 0.5em;
				width: 10px;
				transform: rotateZ(-90deg);
			}
		}

		&.next-btn {
			border-color: ${props => props.theme.colors.primary.val};
			background: ${props => props.theme.colors.primary.val};
			color: ${props => props.theme.colors.white.val};

			&:disabled {
				border-color: ${props => props.theme.colors.midNeutral.val};
				background: none;
				color: ${props => props.theme.colors.midNeutral.val};
			}
		}
	}
`

const RateReviewSkillToolbarView = styled.div`
	${rateReviewSkillToolbarStyles}
`

export type RateReviewSkillToolbarProps = Pick<RatedSkill, 'skillId' | 'skillName'> & {
	skipHandler: () => void
	nextHandler: () => void
	nextStep?: () => void
	isLastStep?: boolean
	isRated: boolean
	skipped: boolean
}
export const RateReviewSkillToolbar = ({
	skipHandler,
	nextHandler,
	nextStep,
	isLastStep,
	skillId,
	skillName,
	isRated,
	skipped,
	...stepProps
}: RateReviewSkillToolbarProps): JSX.Element => {
	const { isAllRated, isLastSkill, submitReview, isPublicReview, reviewType } = useReview()
	const [[showModal, openModal, closeModal]] = useModalCtrl()

	return (
		<RateReviewSkillToolbarView className='review-skill-rate-toolbar'>
			{isPublicReview ? null : (
				<Btn className='history-btn' onClick={openModal}>
					<Text size='normal'>History</Text>
				</Btn>
			)}

			{reviewType === 'review' ? (
				<Btn
					className='skip-btn'
					onClick={() => {
						skipHandler()
						!isLastSkill && nextHandler()
					}}
					disabled={skipped}
					title='If you have not seen the skill enough to comfortably assess'
				>
					<Text size='normal'>{skipped ? 'Skipped' : 'Skip'}</Text>
				</Btn>
			) : null}

			<Btn
				className='next-btn'
				disabled={isAllRated || skipped ? false : !isRated}
				onClick={
					reviewType === 'review' && isAllRated
						? submitReview
						: reviewType === 'kudos' && !isLastStep && isAllRated
						? nextStep
						: nextHandler
				}
			>
				<Text size='normal'>
					{isAllRated ? (reviewType === 'review' ? 'Submit Review' : 'Continue') : 'Next'}
				</Text>{' '}
				{isAllRated ? null : <Icon type='chevron' />}
			</Btn>
			<ModalFull headerContent={skillName} isOpen={showModal} closeHandler={closeModal}>
				<Heading mBot mTop displayType='dialog-heading' dir='center'>
					Previous Reviews
				</Heading>
				<ReviewSkillHistory skillId={skillId} />
			</ModalFull>
		</RateReviewSkillToolbarView>
	)
}
