import { ReactNode, useEffect, useRef } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { SearchField, SearchFieldProps } from '../FormFields/Search.field'

export const searchDropdownStyles = css`
	z-index: 9;
	.ctrl-group {
		padding: 1em;
		width: 100%;
		//background: ${props => props.theme.colors.white.val};
		//box-shadow: 1px 2px 4px ${props => props.theme.colors.black.opacity(40).val};
		transition: all ${props => props.theme.times.tranM};
	}
`

const SearchDropdownView = styled.div`
	${searchDropdownStyles}
`

export type SearchDropdownProps = Omit<SearchFieldProps, 'name'> & {
	name?: string
	suppressClickOutsideToClose?: boolean
	children?: ReactNode
}
export const SearchDropdown = ({
	value,
	onChange,
	children,
	name = 'search-dropdown',
	suppressClickOutsideToClose,
	...props
}: SearchDropdownProps): JSX.Element => {
	const containerEl = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (suppressClickOutsideToClose) return

		const closeDropDown = (e: any) => {
			const target = e.target
			const container = containerEl.current
			if (container?.contains(target)) return
			onChange('')
			document.removeEventListener('click', closeDropDown)
		}

		if (value) document.addEventListener('click', closeDropDown)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, suppressClickOutsideToClose])

	const { colors } = useTheme()

	const boxShadow = value ? `0px 3px 3px ${colors.black.opacity(30).val}` : 'none'

	return (
		<SearchDropdownView style={{ boxShadow }}>
			<div className='wrap' ref={containerEl}>
				<div className='ctrl'>
					<SearchField
						value={value}
						name={name}
						onChange={e => {
							onChange(e.target.value)
						}}
						{...props}
					/>
				</div>
				{value ? (
					<div style={{ boxShadow }} className='ctrl-group'>
						{children}
					</div>
				) : null}
			</div>
		</SearchDropdownView>
	)
}
