import { Heading, skillLevelsWithInfo, Text } from '@merify/ui'
import styled, { css, useTheme } from 'styled-components'

export const skillLevelInfoStyles = css`
	&.__fixed-height {
		height: 60px;
	}
	h3 {
		color: inherit;
		margin: 0;
		padding: 0;
		line-height: initial;
		text-align: center;
	}
	h3 {
		font-weight: 600;
		margin-bottom: 0.5rem;
	}
	p {
		font-size: 1.2rem;
	}
`

const SkillLevelInfoView = styled.div`
	${skillLevelInfoStyles}
`

export type SkillLevelInfoProps = {
	levelId: number
	className?: string
	invert?: boolean
	fixedHeight?: boolean
}
export const SkillLevelInfo = ({
	levelId,
	className,
	invert,
	fixedHeight,
}: SkillLevelInfoProps): JSX.Element => {
	const { colors } = useTheme()
	const { name, shortDesc, color } =
		skillLevelsWithInfo.find(level => level.levelId === levelId) || {}

	return (
		<SkillLevelInfoView
			className={`skill-level-info${className ? ` ${className}` : ''}${
				fixedHeight ? ' __fixed-height' : ''
			}`}
			style={{ color: invert ? colors.white.val : color }}
		>
			<Heading hTag='h3' displayType='section-heading' dir='center'>
				{name}
			</Heading>
			<Text size='normal' textType='p' dir='center'>
				{shortDesc}
			</Text>
		</SkillLevelInfoView>
	)
}
