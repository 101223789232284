import { Async, Btn, Card, CTA, Ctas, Heading, useModalCtrl, WizardStepProps } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetJobs } from '../../../api/api.jobs'
import { Container } from '../../UI/Container'
import { ModalFull } from '../../UI/Modal'
import { CandidateJob } from '../Candidate.job'
import { CandidateJobsList } from '../Candidate.jobs.list'
import { useReviewRequest } from './Review.request.provider'

export const reviewRequestSelectJobStyles = css``

const ReviewRequestSelectJobView = styled.div`
	${reviewRequestSelectJobStyles}
`

type ReviewRequestSelectJobProps = {
	cancel?: () => void
}
export const ReviewRequestSelectJob = ({
	cancel,
	...stepProps
}: ReviewRequestSelectJobProps): JSX.Element => {
	const { nextStep, currentStep, previousStep, isActive } = stepProps as WizardStepProps
	const { candidateJobId, setJobId } = useReviewRequest()
	const results = useGetJobs()
	const { data } = results

	const isFirstStep = currentStep === 1

	const [[showModal, openModal, closeModal, previewJobId]] = useModalCtrl<number>()

	return (
		<ReviewRequestSelectJobView>
			<Container isStickyFooter stickyOffsets={['wizardDots']} isInactiveWizardStep={!isActive}>
				<Heading mBot hTag='h4' displayType='dialog-heading' dir='center'>
					{isFirstStep ? 'First, select a job:' : 'Select a job'}
					<Ctas maxW='350px' mTop>
						<CTA size='sm'>
							<Btn
								onClick={() => {
									if (isFirstStep && cancel) cancel()
									else previousStep()
								}}
							>
								{isFirstStep ? 'Cancel' : 'Back'}
							</Btn>
						</CTA>
						<CTA displayType='solid' size='sm'>
							<Btn onClick={nextStep} disabled={!candidateJobId}>
								Next
							</Btn>
						</CTA>
					</Ctas>
				</Heading>
				<Async fetchResults={results}>
					<Card>
						<CandidateJobsList
							candidateJobs={data?.filter(({ candidateJobSkills }) => !!candidateJobSkills.length)}
							selectedJobIds={candidateJobId ? [candidateJobId] : undefined}
							onSelect={job => setJobId(job.candidateJob.candidateJobId)}
							showSelectRadios
							calculateDisabled={() => {
								// TODO: add this once we have an endpoint that will work
								return false
							}}
							optionBtn={{
								icon: 'arrowRight',
								onClick: openModal,
							}}
						/>
					</Card>
				</Async>
			</Container>
			<ModalFull isOpen={showModal} closeHandler={closeModal}>
				{previewJobId ? <CandidateJob hideBackBtn candidateJobId={previewJobId} /> : null}
			</ModalFull>
		</ReviewRequestSelectJobView>
	)
}
