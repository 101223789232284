import { Btn, Heading, Icon, Text, useModalCtrl } from '@merify/ui'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { ModalAlert } from '../../UI/Modal'

export const DELETE_PROFILE_KEY = 'merify_profile_deleted'
export const DELETE_PROFILE_VAL = 'TRUE'

export const userMenuDeleteProfileStyles = css`
	&&& {
		color: ${props => props.theme.colors.err.val};
		i.icon {
			color: inherit;
			svg {
				width: 18px;
			}
		}
	}
`

const UserMenuDeleteProfileView = styled(Btn)`
	${userMenuDeleteProfileStyles}
`

export type UserMenuDeleteProfileProps = {
	children?: ReactNode
}
export const UserMenuDeleteProfile = ({ children }: UserMenuDeleteProfileProps): JSX.Element => {
	const [[showModal, openModal, closeModal]] = useModalCtrl()
	// const goTo = useNavigate()

	const deleteHandler = async () => {
		window.localStorage.setItem(DELETE_PROFILE_KEY, DELETE_PROFILE_VAL)
		window.location.href = `${process.env.REACT_APP_OIDC_AUTHORITY}/Identity/Account/Manage/DeletePersonalData`
		// goTo(`${process.env.REACT_APP_OIDC_AUTHORITY}/Identity/Account/Manage/DeletePersonalData`)
	}
	return (
		<>
			<UserMenuDeleteProfileView onClick={openModal}>
				<Icon type='trash' /> {children}
			</UserMenuDeleteProfileView>
			<ModalAlert
				isOpen={showModal}
				closeHandler={shouldDelete => {
					if (!shouldDelete) closeModal()
				}}
				showAlertBtns
				asyncConfirmHandler={deleteHandler}
				defaultBtn='cancel'
				canClickBg
			>
				<Heading hTag='h3' displayType='block-heading' color='text' dir='center' mBot>
					Are you sure you want to delete this profile?
				</Heading>
				<Text textType='p' dir='center' size='med'>
					Review data of this WILL remain in the system, but will no longer be linked your profile.
				</Text>

				<Text textType='p' dir='center' size='med'>
					Would you still like to delete your profile?
				</Text>
			</ModalAlert>
		</>
	)
}
