import { Heading } from '@merify/ui'
import styled, { css } from 'styled-components'
import { RatingsGuideButton } from '../Tut.ratingsGuide/Ratings.guide.button'

export const skillsHeaderStyles = css`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: ${props => props.theme.sizes.gutter.mobile.val} 0;
	color: ${props => props.theme.colors.secondary.val};
	h2 {
		font-size: 1.3rem;
		font-weight: 700;
		padding-left: ${props => props.theme.sizes.gutter.mobile.val};
		margin-right: 0.5em;
	}
`

const SkillsHeaderView = styled.div`
	${skillsHeaderStyles}
`

export type SkillsHeaderProps = {
	className?: string
	children: string
}

export const SkillsHeader = ({ className, children }: SkillsHeaderProps): JSX.Element => {
	return (
		<SkillsHeaderView className={`${className ? ` ${className}` : ''} ${' skills-header'}`}>
			<Heading hTag='h2' displayType='dialog-heading'>
				{children}
			</Heading>
			<RatingsGuideButton />
		</SkillsHeaderView>
	)
}
