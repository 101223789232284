import { Text } from '@merify/ui'
import { ModalAlert, ModalAlertProps } from '../../UI/Modal'

export type ReviewProviderConfirmProps = {
	isOpen: ModalAlertProps['isOpen']
	closeHandler: ModalAlertProps['closeHandler']
	name?: string
	employer?: string
}
export const ReviewProviderConfirm = ({
	isOpen,
	closeHandler,
	name,
	employer,
}: ReviewProviderConfirmProps): JSX.Element => {
	return (
		<ModalAlert isOpen={isOpen} closeHandler={closeHandler} showAlertBtns confirmTxt='Send It!'>
			<Text size='med'>
				Submit review for {name}'s work at: {employer}?
			</Text>
		</ModalAlert>
	)
}
