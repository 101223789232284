import { Async, Card, NotificationType } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetNotificationByIdAndType } from '../../../api/api.notifications'
import { DynamicNotificationParams } from '../Notification.detail'
import { NotificationDetailAddSkillsCta } from '../Notification.detail.addSkills.cta'
import { NotificationInfo } from '../Notification.detail.info'
import { NotificationDetailSkillsList } from '../Notification.detail.skillsList'
import { notificationStyles } from '../Notification.styles'

export const skillVerificationNotificationDetailStyles = css`
	${notificationStyles}

	.delete-notification-cta {
	}
`

const SkillVerificationNotificationDetailView = styled.div`
	${skillVerificationNotificationDetailStyles}
`

export type SkillVerificationNotificationDetailProps = DynamicNotificationParams

export const SkillVerificationNotificationDetail = ({
	id,
	cb,
}: SkillVerificationNotificationDetailProps): JSX.Element => {
	const type: NotificationType = 'candidateSkillVerificationNotifications'

	const notificationsRes = useGetNotificationByIdAndType({
		notificationId: id,
		notificationType: type,
	})

	const notification = notificationsRes.data
	const { skills } = notification || {}

	return (
		<SkillVerificationNotificationDetailView>
			<Async fetchResults={[notificationsRes]}>
				<Card>
					{notification ? <NotificationInfo notification={notification} /> : null}
					{skills ? <NotificationDetailSkillsList skills={skills} /> : null}
				</Card>
				<NotificationDetailAddSkillsCta
					skills={skills}
					deleteProps={{ notificationId: id, notificationType: type, cb }}
				/>
			</Async>
		</SkillVerificationNotificationDetailView>
	)
}
