import { boxFieldStyles, Field, FieldProps, Icon } from '@merify/ui'
import { HTMLProps } from 'react'
import styled, { css } from 'styled-components'

// FIXME: This is a hack to support yarn linking
const _boxFieldStyles: any = boxFieldStyles

export const searchFieldStyles = css`
	input {
		padding-left: 35px;
		min-height: 35px;
		${_boxFieldStyles}
		&::-webkit-search-decoration {
			display: none;
		}
	}
`

const SearchFieldView = styled.div`
	${searchFieldStyles}
	color: ${props => props.theme.colors.secondary.val};
	.search-icon {
		position: absolute;
		padding: 8px;
		width: 34px;
	}
`

export type SearchFieldProps = Omit<HTMLProps<HTMLInputElement>, 'onChange' | 'label'> &
	FieldProps & {
		onChange: (val: any) => void
	}
export const SearchField = ({
	name,
	label,
	className,
	displayType,
	spaceBottom,
	spaceRight,
	...props
}: SearchFieldProps): JSX.Element => {
	const fieldWrapProps = { name, label, className, displayType, spaceBottom, spaceRight }
	return (
		<Field {...fieldWrapProps}>
			<SearchFieldView>
				<Icon className='search-icon' type='magnifyingGlass' />
				<input type='search' {...props} />
			</SearchFieldView>
		</Field>
	)
}
