import { Btn, CandidateIntersect, Icon, parseNameFromUser, Text } from '@merify/ui'
import styled, { css } from 'styled-components'
import { Avatar } from '../Avatar'
import { UserListItemHandler } from './User.list'

export const userItemStyles = css`
	width: 100%;

	.inner-wrap {
		width: 100%;
		display: flex;
		align-items: center;
		height: 50px;
		color: ${props => props.theme.colors.secondary.val};
	}

	.user-list-item-remove-btn {
		color: ${props => props.theme.colors.err.val};
		margin-right: 0.5em;
	}

	.avatar {
		margin-right: 7px;
	}
	.text {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		em {
		}
	}
	.name {
		color: inherit;
		margin: 0;
	}
	.icon.clock {
		margin-left: auto;
		color: ${props => props.theme.colors.secondary.opacity(60).val};
	}
`

const UserItemView = styled.div`
	${userItemStyles}
`

export type UserListItemUser = Pick<
	CandidateIntersect,
	'candidateId' | 'firstName' | 'lastName' | 'registered' | 'city' | 'stateOrProvince'
> & {
	email?: string | null
}

export type UserItemProps<T> = {
	user: UserListItemUser
	className?: string
	onDelete?: UserListItemHandler<T>
}
export function UserItem<T>({ user, className, onDelete }: UserItemProps<T>): JSX.Element {
	const { registered } = user
	return (
		<UserItemView className={`user-list-item${className ? ` ${className}` : ''}`}>
			<div className='inner-wrap'>
				{onDelete ? (
					<Btn onClick={() => onDelete(user as unknown as T)} className='user-list-item-remove-btn'>
						<Icon type='minus' />
					</Btn>
				) : null}
				{/* @ts-ignore MIGRATION */}
				<Avatar user={user} size='sm' />
				<div className='text'>
					<Text textType='bold' size='small' className='name'>
						{parseNameFromUser(user, { useEmail: true })}
					</Text>
					{user.city || user.stateOrProvince ? (
						<Text textType='italic'>
							{user.city || ''}
							{user.city && user.stateOrProvince ? ', ' : ''}
							{user.stateOrProvince || ''}
						</Text>
					) : null}
				</div>
				{!registered ? <Icon type='clock' /> : null}
			</div>
		</UserItemView>
	)
}
