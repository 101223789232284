import {
	Async,
	Btn,
	Card,
	CTA,
	Icon,
	MerifyIrmaWorkSvg,
	NoResults,
	Text,
	useModalCtrl,
	useState,
} from '@merify/ui'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useEffect } from 'react'
import styled from 'styled-components'
import { useGetMe, useUpdateNewUserProgress } from '../../../../api/api.candidate'
import { useGetJobs } from '../../../../api/api.jobs'
import { useGetSkillsWithScores } from '../../../../api/api.skills'
import { useSetAppHeader } from '../../../../utils/useSetAppHeader'
import { CandidateJob } from '../../../global/Candidate.job'
import { CreateCandidateJob } from '../../../global/Candidate.job.create'
import { CandidateJobsList } from '../../../global/Candidate.jobs.list/Candidate.jobs.list'
import { AppDrawer, AppDrawerPlaceholder } from '../../../global/Drawer.app'
import { WelcomeSlideout } from '../../../global/Welcome.slideout'
import { ModalAlert, ModalFull } from '../../../UI/Modal'
import { Page404Redirect } from '../../Page404.redirect'
import { CandidateJobsScreenWelcome } from './Candidate.jobs.screen.welcome'

const CandidateJobsScreenView = styled.div`
	.candidate-jobs-screen-header {
		padding: 1em ${props => props.theme.sizes.gutter.mobile.val};
		display: flex;
		flex-direction: row;
	}
	.no-candidate-jobs {
		padding: ${props => props.theme.sizes.gutter.mobile.val};
	}
`

type CandidateJobsScreenProps = RouteComponentProps & {}
export const CandidateJobsScreen = (_: CandidateJobsScreenProps): JSX.Element => {
	useSetAppHeader('default')
	const skillResults = useGetSkillsWithScores()
	const [[showModal, openModal, closeModal]] = useModalCtrl()
	const candidateJobResults = useGetJobs()
	const { data } = candidateJobResults

	const hasSkills = !!skillResults.isSuccess && !!skillResults.data?.length

	/**
	 *  WELCOME SCREEN
	 * */
	const [[showWelcomeSlideout, openWelcomeSlideout, closeWelcomeSlideout]] = useModalCtrl()
	const meResults = useGetMe()
	useEffect(() => {
		if (meResults.data && !meResults.data?.jobsViewed) openWelcomeSlideout()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meResults.data])

	const { mutate } = useUpdateNewUserProgress()
	const handleJobsViewed = () => {
		mutate({
			jobsViewed: true,
		})
	}
	// END WELCOME SCREEN

	const [shouldNav, setNav] = useState(false, 'shouldNav')
	const [[showNotReady, openNotReady]] = useModalCtrl()

	const navTo = useNavigate()

	useEffect(() => {
		if (shouldNav) navTo('/skills')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldNav])

	const [[isAppDrawerOpen, openAppDrawer, closeAppDrawer, currentJobId]] = useModalCtrl<number>()

	return (
		<CandidateJobsScreenView>
			<Async
				fetchResults={[meResults, skillResults, candidateJobResults]}
				isScreen
				Page404={Page404Redirect}
			>
				<header className='candidate-jobs-screen-header'>
					<CTA displayType='solid' size='sm'>
						<Btn
							onClick={() => {
								if (hasSkills) openModal()
								else openNotReady()
							}}
						>
							{data?.length ? 'Add more work' : 'Add work'}
							<Icon type='work' />
						</Btn>
					</CTA>
				</header>

				{data?.length === 0 ? (
					<div className='no-candidate-jobs'>
						<NoResults type='work' titleText='No work yet...' />
					</div>
				) : (
					<Card>
						<CandidateJobsList
							candidateJobs={data}
							onSelect={({ candidateJob }) => openAppDrawer(candidateJob.candidateJobId)}
						/>
					</Card>
				)}
			</Async>

			<AppDrawer
				isOpen={isAppDrawerOpen}
				closeHandler={closeAppDrawer}
				placeholder={<AppDrawerPlaceholder heading='Select a job' svg={<MerifyIrmaWorkSvg />} />}
				displayType={data?.length ? 'two-col' : 'one-col'}
			>
				{currentJobId ? (
					<CandidateJob candidateJobId={currentJobId} hideBackBtn onAfterDelete={closeAppDrawer} />
				) : null}
			</AppDrawer>

			<WelcomeSlideout
				show={showWelcomeSlideout}
				closeHandler={closeWelcomeSlideout}
				handleViewed={handleJobsViewed}
				thumbTitle='watch a quick video on how to add work...'
				videoId='mix-9VRe1-4'
				videoUrl='https://youtu.be/mix-9VRe1-4'
				WelcomeScreen={CandidateJobsScreenWelcome}
			/>

			<ModalFull
				headerContent='Add a new job to your work history'
				isOpen={showModal}
				closeHandler={closeModal}
			>
				<CreateCandidateJob cb={() => closeModal()} includeSkillSelect={hasSkills} />
			</ModalFull>
			<ModalAlert isOpen={showNotReady} closeHandler={() => setNav(true)}>
				<Text size='med'>First you'll need to add some skills...</Text>
				<CTA>
					<Btn onClick={() => setNav(true)}>Okay</Btn>
				</CTA>
			</ModalAlert>
		</CandidateJobsScreenView>
	)
}
