import { Router as ReachRouter, RouteComponentProps, useLocation } from '@reach/router'
import { useLayoutEffect } from 'react'
import { AuthProvider } from '../auth/AuthProvider'
import { AppLevelError } from '../components/AppLevel.Error'
import { AppLayout } from '../components/global/Layout.app'
import { CandidateJobScreen } from '../components/screens/candidate.jobs/Candidate.job.screen'
import { CandidateJobsScreen } from '../components/screens/candidate.jobs/Candidate.jobs.screen'
import { CandidateProfileScreen } from '../components/screens/candidates/candidate.profile.screen/Candidate.profile.screen'
import { FeedbackScreen } from '../components/screens/feedback/Feedback.screen'
import { HelpScreen } from '../components/screens/help/Help.screen'
import { NotificationsScreen } from '../components/screens/notifications/Notifications.screen'
import { ReviewScreen } from '../components/screens/reviews/Review.screen'
import { ReviewsScreen } from '../components/screens/reviews/Reviews.screen'
import { SkillScreen } from '../components/screens/skills/Skill.screen'
import { SkillsScreen } from '../components/screens/skills/Skills.screen'
import { VideosScreen } from '../components/screens/videos/Videos.screen'
import { Page404 } from './Route.404'
import { LoginRoute } from './Route.login'
import { LogoutRoute } from './Route.logout'
import { RouterRedirects } from './Router.redirects'

export type AuthedRoutesProps = RouteComponentProps & {}
export const AuthedRoutes = (_: AuthedRoutesProps): JSX.Element => {
	const { pathname } = useLocation()
	useLayoutEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return (
		<AuthProvider>
			<AppLevelError>
				<ReachRouter>
					<RouterRedirects path='/' />

					<LoginRoute path='/login' />
					<LogoutRoute path='/logout' />

					<AppLayout path='/candidates' displayType='one-col'>
						<CandidateProfileScreen path='/:id' />
						<Page404 default />
					</AppLayout>

					<AppLayout path='/skills' displayType='two-col'>
						<SkillsScreen path='/' />
						<Page404 default />
					</AppLayout>

					<AppLayout path='/skills/:candidateSkillId' displayType='one-col'>
						<SkillScreen path='/' />
						<Page404 default />
					</AppLayout>

					<AppLayout path='/work' displayType='two-col'>
						<CandidateJobsScreen path='/' />
						<Page404 default />
					</AppLayout>

					<AppLayout path='/work/:candidateJobId' displayType='one-col'>
						<CandidateJobScreen path='/' />
						<Page404 default />
					</AppLayout>

					<AppLayout path='/reviews' displayType='two-col'>
						<ReviewsScreen path='/' />
						<Page404 default />
					</AppLayout>

					<AppLayout path='/reviews/:reviewId' displayType='one-col'>
						<ReviewScreen path='/' />
						<Page404 default />
					</AppLayout>

					<AppLayout path='/notifications' displayType='two-col'>
						<NotificationsScreen path='/' />
						<Page404 default />
					</AppLayout>

					<AppLayout path='/help' displayType='one-col'>
						<HelpScreen path='/' />
						<Page404 default />
					</AppLayout>

					<AppLayout path='/video-tutorials' displayType='two-col'>
						<VideosScreen path='/' />
						<Page404 default />
					</AppLayout>

					<AppLayout path='/feedback' displayType='one-col'>
						<FeedbackScreen path='/' />
						<Page404 default />
					</AppLayout>

					<AppLayout path='/*'>
						<Page404 default />
					</AppLayout>
				</ReachRouter>
			</AppLevelError>
		</AuthProvider>
	)
}
