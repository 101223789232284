import React, { ReactNode, ReactText } from 'react'
import { toast, ToastOptions } from 'react-toastify'
import { Toasted, ToastedProps } from './Toast'

export type ToastType = 'err' | 'warn' | 'ok' | 'info'

export const parseType = (type?: ToastType): ToastOptions['type'] =>
	type === 'ok' ? 'success' : type === 'err' ? 'error' : type === 'warn' ? 'warning' : 'default'

export type ToastOpts = {
	type?: ToastType
	time?: number
	auto?: boolean
}

class Toaster {
	constructor(toastId: ReactText) {
		this.toastId = toastId
	}

	toastId

	dismiss() {
		toast.dismiss(this.toastId)
	}

	isActive() {
		toast.isActive(this.toastId)
	}
}

export const toaster = (msg: React.ReactNode, options?: ToastOpts) => {
	const { type, time = 10000, auto, ...opts } = options || {}

	const viewProps: ToastedProps = { type }

	const Content: ReactNode = React.createElement(Toasted as any, viewProps, msg)

	const timeToClose = typeof msg === 'string' ? msg.length * 228 : time

	const autoClose = auto === false ? false : timeToClose

	const toastId = toast(Content, { type: parseType(type), autoClose, ...opts })

	return new Toaster(toastId)
}

toaster.err = (msg: ReactNode, opts?: ToastOpts) => toaster(msg, { type: 'err', ...opts })
toaster.ok = (msg: ReactNode, opts?: ToastOpts) => toaster(msg, { type: 'ok', ...opts })
toaster.warn = (msg: ReactNode, opts?: ToastOpts) => toaster(msg, { type: 'warn', ...opts })
toaster.info = (msg: ReactNode, opts?: ToastOpts) => toaster(msg, { type: 'info', ...opts })
