import {
	CandidateIntersect,
	Card,
	compact,
	PrivateCandidate,
	PublicCandidate,
	Spinner,
	Text,
} from '@merify/ui'
import styled, { css } from 'styled-components'
import { Avatar } from '../../../global/Avatar'
import { ShareBtn } from '../../../global/Share.btn'
import { PublicProfileExplainer } from '../../../global/Tut.publicProfileExplainer'

export const CandidateProfileInfoStyles = css`
	padding: ${props => props.theme.sizes.gutter.mobile.val} 0;
	.row {
		margin: 1em 0;
	}
	.flex {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: ${props => props.theme.sizes.gutter.mobile.val};
		padding-top: 0;
		.buttons {
			display: flex;
			align-items: flex-end;
			.button {
				display: flex;
				flex-direction: column;
				align-items: center;
				&:nth-child(1) {
					margin-right: 15px;
				}
				.icon,
				h6 {
					color: ${props => props.theme.colors.secondary.val};
				}
			}
		}
	}

	.top-btns {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 0.5rem;
		align-items: center;
	}
	.value {
		font-size: 1.33rem;
		line-height: 1.9rem;
	}
	.card-inner {
		border-top: 2px solid ${props => props.theme.colors.lightGrey.val};
		.row {
			&:first-of-type {
				margin-top: 0;
			}
		}
	}
`
const CandidateProfileInfoView = styled.div`
	${CandidateProfileInfoStyles}
`

type CandidateProfileInfoProps = {
	candidateData?: CandidateIntersect
	view?: 'public' | 'private' | 'preview'
	isMyProfile?: boolean
	className?: string
}
export const CandidateProfileInfo = ({
	candidateData,
	isMyProfile,
	view,
	className,
}: CandidateProfileInfoProps): JSX.Element => {
	const publicData = candidateData as PublicCandidate
	const privateData = candidateData as PrivateCandidate
	const locationArry =
		publicData &&
		compact([
			publicData.city,
			publicData.stateOrProvince,
			privateData.postalCode,
			publicData.country,
		])
	const nameArry = publicData && compact([publicData.firstName, publicData.lastName])

	return (
		<CandidateProfileInfoView
			className={`${className ? ` ${className}` : ''} ${' candidate-profile-info'}`}
		>
			{publicData && privateData ? (
				<>
					<div className='flex'>
						{view !== 'private' ? (
							// @ts-ignore MIGRATION
							<Avatar size='l' user={publicData} />
						) : (
							// @ts-ignore MIGRATION
							<Avatar size='l' canEdit user={publicData} />
						)}
						{view === 'preview' && isMyProfile ? (
							<div className='top-btns'>
								<ShareBtn />
								<PublicProfileExplainer />
							</div>
						) : null}
					</div>
					<Card hasGutter>
						{nameArry?.length ? (
							<div className='row'>
								<Text textType='p' className='label'>
									Name
								</Text>
								<Text textType='p' className='value'>
									{nameArry.join(' ')}
								</Text>
							</div>
						) : null}

						{publicData.pronouns ? (
							<div className='row'>
								<Text textType='p' className='label'>
									Pronouns
								</Text>
								<Text textType='p' className='value'>
									{publicData.pronouns}
								</Text>
							</div>
						) : null}
						{publicData.formerName ? (
							<div className='row'>
								<Text textType='p' className='label'>
									Former Name
								</Text>
								<Text textType='p' className='value'>
									{publicData.formerName}
								</Text>
							</div>
						) : null}

						{view === 'private' ? (
							privateData.email ? (
								<div className='row'>
									<Text textType='p' className='label'>
										Email
									</Text>
									<Text textType='p' className='value'>
										{privateData.email}
									</Text>
								</div>
							) : null
						) : null}

						{locationArry?.length ? (
							<div className='row'>
								<Text textType='p' className='label'>
									Location
								</Text>
								<Text textType='p' className='value'>
									{locationArry.join(', ')}
								</Text>
							</div>
						) : null}

						{publicData.industryName ? (
							<div className='row'>
								<Text textType='p' className='label'>
									Industry Name
								</Text>
								<Text textType='p' className='value'>
									{publicData.industryName}
								</Text>
							</div>
						) : null}

						{publicData.title ? (
							<div className='row'>
								<Text textType='p' className='label'>
									Title
								</Text>
								<Text textType='p' className='value'>
									{publicData.title}
								</Text>
							</div>
						) : null}
						{publicData.bio ? (
							<div className='row'>
								<Text textType='p' className='label'>
									Bio
								</Text>
								<Text textType='p' className='value'>
									{publicData.bio}
								</Text>
							</div>
						) : null}
					</Card>
				</>
			) : (
				<Spinner />
			)}
		</CandidateProfileInfoView>
	)
}
