import {
	Async,
	Btn,
	CandidateSkillList,
	CTA,
	Icon,
	MerifyIrmaSkillsSvg,
	NoResults,
	useModalCtrl,
	useState,
} from '@merify/ui'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useEffect } from 'react'
import styled from 'styled-components'
import { useGetMe, useUpdateNewUserProgress } from '../../../../api/api.candidate'
import { useGetSkillsWithScores } from '../../../../api/api.skills'
import { useSetAppHeader } from '../../../../utils/useSetAppHeader'
import { AppDrawer, AppDrawerPlaceholder } from '../../../global/Drawer.app'
import { Skill } from '../../../global/Skill'
import { SkillAdd } from '../../../global/Skill.add'
import { WelcomeSlideout } from '../../../global/Welcome.slideout'
import { ModalFull } from '../../../UI/Modal'
import { Page404Redirect } from '../../Page404.redirect'
import { SkillsSreenWelcome } from './Skills.screen.welcome'

const MAX_SKILLS = 40

const SkillsScreenView = styled.div`
	.no-skills {
		padding: ${props => props.theme.sizes.gutter.mobile.val};
	}
	.skills-screen-header {
		padding: 1em ${props => props.theme.sizes.gutter.mobile.val};
		position: relative;
		display: flex;
	}
`

type SkillsScreenProps = RouteComponentProps & {}
export const SkillsScreen = (_: SkillsScreenProps): JSX.Element => {
	const [[showAddSkillModal, openAddSkillModal, closeAddSkillModal]] = useModalCtrl()
	useSetAppHeader('default')

	const [navToSkillId, setNavToSkill] = useState<number | null>(null, 'navToSkillId')

	const results = useGetSkillsWithScores()

	const { data } = results

	/**
	 *  Skills Welcome screen
	 * */

	const [[showWelcomeSlideout, openWelcomeSlideout, closeWelcomeSlideout]] = useModalCtrl()

	const meResults = useGetMe()

	useEffect(() => {
		if (meResults.data && !meResults.data?.skillsViewed) {
			setTimeout(() => {
				openWelcomeSlideout()
			}, 500)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meResults.data])

	// END Skills Welcome screen

	const hasMaxSkills = (data?.length || 0) > MAX_SKILLS
	const hasSkills = !!data?.length

	const navTo = useNavigate()
	useEffect(() => {
		if (navToSkillId) navTo(`/skills/${navToSkillId}`)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navToSkillId])

	const { mutate } = useUpdateNewUserProgress()
	const handleSkillsViewed = () => {
		mutate({
			skillsViewed: true,
		})
	}

	const [[isAppDrawerOpen, openAppDrawer, closeAppDrawer, currentSkillId]] = useModalCtrl<number>()

	return navToSkillId ? (
		<></>
	) : (
		<SkillsScreenView>
			<Async fetchResults={[meResults, results]} Page404={Page404Redirect}>
				<header className='skills-screen-header'>
					<CTA size='sm' displayType='solid'>
						<Btn onClick={openAddSkillModal} disabled={hasMaxSkills}>
							{hasMaxSkills
								? `Maximum of ${MAX_SKILLS} skills reached`
								: hasSkills
								? 'Add another skill'
								: 'Add a skill'}
							<Icon type='skills' />
						</Btn>
					</CTA>
				</header>
				{results.data?.length === 0 ? (
					<div className='no-skills'>
						<NoResults type='skills' titleText='No skills yet...' />
					</div>
				) : (
					<CandidateSkillList
						skills={data}
						onNavToSkill={candidateSkillId => openAppDrawer(candidateSkillId)}
					/>
				)}
			</Async>
			<AppDrawer
				isOpen={isAppDrawerOpen}
				closeHandler={closeAppDrawer}
				placeholder={
					<AppDrawerPlaceholder heading='Select a skill' svg={<MerifyIrmaSkillsSvg />} />
				}
				displayType={results.data?.length ? 'two-col' : 'one-col'}
			>
				{currentSkillId ? (
					<Skill hideBackBtn candidateSkillId={currentSkillId} onAfterDelete={closeAppDrawer} />
				) : null}
			</AppDrawer>

			<WelcomeSlideout
				show={showWelcomeSlideout}
				closeHandler={closeWelcomeSlideout}
				handleViewed={handleSkillsViewed}
				thumbTitle='Watch a quick video on how to add skills...'
				videoId='nV_9A13CWyc'
				videoUrl='https://www.youtube.com/watch?v=nV_9A13CWyc'
				WelcomeScreen={SkillsSreenWelcome}
			/>

			<ModalFull
				isOpen={showAddSkillModal}
				closeHandler={closeAddSkillModal}
				headerContent='Add a New Skill'
			>
				<SkillAdd
					cb={candidateSkillId => {
						setNavToSkill(candidateSkillId)
					}}
					previouslySelectedSkills={results.data}
				/>
			</ModalFull>
		</SkillsScreenView>
	)
}
