/*
=================================================
QUERIES
=================================================
* */

import { capitalize, Notification, Notifications, NotificationType } from '@merify/ui'
import { useMutation, useQueryClient } from 'react-query'
import { authenticatedRequest } from '../utils/api.utils'
import { useCurrentUser } from '../utils/useCurrentUser'
import { useAuthedFetch, useFetchOptions } from '../utils/useFetch'
import { fetch } from './fetch'

export const getMyNotificationsKey = (uid: number) => `${uid}-notifications`

type UseGetAllNotificationsOptions = useFetchOptions & {
	includeArchived?: boolean
	includeViewed?: boolean
}
export const useGetMyNotifications = (options?: UseGetAllNotificationsOptions) => {
	const { includeArchived = false, includeViewed = true, ...opts } = options || {}
	const { uid, token } = useCurrentUser()
	return useAuthedFetch<Notifications>(
		getMyNotificationsKey(uid),
		() =>
			authenticatedRequest({ uid, token }, () =>
				fetch('GET', `/Notifications/Candidates/${uid}`, token, {
					params: {
						includeArchived,
						includeViewed,
					},
				})
			),
		opts
	)
}

export const getNotificationByIdAndTypeKey = (
	params: UseGetNotificationByIdParams<any> & { uid: number }
) =>
	JSON.stringify({
		name: 'getNotificationByIdAndType',
		...params,
	})

type UseGetNotificationByIdParams<T> = {
	notificationId: number
	notificationType: T
}

type NotificationPayload<T extends keyof Notification> = Notification[T]

export function useGetNotificationByIdAndType<T extends keyof Notification>(
	params: UseGetNotificationByIdParams<T>,
	options?: useFetchOptions
) {
	const { notificationType, notificationId } = params
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<NotificationPayload<T>>(
		getNotificationByIdAndTypeKey({
			uid,
			notificationType,
			notificationId,
		}),
		() =>
			authenticatedRequest({ uid, token }, () =>
				fetch(
					'GET',
					`/Notifications/Candidates/${uid}/${capitalize(notificationType)}/${notificationId}`,
					token
				)
			),
		options
	)
}

/*
 =================================================
  MUTATIONS
 =================================================
* */

export const useNotificationViewed = (
	notificationId: number,
	notificationType: NotificationType
) => {
	const queryClient = useQueryClient()
	const { uid, token } = useCurrentUser()
	return useMutation(
		`${uid}-viewed-notification`,
		(isViewed: boolean) =>
			authenticatedRequest({ uid, token }, () =>
				fetch(
					'PUT',
					`/Notifications/Candidates/${uid}/${capitalize(notificationType)}/${notificationId}`,
					token,
					{
						data: {
							isViewed,
						},
					}
				)
			),
		{
			onSettled: () => {
				queryClient.invalidateQueries(getMyNotificationsKey(uid))
				queryClient.invalidateQueries(
					getNotificationByIdAndTypeKey({ uid, notificationType, notificationId })
				)
			},
		}
	)
}

type UseDeleteNotificationArgs = {
	notificationId: number
	notificationType: NotificationType
}
export const useDeleteNotification = () => {
	const queryClient = useQueryClient()
	const { uid, token } = useCurrentUser()
	return useMutation(
		`${uid}-delete-notification`,
		(args: UseDeleteNotificationArgs) =>
			authenticatedRequest({ uid, token }, () =>
				fetch(
					'PUT',
					`/Notifications/Candidates/${uid}/${capitalize(args.notificationType)}/${
						args.notificationId
					}`,
					token,
					{
						data: {
							isArchived: true,
						},
					}
				)
			),
		{
			onSettled: () => queryClient.invalidateQueries(getMyNotificationsKey(uid)),
		}
	)
}
