import { Router } from '@reach/router'
import { Page404 } from '../../../routing/Route.404'
export type Page404RedirectProps = {}
export const Page404Redirect = (): JSX.Element => {
	return (
		<Router>
			<Page404 default />
		</Router>
	)
}
