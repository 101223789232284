import { CandidateJob, CandidateJobAndSkills, CandidateJobAndSkillsAndCandidate } from '@merify/ui'
import { useMutation, useQueryClient } from 'react-query'
import { authenticatedRequest } from '../utils/api.utils'
import { useCurrentUser } from '../utils/useCurrentUser'
import { useAuthedFetch } from '../utils/useFetch'
import { fetch } from './fetch'

type KudosCandidateJob = Pick<
	CandidateJob,
	| 'candidateJobId'
	| 'candidateId'
	| 'jobTitle'
	| 'employer'
	| 'jobSummary'
	| 'projectName'
	| 'startDate'
	| 'endDate'
> & {
	createdUtc: string
	externalEmployerInstanceId: number
	externalEmployerId: number
}

/*
 =================================================
	QUERIES
 =================================================
* */
export const getJobsKey = (uid: number) => `${uid}-jobs`

export const useGetJobs = () => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<CandidateJobAndSkills[]>(getJobsKey(uid), () =>
		authenticatedRequest({ uid, token }, () =>
			fetch('GET', `/Candidates/${uid}/JobAndSkills`, token)
		)
	)
}

export const getJobKey = (uid: number, candidateJobId: number) =>
	`${uid}-job-skills-${candidateJobId}`

export const useGetJob = (candidateJobId: number) => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<CandidateJobAndSkills>(getJobKey(uid, candidateJobId), () =>
		authenticatedRequest({ uid, token }, () =>
			fetch('GET', `/Candidates/${uid}/JobAndSkills/${candidateJobId}`, token)
		)
	)
}
export const getJobWithCandidateKey = (uid: number, candidateJobId: number) => [
	'job-skills-candidate',
	`${uid}`,
	`${candidateJobId}`,
]

export const useGetJobWithCandidate = (candidateJobId: number) => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<CandidateJobAndSkillsAndCandidate>(
		getJobWithCandidateKey(uid, candidateJobId),
		() =>
			authenticatedRequest({ uid, token }, () =>
				fetch('GET', `/Candidates/${uid}/JobAndSkills/${candidateJobId}/Candidate`, token)
			),
		{
			cacheTime: 500,
		}
	)
}

const getJobsBySkillKey = (uid: number, candidateSkillId: number) =>
	`${uid}-jobs-by-skills-${candidateSkillId}`

export const useGetJobsBySkill = (candidateSkillId: number) => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<CandidateJobAndSkills[]>(getJobsBySkillKey(uid, candidateSkillId), () =>
		authenticatedRequest({ uid, token }, () =>
			fetch('GET', `/Candidates/${uid}/JobAndSkills/JobSkills/${candidateSkillId}`, token)
		)
	)
}

/**
 *  GET JOBS ON KUDOS CANDIDATE
 * */

const getKudosJobsOnCandidateKey = (uid: number, candidateId: number) =>
	`${uid}-kudos-jobs-on-candidate-${candidateId}`

export const useGetKudosJobsOnCandidate = (candidateId: number) => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<KudosCandidateJob[]>(getKudosJobsOnCandidateKey(uid, candidateId), () =>
		authenticatedRequest({ uid, token }, () =>
			fetch('GET', `/Candidates/${uid}/Kudos/CandidateOptions/${candidateId}/JobOptions`, token)
		)
	)
}

/**
 *  GET JOB FOR KUDOS CANDIDATE
 * */

const getKudosJobForCandidateKey = (uid: number, candidateId: number, candidateJobId: number) =>
	`${uid}-kudos-job-for-candidate-${candidateId}-${candidateJobId}`

export const useGetKudosJobForCandidate = (candidateId: number, candidateJobId: number) => {
	const { uid, token } = useCurrentUser()

	return useAuthedFetch<KudosCandidateJob>(
		getKudosJobForCandidateKey(uid, candidateId, candidateJobId),
		() =>
			authenticatedRequest({ uid, token }, () =>
				fetch(
					'GET',
					`/Candidates/${uid}/Kudos/CandidateOptions/${candidateId}/JobOptions/${candidateJobId}`,
					token
				)
			)
	)
}

// END QUERIES

/*
 =================================================
	MUTATIONS
 =================================================
* */

export type JobInputs = {
	jobTitle: string
	employer: string
	jobSummary?: string
	startDate: string
	endDate?: string
}

export const useAddJob = () => {
	const { uid, token } = useCurrentUser()
	const queryClient = useQueryClient()

	return useMutation(
		(data: JobInputs) =>
			authenticatedRequest({ uid, token }, () =>
				fetch<CandidateJob>('POST', `/Candidates/${uid}/Jobs`, token, {
					data: [data],
					transformCb: data => data[0],
				})
			),
		{
			onSettled: () => queryClient.invalidateQueries(getJobsKey(uid)),
		}
	)
}

type UseAddJobWithSkillsInputs = JobInputs & {
	candidateSkillIds: number[]
}

export const useAddJobWithSkills = () => {
	const { uid, token } = useCurrentUser()
	const queryClient = useQueryClient()

	return useMutation(
		(data: UseAddJobWithSkillsInputs) =>
			authenticatedRequest({ uid, token }, () =>
				fetch<CandidateJobAndSkills>('POST', `/Candidates/${uid}/JobAndSkills`, token, {
					data,
				})
			),
		{
			onSettled: () => queryClient.invalidateQueries(getJobsKey(uid)),
		}
	)
}

export const useEditJob = (candidateJobId: number) => {
	const { uid, token } = useCurrentUser()
	const queryClient = useQueryClient()

	return useMutation(
		(data: JobInputs) =>
			authenticatedRequest({ uid, token }, () =>
				fetch('PUT', `/Candidates/${uid}/Jobs/${candidateJobId}`, token, {
					data,
				})
			),
		{
			onSettled: () => {
				queryClient.invalidateQueries(getJobKey(uid, candidateJobId))
				queryClient.invalidateQueries(getJobsKey(uid))
				queryClient.invalidateQueries(getJobWithCandidateKey(uid, candidateJobId))
			},
		}
	)
}

export const useAddSkillsToJob = (candidateJobId: number) => {
	const { uid, token } = useCurrentUser()
	const queryClient = useQueryClient()

	return useMutation(
		(candidateSkillIds: number[]) =>
			authenticatedRequest({ uid, token }, () =>
				fetch('PUT', `/Candidates/${uid}/Jobs/${candidateJobId}/JobSkills`, token, {
					data: {
						candidateSkillIds,
					},
				})
			),
		{
			onSettled: () => {
				queryClient.invalidateQueries(getJobKey(uid, candidateJobId))
				queryClient.invalidateQueries(getJobsKey(uid))
				queryClient.invalidateQueries(getJobWithCandidateKey(uid, candidateJobId))
			},
		}
	)
}

export const useDeleteJob = (candidateJobId: number) => {
	const { uid, token } = useCurrentUser()
	const queryClient = useQueryClient()

	return useMutation(
		() =>
			authenticatedRequest({ uid, token }, () =>
				fetch('DELETE', `/Candidates/${uid}/Jobs/${candidateJobId}`, token)
			),
		{
			onSettled: () => queryClient.invalidateQueries(getJobsKey(uid)),
		}
	)
}
