import { Btn, Icon } from '@merify/ui'
import { useNavigate } from '@reach/router'
import styled, { css } from 'styled-components'
export const backBtnStyles = css`
	button {
		color: ${props => props.theme.colors.secondary.val};
	}
`

const BackBtnView = styled.div`
	${backBtnStyles}
`

export type BackBtnProps = {}
export const BackBtn = (): JSX.Element => {
	const goTo = useNavigate()
	const handler = () => {
		goTo(-1)
	}
	return (
		<BackBtnView className='back-btn'>
			<Btn onClick={handler}>
				<Icon type='arrow' />
			</Btn>
		</BackBtnView>
	)
}
