import { Router as ReachRouter } from '@reach/router'
import { AuthedRoutes } from './Routes.authRoutes'
import { PublicRoutes } from './Routes.publicRoutes'

export type RouterProps = {}

export const Router = (): JSX.Element => {
	return (
		<ReachRouter>
			<AuthedRoutes path='/*' />

			<PublicRoutes path='/public/*' />
		</ReachRouter>
	)
}
