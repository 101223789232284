import { Btn, Icon, useModalCtrl } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useStoreState } from '../../../../store'
import { Avatar } from '../../../global/Avatar'
import { ImageUploader } from '../../../UI/ImageUploader'
import { ModalFull } from '../../../UI/Modal'

export const editCandidateProfilePicStyles = css`
	&& {
		align-items: flex-end;
		display: flex;

		i.icon {
			color: ${props => props.theme.colors.secondary.val};
			width: 25px;
		}
	}
`

const EditCandidateProfilePicView = styled(Btn)`
	${editCandidateProfilePicStyles}
`

export type EditCandidateProfilePicProps = {}

export const EditCandidateProfilePic = (): JSX.Element => {
	const [[showModal, openModal, closeModal]] = useModalCtrl()
	const user = useStoreState(state => state.candidate.user)

	return (
		<>
			<EditCandidateProfilePicView
				onClick={openModal}
				title='Upload/Edit pic'
				className='edit-candidate-profile-pic'
			>
				{/* @ts-ignore MIGRATION */}
				<Avatar user={user} size='l' /> <Icon type='pencil' />
			</EditCandidateProfilePicView>
			<ModalFull
				isOpen={showModal}
				closeHandler={closeModal}
				headerContent={user?.thumbnailImageUrl ? 'Edit Pic' : 'Upload Pic'}
			>
				<ImageUploader cb={closeModal} user={user} />
			</ModalFull>
		</>
	)
}
