import { Async, Btn, Card, CTA, Ctas, NotificationType, useModalCtrl } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetNotificationByIdAndType } from '../../../api/api.notifications'
import { useGetSkillsWithScores } from '../../../api/api.skills'
import { SkillAddSetLevel } from '../../global/Skill.add/Skill.add.setLevel'
import { ModalAlert } from '../../UI/Modal'
import { DeleteNotificationCta } from '../Notification.cta.delete'
import { DynamicNotificationParams } from '../Notification.detail'
import { NotificationInfo } from '../Notification.detail.info'
import { notificationStyles } from '../Notification.styles'

export const skillApprovedNotificationDetailStyles = css`
	${notificationStyles}
	.delete-notification-cta {
	}
`

const SkillApprovedNotificationDetailView = styled.div`
	${skillApprovedNotificationDetailStyles}
`

export type SkillApprovedNotificationDetailProps = DynamicNotificationParams
export const SkillApprovedNotificationDetail = ({
	id,
	cb,
}: SkillApprovedNotificationDetailProps): JSX.Element => {
	const type: NotificationType = 'skillApprovedNotifications'

	const results = useGetNotificationByIdAndType({
		notificationId: id,
		notificationType: type,
	})

	const notification = results.data
	const { skillId, skillName } = notification || {}

	const [[showAddSkill, openAddSkill, closeAddSkill]] = useModalCtrl()
	const skillsResults = useGetSkillsWithScores()
	const hasSkill = !!skillsResults.data?.find(skill => skill.skillId === skillId)

	return (
		<SkillApprovedNotificationDetailView>
			<Async fetchResults={results}>
				<Card hasGutter>
					{notification ? <NotificationInfo notification={notification} /> : null}
				</Card>
				<Ctas>
					<CTA size='sm'>
						<Btn onClick={() => openAddSkill()} disabled={hasSkill}>
							{hasSkill ? 'You have this skill' : 'Add Skill'}
						</Btn>
					</CTA>
					<DeleteNotificationCta notificationId={id} notificationType={type} cb={cb} />
				</Ctas>
			</Async>
			<ModalAlert isOpen={showAddSkill} closeHandler={closeAddSkill} titleTxt='Add Skill'>
				{skillId && skillName ? (
					<SkillAddSetLevel
						cb={closeAddSkill}
						cancel={closeAddSkill}
						skill={{ skillId, name: skillName }}
					/>
				) : null}
			</ModalAlert>
		</SkillApprovedNotificationDetailView>
	)
}
