import { Btn, calcScoreForDisplay, Circle, skillLevelsWithInfo } from '@merify/ui'
import styled, { css, useTheme } from 'styled-components'
import { RatedSkill } from '../Review.provider/useReviewProviderSkills'

export const reviewSkillStyles = css`
	width: 100%;

	button {
		background: none;
		width: 100%;
		border-bottom: 1px solid ${props => props.theme.colors.white.val};
		padding: 1rem ${props => props.theme.sizes.gutter.mobile.val};
		display: flex;
		align-items: center;
		text-align: left;
		font-size: 1.5rem;
		font-weight: 400;

		&:first-child {
			border-top: 1px solid ${props => props.theme.colors.white.val};
		}

		&:disabled {
			border-color: ${props => props.theme.colors.midNeutral.val} !important;
			color: ${props => props.theme.colors.midNeutral.val} !important;
			& > * {
				color: inherit !important;
			}
		}

		&:hover {
			background: ${props => props.theme.colors.primary.opacity(5).val};
		}

		.name {
			padding-right: 1em;
			color: ${props => props.theme.colors.primary.val};
		}
		.rating {
			margin-left: auto;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			.circle-ico {
				margin-left: 1em;
			}
		}
	}

	&.__skipped {
		button {
			.rating {
				color: ${props => props.theme.colors.midNeutral.val};
			}
		}
	}
`

const ReviewSkillView = styled.div`
	${reviewSkillStyles}
`

export type ReviewSkillProps = {
	skill: RatedSkill
	onClick?: () => void
	disabled?: boolean
}
export const ReviewSkill = ({ skill, onClick, disabled }: ReviewSkillProps): JSX.Element => {
	const { skillName, levelId, rating, skipped } = skill
	const levelInfo = skillLevelsWithInfo.find(l => l.levelId === levelId)
	const { colors } = useTheme()

	const isDisabled = typeof disabled === 'boolean' ? disabled : !onClick

	return (
		<ReviewSkillView className={`skill${skipped ? ' __skipped' : ''}`}>
			<Btn disabled={isDisabled} onClick={onClick}>
				<span className='name'>{skillName}</span>
				<span className='rating' style={{ color: levelInfo?.color || undefined }}>
					{levelInfo?.name || (skipped ? 'Skipped' : null)}
					<Circle
						isSolid={!!rating}
						value={rating ? calcScoreForDisplay(rating) : 0}
						color={levelInfo?.color || colors.midNeutral.val}
					/>
				</span>
			</Btn>
		</ReviewSkillView>
	)
}
