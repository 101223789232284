import React, {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'
import { useWindow } from '../Window.provider'
import { AppDrawerTray, AppDrawerTrayProps } from './Drawer.app.tray'

/*
 =================================================
  PROVIDER STATE
 =================================================
* */

export type AppDrawerDisplayType = 'one-col' | 'two-col'

type AppDrawerState = {
	isOpen: boolean
	content: ReactNode
	placeholderContent: ReactNode
	displayType: AppDrawerDisplayType
	renderDrawerInOverlay: boolean
	renderDrawerInCol: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	setContent: (Component: ReactNode) => void
	setPlaceholder: (Component: ReactNode) => void
	setDisplayType: (displayType: AppDrawerDisplayType) => void
	clear: () => void
}

const initialState: AppDrawerState = {
	isOpen: false,
	content: <></>,
	placeholderContent: <></>,
	displayType: 'one-col',
	renderDrawerInOverlay: true,
	renderDrawerInCol: false,
	setOpen: () => {},
	setContent: () => {},
	setPlaceholder: () => {},
	setDisplayType: () => {},
	clear: () => {},
}

const appDrawerContext = createContext(initialState)

const Provider = appDrawerContext.Provider

/*
 =================================================
  PROVIDER HOOK
 =================================================
* */
export const useAppDrawer = () => {
	const {
		isOpen,
		placeholderContent,
		content,
		displayType,
		setDisplayType,
		renderDrawerInCol,
		renderDrawerInOverlay,
		clear,
	} = useContext(appDrawerContext)

	return {
		isAppDrawerOpen: isOpen,
		placeholder: placeholderContent,
		drawerContent: content,
		displayType,
		renderDrawerInCol,
		renderDrawerInOverlay,
		setDisplayType,
		clear,
	}
}

/*
 =================================================
  APP DRAWER PROVIDER
 =================================================
* */

type AppDrawerProviderProps = {
	children?: ReactNode
}
export const AppDrawerProvider = ({ children }: AppDrawerProviderProps) => {
	const [isOpen, setOpen] = useState(false)
	const [content, setContent] = useState<ReactNode>(<></>)
	const [placeholderContent, setPlaceholder] = useState<ReactNode>(<></>)
	const [displayType, setDisplayType] = useState<AppDrawerDisplayType>('one-col')
	const { isMobile, isTablet, isSdesk, isLdesk } = useWindow()

	const clear = useCallback(() => {
		setOpen(false)
		setContent(<></>)
	}, [])

	return (
		<Provider
			value={{
				isOpen,
				content,
				placeholderContent,
				displayType,
				renderDrawerInOverlay: isMobile || isTablet,
				renderDrawerInCol: isSdesk || isLdesk,
				setOpen,
				setContent,
				setPlaceholder,
				setDisplayType,
				clear,
			}}
		>
			{children}
		</Provider>
	)
}

/*
 =================================================
  DRAWER
  NOTE: This provides a JSX API in place of using the hook
  WARNING: Never use this and the hook in the same component
 =================================================
* */
export type AppDrawerProps = {
	isOpen: boolean
	closeHandler: AppDrawerTrayProps['closeHandler']
	children?: ReactNode
	placeholder?: ReactNode
	displayType?: AppDrawerDisplayType
}
export const AppDrawer = ({
	children,
	closeHandler,
	isOpen,
	displayType,
	placeholder,
}: AppDrawerProps): JSX.Element => {
	const { setContent, setOpen, setPlaceholder, setDisplayType } = useContext(appDrawerContext)

	useEffect(() => {
		setOpen(isOpen)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	useEffect(() => {
		if (children)
			setContent(
				<AppDrawerTray closeOverride={() => setOpen(false)} closeHandler={closeHandler}>
					{children}
				</AppDrawerTray>
			)
		// else setContent(<></>)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [children])

	useEffect(() => {
		if (placeholder) setPlaceholder(placeholder)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [placeholder])

	useEffect(() => {
		if (displayType) setDisplayType(displayType)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayType])

	return <></>
}
