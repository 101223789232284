import {
	calcScoreForDisplay,
	Circle,
	getRatingInfoFromScore,
	getSkillLevelFromId,
	Heading,
	SkillLevelId,
	skillLevelsWithInfo,
	skillRatingWithInfo,
	Text,
} from '@merify/ui'
import styled, { css } from 'styled-components'

export const ratingsGuideContentStyles = css`
	padding: ${props => props.theme.sizes.gutter.mobile.val};
	.level,
	.score {
		display: flex;
		align-items: center;
		margin: 5px 0;
		padding-left: 5px;
	}
	.scores {
		.score-info {
			display: flex;
			align-items: center;
			.circle {
				margin-right: 0.5em;
			}
		}
	}

	.level {
		&.__active {
			color: ${props => props.theme.colors.white.val};
			.level-info .level-description {
				color: inherit;
			}
		}
		.level-info {
			color: inherit;
			.level-description {
				color: ${props => props.theme.colors.text.val};
			}
		}
	}
`

const RatingsGuideContentView = styled.div`
	${ratingsGuideContentStyles}
`

type RatingsGuideContentProps = {
	levelId?: SkillLevelId
	score?: number
}
export const RatingsGuideContent = ({ levelId, score }: RatingsGuideContentProps): JSX.Element => {
	const levelInfo = getSkillLevelFromId(levelId)
	const scoreInfo = getRatingInfoFromScore(score)

	const hasRating = !!levelId && !!score

	const currentScore = getRatingInfoFromScore(score)

	return (
		<RatingsGuideContentView>
			<div className='scores'>
				<Heading hTag='h3' displayType='dialog-heading' color='text' mBot mTop>
					Scoring explanation:
				</Heading>
				{skillRatingWithInfo.map(s => {
					const isCurrentScore = !hasRating ? false : currentScore?.rating === s.rating
					return (
						<div
							key={s.rating}
							className={`score score-${s.rating}${
								hasRating && scoreInfo?.rating === s.rating ? ' __active' : ''
							}`}
						>
							<div
								className='score-info'
								style={{ color: isCurrentScore ? levelInfo?.color : 'inherit' }}
							>
								<Circle
									value={isCurrentScore ? calcScoreForDisplay(score) : s.rating}
									isSolid={isCurrentScore}
									color={levelInfo?.color}
								/>
								<Text size='small'>{s.description}</Text>
							</div>
						</div>
					)
				})}
			</div>
			<div className='levels'>
				<Heading hTag='h3' displayType='dialog-heading' color='text' mBot mTop>
					Level explanation:
				</Heading>
				{skillLevelsWithInfo.map(l => {
					const isCurrentLevel = levelId === l.levelId
					return (
						<div
							key={l.levelId}
							className={`level level-${l.levelId}${isCurrentLevel ? ' __active' : ''}`}
							style={{
								background: isCurrentLevel ? l.color : 'none',
								color: isCurrentLevel ? '' : l.color,
							}}
						>
							<div className='level-info'>
								<Text textType='bold' className='level-name' mTop>
									{l.name}
								</Text>
								<Text textType='p' className='level-description' size='small'>
									{l.shortDesc}
								</Text>
							</div>
						</div>
					)
				})}
			</div>
		</RatingsGuideContentView>
	)
}
