import { Avatar, AvatarProps, Text, formatUTC, parseNameFromUser } from '@merify/ui'
import styled, { css } from 'styled-components'
import { ReviewAndKudosJob } from '../Review/Review.job.link'

export const kudosLinkedJobStyles = css`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1em 1.3em;
	border: solid 2px ${props => props.theme.colors.primary.val};
	border-radius: 15px;
	z-index: 1;
	width: 100%;

	.job {
		width: 100%;

		.top {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 1em;

			.name {
				margin-left: 5px;
				font-weight: bold;
				color: ${props => props.theme.colors.primary.val};
			}
		}

		.bottom {
			text-align: left;
			margin-left: 1em;
			* {
				color: ${props => props.theme.colors.primary.val};
			}
		}
	}
`

const KudosLinkedJobView = styled.div`
	${kudosLinkedJobStyles}
`

export type KudosLinkedJobProps = {
	user: AvatarProps['user']
	job: ReviewAndKudosJob
}
export const KudosLinkedJob = ({ user, job }: KudosLinkedJobProps): JSX.Element => {
	return (
		<KudosLinkedJobView className='linked-job'>
			<div className='job'>
				<div className='top'>
					<Avatar user={user} />
					<Text className='name'>{parseNameFromUser(user)}</Text>
				</div>
				<div className='bottom'>
					<div className='info'>
						<Text className='job-title'>{job.jobTitle}</Text>
						<br />
						<Text className='employer'>
							{job.employer} {job.projectName ? ` - ${job.projectName}` : null}
						</Text>
						<div className='dates'>
							<Text>
								{job.endDate
									? `${formatUTC(job.startDate)} - ${formatUTC(job.endDate)}`
									: `${formatUTC(job.startDate)} - Present`}
							</Text>
						</div>
					</div>
				</div>
			</div>
		</KudosLinkedJobView>
	)
}
