import { Spinner } from '@merify/ui'
import { SwipeableList, SwipeableListProps, Type as ListType } from 'react-swipeable-list'
import 'react-swipeable-list/dist/styles.css'
import styled, { css } from 'styled-components'
import { SwipeListItem, SwipeListItemActionCb, SwipeListItemProps } from './SwipeList.item'

export const swipeListStyles = css`
	.swipe-action {
		background: ${props => props.theme.colors.grey.light().val};
	}
	.swipeable-list-item {
		border-top: 1px solid ${props => props.theme.colors.paleGrey.val};
	}

	.swipeable-list-item__trailing-actions--return {
		/* width: auto !important; */
	}
	.swipe-list-clickable-item {
		box-shadow: none;
		outline: none;
		border: none;
		padding: 0;
	}
`

const SwipeListView = styled.div`
	${swipeListStyles}
`

export type SwipeListItemType = Omit<SwipeListItemProps, 'val'> & {
	key?: string | number
	val?: any
}

export type SwipeListProps = {
	items?: SwipeListItemType[]
	isLoading?: boolean
	deleteHandler?: SwipeListItemActionCb
	handlerTrigger?: 'click' | 'swipe'
}
export const SwipeList = ({
	isLoading,
	items,
	deleteHandler,
	handlerTrigger,
}: SwipeListProps): JSX.Element => {
	const swipeListOptions: Omit<SwipeableListProps, 'children'> = {
		threshold: 0.5,
	}

	if (handlerTrigger === 'click') swipeListOptions.type = ListType.IOS

	return (
		<SwipeListView className='swipe-list'>
			{isLoading ? (
				<Spinner />
			) : items?.length ? (
				<SwipeableList {...swipeListOptions}>
					{items.map((item, idx) => {
						const itemProps = {
							...item,
							key: item.key || item.val,
						}
						if (typeof itemProps.key !== 'string' && typeof itemProps.key !== 'number')
							itemProps.key = idx

						if (!item.trailing && deleteHandler) {
							itemProps.trailing = {
								cb: deleteHandler,
							}
							itemProps.isDeletion = true
						}

						return <SwipeListItem {...itemProps} val={item.val || idx} />
					})}
				</SwipeableList>
			) : null}
		</SwipeListView>
	)
}
