import { Btn, CTA, Heading, MerifyIrmaDynamicResumeSvg, Text, WizardStepProps } from '@merify/ui'
import styled, { css } from 'styled-components'
import { SmallCtas } from '../../UI/SmallCtas'
import { welcomeScreenStepStyles } from './Welcome.screen.step.styles'

export const welcomeScreenStepTwoStyles = css`
	${welcomeScreenStepStyles}
`

const WelcomeScreenStepTwoView = styled.div`
	${welcomeScreenStepTwoStyles}
`

export type WelcomeScreenStepTwoProps = {}
export const WelcomeScreenStepTwo = ({ ...stepProps }: WelcomeScreenStepTwoProps): JSX.Element => {
	const { previousStep, nextStep, isActive } = stepProps as WizardStepProps
	return (
		<WelcomeScreenStepTwoView className={`step step-2 ${isActive ? ' __active' : ''}`}>
			<div className='inner-wrap'>
				<div className='content'>
					<MerifyIrmaDynamicResumeSvg />
					<div className='text'>
						<Heading hTag='h2' displayType='screen-heading' dir='center'>
							Merify Dynamic Resume™
						</Heading>
						<Text size='med' color='secondary' dir='center' mTop>
							Your skill-based work history is constantly updating as we learn more from you and
							your professional community.
						</Text>
					</div>
					<SmallCtas>
						<CTA size='sm'>
							<Btn onClick={previousStep}>Back</Btn>
						</CTA>
						<CTA size='sm'>
							<Btn onClick={nextStep}>Continue</Btn>
						</CTA>
					</SmallCtas>
				</div>
			</div>
		</WelcomeScreenStepTwoView>
	)
}
