import { Router as ReachRouter, RouteComponentProps } from '@reach/router'
import { PublicLayout } from '../components/layout/Layout.public'
import { PublicCandidateProfileScreen } from '../components/screens/candidates/public.candidate.profile.screen/Public.candidate.profile.screen'
import { PublicReviewScreen } from '../components/screens/reviews/Review.public.screen/Review.public.screen'
import { Page404 } from './Route.404'

export type PublicRoutesProps = RouteComponentProps & {}
export const PublicRoutes = (_: PublicRoutesProps): JSX.Element => {
	return (
		<ReachRouter>
			<PublicLayout path='/candidates'>
				<PublicCandidateProfileScreen path='/:token' />
			</PublicLayout>
			<PublicLayout path='/reviews'>
				<PublicReviewScreen path='/' />
				<Page404 default />
			</PublicLayout>
		</ReachRouter>
	)
}
