import { CTA, Heading, Wizard } from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetReviewRequest, useIgnoreReview, usePostReview } from '../../../api/api.reviews'
import { Link } from '../../UI/Link'
import { ReviewProvider } from '../Review.provider/Review.provider'
import { ReviewProviderSuccess } from '../Review.provider/Review.provider.success'
import { ReviewSelectJob } from './Review.selectJob'
import { ReviewSkills } from './Review.skills'

export const reviewStyles = css``

const PrivateReviewView = styled.div`
	${reviewStyles}
`

export type PrivateReviewProps = {
	reviewId: number
	cancel: () => void
	isRoute?: boolean
}
export const PrivateReview = ({ reviewId, cancel, isRoute }: PrivateReviewProps): JSX.Element => {
	const results = useGetReviewRequest(reviewId)

	const {
		mutateAsync: reviewMut,
		isLoading: isSubmitting,
		error: submitError,
	} = usePostReview(reviewId)

	const {
		mutateAsync: ignoreMut,
		isLoading: ignoreLoading,
		error: ignoreError,
	} = useIgnoreReview(reviewId)

	const isUpdate = !!results.data?.existingReview?.needsUpdate
	const includeJobSelect = !isUpdate

	const isPreviouslyCompleted =
		!isUpdate &&
		!!results?.data?.jobSkills.every(({ hasExistingSkillReview }) => hasExistingSkillReview)

	return (
		<PrivateReviewView>
			{isRoute && isPreviouslyCompleted ? (
				<ReviewProviderSuccess
					previouslyCompleted
					cta={
						<CTA color='white'>
							<Link to='/reviews'>Go to incoming reviews</Link>
						</CTA>
					}
				/>
			) : (
				<>
					<Heading mBot mTop dir='center' displayType='dialog-heading'>
						{isUpdate ? 'Review new skills' : 'Respond to a Review Request'}
					</Heading>
					<ReviewProvider
						reviewFetchRequest={results}
						reviewMut={reviewMut}
						mutLoading={isSubmitting || ignoreLoading}
						mutError={submitError || ignoreError}
						ignoreMut={ignoreMut}
						cancel={cancel}
					>
						{includeJobSelect ? (
							<Wizard isLazyMount>
								<ReviewSelectJob />
								<ReviewSkills />
							</Wizard>
						) : (
							<ReviewSkills />
						)}
					</ReviewProvider>
				</>
			)}
		</PrivateReviewView>
	)
}
