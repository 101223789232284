import { Btn, CTA, ErrorBlock, Heading, MerifyIrma404Svg, Spinner } from '@merify/ui'
import React from 'react'
import styled, { css } from 'styled-components'
import { useStoreState } from '../../store'

const AppLevelErrorStyles = css`
	background: ${p => p.theme.colors.white.val};
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	& > .app-error-content {
		border: solid 1px ${p => p.theme.colors.darkNeutral.val};
		border-radius: 5px;

		& > .top {
			padding: 1rem;

			${p => p.theme.media.tablet} {
				padding: 2rem;
			}

			${p => p.theme.media.sdesk} {
				padding: 4rem;
			}

			& > p {
				font-size: 1.5rem;
			}
		}

		& > .error-details {
			padding: 1rem;
			background: ${p => p.theme.colors.midNeutral.val};

			${p => p.theme.media.tablet} {
				padding: 2rem;
			}

			${p => p.theme.media.sdesk} {
				padding: 4rem;
			}

			.error-block {
				padding: 0;
				.simple {
					background: ${p => p.theme.colors.white.val};
				}
			}
		}
	}

	.error-detail-heading {
		color: ${p => p.theme.colors.darkNeutral.val};
		margin-bottom: 0.5rem;
	}
`

const AppLevelErrorView = styled.div`
	${AppLevelErrorStyles}
`

type AppLevelErrorProps = {
	className?: string
	children?: React.ReactNode
}
export const AppLevelError = ({ className, children }: AppLevelErrorProps): JSX.Element => {
	const appError = useStoreState(state => state.view.appError)

	const [showErr, setShowErr] = React.useState(false)

	React.useEffect(() => {
		if (appError) {
			setTimeout(() => {
				setShowErr(true)
			}, 5000)
		}
	}, [showErr, appError])

	if (!appError) return <>{children}</>
	return (
		<AppLevelErrorView className={`app-level-error${className ? ` ${className}` : ''}`}>
			{!showErr ? <Spinner type='circle' height='lg' /> : null}

			<div className='app-error-content'>
				<div className='top'>
					<Heading displayType='screen-heading' mBot dir='center'>
						Uh oh...
					</Heading>
					<p>The system is currently experiencing problem.</p>
					<p>If the issue persists please contact an admin.</p>
					<MerifyIrma404Svg />
					<CTA>
						<Btn onClick={() => window.location.reload()}>Refresh</Btn>
					</CTA>
				</div>
				<div className='error-details'>
					<Heading displayType='block-heading' className='error-detail-heading'>
						Error info:
					</Heading>
					<ErrorBlock error={appError} showErr display='simple' />
				</div>
			</div>
		</AppLevelErrorView>
	)
}
