import {
	Async,
	calcScoreForDisplay,
	Circle,
	formatUTC,
	parseNameFromUser,
	ReviewJob,
	skillLevelsWithInfo,
	Text,
	UserIntersect,
} from '@merify/ui'
import styled, { css } from 'styled-components'
import { useGetSkillHistory } from '../../../api/api.skills'
import { Avatar } from '../Avatar'

export const reviewSkillHistoryStyles = css`
	.history-item {
		padding: 0.5em ${props => props.theme.sizes.gutter.mobile.val};
		display: flex;
		align-items: center;
		border-bottom: solid 1px ${props => props.theme.colors.paleGrey.val};

		&:last-child {
			border-bottom: none;
		}

		& > * {
			margin-right: 1em;
			&:last-child {
				margin-right: 0;
			}
		}
		.info {
			p {
				margin: 0;
			}
		}
		.skill-level-name {
			margin-left: auto;
		}
		.circle {
		}
	}
	.no-history-items {
		display: block;
		padding: 2em ${props => props.theme.sizes.gutter.mobile.val};
	}
`

const ReviewSkillHistoryView = styled.div`
	${reviewSkillHistoryStyles}
`

export type ReviewSkillHistoryProps = {
	skillId: number
}
export const ReviewSkillHistory = ({ skillId }: ReviewSkillHistoryProps): JSX.Element => {
	const results = useGetSkillHistory(skillId)
	const { data } = results
	return (
		<ReviewSkillHistoryView>
			<Async fetchResults={results}>
				{data?.length ? (
					data.map(({ person, skillReviewData }) => {
						const { jobTitle, employer } = {} as ReviewJob //TODO: this should come in along with 'person' once job has been added
						const { reviewEffectiveDate, rating, ratedAtSkillLevel, skillReviewId, reviewId } =
							skillReviewData
						const { color, name: levelName } =
							skillLevelsWithInfo.find(({ levelId }) => levelId === ratedAtSkillLevel) || {}
						return (
							<div className='history-item' key={`${skillReviewId}${reviewId}${rating}`}>
								{/* @ts-ignore MIGRATION */}
								<Avatar size='sm' user={person as UserIntersect} />
								<div className='info'>
									<Text textType='bold'>{parseNameFromUser(person as UserIntersect)}</Text>
									{jobTitle ? <Text>{jobTitle}</Text> : null}
									{employer ? <Text>{employer}</Text> : null}
									<Text>Reviewed On: {formatUTC(reviewEffectiveDate)}</Text>
								</div>
								<Text color={color} className='skill-level-name'>
									{levelName}
								</Text>
								<Circle className='circle' value={calcScoreForDisplay(rating)} color={color} />
							</div>
						)
					})
				) : (
					<Text textType='italic' className='no-history-items'>
						This is your first time reviewing this skill.
					</Text>
				)}
			</Async>
		</ReviewSkillHistoryView>
	)
}
