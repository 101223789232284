import { Btn, Icon } from '@merify/ui'
import { ReactNode, useEffect } from 'react'
import styled, { css } from 'styled-components'

export const appDrawerTrayStyles = css`
	height: 100%;
	margin: 0 auto;
	overflow-x: hidden;
	overflow-y: auto;

	${props => props.theme.media.tablet} {
		scrollbar-gutter: stable;
	}

	${props => props.theme.media.sdesk} {
		width: 100%;
		padding-right: 2rem;
	}

	.inner-wrap {
		${props => props.theme.media.sdesk} {
			background: ${props => props.theme.colors.offWhite.val};
			padding: 2rem 0;
			width: 100%;
		}

		& > header {
			padding: 0.5em 2em;
			background: ${props => props.theme.colors.midNeutral.opacity(40).val};

			${props => props.theme.media.sdesk} {
				display: none;
			}

			button {
				color: ${props => props.theme.colors.primary.val};
			}
		}
	}
`

const AppDrawerTrayView = styled.div`
	${appDrawerTrayStyles}
`

export type AppDrawerTrayProps = {
	closeHandler: () => void
	closeOverride: () => void
	children?: ReactNode
}
export const AppDrawerTray = ({
	closeHandler,
	closeOverride,
	children,
}: AppDrawerTrayProps): JSX.Element => {
	useEffect(() => {
		// Handles case where user has navigated to a new page while the app drawer is still open
		return () => {
			closeOverride()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<AppDrawerTrayView className='app-drawer-tray'>
			<div className='inner-wrap'>
				<header>
					<Btn onClick={closeHandler}>
						<Icon type='arrowLeft' />
					</Btn>
				</header>
				<main>{children}</main>
			</div>
		</AppDrawerTrayView>
	)
}
