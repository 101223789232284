import {
	Accordion,
	Async,
	Btn,
	Card,
	CTA,
	ErrorBlock,
	Heading,
	Spinner,
	Text,
	ToggleField,
	useModalCtrl,
	useState,
} from '@merify/ui'
import { Redirect } from '@reach/router'
import { useEffect } from 'react'
import styled, { css } from 'styled-components'
import {
	useDeleteSkill,
	useGetSkillWithScores,
	useSetSkillVisibility,
} from '../../../api/api.skills'
import { Page404Redirect } from '../../screens/Page404.redirect'
import { Container } from '../../UI/Container'
import { ModalAlert } from '../../UI/Modal'
import { BackBtn } from '../Back.btn/Back.btn'
import { SkillJobs } from './Skill.jobs'
import { SkillScore } from './Skill.score'

export const skillStyles = css`
	.skill-header {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px ${props => props.theme.sizes.gutter.mobile.num}px;

		.back-btn {
			position: absolute;
			top: 50%;
			left: ${props => props.theme.sizes.gutter.mobile.val};
			transform: translateY(-50%);
		}

		h4 {
			text-align: center;
			font-size: 1.5rem;
		}
	}
	.public-toggle {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 1em;
	}
	.vetting {
		padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
		margin-bottom: 2em;
	}
	.circle {
		.ratings-guide-btn {
			position: absolute;
			right: 0;
			bottom: 50px;
			z-index: 10;
		}
	}
	.jobs-section,
	.reviews-section {
		//padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
		margin: 20px 0;
		h6 {
			font-size: 1.3rem;
		}
	}

	.jobs-section {
		.ctrl {
			margin: 20px 0;
			padding: 0 ${props => props.theme.sizes.gutter.mobile.val};
		}
		.ctrl-group {
			.btn {
				padding-left: ${props => props.theme.sizes.gutter.mobile.val};
				.job-info {
					margin-left: 0;
				}
			}
		}
	}
`

const SkillView = styled.div`
	${skillStyles}
`

export type SkillProps = {
	candidateSkillId: number
	hideBackBtn?: boolean
	hideDeleteBtn?: boolean
	hideLinkedJobs?: boolean
	onAfterDelete?: () => void
}
export const Skill = ({
	candidateSkillId,
	hideBackBtn,
	hideDeleteBtn,
	hideLinkedJobs,
	onAfterDelete,
}: SkillProps): JSX.Element => {
	const results = useGetSkillWithScores(candidateSkillId)

	const { skillName, visible } = results.data || {}
	const [[showDeleteModal, openDeleteModal, closeDeleteModal]] = useModalCtrl()
	const setVisibility = useSetSkillVisibility(candidateSkillId)
	const deleteSkillResults = useDeleteSkill(candidateSkillId)

	const [isPublic, setPublic] = useState(visible || false, 'isPublic')
	useEffect(() => {
		if (typeof visible === 'boolean' && !deleteSkillResults.isSuccess) setPublic(visible)
	}, [visible, deleteSkillResults.isSuccess])
	const [[showToggleAlert, openToggleAlert, closeToggleAlert]] = useModalCtrl()

	const handleTogglePublic = (setToPublic: boolean) => {
		try {
			setPublic(setToPublic)
			if (!setToPublic) openToggleAlert()
			else setVisibility.mutateAsync(true)
		} catch (err) {
			setPublic(!setToPublic)
		}
	}

	const invalidSkillId = !results.isLoading && !results.error && !results.data

	if (invalidSkillId) return <Page404Redirect />

	return !onAfterDelete && deleteSkillResults.isSuccess ? (
		<Redirect to='/skills' noThrow />
	) : (
		<SkillView>
			{deleteSkillResults.isLoading ? <Spinner /> : null}
			<Async fetchResults={results} Page404={Page404Redirect}>
				<Container isStickyFooter stickyOffsets={['footer']}>
					<header className={`skill-header${results.data?.visible === true ? '' : ' __private'}`}>
						{hideBackBtn ? null : <BackBtn />}
						<Heading hTag='h4' displayType='block-heading'>
							{skillName}
						</Heading>
					</header>
					<section className='public-toggle'>
						<ToggleField
							isOn={isPublic}
							label={['PRIVATE', 'PUBLIC']}
							onChange={handleTogglePublic}
						/>
					</section>
					<ErrorBlock error={setVisibility.error} display='inline' hideCta />

					<section className='score-section'>
						<Card>{results.data ? <SkillScore skill={results.data} /> : null}</Card>
					</section>
					{hideLinkedJobs ? null : (
						<section className='jobs-section'>
							<Accordion ctrlIcon='link' ctrl='Linked Jobs'>
								<Card>
									<SkillJobs candidateSkillId={candidateSkillId} />
								</Card>
							</Accordion>
						</section>
					)}
					{hideDeleteBtn ? null : (
						<section className='btns'>
							<ErrorBlock error={deleteSkillResults.error} display='inline' />
							<CTA color='err' displayType='no-border'>
								<Btn onClick={openDeleteModal}>Delete Skill</Btn>
							</CTA>
						</section>
					)}
				</Container>
			</Async>
			<ModalAlert
				isOpen={showToggleAlert}
				closeHandler={confirm => {
					if (!confirm) setPublic(true)
					closeToggleAlert()
				}}
				asyncConfirmHandler={async () => setVisibility.mutateAsync(false)}
				showAlertBtns
			>
				<Heading hTag='h5' displayType='block-heading' color='text' dir='center' mBot>
					Are you sure you want to make this skill private?
				</Heading>
				<Text size='normal' textType='p' dir='center'>
					By making this skill private, it will not be visible to anyone other than you.
				</Text>
				{/* <Text size='normal' textType='p' dir='center'>
					NOTE: Your vetting status will not be publicly visible unless the skill is fully vetted.
				</Text> */}
			</ModalAlert>
			<ModalAlert
				isOpen={showDeleteModal}
				confirmTxt='YES'
				cancelTxt='NO'
				defaultBtn='cancel'
				showAlertBtns
				reverseBtns
				canClickBg
				asyncConfirmHandler={async () => {
					try {
						await deleteSkillResults.mutateAsync()
						if (onAfterDelete) {
							onAfterDelete()
							closeDeleteModal()
						}
					} catch (err) {}
				}}
				closeHandler={shouldDelete => (shouldDelete ? undefined : closeDeleteModal())}
			>
				<Heading hTag='h5' displayType='block-heading' color='text' dir='center' mBot>
					Are you sure you want to delete this skill?
				</Heading>
				<Text size='med' textType='p' dir='center'>
					Review data of this skill WILL remain in the system, but will no longer be displayed in
					your profile.
				</Text>
				<Text size='med' textType='p' dir='center'>
					Would you still like to delete this skill?
				</Text>
			</ModalAlert>
		</SkillView>
	)
}
