import { Btn, Icon, useModalCtrl } from '@merify/ui'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { CandidateProfileEditForm } from '../../screens/candidates/candidate.profile.screen/Candidate.profile.edit.form'
import { ModalFull } from '../../UI/Modal'

export const userMenuEditProfileStyles = css``

const UserMenuEditProfileView = styled(Btn)`
	${userMenuEditProfileStyles}
`

export type UserMenuEditProfileProps = {
	children?: ReactNode
}
export const UserMenuEditProfile = ({ children }: UserMenuEditProfileProps): JSX.Element => {
	const [[showModal, openModal, closeModal]] = useModalCtrl()

	return (
		<>
			<UserMenuEditProfileView onClick={openModal}>
				<Icon type='pencil' /> {children}
			</UserMenuEditProfileView>
			<ModalFull isOpen={showModal} closeHandler={closeModal} headerContent='Edit Profile'>
				<CandidateProfileEditForm cb={closeModal} />
			</ModalFull>
		</>
	)
}
