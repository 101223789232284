import styled, { css } from 'styled-components'
import { SwipeList, SwipeListProps } from '../../UI/SwipeList'
import { EmailListItem } from './Email.list.item'

export const emailListStyles = css``

const EmailListView = styled.div`
	${emailListStyles}
`

export type EmailListProps = {
	emails?: string[]
	onDelete?: (email: string) => void
	className?: string
}
export const EmailList = ({ emails, onDelete, className }: EmailListProps): JSX.Element => {
	const swipeProps: SwipeListProps = {
		handlerTrigger: 'swipe',
		items: emails?.map(email => ({
			content: (
				<EmailListItem key={email} email={email} onDelete={onDelete && (() => onDelete(email))} />
			),
			key: email,
			val: email,
		})),
	}

	if (onDelete) swipeProps.deleteHandler = onDelete

	return (
		<EmailListView className={`email-list${className ? ` ${className}` : ''}`}>
			{onDelete ? (
				<SwipeList {...swipeProps} />
			) : (
				<ul>{emails?.length ? emails.map(email => <EmailListItem email={email} />) : null}</ul>
			)}
		</EmailListView>
	)
}
