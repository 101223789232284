// Stores and provides oidc state data for the app

import { RouteComponentProps } from '@reach/router'
import { ReactNode, useEffect } from 'react'
import {
	AuthProvider as OIDCProvider,
	AuthProviderProps as OIDCProviderProps,
} from 'react-oidc-context'
import { useStoreActions } from '../../store'
import { storeInitialRedirect } from '../../utils/auth.utils'
import { AuthOidcUser } from './Auth.oidc.user'
import { AuthUser } from './Auth.user'
import { AuthWelcome } from './Auth.welcome'

const LOGOUT_REDIRECT_URI = process.env.REACT_APP_OIDC_LOGOUT_REDIRECT

export const oidcConfig: OIDCProviderProps = {
	authority: process.env.REACT_APP_OIDC_AUTHORITY as string,
	client_id: process.env.REACT_APP_OIDC_CLIENT_ID as string,
	redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI as string,
	scope: process.env.REACT_APP_OIDC_SCOPE,
	loadUserInfo: true, // Loads the candidate/person info into the returned identity user data
	automaticSilentRenew: true, // NOTE: should be true by default
}

// NOTE: This does not currently appear to work - perhaps the redirect is hard coded in the identity server?
// TODO: Remove if no plans for support are in roadmap
if (LOGOUT_REDIRECT_URI) oidcConfig.post_logout_redirect_uri = LOGOUT_REDIRECT_URI

export type AuthProviderProps = RouteComponentProps & {
	children?: ReactNode
}
export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
	const clearUser = useStoreActions(actions => actions.candidate.clearUser)

	useEffect(() => {
		storeInitialRedirect()
	}, [])

	const oidcSettings: OIDCProviderProps = {
		...oidcConfig,
		onRemoveUser: () => {
			clearUser()
		},
	}

	return (
		<OIDCProvider {...oidcSettings}>
			<AuthOidcUser>
				<AuthUser>
					<AuthWelcome>{children}</AuthWelcome>
				</AuthUser>
			</AuthOidcUser>
		</OIDCProvider>
	)
}
