import { Async, CandidateSkillList } from '@merify/ui'
import { RouteComponentProps } from '@reach/router'
import { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useGetPublicCandidate } from '../../../../api/api.candidate'
import { useAppDrawer } from '../../../global/Drawer.app'
import { SkillsHeader } from '../../../global/Skills.header/Skills.header'
import { Page404Redirect } from '../../Page404.redirect'
import { CandidateProfileInfo } from '../candidate.profile.screen/Candidate.profile.info'

export const PublicCandidateProfileScreenStyles = css`
	.row {
		margin: 20px 0;
	}
	p {
		margin: 0;
		&.label {
			font-size: 0.8rem;
			color: ${props => props.theme.colors.grey.val};
			margin: 0;
			line-height: 15px;
		}
	}
	.cta-wrap {
		padding-top: 3em;
	}
`

const PublicCandidateProfileScreenView = styled.div`
	${PublicCandidateProfileScreenStyles}
`

type PublicCandidateProfileScreenProps = RouteComponentProps & {
	token?: string
}
export const PublicCandidateProfileScreen = ({
	token,
}: PublicCandidateProfileScreenProps): JSX.Element => {
	const results = useGetPublicCandidate({ token: token as string })

	const { candidate, skills } = results.data || {}

	const { setDisplayType } = useAppDrawer()
	useEffect(() => {
		setDisplayType('one-col')
		document.documentElement.classList.add('public')
		return () => {
			document.documentElement.classList.remove('public')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const error = results.error as any

	if (!results.data && error?.message.includes('400')) {
		return <Page404Redirect />
	}
	return (
		<PublicCandidateProfileScreenView>
			<Async fetchResults={results}>
				<CandidateProfileInfo view='public' candidateData={candidate} />
				<SkillsHeader>Skills</SkillsHeader>
				<CandidateSkillList skills={skills} error={results.error} isLoading={results.isLoading} />
			</Async>
		</PublicCandidateProfileScreenView>
	)
}
