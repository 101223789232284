import { Heading, SimpleSkill, Text } from '@merify/ui'
import styled, { css } from 'styled-components'

export const notificationDetailSkillsListStyles = css`
	ul {
		list-style-type: disc;
		margin-left: 1em;
		padding-left: 1em;

		li {
			display: list-item;
			margin: 0.3em 0;
		}
	}
`

const NotificationDetailSkillsListView = styled.div`
	${notificationDetailSkillsListStyles}
`

export type NotificationDetailSkillsListProps = {
	skills: SimpleSkill[]
	heading?: string
}
export const NotificationDetailSkillsList = ({
	skills,
	heading = 'Skills:',
}: NotificationDetailSkillsListProps): JSX.Element => {
	return (
		<NotificationDetailSkillsListView>
			<Heading>{heading}</Heading>
			<ul>
				{skills.map(({ skillId, skillName }) => (
					<li key={skillId}>
						<Text>{skillName}</Text>
					</li>
				))}
			</ul>
		</NotificationDetailSkillsListView>
	)
}
